import React from 'react';
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/styles";
import {Theme} from "@material-ui/core/styles";
import * as yup from "yup";
import {useFormik} from "formik";
import MerchantsClient from "../../../apiClient/MerchantsClient";
import {ICreateMerchant} from "../../../types/Merchant";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import AutocompleteInput from "../../shared/AutoCompleteStep";
import { useDispatch } from 'react-redux';
import { DispatchErrorSnackbar } from '../../../state/actions/UiActions';


const useStyles = makeStyles((theme: Theme) => ({
    container: {
        [theme.breakpoints.down("xs")]: {
            paddingLeft: "1.2rem",
            paddingRight: "1.2rem",

        },
        paddingLeft: "3rem",
        paddingRight: "3rem",
        paddingTop: "1rem",
        paddingBottom: "3rem",
    },

    divider: {
        marginTop: "5rem",
        marginBottom: "1rem"
    },
    sectionTitle: {
        textAlign: "start"
    },
    footer: {
        textAlign: "end"
    },
    input: {
        "& .MuiFormLabel-root": {
            left: "auto",
            right: "auto",
            transformOrigin: "top right",
            textAlign: "right",
        }
    }

}))

const validationSchema = yup.object({
    nameAr: yup
        .string()
        .min(3, " Minimum 3 Characters")
        .required(" is Required"),
    nameEn: yup
        .string()
        .min(3, " Minimum 3 Characters")
        .required(" is Required"),
    username: yup
        .string()
        .min(3, " Minimum 3 Characters")
        .required(" is Required"),
    password: yup
        .string()
        .max(20, " Maximum 20 Character").min(5, " Minimum 5 Characters")
        .required(" is Required"),
    phone: yup
        .string()
        .max(9, " Maximum 10 Character").min(9, " Minimum 10 Characters")
        .required(" is Required"),
    requestType: yup
        .string()
        .required(" is Required"),
});

const Component = () => {
    const classes = useStyles();
    const dispatch=useDispatch()
    const {t,i18n} = useTranslation("merchants");
    let labelStyle = i18n.language.slice(0, 2).toLowerCase() === "ar" ? true : false;
    const client = new MerchantsClient();
    let history = useHistory();
    const formik = useFormik({
        initialValues: {
            nameAr: '',
            nameEn: '',
            username: '',
            password: '',
            phone: '',
            requestType: '',
            commission:''
        },
        validationSchema: validationSchema,
        validateOnBlur: true,

        onSubmit: async (values, {resetForm}) => {
            let res = await client.Create(values as ICreateMerchant);
            if (res.succeeded) {
                history.push("/merchants")
            } else {
                dispatch(DispatchErrorSnackbar(res.data.REGISTRATION_FAILED))
            }
        },
    });


    return (
        <div className={classes.container}>
            <Paper className={classes.container}>

                <Typography variant="h6" gutterBottom>
                    {t("add")}
                </Typography>


                <Grid>

                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                            className={labelStyle ? classes.input : ''}
                                required
                                id="nameEn"
                                name="nameEn"
                                label={t("nameEn")}
                                fullWidth
                                value={formik.values.nameEn}
                                onChange={formik.handleChange}
                                helperText={(formik.touched.nameEn && formik.errors.nameEn &&t("nameEn")+ formik.errors.nameEn) || ""}
                                error={
                                    formik.touched.nameEn &&
                                    Boolean(formik.errors.nameEn)
                                }
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                            className={labelStyle ? classes.input : ''}
                                required
                                id="nameAr"
                                name="nameAr"
                                label={t("nameAr")}
                                fullWidth
                                value={formik.values.nameAr}
                                onChange={formik.handleChange}
                                helperText={(formik.touched.nameAr&& formik.errors.nameAr &&t("nameAr")+ formik.errors.nameAr) || ""}
                                error={
                                    formik.touched.nameAr &&
                                    Boolean(formik.errors.nameAr)
                                }
                            />
                        </Grid>

                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                            className={labelStyle ? classes.input : ''}
                                required
                                id="username"
                                name="username"
                                label={t("username")}
                                fullWidth
                                value={formik.values.username}
                                onChange={formik.handleChange}
                                helperText={(formik.touched.nameEn&& formik.errors.username &&t("username")+ formik.errors.username) || ""}
                                error={
                                    formik.touched.username &&
                                    Boolean(formik.errors.username)
                                }
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                            className={labelStyle ? classes.input : ''}
                                required
                                id="password"
                                name="password"
                                label={t("password")}
                                fullWidth
                                value={formik.values.password}
                                onChange={formik.handleChange}
                                helperText={(formik.touched.nameEn&& formik.errors.password &&t("password")+ formik.errors.password) || ""}
                                error={
                                    formik.touched.password &&
                                    Boolean(formik.errors.password)
                                }
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                            className={labelStyle ? classes.input : ''}
                                type={"number"}
                                required
                                id="mobile"
                                name="phone"
                                label={t("phone")}
                                fullWidth
                                value={formik.values.phone}
                                onChange={formik.handleChange}
                                helperText={(formik.touched.phone&& formik.errors.phone &&t("phone")+ formik.errors.phone) || ""}
                                error={
                                    formik.touched.phone &&
                                    Boolean(formik.errors.phone)
                                }
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <AutocompleteInput
                                options={[{value: "AutoLoan", label: "AutoLoan"}, {
                                    label: 'ProductLoan',
                                    value: "ProductLoan"
                                }]}
                                name={"requestType"}
                                value={formik.values.requestType}
                                onChange={(v:any) =>
                                    formik.setFieldValue("requestType", v)
                                }
                                isInvalid={
                                    formik.touched?.requestType &&
                                    Boolean(formik.errors.requestType)
                                }
                                validationError={formik.errors?.requestType}
                                onBlur={formik.handleBlur}
                                placeHolder={t('requestType')}
                                fullWidth
                                helperText={(formik.touched.requestType&& formik.errors.requestType &&t("requestType")+ formik.errors.requestType) || ""}
                                error={
                                    formik.touched.requestType &&
                                    Boolean(formik.errors.requestType)
                                }
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                            className={labelStyle ? classes.input : ''}
                                type={"number"}
                                required
                                id="commission"
                                name="commission"
                                label={t("commission")}
                                fullWidth
                                value={formik.values.commission}
                                onChange={formik.handleChange}
                                helperText={(formik.touched.commission&& formik.errors.commission &&t("commission")+ formik.errors.commission) || ""}
                                error={
                                    formik.touched.commission &&
                                    Boolean(formik.errors.commission)
                                }
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>

                        </Grid>
                    </Grid>

                </Grid>

                <Divider className={classes.divider}/>


                <Grid className={classes.footer}>
                    <Button onClick={formik.submitForm} variant="contained">{t("save")}</Button>

                </Grid>
            </Paper>
        </div>
    )
}
export default Component;
