import {ICreateSubmission, IDenySubmission, IStudySubmission, ISubmitOfferModel} from "../../types/Request";
import SubmissionsClient from "../../apiClient/submissionsClient";
import {DispatchErrorSnackbar, DispatchSuccessSnackbar, SetIsLoading} from "./UiActions";
import IAppStateModel from "../../types/AppState";

export const SubmitToLender = (model: ICreateSubmission,onFinish:()=>any) => {
    return async (dispatch: any, getState: any) => {
        dispatch(SetIsLoading(true))

        const submissionsClient = new SubmissionsClient();
        if ((!model.LenderId) && (!model.requestId)) {
            return
        }
        const state: IAppStateModel = getState()
        const lenderName = state.lookUpTables.lenders.find((i) => Number(i.value)===model.LenderId)

        const response = await submissionsClient.CreateSubmissionAsync(model);
        if (response.succeeded) {
            dispatch(DispatchSuccessSnackbar(`Request has been submitted to ${lenderName?.label} successfully`))
            onFinish();

        } else {
            dispatch(DispatchErrorSnackbar())

        }
        dispatch(SetIsLoading(false))

    };
}

export const AssignToBranch = (requestId:string,branchId:string,onFinish:()=>any) => {
    return async (dispatch: any, getState: any) => {
        dispatch(SetIsLoading(true))

        const submissionsClient = new SubmissionsClient();
        const response = await submissionsClient.AssignToBranchAsync(requestId,branchId);
        if (response.succeeded) {
            dispatch(DispatchSuccessSnackbar(`Request has been assigned successfully`))
            onFinish();

        } else {
            dispatch(DispatchErrorSnackbar())

        }
        dispatch(SetIsLoading(false))

    };
}

export const SubmitToCustomer = (model: ISubmitOfferModel, onFinish:() => any) => {
    return async (dispatch: any, getState: any) => {
        dispatch(SetIsLoading(true))

        const submissionsClient = new SubmissionsClient();


        const response = await submissionsClient.SendOfferAsync(model);

        if (response.succeeded) {
            onFinish();

        } else {
            dispatch(DispatchErrorSnackbar())

        }
        dispatch(SetIsLoading(false))

    };
}


export const StartStudying = (id: string, onFinish: (id: string) => any, model?: IStudySubmission) => {
    return async (dispatch: any, getState: any) => {
        dispatch(SetIsLoading(true))

        const submissionsClient = new SubmissionsClient();


        const response = await submissionsClient.StudyAsync(id, {});

        if (response.succeeded) {
            await onFinish(id)

            dispatch(DispatchSuccessSnackbar(`Offer study has started successfully`))

        } else {
            dispatch(DispatchErrorSnackbar())

        }
        dispatch(SetIsLoading(false))

    };
}

export const DenyOffer = (id: string,notes:string, onFinish: (id: string) => any, model?: IDenySubmission) => {
    return async (dispatch: any, getState: any) => {
        dispatch(SetIsLoading(true))

        const submissionsClient = new SubmissionsClient();


        const response = await submissionsClient.DenyAsync(id, {Note:notes});

        if (response.succeeded) {


            dispatch(DispatchSuccessSnackbar(`The offer has been denied successfully`))
            await onFinish(id)

        } else {
            dispatch(DispatchErrorSnackbar())

        }
        dispatch(SetIsLoading(false))

    };
}



