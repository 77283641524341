//UI Actions
export const SET_IS_LOADING = "SET_IS_LOADING";
export const SET_LOOKUP_TABLES = "SET_LOOKUP_TABLES"
export const SET_SNACKBAR = "SET_SNACKBAR"


//Users Actions
export const SET_PROFILE = "SET_PROFILE";
export const DELETE_PROFILE = "DELETE_PROFILE";
export const SET_USERS = "SET_USERS";
export const SET_USERS_SEARCH = "SET_USERS_SEARCH";


//Requests Actions
export const SET_REQUESTS = "SET_REQUESTS";
export const SET_REQUESTS_SEARCH = "SET_REQUESTS_SEARCH";


//Lenders Actions
export const SET_LENDERS_LOOKUP = "SET_LENDERS_LOOKUP";
export const SET_LENDERS = "SET_LENDERS";
export const SET_LENDERS_SEARCH = "SET_LENDERS_SEARCH";


//Platform Users Actions
export const SET_PLATFORM_USERS = "SET_PLATFORM_USERS";
export const SET_PLATFORM_USERS_SEARCH = "SET_PLATFORM_USERS_SEARCH";


//Merchants Actions
export const SET_MERCHANTS = "SET_MERCHANTS";
export const SET_MERCHANTS_SEARCH = "SET_MERCHANTS_SEARCH";

//Validations Actions
export const SET_VALIDATIONS = "SET_VALIDATIONS";
export const SET_VALIDATIONS_SEARCH = "SET_VALIDATIONS_SEARCH";

//Crm Actions
export const SET_CRM_SEARCH = "SET_CRM_SEARCH";
export const SET_CRM = "SET_CRM";
export const SET_CRM_LOOKUP = "SET_CRM_LOOKUP";


//Agents Actions
export const SET_AGENTS = "SET_AGENTS";

//Companies Actions
export const SET_COMPANIES = "SET_COMPANIES";

// sme validations 

export const SET_SME_VALIDATIONS = "SET_SME_VALIDATIONS";

