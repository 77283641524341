import {TextField, TextFieldProps} from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab";
import {LookUpModel} from "../../types/AppState";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { useTranslation } from 'react-i18next';
interface IProps {
    placeHolder?: string;
    onChange: (v: string | undefined | null) => void;
    isInvalid?: boolean;
    validationError?: string;
    onBlur?: (e: any) => void;
    disabled?: boolean;
    name: string;
    fullWidth?: boolean;
    icon?: any;
    options: LookUpModel[];
    value: number | string | null | undefined;
    variant?:"standard" | "filled" | "outlined";
    required?: boolean;

}
const useStyles = makeStyles((theme: Theme) => ({

    input: {
        "& .MuiFormLabel-root": {
            left: "auto",
            right: "auto",
            transformOrigin: "top right",
            textAlign: "right",
            paddingRight:"3rem"
        }
    }
}))
const AutocompleteInput = ({ options,variant, ...props }:  TextFieldProps & IProps) => {
    const classes = useStyles()
    const {i18n } = useTranslation();
    let labelStyle = i18n.language.slice(0, 2).toLowerCase() === "ar" ? true : false;
   

    return (
        <Autocomplete
            value={
                options.find((i) => i.value === props.value) || { label: "", value: "" }
            }
            onChange={(e, newValue) => props.onChange(newValue?.value?.toString() ?? "")}
            getOptionLabel={(option: any) => option.label}
            options={options}
            renderInput={(params) => (
                <TextField
                required={props.required}
               className={labelStyle ? classes.input : ''}
                    {...params}
                    variant={variant}
                    key={props.placeHolder}
                    onBlur={props.onBlur}
                    error={props.isInvalid}
                  //  helperText={ErrorCodeResolver(props.validationError,props.placeHolder,router.locale)}
                    placeholder={props.placeHolder}
                    disabled={props.disabled}
                    name={props.name}
                    fullWidth={props.fullWidth}
                    label={variant === "outlined" ? undefined: props.placeHolder}
                />
            )}
        />
    );
};
export default AutocompleteInput;
