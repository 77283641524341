import {ApiResponse, ClientFactory} from "@simplify9/simplyapiclient";
import IClient from "@simplify9/simplyapiclient/dist/Models/IClient";
import CookieManager from "../utils/CookiesManager";
import {ICreateAgent,ISearchAgents} from "../types/Agents";
import {IPagedSearch} from "../types/Paged";

class AgentsClient {
    client: IClient;
    locale: string;

    constructor() {
        this.client = ClientFactory();
        this.locale = CookieManager.getLocale();
    }
    async Create(info: ICreateAgent): Promise<ApiResponse> {
        console.log("info",info)
        let res = await this.client.SimplyPostAsync(`agents?culture=${this.locale}`, info);
        return res;
    }

    async Search(
        search?: IPagedSearch<ISearchAgents>
    ): Promise<ApiResponse> {
        const rs: ApiResponse = this.client.SimplyGetAsync(`agents?culture=${this.locale}&mobileNumber=${search?.search?.mobileNumber?? ''}&metaLenderId=${search?.search?.metaLenderId ?? ''}&limit=${search?.limit??20}&offset=${search?.offset??0}`);
        return rs;
    }
}

export default AgentsClient;