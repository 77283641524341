import React, {useEffect, useState} from 'react';
import {Button, FormControl, Grid, InputLabel, MenuItem, Select} from "@material-ui/core";
import Table from "../../shared/Table";
import {makeStyles} from "@material-ui/styles";
import {Theme} from "@material-ui/core/styles";
import {useDispatch, useSelector} from "react-redux";
import IAppStateModel from "../../../types/AppState";
import {useTranslation} from "react-i18next";
import {EditUser, SearchPlatformUsers, SetPlatformUsersSearch} from "../../../state/actions/UserActions";
import {ICreateUserModel, IPlatformUser, UserTypes} from "../../../types/User";
import Typography from "@material-ui/core/Typography";
import AddUserModal, {adminTypes} from "../../shared/AddUserModal";
import {parseDate} from "../../../utils/dateUtil";
import {SwitchLenderStatusAsync} from "../../../state/actions/LendersActions";


const useStyles = makeStyles((theme: Theme) => ({
    container: {
        [theme.breakpoints.down("xs")]: {
            paddingLeft: "1.2rem",
            paddingRight: "1.2rem",

        },
        paddingLeft: "3rem",
        paddingRight: "3rem",
        paddingTop: "1rem",
    },
    title: {
        fontWeight: "bold",
        fontSize: "1.5rem"
    },
    tableContainer: {
        marginTop: '2rem'
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },

}))

const Component = () => {
    const classes = useStyles();
    const {platformUsersResults, search} = useSelector((state: IAppStateModel) => {
        return {
            platformUsersResults: state.platformUsers?.results,
            search: state.platformUsers?.search,

        }
    });

    const {t} = useTranslation("platformUsers")

    const dispatch = useDispatch();
    const refreshData = () => {
        dispatch(SearchPlatformUsers());
    }

    useEffect(() => {
        refreshData();
    }, [search])
    type ModalNames = "none" | "add"
    const [openModal, setOpenModal] = useState<ModalNames>("none")

    const GetModal = ({openModal}: { openModal: ModalNames }): JSX.Element => {
        const onCLoseModal = () => {
            refreshData();
            setOpenModal("none")
        }

        switch (openModal) {
            case "add": {
                return <AddUserModal open={openModal === "add"} onClose={onCLoseModal}
                                     userType={UserTypes.PlatformEmployee}/>
            }

        }
        return <></>


    }
    return (
        <Grid container className={classes.container}>
            <GetModal openModal={openModal}/>
            <Grid container justifyContent={"space-between"} alignItems={"center"}>
                <Grid item>
                    <Typography className={classes.title}>{t("title")}</Typography>
                </Grid>
                <Grid item sm={1}>
                    <FormControl fullWidth>
                        <Button variant={"contained"} color={"secondary"} onClick={() =>
                            setOpenModal("add")}>
                            {t('add')}
                        </Button>
                    </FormControl>
                </Grid>

            </Grid>

            <Grid className={classes.tableContainer} container>
                <Table
                    columns={[
                        {field: "id", name: t("id")},
                        {field: "name", name: t("name")},
                        {field: "email", name: t("email")},
                        {field: "phone", name: t("phone")},
                        {field: "userType", name: t("userType")},
                        {
                            field: "createdOn", name: t("createdOn"), resolveData: (row: IPlatformUser) => {
                                return parseDate(row.createdOn)
                            }
                        },
                        {
                            field: "inactive",
                            name: t("status"),
                            resolveData: (row: IPlatformUser) => row.inactive ? t("inactive") : t("active")
                        },
                    ]}
                    actions={(row: IPlatformUser) =>
                        <>

                            <FormControl className={classes.formControl}>
                                <InputLabel id="demo-simple-select-label">User Type</InputLabel>

                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"

                                    value={row.userType}
                                    onChange={(i) => {
                                        dispatch(EditUser(row.id, Number(i.target.value)))
                                    }}
                                >

                                    {
                                        adminTypes?.map((i) =>
                                            (<MenuItem key={Math.random()} value={i.value}>{i.label}</MenuItem>)
                                        )
                                    }
                                </Select>
                            </FormControl>

                            <Button onClick={() => dispatch(SwitchLenderStatusAsync(row.id, () => refreshData()))}
                                    style={{backgroundColor: row.inactive ? "green" : "red", marginTop: "10%"}}
                                    type="button">
                                <Typography style={{
                                    color: "white",
                                    fontSize: 13
                                }}>{row.inactive ? t("activate") : t("deActivate")}</Typography>
                            </Button>
                        </>}
                    data={platformUsersResults?.matches ?? []}
                    limit={platformUsersResults?.limit!}
                    offset={platformUsersResults?.offset}
                    total={platformUsersResults?.total}
                    onPageChange={(page: number) => {
                        dispatch(SetPlatformUsersSearch({...search, offset: (search?.limit! * page)}))
                    }}
                    onCountChange={(count: number) => {
                        dispatch(SetPlatformUsersSearch({...search, limit: count}))
                    }
                    }
                />
            </Grid>
        </Grid>
    )
}
export default Component;


