import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import {
    Box,
    Button,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    TextField
} from "@material-ui/core";
import IPagedResults, { IPagedSearch } from "../../../types/Paged";
import Typography from "@material-ui/core/Typography";
import Table from "../../shared/Table";
import IAppStateModel from "../../../types/AppState";
import { ContactAction, ExportCrmToExcel } from "../../../state/actions/CrmActions";
import ICrm, { IContactTrailModel, ISearchCrm } from "../../../types/Crm";
import { GiOctoman, ImSearch, MdOutlineHistory } from "react-icons/all";
import AddModal from "./CreateContact";
import TrailModal from "./TrailModal";
import SalesManModal, { SalesManModel } from "../Requests/components/SalesManModal";
import CrmClient from "../../../apiClient/CrmClient";
import { DispatchErrorSnackbar, DispatchSuccessSnackbar, SetIsLoading } from "../../../state/actions/UiActions";
import InfoIcon from '@material-ui/icons/Info';
import InfoModal from "./InfoModal";
import { RiFileExcel2Line } from "react-icons/ri";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import {SearchCrmAsync} from "../../../state/actions/CrmActions"


const Contact = () => {
    const { t, i18n } = useTranslation("crm");
    let labelStyle = i18n.language.slice(0, 2).toLowerCase() === "ar" ? true : false;
    const salesMen = useSelector((state: IAppStateModel) => state.lookUpTables.salesMen);
    const dispatch = useDispatch()
    const crmResults = useSelector((state: IAppStateModel) => state.crm.results);
    const [search, setSearch] = useState<IPagedSearch<ISearchCrm>>({
        search: {
            name: "",
            mobileNumber: "",
            email: "",
            status: null,
            assignedSalesmanId: null
        },
        limit: 20,
        offset: 0
    })
    const [modal, setModal] = useState(false)
    const [trails, setTrails] = useState<IContactTrailModel[]>()
    const [showTrailModal, setShowTrailModal] = useState(false)
    const [showSalesManModal, setShowSalesManModal] = useState<{ isOpen: boolean, id?: string }>()
    const [openModal, setOpenModal] = useState("SalesManModal");
    const [accountMangerId, setAccountMangerId] = useState<SalesManModel>()
    const [showInfoModal, setShowInfoModal] = useState(false)
    const [extraInfo, setExtraInfo] = useState<any>();
    const client = new CrmClient()

    const refreshData = () => {
        dispatch(SearchCrmAsync())
    }
    useEffect(() => {
        refreshData();
    }, [])


    const onSubmit = async () => {
        if (accountMangerId) {
            dispatch(SetIsLoading(true))
            const res = await client.AssignToSalesMan(showSalesManModal?.id, accountMangerId.AccountManagerId);
            if (res.succeeded) {
                dispatch(DispatchSuccessSnackbar(t('salesManSuccessMessage')))
                setShowSalesManModal({ isOpen: false })
            } else {
                dispatch(DispatchErrorSnackbar())
            }

        } else {
            dispatch(DispatchErrorSnackbar(t('requiredMessage')))
        }
        dispatch(SetIsLoading(false))
    }

    const onClickExport = () => {
        dispatch(ExportCrmToExcel(search))
    }


    const uploadExcelFile = async (excelFile: any) => {
        dispatch(SetIsLoading(true))
        let formData = new FormData();
        const crmClient = new CrmClient();
        formData.append("file", excelFile);
        const res = await crmClient.ImportExcelFile(formData)
        if (res.succeeded) {
            const response = await crmClient.AddDataFromExcelFile(res.data.location)
            if (response.succeeded) {
                dispatch(DispatchSuccessSnackbar("added!"))
                await refreshData()

            } else {
                dispatch(DispatchErrorSnackbar())
            }
        } else {
            dispatch(DispatchErrorSnackbar())
        }
        dispatch(SetIsLoading(false))
    };

    const classes = useStyles();
    return (
        <>
            {showSalesManModal?.isOpen &&
                <SalesManModal
                    open={openModal === "SalesManModal"}
                    onClose={() =>
                        setShowSalesManModal({ isOpen: false })
                    }
                    id={showSalesManModal?.id}
                    onSubmit={onSubmit}
                    setState={(e: any) => {
                        setAccountMangerId(e)
                    }}
                />}
            {showTrailModal &&
                <TrailModal
                    trails={trails}
                    onClose={() =>
                        setShowTrailModal(false)
                    }
                />}
            {showInfoModal &&
                <InfoModal
                    extraInfo={extraInfo}
                    onClose={() =>
                        setShowInfoModal(false)
                    }
                />}
            {modal &&
                <AddModal
                    title={t("add")}
                    onClose={() => {
                        setModal(false)
                        refreshData();
                    }

                    }
                />}
            <Grid container className={classes.container}>
                <Grid container alignItems={"center"}>
                    <Grid item>
                        <Typography className={classes.title}>{t("title")}</Typography>
                    </Grid>
                </Grid>
                <Grid container justifyContent={"space-between"} alignItems={"center"}>

                    <Box width={"100%"} display={"flex"} alignItems={"center"}
                        justifyContent={"flex-end"}>
                        <FormControl>
                            <div>
                                <Button style={{ marginRight: '10px' }} variant={"contained"} color={"secondary"}
                                    onClick={() => {
                                        setModal(true)
                                    }}
                                >
                                    {t("addButton")}
                                </Button>
                                <Button
                                    // variant="contained"
                                    component="label"
                                    style={{ backgroundColor: "#1D6F42", color: "white" }}
                                >
                                    {t("Import")}
                                    <input
                                        id="upload" accept=".xlsx"
                                        type="file"
                                        hidden
                                        onChange={(e: any) => {
                                            uploadExcelFile(e.target.files[0])
                                        }}
                                    />
                                </Button>
                            </div>

                        </FormControl>
                    </Box>

                </Grid>
                <Paper className={classes.paper} elevation={3}>
                    <Box width={"100%"} display={"flex"} justifyContent={"flex-end"}>
                        <Button
                            style={{ color: "orange" }}
                            onClick={() => {
                                window.open("https://ams3.digitaloceanspaces.com/tamweelna/uploads/42e9fe0d20b24d93ba4965fbe7bb0f43", "_blank", "noreferrer")
                            }}
                        >
                            <CloudDownloadIcon style={{ color: "orange" }} />
                        </Button>
                        <IconButton className={classes.exportButton}
                            onClick={() => onClickExport()}>
                            <RiFileExcel2Line size={29} />
                        </IconButton>
                    </Box>
                    <Grid container>
                        <Grid item xs={12} md={2} className={classes.search}>
                            <FormControl fullWidth>
                                <TextField
                                    placeholder={t("searchNamePlaceholder")}
                                    value={search.search?.name}
                                    onChange={(e) =>
                                        setSearch(s => ({
                                            ...s,
                                            search: { ...s.search, name: e.target.value }
                                        }))}
                                    size={"medium"}

                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={2} className={classes.search}>
                            <FormControl fullWidth>
                                <TextField
                                    placeholder={t("searchEmailPlaceholder")}
                                    value={search.search?.email}
                                    onChange={(e) =>
                                        setSearch(s => ({
                                            ...s,
                                            search: { ...s.search, email: e.target.value }
                                        }))}
                                    size={"medium"}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} md={2} className={classes.search}>
                            <FormControl fullWidth>
                                <TextField
                                    placeholder={t("searchMobileNumberPlaceholder")}
                                    value={search.search?.mobileNumber}
                                    onChange={(e) =>
                                        setSearch(s => ({
                                            ...s,
                                            search: { ...s.search, mobileNumber: e.target.value }
                                        }))
                                    }
                                    size={"medium"}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} md={2} className={classes.search}>
                            <FormControl style={{ width: "85%" }}>
                                <InputLabel
                                    className={labelStyle ? classes.selectLabel : ''}>{t("assignedSalesman")}</InputLabel>
                                <Select onChange={(e) => {
                                    setSearch(s => ({
                                        ...s,
                                        search: {
                                            ...s.search,
                                            assignedSalesmanId: Number(e.target.value)
                                        }

                                    }))
                                }}
                                    value={search?.search?.assignedSalesmanId || ""}
                                    displayEmpty={true}>
                                    {salesMen && salesMen?.map((i) =>
                                        <MenuItem value={i.value}>{i.label}</MenuItem>)
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={2} className={classes.search}>
                            <FormControl style={{ width: "85%" }}>
                                <InputLabel className={labelStyle ? classes.selectLabel : ''}>{t("status")}</InputLabel>
                                <Select onChange={(e) => {
                                    setSearch(s => ({
                                        ...s,
                                        search: { ...s.search, status: Number(e.target.value) }
                                    }))

                                }}
                                    value={search?.search?.status || ""}
                                    displayEmpty={true}>
                                    <MenuItem value={11}>{"Lead"}</MenuItem>
                                    <MenuItem value={21}>{"Opportunity"}</MenuItem>
                                    <MenuItem value={31}>{"Customer"}</MenuItem>
                                    <MenuItem value={41}>{"Uninterested"}</MenuItem>

                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Box width={"100%"} pt={5} display={"flex"} alignItems={"center"}
                        justifyContent={"flex-end"}>
                            
                        <FormControl>
                            <Button
                                style={{ margin: 2, backgroundColor: "red", color: "white" }}
                                variant={"contained"}
                                color={"primary"}
                                onClick={() => {
                                    setSearch({ 
                                        search: {
                                        name: "",
                                        mobileNumber: "",
                                        email: "",
                                        status: null,
                                        assignedSalesmanId: null
                                    }, 
                                    limit: 20, offset: 0 
                                })
                                    refreshData()
                            }}
                                >
                                {t("clearButton")}
                            </Button>
                        </FormControl>
                        <FormControl>
                            <Button variant={"contained"} color={"primary"}
                               onClick={() => dispatch(SearchCrmAsync(search))}>
                                {t("searchButton")}
                                <ImSearch style={{ marginLeft: "0.5rem" }} />
                            </Button>
                        </FormControl>
                    </Box>
                </Paper>

                <Table

                    key={"tableKey"}
                    columns={[
                        // {field: "id", name: t("id")},
                        {
                            field: "id",
                            name: t("id"),
                            resolveData: (row: ICrm) => 
                            row.status === "Customer" ? 
                             <p style={{
                                fontWeight: 'bold',
                                color: "green"
                            }}>{row.id}
                            </p>
                           :   row.highPriority ? 
                           <p style={{
                               fontWeight: 'bold',
                               color: "red"
                           }}>{row.id}
                           </p> :
                            row.id
                        },
                        { field: "status", name: t("status") },
                        {
                            field: "assignedTo",
                            name: t("assignedTo"),
                            resolveData: (results) => results?.assignedTo?.name ?? "-"
                        },
                        { field: "name", name: t("name") },
                        { field: "mobileNumber", name: t("mobileNumber") },
                        {
                            field: "productType", name: t("productType"),
                            resolveData: (results) => results?.productType ?? "-"
                        },
                        {
                            field: "CreatedByUser",
                            name: t("createdBy"),
                            resolveData: (results) => results?.createdByUser?.name
                        },

                        {
                            field: "Actions",
                            name: t("contactResponse"),
                            resolveData: (row) => <Select
                                style={{ width: '100px' }}
                                native
                                value={t("action")}
                                onChange={(i) => {
                                    dispatch(ContactAction(typeof i.target.value === 'string' ? i.target.value : '', row.id))
                                    refreshData()
                                }}
                            >
                                <option value="">{t("action")}</option>
                                <option value="11">{t("Uninterested")}</option>
                                <option value="21">{t("ShowedInterest")}</option>
                                <option value="31">{t("AskedToBeCalledBack")}</option>
                                <option value="41">{t("DidntAnswer")}</option>
                            </Select>
                        },

                    ]}
                    actions={(row: ICrm) => <>

                        <Button size={"small"}
                            onClick={() => {
                                setShowTrailModal(true);
                                setTrails(row.trails)
                            }}><MdOutlineHistory color={"orange"} size={27} /></Button>
                        <Button size={"small"}
                            onClick={() => {
                                setShowInfoModal(true);
                                setExtraInfo({
                                    companyName: row.companyName,
                                    lastContactDate: row.lastContactDate,
                                    note: row.note,
                                    contactEconomicalStatus: row.contactEconomicalStatus,
                                    highPriority: row.highPriority,
                                    companyLocation: row.companyLocation,
                                    isRegisteredWithSocialSecurity: row.isRegisteredWithSocialSecurity,
                                    isVocationalLicenseIssued: row.isVocationalLicenseIssued,
                                    isTradeLicenseIssued: row.isTradeLicenseIssued,
                                    companyRegistrationDate: row.companyRegistrationDate,
                                    status: row.status,
                                    assignedTo: row.assignedTo,
                                    name: row.name,
                                    mobileNumber: row.mobileNumber,
                                    email: row.email,
                                    createdByUser: row.createdByUser?.name,
                                    gender: row.gender,
                                    contactSource: row.contactSource,
                                    productType: row.productType,
                                    countryCode: row.address?.countryCode,
                                    area: row.address?.area
                                })
                            }}><InfoIcon style={{ color: "orange" }} /></Button>

                        <Button
                            size={"small"}
                            onClick={() =>
                                setShowSalesManModal({ id: row.id, isOpen: true })
                            }
                        ><GiOctoman color={"gold"}
                            size={27} /></Button>
                    </>}

                    data={crmResults?.matches ?? []}
                    limit={crmResults?.limit!}
                    offset={crmResults?.offset}
                    total={crmResults?.total}
                    onPageChange={(page: number) => dispatch(SearchCrmAsync({...search,offset:(search.limit * page)}))}
                    onCountChange={(count: number) => dispatch(SearchCrmAsync({...search,limit: count}))} />
            </Grid>
        </>
    )
}
export default Contact

const useStyles = makeStyles((theme: Theme) => ({
    search: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        padding: "1rem",
        paddingBottom: "0",
        width: "100%"
    },
    exportButton: {
        color: "#1D6F42",
    },
    container: {
        [theme.breakpoints.down("xs")]: {
            paddingLeft: "1.2rem",
            paddingRight: "1.2rem",
        },
        paddingLeft: "3rem",
        paddingRight: "3rem",
        paddingTop: "1rem",
        display: "flex",

    },
    title: {
        fontWeight: "bold",
        fontSize: "1.5rem",
    },
    paper: {
        width: "100%",
        marginTop: "0.4rem",

        marginBottom: "1.66rem",
        padding: "1rem"
    },
    selectLabel: {

        left: "auto",
        right: "auto",
        transformOrigin: "top right",
        textAlign: "right",
        paddingRight: "2rem",

    }
}));
