import { useDispatch, useSelector, batch } from "react-redux";
import { SearchRequestValidation, SearchSmeRequestValidation } from "../../../state/actions/RequestValidation";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { AppBar, Box, Grid, Tab, Tabs, useTheme } from "@material-ui/core";
import { IPagedSearch } from "../../../types/Paged";
import { ISearchValidation,  SMERequestScoreCriteriaModel } from "../../../types/RequestValidation";
import Typography from "@material-ui/core/Typography";
import SwipeableViews from "react-swipeable-views";
import SwipeableView from "./components/swipeableView";
import AppState from "../../../types/AppState";
import SmeValidationForm from "./components/smeValidationForm";

const RequestsValidations = () => {
    const smeData = useSelector((state: AppState) => state.smeRequestValidation.results?.matches) as SMERequestScoreCriteriaModel[]
    const { t, i18n } = useTranslation("validations")
    const dispatch = useDispatch()
    const [search, setSearch] = useState<IPagedSearch<ISearchValidation>>({ offset: 0, limit: 10 })
    const refreshData = () => {
        batch(() => {
            dispatch(SearchRequestValidation(i18n.language, search))
            dispatch(SearchSmeRequestValidation(i18n.language, search))
          });
    }

    useEffect(() => {
        refreshData()
    },[search])


    const classes = useStyles();
    const [value, setValue] = React.useState<number>();
    const [requestCriteria, setRequestCriteria] = useState<{ requestTypeId?: number, jobTypeId?: number }>()

    const handleChange = (event: any, newValue: number) => {
        setValue(newValue);
    };
    const handleChangeIndex = (index: number) => {
        setValue(index);
    };
    const theme = useTheme();
    return (
        <Grid container className={classes.container}>
            <Grid container alignItems={"center"}>
                <Grid item>
                    <Typography className={classes.title}>{t("title")}</Typography>
                </Grid>
            </Grid>
            <Box sx={{ bgcolor: 'background.paper', width: "100%", marginTop: 5 }}>
                <AppBar position="static">
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="fullWidth"
                        aria-label="full width tabs example"
                    >
                        <Tab key={0} onClick={() => setRequestCriteria({ ...requestCriteria, requestTypeId: 1 })}
                            label={t('personalLoan')} {...a11yProps(0)} />
                        <Tab key={1} onClick={() => setRequestCriteria({ ...requestCriteria, requestTypeId: 11 })}
                            label={t('autoLoan')} {...a11yProps(1)} />
                        <Tab key={2} onClick={() => setRequestCriteria({ ...requestCriteria, requestTypeId: 71 })}
                            label={t('mortgageLoan')} {...a11yProps(2)} />{/* */}
                        <Tab key={3} onClick={() => setRequestCriteria({ ...requestCriteria, requestTypeId: 51 })}
                            label={t('SMELoan')} {...a11yProps(3)} />
                    </Tabs>
                </AppBar>
                <SwipeableViews
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={value}
                    onChangeIndex={handleChangeIndex}
                >
                    <TabPanel key={0} value={value} index={0} dir={theme.direction}>
                        <SwipeableView
                            requestTypeId={1}
                            requestType={'PersonalLoan'}
                            businessOwner={true}
                            salariedPublic={true}
                            salariedPrivate={true}
                            selfEmployed={true}
                            companyRentPersonalFlag={true}
                        />
                    </TabPanel>
                    <TabPanel key={1} value={value} index={1} dir={theme.direction}>
                        <SwipeableView
                            requestTypeId={11}
                            requestType={'AutoLoan'}
                            businessOwner={true}
                            salariedPublic={true}
                            salariedPrivate={true}
                            selfEmployed={true}
                            automobileAgeWeightFlag={true}
                            autoLoanDownPaymentWeightFlag={true}
                            companyRentFlag={true}
                        />
                    </TabPanel>
                    <TabPanel key={2} value={value} index={2} dir={theme.direction}>
                        <SwipeableView
                            selfEmployed={true}
                            businessOwner={true}
                            salariedPublic={true}
                            salariedPrivate={true}
                            requestTypeId={71}
                            requestType={'MortgageLoan'}
                            mortgageDownPaymentWeightFlag={true}
                            realStateTypeWeightFlag={true}
                            realStateAgeWeightFlag={true}
                            companyRentFlag={true}
                            expat={true}
                        />
                    </TabPanel>
                    <TabPanel key={3} value={3} index={3} dir={theme.direction}>
                        <SmeValidationForm
                            row={smeData?.[0]}
                        />
                    </TabPanel>
                </SwipeableViews>
            </Box>
        </Grid>
    )
}
export default RequestsValidations

interface TabPanelProps {
    children?: React.ReactNode;
    dir?: string;
    index: number;
    value?: number;
}

const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const a11yProps = (index: number) => {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme: Theme) => ({
    search: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        padding: "1rem",
        paddingBottom: "0",
        width: "100%"
    },
    container: {
        [theme.breakpoints.down("xs")]: {
            paddingLeft: "1.2rem",
            paddingRight: "1.2rem",
        },
        paddingLeft: "3rem",
        paddingRight: "3rem",
        paddingTop: "1rem",
        display: "flex",

    },
    title: {
        fontWeight: "bold",
        fontSize: "1.5rem",
    },
    paper: {
        width: "100%",
        marginTop: "0.4rem",

        marginBottom: "1.66rem",
        padding: "1rem"
    }
}));
