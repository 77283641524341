import * as ActionsTypes from './ActionsTypes';
import InitialState from './InitialState';
import IAppStateModel from '../types/AppState';
import {SET_VALIDATIONS, SET_VALIDATIONS_SEARCH} from "./ActionsTypes";


const appReducer = (state = InitialState, action: any): IAppStateModel => {
    switch (action.type) {

        //UI Actions
        case ActionsTypes.SET_IS_LOADING:
            return {
                ...state,
                isLoading: action.payload.isLoading
            }

        case ActionsTypes.SET_LOOKUP_TABLES:
            return {
                ...state,
                lookUpTables: action.payload.model
            }

        case ActionsTypes.SET_SNACKBAR:
            return {
                ...state,
                snackBar: action.payload.snackbar
            }




        //Users Actions
        case ActionsTypes.SET_PROFILE:
            return {
                ...state,
                profile: action.payload
            }
        case ActionsTypes.DELETE_PROFILE:
            return {
                ...state,
                profile: undefined
            }
        case ActionsTypes.SET_USERS:
            return {
                ...state,
                users: {
                    ...state.users,
                    results: action.payload
                }
            }
        case ActionsTypes.SET_USERS_SEARCH:
            return {
                ...state,
                users: {
                    ...state.users,
                    search: action.payload
                }
            }



        //Requests Actions
        case ActionsTypes.SET_REQUESTS:
            return {
                ...state,
                requests: {
                    ...state.requests,
                    results: action.payload
                }
            }
        case ActionsTypes.SET_REQUESTS_SEARCH:
            return {
                ...state,
                requests: {
                    ...state.requests,
                    search: action.payload
                }
            }




        //Lenders Actions
        case ActionsTypes.SET_LENDERS:
            return {
                ...state,
                lenders: {
                    ...state.lenders,
                    results: action.payload
                }
            }
        case ActionsTypes.SET_LENDERS_SEARCH:
            return {
                ...state,
                lenders: {
                    ...state.lenders,
                    search: action.payload
                }
            }
        case ActionsTypes.SET_LENDERS_LOOKUP:
            return {
                ...state,
               lendersLookup:action.payload
            }



        //Merchants Actions
        case ActionsTypes.SET_MERCHANTS:
            return {
                ...state,
                merchants: {
                    ...state.merchants,
                    results: action.payload
                }
            }
        case ActionsTypes.SET_MERCHANTS_SEARCH:
            return {
                ...state,
                merchants: {
                    ...state.merchants,
                    search: action.payload
                }
            }

        //Validations Actions
        case ActionsTypes.SET_VALIDATIONS:
            return {
                ...state,
                requestValidation: {
                    ...state.requestValidation,
                    results: action.payload
                }
            }
        case ActionsTypes.SET_VALIDATIONS_SEARCH:
            return {
                ...state,
                requestValidation: {
                    ...state.requestValidation,
                    search: action.payload
                }
            }
        // validations sme 
        case ActionsTypes.SET_SME_VALIDATIONS:
            return {
                ...state,
                smeRequestValidation: {
                    ...state.smeRequestValidation,
                    results: action.payload
                }
            }
        //Platform Users Actions
        case ActionsTypes.SET_PLATFORM_USERS:
            return {
                ...state,
                platformUsers: {
                    ...state.platformUsers,
                    results: action.payload
                }
            }
        case ActionsTypes.SET_PLATFORM_USERS_SEARCH:
            return {
                ...state,
                platformUsers: {
                    ...state.platformUsers,
                    search: action.payload
                }
            }
        
        //Crm Actions
        case ActionsTypes.SET_CRM:
            return {
                ...state,
                crm: {
                    ...state.crm,
                    results: action.payload
                }
            }
        case ActionsTypes.SET_CRM_SEARCH:
            return {
                ...state,
                crm: {
                    ...state.crm,
                    search: action.payload
                }
            }
        case ActionsTypes.SET_CRM_LOOKUP:
                return {
                    ...state,
                   crmLookup:action.payload
                }   
        
         // Agents Actions
         case ActionsTypes.SET_AGENTS:
            return {
                ...state,
                agents: {
                    ...state.agents,
                    results: action.payload
                }
            }
         // Companies Actions
         case ActionsTypes.SET_COMPANIES:
            return {
                ...state,
                companies: {
                    ...state.companies,
                    results: action.payload
                }
            }

        default:
            return state;
    }
}

export default appReducer;
