import React, {FunctionComponent, useState} from "react";
import {makeStyles, Theme} from "@material-ui/core/styles";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import LoanView from "../../common/LoanView";
import {ComponentRequestsAttachmentsComponent} from "../../../types/content";
import {IRequestAttachment, RequestType} from "../../../types/Request";
import {useTranslation} from "react-i18next";
import {Box, Grid} from "@material-ui/core";
import UploadMultipleFilesInput from "../views/uploadMultipleFileInput";
import {useSelector} from "react-redux";
import IAppStateModel from "../../../types/AppState";


const useStyles = makeStyles((theme: Theme) => ({
  label: {
    paddingLeft: "0.2rem",
    paddingRight: "0.2rem",
    marginBottom: "2.4rem",
    fontSize: "1.1rem",
  },
  item: {
    padding: "1rem",
  },
}));

interface AttachmentsScheme {
  title: string;
  key: string;
}

interface IProps {
  content?: ComponentRequestsAttachmentsComponent;
  onEditClick?: (e: any) => void;
  attachments: IRequestAttachment[];
  requestType: RequestType;
  setAttachments: (attachments: any) => void
}


const AttachmentsInfoView: FunctionComponent<IProps> = ({
  content,
  requestType,
  attachments,
  onEditClick,
  setAttachments
}: IProps): JSX.Element => {
  const { t, i18n } = useTranslation("common");
  const userType = useSelector((state: IAppStateModel) => state.profile?.type);


  const [isLoading, setLoading] = useState(false);

  const onUpload = (type: string, fileLocation: string) => {
    const data = { fileLocation, type };
    setAttachments((a: IRequestAttachment[]) => [...(a ?? []), data]);
  };

  const onDelete = (name: string) => {
    setAttachments((a: IRequestAttachment[]) => [...a.filter(f => f.type != name)]);
  }


  const [modal, setModal] = useState(false)
  return (
    <LoanView.Card
      title={content?.stepTitle || ""}
      icon={<AccountBalanceWalletIcon fontSize={"large"}/>}
      onEditClick={() => setModal(true)}
      isAdmin={userType == "PlatformEmployee" || userType == "HeadOfBusiness"}
    >
      {attachments.length > 0 ? attachments.map((i, index) => {
        return (
          index % 3 === 0 ?
            <LoanView.CardSection isLastSection={true}>
              <LoanView.SectionItem
                isLink={true}
                link={attachments[index]?.fileLocation || ""}
                value={
                  attachments[index]?.type || ""
                }
              />
              <LoanView.SectionItem
                isLink={true}
                link={attachments[index + 1]?.fileLocation || ""}
                value={
                  attachments[index + 1]?.type || ""
                }
              />
              <LoanView.SectionItem
                isLink={true}
                link={attachments[index + 2]?.fileLocation || ""}
                value={
                  attachments[index + 2]?.type || ""
                }
              />
            </LoanView.CardSection> : <></>)
      }) : <LoanView.SectionItem
        label={content?.stepTitle || ""}
        value={(userType == "PlatformEmployee" || userType == "HeadOfBusiness") ? t("AddAttachments") : t("NoAttachments")}
      />

      }

      {modal && <Box><> <Grid item lg={12}>
        <UploadMultipleFilesInput
          setLoading={(b) => setLoading(b)}
          initialValue={attachments}
          dropzoneText={content?.attachments || ""}
          onFileDelete={onDelete}
          onFileUpload={(name: string, location: string) =>
            onUpload(name, location)
          }
          onClear={() => setAttachments([])}
        />
      </Grid></>
      </Box>}
    </LoanView.Card>
  );
};

export default AttachmentsInfoView;


