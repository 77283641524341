import {ApiResponse, ClientFactory} from "@simplify9/simplyapiclient";
import IClient from "@simplify9/simplyapiclient/dist/Models/IClient";
import CookieManager from "../utils/CookiesManager";
import {IPagedSearch} from "../types/Paged";
import {ICreateEmployee, ICreateLender, ILenderPerRequest, ISearchLenders, IUpdateLender} from "../types/Lender";

class LendersClient {
    client: IClient;
    // BaseUrl: string;
    locale: string;

    constructor() {
        // this.BaseUrl = `${Config.baseUrl}`;
        this.client = ClientFactory();
        this.locale = CookieManager.getLocale();
    }


    async Search(
        search?: IPagedSearch<ISearchLenders>
    ): Promise<ApiResponse> {
        const rs: ApiResponse = this.client.SimplyGetAsync(`lenders?culture=${this.locale}&name=${search?.search?.name}&limit=${search?.limit}&offset=${search?.offset}&lookUp=${search?.search?.lookUp?search?.search?.lookUp:false}&AcceptsPersonalLoans=${search?.search?.AcceptsPersonalLoans?search?.search?.AcceptsPersonalLoans:""}&AcceptsAutoLoans=${search?.search?.AcceptsAutoLoans?search?.search?.AcceptsAutoLoans:""}&AcceptsMortgageLoans=${search?.search?.AcceptsMortgageLoans?search?.search?.AcceptsMortgageLoans:""}&AcceptsProductLoans=${search?.search?.AcceptsProductLoans?search?.search?.AcceptsProductLoans:""}&AcceptsSMELoans=${search?.search?.AcceptsSMELoans?search?.search?.AcceptsSMELoans:""}`);
        return rs;
    }

    async SearchPerRequest(
        search?: IPagedSearch<ILenderPerRequest>
    ): Promise<ApiResponse> {
        const rs: ApiResponse = this.client.SimplyGetAsync(`lenders/MatchingRequest?culture=${this.locale}&limit=${search?.limit}&offset=${search?.offset}&lookUp=true&requestId=${search?.search?.requestId}`);
        return rs;
    }

    async SwitchStatus(id: number): Promise<ApiResponse> {
        let res = await this.client.SimplyPostAsync(`users/${id}/ChangeAccountStatus`, {});
        return res;
    }

    async GetBranches(
    ): Promise<ApiResponse> {
        const rs: ApiResponse = this.client.SimplyGetAsync(`lenders/branches`)
        return rs;
    }

    async Get(
        id: string
    ): Promise<ApiResponse> {
        const rs: ApiResponse = this.client.SimplyGetAsync(`lenders/${id}?culture=${this.locale}`);
        return rs;
    }

    async Create(info: ICreateLender): Promise<ApiResponse> {
        let res = await this.client.SimplyPostAsync("lenders", info);
        return res;
    }

    async Edit(info: IUpdateLender,id:number): Promise<ApiResponse> {
        let res = await this.client.SimplyPostAsync(`lenders/${id}`, info);
        return res;
    }

    async AddEmployee(lenderId: string, info: ICreateEmployee): Promise<ApiResponse> {
        if (!info.branchId || info.branchId==='') info.branchId = undefined
        let res = await this.client.SimplyPostAsync(`lenders/${lenderId}/employee`, info);
        return res;
    }
    async AddBranch(lenderId: string, info: any): Promise<ApiResponse> {
        let res = await this.client.SimplyPostAsync(`lenders/${lenderId}/branch`, info);
        return res;
    }
}

export default LendersClient;
