import React from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import {useFormik} from 'formik';
import * as yup from "yup";
import {ICreateContactModel} from "../../../types/Crm";
import {CreateCrmAsync} from "../../../state/actions/CrmActions";
import {useDispatch, useSelector} from "react-redux";
import {Button, Grid, Select, TextField, Typography} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import AppState from "../../../types/AppState";
import AutocompleteInput from "../../shared/AutoCompleteStep";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      position: 'absolute',
      top: '10%',
      left: '10%',
      overflow: 'scroll',
      height: '100%',
      display: 'block',
      padding: 50,
      direction: theme.direction,
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    footer: {
      textAlign: "end",
      paddingTop: 25

    },
    input: {
      "& .MuiFormLabel-root": {
        left: "auto",
        right: "auto",
        transformOrigin: "top right",
        textAlign: "right",
      }
    }
  }),
);

const validationSchema = yup.object({
  nameAr: yup
    .string()
    .min(3, " Minimum 3 Characters")
    .required(" is Required"),
  nameEn: yup
      .string()
      .min(3, " Minimum 3 Characters")
      .required(" is Required"),
  email: yup
      .string()
      .email()
      .required(" must be a valid email"),
  mobileNumber: yup
    .string().min(8, " must be at least 8 characters")
    .max(10, " must be at most 10 characters")
    .required(" is Required"),
});

interface IProps {
  onClose: () => void
  title: string
}

const CreateContact = ({ onClose, title }: IProps) => {
  const classes = useStyles();
  const dispatch = useDispatch()
  const metaLookUps = useSelector((state: AppState) => state.lookUpTables)
  const { t, i18n } = useTranslation("crm")
  let labelStyle = i18n.language.slice(0, 2).toLowerCase() === "ar" ? true : false;
  const formik = useFormik({
    initialValues: {
      nameEn: null,
      nameAr: "",
      email: null,
      initialDigitsOfMobileNumber: "962",
      mobileNumber: "",
      gender: null,
      age: null,
      contactSource: null,
      address: {
        cityId: null,
        area: null,
        countryCode: null,
      },
      note: null,
      companyName: null,
      contactEconomicalStatus: null,
      isHighPriority: null,
      companyLocation: null,
      isRegisteredWithSocialSecurity: null,
      isVocationalLicenseIssued: null,
      isTradeLicenseIssued: null,
      companyRegistrationDate: null,
      productType: null
    },
    validationSchema: validationSchema,
    validateOnBlur: true,

    onSubmit: async (values: ICreateContactModel) => {
      if (values.mobileNumber.length == 10) {
        values.mobileNumber = values.initialDigitsOfMobileNumber + values.mobileNumber;
        values.mobileNumber = values.mobileNumber.replace("0", "")
      } else if (values.mobileNumber.length == 9) {
        values.mobileNumber = values.initialDigitsOfMobileNumber + values.mobileNumber;
      } else {
        values.mobileNumber = values.initialDigitsOfMobileNumber + values.mobileNumber
      }
      dispatch(CreateCrmAsync(values))
      onClose();
    },
  });


  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={true}
        onClose={onClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={true}>
          <div className={classes.paper}>
            <Typography variant="h6" gutterBottom>
              {t("addContact")}
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  required
                  className={labelStyle ? classes.input : ''}
                  id="nameEn"
                  name="nameEn"
                  label={t("nameEn")}
                  fullWidth
                  value={formik.values.nameEn}
                  onChange={(i) => formik.setFieldValue("nameEn", i.target.value)}
                  // helperText={(formik.touched.nameAr && formik.errors.nameAr && t("nameAr") + formik.errors.nameAr) || ""}
                  error={
                    formik.touched.nameEn &&
                    Boolean(formik.errors.nameEn)
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  required
                  className={labelStyle ? classes.input : ''}
                  id="nameAr"
                  name="nameAr"
                  label={t("nameAr")}
                  fullWidth
                  value={formik.values.nameAr}
                  onChange={(i) => formik.setFieldValue("nameAr", i.target.value)}
                  // helperText={(formik.touched.nameAr && formik.errors.nameAr && t("nameAr") + formik.errors.nameAr) || ""}
                  error={
                    formik.touched.nameAr &&
                    Boolean(formik.errors.nameAr)
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  required
                  className={labelStyle ? classes.input : ''}
                  id="email"
                  name="email"
                  label={t("email")}
                  fullWidth
                  value={formik.values?.email}
                  onChange={(i) => formik.setFieldValue("email", i.target.value)}
                  helperText={(formik.touched.email && formik.errors.nameAr && t("email") + formik.errors.email) || ""}
                  error={
                    formik.touched.email &&
                    Boolean(formik.errors.email)
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Select
                    style={{ maxWidth: '200px' }}
                    native
                    fullWidth
                    value={formik.values.initialDigitsOfMobileNumber}
                    placeholder={"الاردن"}
                    onChange={(i) => formik.setFieldValue("initialDigitsOfMobileNumber", i.target.value ?? "962")}
                  >
                    <option value="962">{("962 الاردن")}</option>
                    <option value="965">{("965 الكويت")}</option>
                    <option value="966">{("966 السعودية")}</option>
                    <option value="971">{("971 الامارات")}</option>
                    <option value="968">{("968 عمان")}</option>
                    <option value="973">{("973 بحرين")}</option>
                    <option value="974">{("974 قطر")}</option>


                  </Select>
                  <TextField
                    className={labelStyle ? classes.input : ''}
                    required
                    id="mobileNumber"
                    name="mobileNumber"
                    label={t("mobileNumber")}
                    style={{ width: '500px' }}
                    value={formik.values.mobileNumber}
                    onChange={(i) => formik.setFieldValue("mobileNumber", i.target.value)}
                    helperText={(formik.touched.mobileNumber && formik.errors.mobileNumber && t("mobileNumber") + formik.errors.mobileNumber) || ""}
                    error={
                      formik.touched.mobileNumber &&
                      Boolean(formik.errors.mobileNumber)
                    }
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  className={labelStyle ? classes.input : ''}
                  InputProps={{
                    inputProps: {
                      min: 0
                    }
                  }}
                  type="number"
                  id="age"
                  name="age"
                  label={t("age")}
                  fullWidth
                  value={formik.values?.age}
                  onChange={(i) => formik.setFieldValue("age", i.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  className={labelStyle ? classes.input : ''}
                  id="address.countryCode"
                  name="address.countryCode"
                  label={t("countryCode")}
                  fullWidth
                  value={formik.values?.address?.countryCode}
                  onChange={(i) => formik.setFieldValue("address.countryCode", i.target.value)}
                />
              </Grid>
              {/*<Grid item xs={12} sm={12} md={6}>*/}
              {/*<TextField*/}
              {/*    InputProps={{*/}
              {/*        inputProps: { */}
              {/*            min: 0 */}
              {/*        }*/}
              {/*    }}*/}
              {/*    type="number"*/}
              {/*    required*/}
              {/*    id="address.cityId"*/}
              {/*    name="address.cityId"*/}
              {/*    label={t("cityId")}*/}
              {/*    fullWidth*/}
              {/*    value={formik.values.address?.cityId}*/}
              {/*    onChange={(i) => formik.setFieldValue("address.cityId", i.target.value)}*/}
              {/*/>*/}
              {/*<Autocomplete*/}
              {/*    value={*/}
              {/*        metaLookUps.cities?.find((i) => i.value === formik.values.address?.cityId?.toString() || i.value === formik.values.address?.cityId?.toString() || { label: "", value: "" })*/}
              {/*    }*/}
              {/*    onChange={(v,newValue) => formik.setFieldValue("address.cityId", newValue?.value?.toString())}*/}
              {/*    options={metaLookUps.cities}*/}
              {/*    getOptionLabel={(option: any) => option.label}*/}
              {/*    renderInput={(params) => (*/}
              {/*        <TextField*/}
              {/*            {...params}*/}
              {/*            onBlur={formik.handleBlur}*/}

              {/*            placeholder={t('cityId')}*/}
              {/*            name={"address.cityId"}*/}
              {/*            label={t("cityId")}*/}
              {/*            fullWidth*/}
              {/*        />*/}
              {/*    )}*/}
              {/*    fullWidth*/}
              {/*/>*/}

              {/*</Grid>*/}
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  className={labelStyle ? classes.input : ''}
                  id="address.area"
                  name="address.area"
                  label={t("area")}
                  fullWidth
                  value={formik.values?.address?.area}
                  onChange={(i) => formik.setFieldValue("address.area", i.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  className={labelStyle ? classes.input : ''}
                  id="companyName"
                  name="companyName"
                  label={t("companyName")}
                  fullWidth
                  value={formik.values?.companyName}
                  onChange={(i) => formik.setFieldValue("companyName", i.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  className={labelStyle ? classes.input : ''}
                  id="companyLocation"
                  name="companyLocation"
                  label={t("companyLocation")}
                  fullWidth
                  value={formik.values?.companyLocation}
                  onChange={(i) => formik.setFieldValue("companyLocation", i.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  className={labelStyle ? classes.input : ''}
                  id="note"
                  name="note"
                  label={t("note")}
                  fullWidth
                  value={formik.values?.note}
                  onChange={(i) => formik.setFieldValue("note", i.target.value)}
                />
              </Grid>


              <Grid item xs={12} sm={12} md={6}>

                <AutocompleteInput
                  options={[{ value: "1", label: t("male") }, {
                    label: t("female"),
                    value: "2"
                  }]}
                  name={"gender"}
                  value={formik.values?.gender}
                  onChange={(v: any) =>
                    formik.setFieldValue("gender", v)
                  }

                  onBlur={formik.handleBlur}
                  placeHolder={t("gender")}
                  fullWidth={true}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <AutocompleteInput
                  options={[{ value: "2", label: t("Professional") },
                    { label: t("Non-Professional"), value: "1" },
                    { label: t("Unknown"), value: "0" },


                  ]}
                  name={"contactEconomicalStatus"}
                  value={formik.values?.contactEconomicalStatus}
                  onChange={(v: any) =>
                    formik.setFieldValue("contactEconomicalStatus", v)
                  }

                  onBlur={formik.handleBlur}
                  placeHolder={t("contactEconomicalStatus")}
                  fullWidth={true}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <AutocompleteInput
                  options={[{ value: "true", label: t("High") },
                    { label: t("Not-High"), value: "false" }
                  ]}
                  name={"isHighPriority"}
                  value={formik.values?.isHighPriority}
                  onChange={(v: any) =>
                    formik.setFieldValue("isHighPriority", v)
                  }

                  onBlur={formik.handleBlur}
                  placeHolder={t("priority")}
                  fullWidth={true}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6}>

                <AutocompleteInput
                  options={[{ value: "true", label: t("Yes") },
                    { label: t("No"), value: "false" }
                  ]}
                  name={"isTradeLicenseIssued"}
                  value={formik.values?.isTradeLicenseIssued}
                  onChange={(v: any) =>
                    formik.setFieldValue("isTradeLicenseIssued", v)
                  }

                  onBlur={formik.handleBlur}
                  placeHolder={t("isTradeLicenseIssued")}
                  fullWidth={true}
                />
              </Grid>


              <Grid item xs={12} sm={12} md={6}>

                <AutocompleteInput
                  options={[
                    { label: t("Facebook"), value: "11" },
                    { label: t("Instagram"), value: "21" },
                    { label: t("Google"), value: "31" },
                    { label: t("Marketing Team"), value: "81" },
                    { label: t("Word of Mouth"), value: "91" },
                    { label: t("Phone"), value: "71" },
                    { label: t("Others"), value: "0" },

                  ]}
                  name={"contactSource"}
                  value={formik.values?.contactSource}
                  onChange={(v: any) =>
                    formik.setFieldValue("contactSource", v)
                  }

                  onBlur={formik.handleBlur}
                  placeHolder={t("contactSource")}
                  fullWidth={true}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <AutocompleteInput
                  options={[{ value: "true", label: t("Yes") },
                    { label: t("No"), value: "false" }
                  ]}
                  name={"isRegisteredWithSocialSecurity"}
                  value={formik.values?.isRegisteredWithSocialSecurity}
                  onChange={(v: any) =>
                    formik.setFieldValue("isRegisteredWithSocialSecurity", v)
                  }

                  onBlur={formik.handleBlur}
                  placeHolder={t("isRegisteredWithSocialSecurity")}
                  fullWidth={true}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <AutocompleteInput
                  options={[{ value: "true", label: t("Yes") },
                    { label: t("No"), value: "false" }
                  ]}
                  name={"isVocationalLicenseIssued"}
                  value={formik.values?.isVocationalLicenseIssued}
                  onChange={(v: any) =>
                    formik.setFieldValue("isVocationalLicenseIssued", v)
                  }

                  onBlur={formik.handleBlur}
                  placeHolder={t("isVocationalLicenseIssued")}
                  fullWidth={true}
                />

              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <AutocompleteInput
                  options={[
                    { label: t("PersonalLoan"), value: "1" },
                    { label: t("AutoLoan"), value: "11" },
                    { label: t("ProductLoan"), value: "21" },
                    { label: t("SmeLoan"), value: "51" },
                    { label: t("MortgageLoan"), value: "71" },

                  ]}
                  name={"productType"}
                  value={formik.values?.productType}
                  onChange={(v: any) =>
                    formik.setFieldValue("productType", v)
                  }

                  onBlur={formik.handleBlur}
                  placeHolder={t("productType")}
                  fullWidth={true}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <p>{t("companyRegistrationDate")}</p>
                <TextField
                  className={labelStyle ? classes.input : ''}
                  fullWidth
                  id="companyRegistrationDate"
                  type="date"
                  value={formik.values?.companyRegistrationDate}
                  onChange={(i) => formik.setFieldValue("companyRegistrationDate", (i.target.value))}
                />
              </Grid>

            </Grid>
            <Grid className={classes.footer}>
              <Button onClick={formik.submitForm}
                      variant="contained">{t("saveButton")}</Button>

            </Grid>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
export default CreateContact;