import React from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            '& > * + *': {
                marginTop: theme.spacing(2),
            },
            position:"absolute",
            top:0,


        },
        bar:{
            height:"2vh",

        }
    }),
);

const LoadingBar = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <LinearProgress color="primary" className={classes.bar}/>
        </div>
    );
}

export default LoadingBar
