import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

export const parseDate = (date: Date|undefined,withoutTime?:boolean): string => {

    if(!(date)){
        return "-"
    }
    dayjs.extend(timezone);
    dayjs.extend(utc);
    const djs = dayjs.utc(date)
    let s: string

    if(withoutTime){
        s=djs.tz("Asia/Amman").format("YYYY/MM/DD")
    }
    else {
        s=djs.tz("Asia/Amman").format("YYYY/MM/DD hh:mm")
    }

    return s;
}


export const parseDateOnly = (date: Date|undefined): string => {

    if(!(date)){
        return "-"
    }
    dayjs.extend(timezone);
    dayjs.extend(utc);
    const djs = dayjs.utc(date)
    const s: string = djs.tz("Asia/Amman").format("YYYY/MM/DD")

    return s;
}
