import React, {FunctionComponent} from "react";
import PersonIcon from "@material-ui/icons/Person";
import {ComponentGeneralMortgageLoan} from "../../../../types/content";
import { MortgageLoanModel } from "../../../../types/Request";
import LoanView from "../../LoanView";

interface IProps {
  model?: MortgageLoanModel;
  onEditClick?: () => void;
  withLookUpResolving?: boolean;
  content?: ComponentGeneralMortgageLoan
}

const MortgageLoanInfo: FunctionComponent<IProps> = ({
                                                  onEditClick,
                                                  model,
    withLookUpResolving,
                                                         content
                                                }) => {
  return (
      <LoanView.Card
          title={content?.StepTitle||""}
          icon={<PersonIcon fontSize={"large"}/>}
          onEditClick={onEditClick}
      >
        <LoanView.CardSection>
          <LoanView.SectionItem
              value={
                 model?.realEstateType || ""}
              label={content?.RealEstateType||""}
          />
            <LoanView.SectionItem
                value={model?.realEstatePrice.toString()||""}
                label={content?.RealEstatePrice||""}
            />
        </LoanView.CardSection>
      </LoanView.Card>
  );
};
export default MortgageLoanInfo;
