import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import AppState, {LookUpModel} from "../../../../types/AppState";
import {useState} from "react";
import SimplyModal from "../../../shared/SimplyModal";
import {Box, Button, Grid, TextField} from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";

interface IProps {
    onClose: () => void
    id?: string
    accountManagerId?: number
    open: boolean;
    onSubmit?: () => void
    setState: (e: any) => void
}
const useStyles = makeStyles((theme: Theme) => ({
   
    input: {
        "& .MuiFormLabel-root": {
            left: "auto",
            right: "auto",
            transformOrigin: "top right",
            textAlign: "right",
            paddingRight:"3rem"
        }
    }
}))

const SalesManModal = (props: IProps) => {
    const classes = useStyles()
    const {t,i18n} = useTranslation("requests");
    const salesmen = useSelector((state: AppState) => state.lookUpTables.salesMen)
    const [salesMan, setSalesMan] = useState<SalesManModel>({RequestId: props.id || ""})
    let labelStyle = i18n.language.slice(0, 2).toLowerCase() === "ar" ? true : false;
    const changeHandler = (v: any, newValue: LookUpModel | null) => {
        setSalesMan({...salesMan, AccountManagerId: newValue?.value})
        props.setState({...salesMan, AccountManagerId: newValue?.value})
    }


    return (
        <SimplyModal open={props.open} onClose={() => props.onClose()} title={t('assignSalesMan')}>
            <Box display={"flex"} width={"25vw"} p={1}>
                <Grid container spacing={3}>
                    <Grid style={{alignSelf: "end"}} item xs={12} sm={11} md={12} xl={12}>
                        <Autocomplete
                            value={
                                salesmen?.find((i) => i.value === salesMan?.AccountManagerId?.toString()) || {
                                    label: "",
                                    value: ""
                                }
                            }
                            onChange={changeHandler}
                            options={salesmen}
                            getOptionLabel={(option: any) => option.label}
                            renderInput={(params) => (
                                <TextField
                               className={labelStyle ? classes.input : ''}
                                    {...params}
                                    placeholder={t('salesMan')}
                                    label={t('salesMan')}
                                    fullWidth
                                />
                            )}
                            fullWidth
                        />
                    </Grid>
                    <Grid style={{alignSelf: "end", width: "100%", textAlign: "end"}} item>
                        <Button
                            style={{textTransform: "none"}}
                            onClick={props.onSubmit}
                            color={"primary"}
                            variant={"contained"}
                        >
                            {t('save')}
                        </Button>
                    </Grid>
                </Grid>
            </Box></SimplyModal>
    )
}
export default SalesManModal

export type SalesManModel = {
    RequestId?: string
    AccountManagerId?: string
}
