import './styles/globals.css'
import React, {useEffect} from 'react';
import './App.css';
import {CssBaseline, ThemeProvider} from '@material-ui/core';
import {StylesProvider} from "@material-ui/styles";
import theme from './theme';
import Helmet from 'react-helmet';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {useTranslation} from "react-i18next";
import AnonymousLayout from "./components/layouts/AnonymousLayout";
import LoggedInLayout from "./components/layouts/LoggedInLayout";
import cookiesManager from "./utils/CookiesManager";
import CookieManager from "./utils/CookiesManager";
import SnackBar from "./components/shared/SnackBar";
import {useDispatch, useSelector} from "react-redux";
import IAppStateModel from "./types/AppState";
import {setSnackBar} from "./state/actions/UiActions";
import LoadingBar from "./components/shared/LoadingBar";
import {SetBaseUrl, SetClientConfig} from "@simplify9/simplyapiclient";
import Config from './config';
import {Logout} from "./state/actions/UserActions";


const App = () => {
  const { i18n } = useTranslation();
  const snackBar = useSelector((state: IAppStateModel) => state.snackBar);
  const isLoading = useSelector((state: IAppStateModel) => state.isLoading);
  const isLoggedIn = useSelector((state: IAppStateModel) => state.profile?.id);


  const dispatch = useDispatch()
  useEffect(() => {
    let storedLocale = cookiesManager.getLocale();
    if (storedLocale !== i18n.language) cookiesManager.setLocale(i18n.language);
    SetClientConfig({
      authType: "bearer",
      getBearer: () => CookieManager.getAccessToken(),
      onAuthFail: () => dispatch(Logout()),

    });
    SetBaseUrl(`${Config.baseUrl}`);


  })

  useEffect(() => {
    if (snackBar.open) {
      setTimeout(() => {
        dispatch(setSnackBar({ open: false, message: "", severity: undefined }))
      }, 7429)
    }

  }, [snackBar.open])

  return (
    <>

      <Helmet>
        <title>Tamweelna</title>
      </Helmet>
      <StylesProvider injectFirst>
        <ThemeProvider
          theme={theme(i18n.language.slice(0, 2).toLowerCase() === "ar" ? "rtl" : "ltr")}>
          <div className="App"
               dir={i18n.language.slice(0, 2).toLowerCase() === "ar" ? "rtl" : "ltr"}>
            <CssBaseline/>
            {
              isLoading && <LoadingBar/>
            }

            {isLoggedIn &&
              <SnackBar open={snackBar.open} severity={snackBar.severity}
                        message={snackBar.message}/>}
            <Router>
              <Switch>
                <Route path="/login">
                  <AnonymousLayout/>
                </Route>
                <Route path="/forgetPassword">
                  <AnonymousLayout/>
                </Route>
                <Route path="/">
                  <LoggedInLayout/>
                </Route>
              </Switch>
            </Router>
          </div>
        </ThemeProvider>
      </StylesProvider>
    </>

  )
}

export default App;
