import ValidationClient from "../../../../apiClient/validationClient";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { RequestScoreCriteriaModel } from "../../../../types/RequestValidation";
import { DispatchErrorSnackbar } from "../../../../state/actions/UiActions";
import {
    Button,
    Grid,
    TextField,
    Paper,
    Typography
} from "@material-ui/core";
import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

interface IProps {
    row?: RequestScoreCriteriaModel
    requestTypeId?: number
    jobTypeId?: number
    mortgageDownPaymentWeightFlag?: boolean
    realStateTypeWeightFlag?: boolean
    realStateAgeWeightFlag?: boolean
    automobileAgeWeightFlag?: boolean
    autoLoanDownPaymentWeightFlag?: boolean
    salariedPrivateFlag?: boolean
    selfEmployedFlag?: boolean
    companyRentFlag?: boolean
    companyRentPersonalFlag?: boolean;
    expatSalaryWeightFlag?: boolean;
}

const ValidationsForm = (props: IProps) => {
    const classes = useStyles()
    const validationClient = new ValidationClient();
    const { t, i18n } = useTranslation("validations")
    const dispatch = useDispatch()
    let labelStyle = i18n.language.slice(0, 2).toLowerCase() === "ar" ? true : false;
    const formik = useFormik<RequestScoreCriteriaModel>({
        initialValues: {
            companyForm: props.row?.companyForm,
            requestType: props.row?.requestType,
            jobType: props.row?.jobType,
            vocationalLicenseWeight: {
                issued: props.row?.vocationalLicenseWeight?.issued ?? 0,
                notIssued: props.row?.vocationalLicenseWeight?.notIssued ?? 0,
            },
            socialSecurityWeight: {
                enrolled: props.row?.socialSecurityWeight?.enrolled ?? 0,
                notEnrolled: props.row?.socialSecurityWeight?.notEnrolled ?? 0,
            },
            // realStateTypeWeight: {
            //     apartment: props.row?.realStateTypeWeight?.apartment ?? 0,
            //     villa: props.row?.realStateTypeWeight?.villa ?? 0,
            //     residentialLand: props.row?.realStateTypeWeight?.residentialLand ?? 0,
            // },
            backgroundCheckWeight: {
                noRecord: props.row?.backgroundCheckWeight?.noRecord ?? 0,
                lowRecord: props.row?.backgroundCheckWeight?.lowRecord ?? 0,
                mediumRecord: props.row?.backgroundCheckWeight?.mediumRecord ?? 0,
                highRecord: props.row?.backgroundCheckWeight?.highRecord ?? 0,
            },
            realStateAgeWeight: {
                rangeA: props.row?.realStateAgeWeight?.rangeA ?? 0,
                rangeB: props.row?.realStateAgeWeight?.rangeB ?? 0,
                rangeC: props.row?.realStateAgeWeight?.rangeC ?? 0,
                rangeD: props.row?.realStateAgeWeight?.rangeD ?? 0,
            },
            automobileAgeWeight: {
                rangeA: props.row?.automobileAgeWeight?.rangeA ?? 0,
                rangeB: props.row?.automobileAgeWeight?.rangeB ?? 0,
                rangeC: props.row?.automobileAgeWeight?.rangeC ?? 0,
                rangeD: props.row?.automobileAgeWeight?.rangeD ?? 0,
            },
            companyRegistrationDateWeight: {
                rangeA: props.row?.companyRegistrationDateWeight?.rangeA ?? 0,
                rangeB: props.row?.companyRegistrationDateWeight?.rangeB ?? 0,
                rangeC: props.row?.companyRegistrationDateWeight?.rangeC ?? 0,
                rangeD: props.row?.companyRegistrationDateWeight?.rangeD ?? 0,
                rangeE: props.row?.companyRegistrationDateWeight?.rangeE ?? 0,
            },
            salaryWeight: {
                rangeA: props.row?.salaryWeight?.rangeA ?? 0,
                rangeB: props.row?.salaryWeight?.rangeB ?? 0,
                rangeC: props.row?.salaryWeight?.rangeC ?? 0,
                rangeD: props.row?.salaryWeight?.rangeD ?? 0,
                rangeE: props.row?.salaryWeight?.rangeE ?? 0,
            },
            mortgageDownPaymentWeight: {
                rangeA: props.row?.mortgageDownPaymentWeight?.rangeA ?? 0,
                rangeB: props.row?.mortgageDownPaymentWeight?.rangeB ?? 0,
                rangeC: props.row?.mortgageDownPaymentWeight?.rangeC ?? 0,
                rangeD: props.row?.mortgageDownPaymentWeight?.rangeD ?? 0,
                rangeE: props.row?.mortgageDownPaymentWeight?.rangeE ?? 0,
                rangeF: props.row?.mortgageDownPaymentWeight?.rangeF ?? 0,
                rangeG: props.row?.mortgageDownPaymentWeight?.rangeG ?? 0,
            },
            autoLoanDownPaymentWeight: {
                rangeA: props.row?.autoLoanDownPaymentWeight?.rangeA ?? 0,
                rangeB: props.row?.autoLoanDownPaymentWeight?.rangeB ?? 0,
                rangeC: props.row?.autoLoanDownPaymentWeight?.rangeC ?? 0,
                rangeD: props.row?.autoLoanDownPaymentWeight?.rangeD ?? 0,
                rangeE: props.row?.autoLoanDownPaymentWeight?.rangeE ?? 0,
                rangeF: props.row?.autoLoanDownPaymentWeight?.rangeF ?? 0,
            },
            employmentDateWeight: {
                rangeA: props.row?.employmentDateWeight?.rangeA ?? 0,
                rangeB: props.row?.employmentDateWeight?.rangeB ?? 0,
                rangeC: props.row?.employmentDateWeight?.rangeC ?? 0,
                rangeD: props.row?.employmentDateWeight?.rangeD ?? 0,
                rangeE: props.row?.employmentDateWeight?.rangeE ?? 0,
                rangeF: props.row?.employmentDateWeight?.rangeF ?? 0,
                rangeG: props.row?.employmentDateWeight?.rangeG ?? 0,
            },
            dbrWeight: {
                rangeA: props.row?.dbrWeight?.rangeA ?? 0,
                rangeB: props.row?.dbrWeight?.rangeB ?? 0,
                rangeC: props.row?.dbrWeight?.rangeC ?? 0,
                rangeD: props.row?.dbrWeight?.rangeD ?? 0,
                rangeE: props.row?.dbrWeight?.rangeE ?? 0,
                rangeF: props.row?.dbrWeight?.rangeF ?? 0,
                rangeG: props.row?.dbrWeight?.rangeG ?? 0,
                rangeH: props.row?.dbrWeight?.rangeH ?? 0,
            },
            bankTransferMethodWeight: {
                cashDepositWeight: props.row?.bankTransferMethodWeight?.cashDepositWeight ?? 0,
                checkDepositWeight: props.row?.bankTransferMethodWeight?.checkDepositWeight ?? 0,
                salarySlipWeight: props.row?.bankTransferMethodWeight?.salarySlipWeight ?? 0,
                notAvailableWeight: props.row?.bankTransferMethodWeight?.notAvailableWeight ?? 0,
            },
            commercialLicenseWeight: {
                provided: props.row?.commercialLicenseWeight?.provided ?? 0,
                notProvided: props.row?.commercialLicenseWeight?.notProvided ?? 0,
            },
            hasCheckBookWeight: {
                provided: props.row?.hasCheckBookWeight?.provided ?? 0,
                notProvided: props.row?.hasCheckBookWeight?.notProvided ?? 0,
            },
            depositsWeight: {
                rangeA: props.row?.depositsWeight?.rangeA ?? 0,
                rangeB: props.row?.depositsWeight?.rangeB ?? 0,
                rangeC: props.row?.depositsWeight?.rangeC ?? 0,
                rangeD: props.row?.depositsWeight?.rangeD ?? 0,
                rangeE: props.row?.depositsWeight?.rangeE ?? 0,
            },
            hasStatementOfAccountsWeight: {
                provided: props.row?.hasStatementOfAccountsWeight?.provided ?? 0,
                notProvided: props.row?.hasStatementOfAccountsWeight?.notProvided ?? 0,
            },
            companyRateWeight: {
                a: props.row?.companyRateWeight?.a ?? 0,
                b: props.row?.companyRateWeight?.b ?? 0,
                c: props.row?.companyRateWeight?.c ?? 0,
                other: props.row?.companyRateWeight?.other ?? 0,
            },
            companyOfficeRentContractPeriodWeight: {
                rangeA: props.row?.companyOfficeRentContractPeriodWeight?.rangeA ?? 0,
                rangeB: props.row?.companyOfficeRentContractPeriodWeight?.rangeB ?? 0,
                rangeC: props.row?.companyOfficeRentContractPeriodWeight?.rangeC ?? 0,
                rangeD: props.row?.companyOfficeRentContractPeriodWeight?.rangeD ?? 0,
            },
            expatSalaryWeight: {
                rangeA: props.row?.expatSalaryWeight?.rangeA ?? 0,
                rangeB: props.row?.expatSalaryWeight?.rangeB ?? 0,
                rangeC: props.row?.expatSalaryWeight?.rangeC ?? 0,
            }
        },
        validateOnBlur: true,
        onSubmit: async (values, { resetForm }) => {
            let res
            console.log("values to update", values)
            res = await validationClient.Update(values, props.row?.id!);

            if (res.succeeded) {

                window.location.reload();
            } else {
                dispatch(DispatchErrorSnackbar(`Something went wrong!`))
            }
        },
    });
    return (
        <div>
            <Grid container spacing={5}>
                <Grid item xs={12} sm={11} md={4} xl={4}>
                    <Paper className={classes.section}>
                        <Grid item>
                            {props.selfEmployedFlag ?
                                <Typography className={classes.title} >{t("BusinessLicenseWeight")}</Typography>
                                :
                                <Typography className={classes.title} >{t("VocationalLicenseWeight")}</Typography>
                            }
                        </Grid>
                        <TextField
                            className={labelStyle ? classes.input : ''}
                            type={"number"}
                            value={formik.values.vocationalLicenseWeight?.issued}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            placeholder={t('Issued')}
                            name={"vocationalLicenseWeight.issued"}
                            fullWidth
                            label={t('Issued')}
                        />
                        <TextField
                            className={labelStyle ? classes.input : ''}
                            type={"number"}
                            value={formik.values.vocationalLicenseWeight?.notIssued}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            placeholder={t('NotIssued')}
                            name={"vocationalLicenseWeight.notIssued"}
                            fullWidth
                            label={t('NotIssued')}
                        />
                    </Paper>
                </Grid>
                {!props.salariedPrivateFlag &&
                     <Grid item xs={12} sm={11} md={4} xl={4}>
                        <Paper className={classes.section}>

                            <Grid item>
                                <Typography
                                    className={classes.title}>{t("CommercialLicenseWeight")}</Typography>
                            </Grid>

                            <TextField
                                className={labelStyle ? classes.input : ''}
                                type={"number"}
                                value={formik.values.commercialLicenseWeight?.provided}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                placeholder={t('Provided')}
                                name={"commercialLicenseWeight.provided"}
                                fullWidth
                                label={t('Provided')}
                            />
                            <TextField
                                className={labelStyle ? classes.input : ''}
                                type={"number"}
                                value={formik.values.commercialLicenseWeight?.notProvided}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                placeholder={t('NotProvided')}
                                name={"commercialLicenseWeight.notProvided"}
                                fullWidth
                                label={t('NotProvided')}
                            />

                        </Paper>
                    </Grid>
                }
               
                <Grid item xs={12} sm={11} md={4} xl={4}>
                    <Paper className={classes.section}>

                        <Grid item>
                            <Typography
                                className={classes.title}>{t("StatementOfAccountsWeight")}</Typography>
                        </Grid>

                        <TextField
                            className={labelStyle ? classes.input : ''}
                            type={"number"}
                            value={formik.values.hasStatementOfAccountsWeight?.provided}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            placeholder={t('Provided')}
                            name={"hasStatementOfAccountsWeight.provided"}
                            fullWidth
                            label={t('Provided')}
                        />
                        <TextField
                            className={labelStyle ? classes.input : ''}
                            type={"number"}
                            value={formik.values.hasStatementOfAccountsWeight?.notProvided}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            placeholder={t('NotProvided')}
                            name={"hasStatementOfAccountsWeight.notProvided"}
                            fullWidth
                            label={t('NotProvided')}
                        />

                    </Paper>
                </Grid>
                <Grid item xs={12} sm={11} md={4} xl={4}>
                    <Paper className={classes.section}>

                        <Grid item>
                            <Typography
                                className={classes.title}>{t("CheckBookWeight")}</Typography>
                        </Grid>

                        <TextField
                            className={labelStyle ? classes.input : ''}
                            type={"number"}
                            value={formik.values.hasCheckBookWeight?.provided}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            placeholder={t('Provided')}
                            name={"hasCheckBookWeight.provided"}
                            fullWidth
                            label={t('Provided')}
                        />
                        <TextField
                            className={labelStyle ? classes.input : ''}
                            type={"number"}
                            value={formik.values.hasCheckBookWeight?.notProvided}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            placeholder={t('NotProvided')}
                            name={"hasCheckBookWeight.notProvided"}
                            fullWidth
                            label={t('NotProvided')}
                        />

                    </Paper>
                </Grid>
                <Grid item xs={12} sm={11} md={4} xl={4}>
                    <Paper className={classes.section}>

                        <Grid item>
                            <Typography
                                className={classes.title}>{t("SocialSecurityWeight")}</Typography>
                        </Grid>

                        <TextField
                            className={labelStyle ? classes.input : ''}
                            type={"number"}
                            value={formik.values.socialSecurityWeight?.enrolled}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            placeholder={t('Enrolled')}
                            name={"socialSecurityWeight.enrolled"}
                            fullWidth
                            label={t('Enrolled')}
                        />
                        <TextField
                            className={labelStyle ? classes.input : ''}
                            type={"number"}
                            value={formik.values.socialSecurityWeight?.notEnrolled}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            placeholder={t('NotEnrolled')}
                            name={"socialSecurityWeight.notEnrolled"}
                            fullWidth
                            label={t('NotEnrolled')}
                        />

                    </Paper>
                </Grid>

                {props.expatSalaryWeightFlag &&

                    <Grid item xs={12} sm={11} md={4} xl={4}>
                        <Paper className={classes.section}>

                            <Grid item>
                                <Typography
                                    className={classes.title}>{t("ExpatSalaryWeight")}</Typography>
                            </Grid>
                            <TextField
                                className={labelStyle ? classes.input : ''}
                                type={"number"}
                                value={formik.values.expatSalaryWeight?.rangeA}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                placeholder={t('ExpatSalaryWeightRangeA')}
                                name={"expatSalaryWeight.rangeA"}
                                fullWidth
                                label={t('ExpatSalaryWeightRangeA')}
                            />
                            <TextField
                                className={labelStyle ? classes.input : ''}
                                type={"number"}
                                value={formik.values.expatSalaryWeight?.rangeB}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                placeholder={t('ExpatSalaryWeightRangeB')}
                                name={"expatSalaryWeight.rangeB"}
                                fullWidth
                                label={t('ExpatSalaryWeightRangeB')}
                            />
                            <TextField
                                className={labelStyle ? classes.input : ''}
                                type={"number"}
                                value={formik.values.expatSalaryWeight?.rangeC}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                placeholder={t('ExpatSalaryWeightRangeC')}
                                name={"expatSalaryWeight.rangeC"}
                                fullWidth
                                label={t('ExpatSalaryWeightRangeC')}
                            />

                        </Paper>
                    </Grid>
                }
                {/* {props.realStateTypeWeightFlag &&
                    <Grid item xs={12} sm={11} md={4} xl={4}>

                        <Paper className={classes.section}>

                            <Grid item>
                                <Typography
                                    className={classes.title}>{t("RealStateTypeWeight")}</Typography>
                            </Grid>

                            <TextField
                                className={labelStyle ? classes.input : ''}
                                type={"number"}
                                value={formik.values.realStateTypeWeight?.apartment}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                placeholder={t('Apartment')}
                                name={"realStateTypeWeight.apartment"}
                                fullWidth
                                label={t('Apartment')}
                            />
                            <TextField
                                className={labelStyle ? classes.input : ''}
                                type={"number"}
                                value={formik.values.realStateTypeWeight?.villa}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                placeholder={t('Villa')}
                                name={"realStateTypeWeight.villa"}
                                fullWidth
                                label={t('Villa')}
                            />
                            <TextField
                                className={labelStyle ? classes.input : ''}
                                type={"number"}
                                value={formik.values.realStateTypeWeight?.residentialLand}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                placeholder={t('ResidentialLand')}
                                name={"realStateTypeWeight.residentialLand"}
                                fullWidth
                                label={t('ResidentialLand')}
                            />
                        </Paper>
                    </Grid>
                } */}
                <Grid item xs={12} sm={11} md={4} xl={4}>
                    <Paper className={classes.section}>
                        <Grid item>
                            <Typography
                                className={classes.title}>{t("BankTransferMethodWeight")}</Typography>
                        </Grid>
                        <TextField
                            className={labelStyle ? classes.input : ''}
                            type={"number"}
                            value={formik.values.bankTransferMethodWeight?.cashDepositWeight}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            placeholder={t('CashDeposit')}
                            name={"bankTransferMethodWeight.cashDepositWeight"}
                            fullWidth
                            label={t('CashDeposit')}
                        />
                        <TextField
                            className={labelStyle ? classes.input : ''}
                            type={"number"}
                            value={formik.values.bankTransferMethodWeight?.checkDepositWeight}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            placeholder={t('CheckDeposit')}
                            name={"bankTransferMethodWeight.checkDepositWeight"}
                            fullWidth
                            label={t('CheckDeposit')}
                        />
                        <TextField
                            className={labelStyle ? classes.input : ''}
                            type={"number"}
                            value={formik.values.bankTransferMethodWeight?.salarySlipWeight}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            placeholder={t('SalarySlip')}
                            name={"bankTransferMethodWeight.salarySlipWeight"}
                            fullWidth
                            label={t('SalarySlip')}
                        />
                        <TextField
                            className={labelStyle ? classes.input : ''}
                            type={"number"}
                            value={formik.values.bankTransferMethodWeight?.notAvailableWeight}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            placeholder={t('NA')}
                            name={"bankTransferMethodWeight.notAvailableWeight"}
                            fullWidth
                            label={t('NA')}
                        />
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={11} md={4} xl={4}>
                    <Paper className={classes.section}>
                        <Grid item>
                            <Typography className={classes.title} >{t("BackgroundCheckWeight")}</Typography>
                        </Grid>
                        <TextField
                            className={labelStyle ? classes.input : ''}
                            type={"number"}
                            value={formik.values.backgroundCheckWeight?.noRecord}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            placeholder={t('NoRecord')}
                            name={"backgroundCheckWeight.noRecord"}
                            fullWidth
                            label={t('NoRecord')}
                        />
                        <TextField
                            className={labelStyle ? classes.input : ''}
                            type={"number"}
                            value={formik.values.backgroundCheckWeight?.lowRecord}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            placeholder={t('LowRecord')}
                            name={"backgroundCheckWeight.lowRecord"}
                            fullWidth
                            label={t('LowRecord')}
                        />
                        <TextField
                            className={labelStyle ? classes.input : ''}
                            type={"number"}
                            value={formik.values.backgroundCheckWeight?.mediumRecord}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            placeholder={t('MediumRecord')}
                            name={"backgroundCheckWeight.mediumRecord"}
                            fullWidth
                            label={t('MediumRecord')}
                        />
                        <TextField
                            className={labelStyle ? classes.input : ''}
                            type={"number"}
                            value={formik.values.backgroundCheckWeight?.highRecord}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            placeholder={t('HighRecord')}
                            name={"backgroundCheckWeight.highRecord"}
                            fullWidth
                            label={t('HighRecord')}
                        />
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={11} md={4} xl={4}>
                    <Paper className={classes.section}>
                        <Grid item>
                            <Typography className={classes.title} >{t("CompanyRateWeight")}</Typography>
                        </Grid>
                        <TextField
                            className={labelStyle ? classes.input : ''}
                            type={"number"}
                            value={formik.values.companyRateWeight?.a}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            placeholder={t('A')}
                            name={"companyRateWeight.a"}
                            fullWidth
                            label={t('A')}
                        />

                        <TextField
                            className={labelStyle ? classes.input : ''}
                            type={"number"}
                            value={formik.values.companyRateWeight?.b}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            placeholder={t('B')}
                            name={"companyRateWeight.b"}
                            fullWidth
                            label={t('B')}
                        />
                        <TextField
                            className={labelStyle ? classes.input : ''}
                            type={"number"}
                            value={formik.values.companyRateWeight?.c}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            placeholder={t('C')}
                            name={"companyRateWeight.c"}
                            fullWidth
                            label={t('C')}
                        />
                        <TextField
                            className={labelStyle ? classes.input : ''}
                            type={"number"}
                            value={formik.values.companyRateWeight?.other}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            placeholder={t('Other')}
                            name={"companyRateWeight.other"}
                            fullWidth
                            label={t('Other')}
                        />
                    </Paper>
                </Grid>
                {(props.companyRentFlag || props.companyRentPersonalFlag) &&
                    <Grid item xs={12} sm={11} md={4} xl={4}>
                        <Paper className={classes.section}>
                            <Grid item>
                                <Typography className={classes.title} >{t("CompanyOfficeRentContractPeriodWeight")}</Typography>
                            </Grid>
                            <TextField
                                className={labelStyle ? classes.input : ''}
                                type={"number"}
                                value={formik.values.companyOfficeRentContractPeriodWeight?.rangeA}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                // error={formik.touched.requestScoreDataCriteria. && Boolean(formik.errors.)}
                                placeholder={t('CompanyOfficeRentContractPeriodWeightRangeA')}
                                name={"companyOfficeRentContractPeriodWeight.rangeA"}
                                fullWidth
                                label={t('CompanyOfficeRentContractPeriodWeightRangeA')}
                            />
                            <TextField
                                className={labelStyle ? classes.input : ''}
                                type={"number"}
                                value={formik.values.companyOfficeRentContractPeriodWeight?.rangeB}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                // error={formik.touched.requestScoreDataCriteria. && Boolean(formik.errors.)}
                                placeholder={t('CompanyOfficeRentContractPeriodWeightRangeB')}
                                name={"companyOfficeRentContractPeriodWeight.rangeB"}
                                fullWidth
                                label={t('CompanyOfficeRentContractPeriodWeightRangeB')}
                            />
                            <TextField
                                className={labelStyle ? classes.input : ''}
                                type={"number"}
                                value={formik.values.companyOfficeRentContractPeriodWeight?.rangeC}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                // error={formik.touched.requestScoreDataCriteria. && Boolean(formik.errors.)}
                                placeholder={t('CompanyOfficeRentContractPeriodWeightRangeC')}
                                name={"companyOfficeRentContractPeriodWeight.rangeC"}
                                fullWidth
                                label={t('CompanyOfficeRentContractPeriodWeightRangeC')}
                            />
                            <TextField
                                className={labelStyle ? classes.input : ''}
                                type={"number"}
                                value={formik.values.companyOfficeRentContractPeriodWeight?.rangeD}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                // error={formik.touched.requestScoreDataCriteria. && Boolean(formik.errors.)}
                                placeholder={t('CompanyOfficeRentContractPeriodWeightRangeD')}
                                name={"companyOfficeRentContractPeriodWeight.rangeD"}
                                fullWidth
                                label={t('CompanyOfficeRentContractPeriodWeightRangeD')}
                            />
                        </Paper>
                    </Grid>
                }
                {props.realStateAgeWeightFlag &&
                    <Grid item xs={12} sm={11} md={4} xl={4}>
                        <Paper className={classes.section}>
                            <Grid item>
                                <Typography className={classes.title} >{t("RealStateAgeWeight")}</Typography>
                            </Grid>

                            <TextField
                                className={labelStyle ? classes.input : ''}
                                type={"number"}
                                value={formik.values.realStateAgeWeight?.rangeA}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                placeholder={t('RealStateAgeWeightRangeA')}
                                name={"realStateAgeWeight.rangeA"}
                                fullWidth
                                label={t('RealStateAgeWeightRangeA')}
                            />
                            <TextField
                                className={labelStyle ? classes.input : ''}
                                type={"number"}
                                value={formik.values.realStateAgeWeight?.rangeB}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                // error={formik.touched.requestScoreDataCriteria. && Boolean(formik.errors.)}
                                placeholder={t('RealStateAgeWeightRangeB')}
                                name={"realStateAgeWeight.rangeB"}
                                fullWidth
                                label={t('RealStateAgeWeightRangeB')}
                            />
                            <TextField
                                className={labelStyle ? classes.input : ''}
                                type={"number"}
                                value={formik.values.realStateAgeWeight?.rangeC}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                // error={formik.touched.requestScoreDataCriteria. && Boolean(formik.errors.)}
                                placeholder={t('RealStateAgeWeightRangeC')}
                                name={"realStateAgeWeight.rangeC"}
                                fullWidth
                                label={t('RealStateAgeWeightRangeC')}
                            />
                            <TextField
                                className={labelStyle ? classes.input : ''}
                                type={"number"}
                                value={formik.values.realStateAgeWeight?.rangeD}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                // error={formik.touched.requestScoreDataCriteria. && Boolean(formik.errors.)}
                                placeholder={t('RealStateAgeWeightRangeD')}
                                name={"realStateAgeWeight.rangeD"}
                                fullWidth
                                label={t('RealStateAgeWeightRangeD')}
                            />
                        </Paper>
                    </Grid>
                }
                {props.automobileAgeWeightFlag &&

                    <Grid item xs={12} sm={11} md={4} xl={4}>
                        <Paper className={classes.section}>

                            <Grid item>
                                <Typography
                                    className={classes.title}>{t("AutomobileAgeWeight")}</Typography>
                            </Grid>

                            <TextField
                                className={labelStyle ? classes.input : ''}
                                type={"number"}
                                value={formik.values.automobileAgeWeight?.rangeA}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                // error={formik.touched.requestScoreDataCriteria. && Boolean(formik.errors.)}
                                placeholder={t('AutomobileAgeWeightRangeA')}
                                name={"automobileAgeWeight.rangeA"}
                                fullWidth
                                label={t('AutomobileAgeWeightRangeA')}
                            />
                            <TextField
                                className={labelStyle ? classes.input : ''}
                                type={"number"}
                                value={formik.values.automobileAgeWeight?.rangeB}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                // error={formik.touched.requestScoreDataCriteria. && Boolean(formik.errors.)}
                                placeholder={t('AutomobileAgeWeightRangeB')}
                                name={"automobileAgeWeight.rangeB"}
                                fullWidth
                                label={t('AutomobileAgeWeightRangeB')}
                            />
                            <TextField
                                className={labelStyle ? classes.input : ''}
                                type={"number"}
                                value={formik.values.automobileAgeWeight?.rangeC}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                // error={formik.touched.requestScoreDataCriteria. && Boolean(formik.errors.)}
                                placeholder={t('AutomobileAgeWeightRangeC')}
                                name={"automobileAgeWeight.rangeC"}
                                fullWidth
                                label={t('AutomobileAgeWeightRangeC')}
                            />
                            <TextField
                                className={labelStyle ? classes.input : ''}
                                type={"number"}
                                value={formik.values.automobileAgeWeight?.rangeD}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                placeholder={t('AutomobileAgeWeightRangeD')}
                                name={"automobileAgeWeight.rangeD"}
                                fullWidth
                                label={t('AutomobileAgeWeightRangeD')}
                            />
                        </Paper>
                    </Grid>
                }
                <Grid item xs={12} sm={11} md={4} xl={4}>
                    <Paper className={classes.section}>

                        <Grid item>
                            <Typography
                                className={classes.title}>{t("CompanyRegistrationDateWeight")}</Typography>
                        </Grid>

                        <TextField
                            className={labelStyle ? classes.input : ''}
                            type={"number"}
                            value={formik.values.companyRegistrationDateWeight?.rangeA}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            // error={formik.touched.requestScoreDataCriteria. && Boolean(formik.errors.)}
                            placeholder={t('CompanyRegistrationDateWeightRangeA')}
                            name={"companyRegistrationDateWeight.rangeA"}
                            fullWidth
                            label={t('CompanyRegistrationDateWeightRangeA')}
                        />
                        <TextField
                            className={labelStyle ? classes.input : ''}
                            type={"number"}
                            value={formik.values.companyRegistrationDateWeight?.rangeB}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            // error={formik.touched.requestScoreDataCriteria. && Boolean(formik.errors.)}
                            placeholder={t('CompanyRegistrationDateWeightRangeB')}
                            name={"companyRegistrationDateWeight.rangeB"}
                            fullWidth
                            label={t('CompanyRegistrationDateWeightRangeB')}
                        />
                        <TextField
                            className={labelStyle ? classes.input : ''}
                            type={"number"}
                            value={formik.values.companyRegistrationDateWeight?.rangeC}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            // error={formik.touched.requestScoreDataCriteria. && Boolean(formik.errors.)}
                            placeholder={t('CompanyRegistrationDateWeightRangeC')}
                            name={"companyRegistrationDateWeight.rangeC"}
                            fullWidth
                            label={t('CompanyRegistrationDateWeightRangeC')}
                        />
                        <TextField
                            className={labelStyle ? classes.input : ''}
                            type={"number"}
                            value={formik.values.companyRegistrationDateWeight?.rangeD}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            // error={formik.touched.requestScoreDataCriteria. && Boolean(formik.errors.)}
                            placeholder={t('CompanyRegistrationDateWeightRangeD')}
                            name={"companyRegistrationDateWeight.rangeD"}
                            fullWidth
                            label={t('CompanyRegistrationDateWeightRangeD')}
                        />
                        <TextField
                            className={labelStyle ? classes.input : ''}
                            type={"number"}
                            value={formik.values.companyRegistrationDateWeight?.rangeE}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            // error={formik.touched.requestScoreDataCriteria. && Boolean(formik.errors.)}
                            placeholder={t('CompanyRegistrationDateWeightRangeE')}
                            name={"companyRegistrationDateWeight.rangeE"}
                            fullWidth
                            label={t('CompanyRegistrationDateWeightRangeE')}
                        />

                    </Paper>
                </Grid>
                {!props.expatSalaryWeightFlag &&
                    <Grid item xs={12} sm={11} md={4} xl={4}>
                        <Paper className={classes.section}>

                            <Grid item>
                                <Typography className={classes.title} >{t("SalaryWeight")}</Typography>
                            </Grid>
                            <TextField
                                className={labelStyle ? classes.input : ''}
                                type={"number"}
                                value={formik.values.salaryWeight?.rangeA}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                // error={formik.touched.requestScoreDataCriteria. && Boolean(formik.errors.)}
                                placeholder={t('SalaryWeightRangeA')}
                                name={"salaryWeight.rangeA"}
                                fullWidth
                                label={t('SalaryWeightRangeA')}
                            />
                            <TextField
                                className={labelStyle ? classes.input : ''}
                                type={"number"}
                                value={formik.values.salaryWeight?.rangeB}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                // error={formik.touched.requestScoreDataCriteria. && Boolean(formik.errors.)}
                                placeholder={t('SalaryWeightRangeB')}
                                name={"salaryWeight.rangeB"}
                                fullWidth
                                label={t('SalaryWeightRangeB')}
                            />
                            <TextField
                                className={labelStyle ? classes.input : ''}
                                type={"number"}
                                value={formik.values.salaryWeight?.rangeC}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                // error={formik.touched.requestScoreDataCriteria. && Boolean(formik.errors.)}
                                placeholder={t('SalaryWeightRangeC')}
                                name={"salaryWeight.rangeC"}
                                fullWidth
                                label={t('SalaryWeightRangeC')}
                            />
                            <TextField
                                className={labelStyle ? classes.input : ''}
                                type={"number"}
                                value={formik.values.salaryWeight?.rangeD}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                // error={formik.touched.requestScoreDataCriteria. && Boolean(formik.errors.)}
                                placeholder={t('SalaryWeightRangeD')}
                                name={"salaryWeight.rangeD"}
                                fullWidth
                                label={t('SalaryWeightRangeD')}
                            />
                            <TextField
                                className={labelStyle ? classes.input : ''}
                                type={"number"}
                                value={formik.values.salaryWeight?.rangeE}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                // error={formik.touched.requestScoreDataCriteria. && Boolean(formik.errors.)}
                                placeholder={t('SalaryWeightRangeE')}
                                name={"salaryWeight.rangeE"}
                                fullWidth
                                label={t('SalaryWeightRangeE')}
                            />
                        </Paper>
                    </Grid>
                }
                <Grid item xs={12} sm={11} md={4} xl={4}>
                    <Paper className={classes.section}>

                        <Grid item>
                            <Typography className={classes.title} >{t("DepositsWeight")}</Typography>
                        </Grid>
                        <TextField
                            className={labelStyle ? classes.input : ''}
                            type={"number"}
                            value={formik.values.depositsWeight?.rangeA}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            placeholder={t('DepositsWeightRangeA')}
                            name={"depositsWeight.rangeA"}
                            fullWidth
                            label={t('DepositsWeightRangeA')}
                        />
                        <TextField
                            className={labelStyle ? classes.input : ''}
                            type={"number"}
                            value={formik.values.depositsWeight?.rangeB}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            placeholder={t('DepositsWeightRangeB')}
                            name={"depositsWeight.rangeB"}
                            fullWidth
                            label={t('DepositsWeightRangeB')}
                        />
                        <TextField
                            className={labelStyle ? classes.input : ''}
                            type={"number"}
                            value={formik.values.depositsWeight?.rangeC}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            placeholder={t('DepositsWeightRangeC')}
                            name={"depositsWeight.rangeC"}
                            fullWidth
                            label={t('DepositsWeightRangeC')}
                        />
                        <TextField
                            className={labelStyle ? classes.input : ''}
                            type={"number"}
                            value={formik.values.depositsWeight?.rangeD}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            placeholder={t('DepositsWeightRangeD')}
                            name={"depositsWeight.rangeD"}
                            fullWidth
                            label={t('DepositsWeightRangeD')}
                        />
                        <TextField
                            className={labelStyle ? classes.input : ''}
                            type={"number"}
                            value={formik.values.depositsWeight?.rangeE}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            placeholder={t('DepositsWeightRangeE')}
                            name={"depositsWeight.rangeE"}
                            fullWidth
                            label={t('DepositsWeightRangeE')}
                        />
                    </Paper>
                </Grid>
                {props.mortgageDownPaymentWeightFlag &&
                    <Grid item xs={12} sm={11} md={4} xl={4}>
                        <Paper className={classes.section}>

                            <Grid item>
                                <Typography className={classes.title}>{t("MortgageDownPaymentWeight")}</Typography>
                            </Grid>

                            <TextField
                                className={labelStyle ? classes.input : ''}
                                type={"number"}
                                value={formik.values.mortgageDownPaymentWeight?.rangeA}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                // error={formik.touched.requestScoreDataCriteria. && Boolean(formik.errors.)}
                                placeholder={t('MortgageDownPaymentWeightRangeA')}
                                name={"mortgageDownPaymentWeight.rangeA"}
                                fullWidth
                                label={t('MortgageDownPaymentWeightRangeA')}
                            />
                            <TextField
                                className={labelStyle ? classes.input : ''}
                                type={"number"}
                                value={formik.values.mortgageDownPaymentWeight?.rangeB}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                // error={formik.touched.requestScoreDataCriteria. && Boolean(formik.errors.)}
                                placeholder={t('MortgageDownPaymentWeightRangeB')}
                                name={"mortgageDownPaymentWeight.rangeB"}
                                fullWidth
                                label={t('MortgageDownPaymentWeightRangeB')}
                            />
                            <TextField
                                className={labelStyle ? classes.input : ''}
                                type={"number"}
                                value={formik.values.mortgageDownPaymentWeight?.rangeC}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                // error={formik.touched.requestScoreDataCriteria. && Boolean(formik.errors.)}
                                placeholder={t('MortgageDownPaymentWeightRangeC')}
                                name={"mortgageDownPaymentWeight.rangeC"}
                                fullWidth
                                label={t('MortgageDownPaymentWeightRangeC')}
                            />
                            <TextField
                                className={labelStyle ? classes.input : ''}
                                type={"number"}
                                value={formik.values.mortgageDownPaymentWeight?.rangeD}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                // error={formik.touched.requestScoreDataCriteria. && Boolean(formik.errors.)}
                                placeholder={t('MortgageDownPaymentWeightRangeD')}
                                name={"mortgageDownPaymentWeight.rangeD"}
                                fullWidth
                                label={t('MortgageDownPaymentWeightRangeD')}
                            />

                            <TextField
                                className={labelStyle ? classes.input : ''}
                                type={"number"}
                                value={formik.values.mortgageDownPaymentWeight?.rangeE}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                // error={formik.touched.requestScoreDataCriteria. && Boolean(formik.errors.)}
                                placeholder={t('MortgageDownPaymentWeightRangeE')}
                                name={"mortgageDownPaymentWeight.rangeE"}
                                fullWidth
                                label={t('MortgageDownPaymentWeightRangeE')}
                            />

                            <TextField
                                className={labelStyle ? classes.input : ''}
                                type={"number"}
                                value={formik.values.mortgageDownPaymentWeight?.rangeF}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                // error={formik.touched.requestScoreDataCriteria. && Boolean(formik.errors.)}
                                placeholder={t('MortgageDownPaymentWeightRangeF')}
                                name={"mortgageDownPaymentWeight.rangeF"}
                                fullWidth
                                label={t('MortgageDownPaymentWeightRangeF')}
                            />
                        </Paper>
                    </Grid>
                }
                {props.autoLoanDownPaymentWeightFlag &&
                    <Grid item xs={12} sm={11} md={4} xl={4}>
                        <Paper className={classes.section}>

                            <Grid item>
                                <Typography className={classes.title} >{t("AutoLoanDownPaymentWeight")}</Typography>
                            </Grid>

                            <TextField
                                className={labelStyle ? classes.input : ''}
                                type={"number"}
                                value={formik.values.autoLoanDownPaymentWeight?.rangeA}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                // error={formik.touched.requestScoreDataCriteria. && Boolean(formik.errors.)}
                                placeholder={t('AutoLoanDownPaymentWeightRangeA')}
                                name={"autoLoanDownPaymentWeight.rangeA"}
                                fullWidth
                                label={t('AutoLoanDownPaymentWeightRangeA')}
                            />
                            <TextField
                                className={labelStyle ? classes.input : ''}
                                type={"number"}
                                value={formik.values.autoLoanDownPaymentWeight?.rangeB}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                // error={formik.touched.requestScoreDataCriteria. && Boolean(formik.errors.)}
                                placeholder={t('AutoLoanDownPaymentWeightRangeB')}
                                name={"autoLoanDownPaymentWeight.rangeB"}
                                fullWidth
                                label={t('AutoLoanDownPaymentWeightRangeB')}
                            />
                            <TextField
                                className={labelStyle ? classes.input : ''}
                                type={"number"}
                                value={formik.values.autoLoanDownPaymentWeight?.rangeC}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                // error={formik.touched.requestScoreDataCriteria. && Boolean(formik.errors.)}
                                placeholder={t('AutoLoanDownPaymentWeightRangeC')}
                                name={"autoLoanDownPaymentWeight.rangeC"}
                                fullWidth
                                label={t('AutoLoanDownPaymentWeightRangeC')}
                            />
                            <TextField
                                className={labelStyle ? classes.input : ''}
                                type={"number"}
                                value={formik.values.autoLoanDownPaymentWeight?.rangeD}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                // error={formik.touched.requestScoreDataCriteria. && Boolean(formik.errors.)}
                                placeholder={t('AutoLoanDownPaymentWeightRangeD')}
                                name={"autoLoanDownPaymentWeight.rangeD"}
                                fullWidth
                                label={t('AutoLoanDownPaymentWeightRangeD')}
                            />
                            <TextField
                                className={labelStyle ? classes.input : ''}
                                type={"number"}
                                value={formik.values.autoLoanDownPaymentWeight?.rangeE}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                // error={formik.touched.requestScoreDataCriteria. && Boolean(formik.errors.)}
                                placeholder={t('AutoLoanDownPaymentWeightRangeE')}
                                name={"autoLoanDownPaymentWeight.rangeE"}
                                fullWidth
                                label={t('AutoLoanDownPaymentWeightRangeE')}
                            />

                            <TextField
                                className={labelStyle ? classes.input : ''}
                                type={"number"}
                                value={formik.values.autoLoanDownPaymentWeight?.rangeF}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                // error={formik.touched.requestScoreDataCriteria. && Boolean(formik.errors.)}
                                placeholder={t('AutoLoanDownPaymentWeightRangeF')}
                                name={"autoLoanDownPaymentWeight.rangeF"}
                                fullWidth
                                label={t('AutoLoanDownPaymentWeightRangeF')}
                            />

                        </Paper>
                    </Grid>
                }
                <Grid item xs={12} sm={11} md={4} xl={4}>
                    <Paper className={classes.section}>

                        <Grid item>
                            <Typography className={classes.title} >{t("EmploymentDateWeight")}</Typography>
                        </Grid>

                        <TextField
                            className={labelStyle ? classes.input : ''}
                            type={"number"}
                            value={formik.values.employmentDateWeight?.rangeA}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            // error={formik.touched.requestScoreDataCriteria. && Boolean(formik.errors.)}
                            placeholder={t('EmploymentDateWeightRangeA')}
                            name={"employmentDateWeight.rangeA"}
                            fullWidth
                            label={t('EmploymentDateWeightRangeA')}
                        />
                        <TextField
                            className={labelStyle ? classes.input : ''}
                            type={"number"}
                            value={formik.values.employmentDateWeight?.rangeB}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            // error={formik.touched.requestScoreDataCriteria. && Boolean(formik.errors.)}
                            placeholder={t('EmploymentDateWeightRangeB')}
                            name={"employmentDateWeight.rangeB"}
                            fullWidth
                            label={t('EmploymentDateWeightRangeB')}
                        />
                        <TextField
                            className={labelStyle ? classes.input : ''}
                            type={"number"}
                            value={formik.values.employmentDateWeight?.rangeC}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            // error={formik.touched.requestScoreDataCriteria. && Boolean(formik.errors.)}
                            placeholder={t('EmploymentDateWeightRangeC')}
                            name={"employmentDateWeight.rangeC"}
                            fullWidth
                            label={t('EmploymentDateWeightRangeC')}
                        />
                        <TextField
                            className={labelStyle ? classes.input : ''}
                            type={"number"}
                            value={formik.values.employmentDateWeight?.rangeD}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            // error={formik.touched.requestScoreDataCriteria. && Boolean(formik.errors.)}
                            placeholder={t('EmploymentDateWeightRangeD')}
                            name={"employmentDateWeight.rangeD"}
                            fullWidth
                            label={t('EmploymentDateWeightRangeD')}
                        />

                        <TextField
                            className={labelStyle ? classes.input : ''}
                            type={"number"}
                            value={formik.values.employmentDateWeight?.rangeE}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            // error={formik.touched.requestScoreDataCriteria. && Boolean(formik.errors.)}
                            placeholder={t('EmploymentDateWeightRangeE')}
                            name={"employmentDateWeight.rangeE"}
                            fullWidth
                            label={t('EmploymentDateWeightRangeE')}
                        />
                        <TextField
                            className={labelStyle ? classes.input : ''}
                            type={"number"}
                            value={formik.values.employmentDateWeight?.rangeF}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            // error={formik.touched.requestScoreDataCriteria. && Boolean(formik.errors.)}
                            placeholder={t('EmploymentDateWeightRangeF')}
                            name={"employmentDateWeight.rangeF"}
                            fullWidth
                            label={t('EmploymentDateWeightRangeF')}
                        />
                        <TextField
                            className={labelStyle ? classes.input : ''}
                            type={"number"}
                            value={formik.values.employmentDateWeight?.rangeG}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            // error={formik.touched.requestScoreDataCriteria. && Boolean(formik.errors.)}
                            placeholder={t('EmploymentDateWeightRangeG')}
                            name={"employmentDateWeight.rangeG"}
                            fullWidth
                            label={t('EmploymentDateWeightRangeG')}
                        />
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={11} md={4} xl={4}>
                    <Paper className={classes.section}>

                        <Grid item>
                            <Typography className={classes.title} >{t("DBRWeight")}</Typography>
                        </Grid>

                        <TextField
                            className={labelStyle ? classes.input : ''}
                            type={"number"}
                            value={formik.values.dbrWeight?.rangeA}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            // error={formik.touched.requestScoreDataCriteria. && Boolean(formik.errors.)}
                            placeholder={t('DBRWeightRangeA')}
                            name={"dbrWeight.rangeA"}
                            fullWidth
                            label={t('DBRWeightRangeA')}
                        />
                        <TextField
                            className={labelStyle ? classes.input : ''}
                            type={"number"}
                            value={formik.values.dbrWeight?.rangeB}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            // error={formik.touched.requestScoreDataCriteria. && Boolean(formik.errors.)}
                            placeholder={t('DBRWeightRangeB')}
                            name={"dbrWeight.rangeB"}
                            fullWidth
                            label={t('DBRWeightRangeB')}
                        />
                        <TextField
                            className={labelStyle ? classes.input : ''}
                            type={"number"}
                            value={formik.values.dbrWeight?.rangeC}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            // error={formik.touched.requestScoreDataCriteria. && Boolean(formik.errors.)}
                            placeholder={t('DBRWeightRangeC')}
                            name={"dbrWeight.rangeC"}
                            fullWidth
                            label={t('DBRWeightRangeC')}
                        />
                        <TextField
                            className={labelStyle ? classes.input : ''}
                            type={"number"}
                            value={formik.values.dbrWeight?.rangeD}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            // error={formik.touched.requestScoreDataCriteria. && Boolean(formik.errors.)}
                            placeholder={t('DBRWeightRangeD')}
                            name={"dbrWeight.rangeD"}
                            fullWidth
                            label={t('DBRWeightRangeD')}
                        />

                        <TextField
                            className={labelStyle ? classes.input : ''}
                            type={"number"}
                            value={formik.values.dbrWeight?.rangeE}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            // error={formik.touched.requestScoreDataCriteria. && Boolean(formik.errors.)}
                            placeholder={t('DBRWeightRangeE')}
                            name={"dbrWeight.rangeE"}
                            fullWidth
                            label={t('DBRWeightRangeE')}
                        />

                        <TextField
                            className={labelStyle ? classes.input : ''}
                            type={"number"}
                            value={formik.values.dbrWeight?.rangeF}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            // error={formik.touched.requestScoreDataCriteria. && Boolean(formik.errors.)}
                            placeholder={t('DBRWeightRangeF')}
                            name={"dbrWeight.rangeF"}
                            fullWidth
                            label={t('DBRWeightRangeF')}
                        />

                        <TextField
                            className={labelStyle ? classes.input : ''}
                            type={"number"}
                            value={formik.values.dbrWeight?.rangeG}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            // error={formik.touched.requestScoreDataCriteria. && Boolean(formik.errors.)}
                            placeholder={t('DBRWeightRangeG')}
                            name={"dbrWeight.rangeG"}
                            fullWidth
                            label={t('DBRWeightRangeG')}
                        />

                        <TextField
                            className={labelStyle ? classes.input : ''}
                            type={"number"}
                            value={formik.values.dbrWeight?.rangeH}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            // error={formik.touched.requestScoreDataCriteria. && Boolean(formik.errors.)}
                            placeholder={t('DBRWeightRangeH')}
                            name={"dbrWeight.rangeH"}
                            fullWidth
                            label={t('DBRWeightRangeH')}
                        />
                    </Paper>
                </Grid>
            </Grid>
            <Button
                variant="contained"
                color="primary"
                onClick={() => formik.handleSubmit()}
            >{t('submitButton')}</Button>
        </div>

    )
}
export default ValidationsForm

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modal: {
            position: 'absolute',
            top: '10%',
            left: '10%',
            overflow: 'scroll',
            height: '100%',
            display: 'block',
            padding: 50,
            direction: theme.direction,
        },
        paper: {
            backgroundColor: theme.palette.background.paper,
            // border: '2px solid #000',
            // boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
        },
        footer: {
            textAlign: "end",
            paddingTop: 25

        },
        section: {
            marginTop: "0.4rem",
            marginBottom: "1.66rem",
            padding: "1rem",
            boxShadow: theme.shadows[5],
        },
        title: {
            fontWeight: "bold",
            fontSize: "1rem",
        },
        input: {
            "& .MuiFormLabel-root": {
                left: "auto",
                right: "auto",
                transformOrigin: "top right",
                textAlign: "right",
                paddingRight: "3rem"
            }
        }
    }),
);
