export const getValueWithFallback =
    (data: string | number | null | undefined, fallBack: string | number | null | undefined, empty?: string): string|number => {

    if (data) {
        return data
    }
    if (fallBack) {
        return fallBack
    }
    if (empty) {
        return empty
    }

    return ""

}
