import {Box} from "@material-ui/core";
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import FacebookIcon from '@material-ui/icons/Facebook';
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    item: {
      flexDirection: "row",
      display: "flex",
      paddingTop: "0.4rem",

      color: "#fff"
    },
    link: {
      color: "#9AE4FF",
      paddingTop: "0.3rem",

      fontSize: 12,
      textTransform: "none",

    },
    copyright: {
      fontSize: 12,

      color: theme.palette.common.white,
    }
  }),
);

interface IItem {
  icon: JSX.Element
  url: string

}

const Footer = () => {
  const { t } = useTranslation("footer")
  const icons: IItem[] = [{
    icon: <FacebookIcon key={'FacebookIcon'} color={"inherit"}/>,
    url: "https://www.facebook.com/tamweelnajordan/"
  },
    {
      icon: <InstagramIcon key={'InstagramIcon'} color={"inherit"}/>,
      url: "https://www.instagram.com/tamweelna/"
    }, {
      icon: <LinkedInIcon key={'LinkedInIcon'} color={"inherit"}/>,
      url: "https://www.linkedin.com/company/tradehubjo/"
    }, {
      icon: <TwitterIcon  key={'TwitterIcon'} color={"inherit"}/>,
      url: "https://twitter.com/Tamweelna"
    },]
  const classes = useStyles();
  return (
    <footer className={"footer"}>

      <Box className={classes.item} gridGap={13}>
        {icons.map(x => {
          return (x.icon)
        })}
      </Box>
      <Box className={classes.item} gridGap={13}>
        <a href={"/"}>


          <Typography className={classes.link}>
            {t("privacy")}
          </Typography>
        </a>

        <Typography className={classes.link}>
          {" | "}
        </Typography>


        <a href={"/"}>

          <Typography className={classes.link}>
            {t("terms")}
          </Typography>
        </a>

      </Box>
      <Box className={classes.item}>
        <Typography className={classes.copyright}>{t("copyright")}</Typography>
      </Box>


    </footer>)
}

export default Footer
