import { InputAdornment, TextField, Theme, useTheme } from "@material-ui/core";
import { CheckCircle } from "@material-ui/icons";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

interface Iprops {
    placeHolder: string;
    onChange: (e: any) => void;
    validatedField: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
    // resize:{
    //     fontSize:"0.8rem",
    //     [theme.breakpoints.up("xl")]: {
    //         fontSize:"1rem",
    //     }
    // },
}));

const TextFieldWithValidation = (props: Iprops) => {
    const classes = useStyles();
    const {
        palette: { success, grey },
    } = useTheme();

    return (
        <TextField
            placeholder={props.placeHolder}
            onChange={props.onChange}
                InputProps={{
                // classes: {
                //     input: classes.resize,
                // },
                endAdornment: (
                    <InputAdornment position="end">
                        <CheckCircle
                            htmlColor={props.validatedField ? success.main : grey[300]}
                        />
                    </InputAdornment>
                ),
            }}
        />
    );
};
export default TextFieldWithValidation;
