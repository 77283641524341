import React, {FunctionComponent, useState} from "react";
import SimplyModal from "../../../shared/SimplyModal";
import {Box, Button, FormControl, Select, TextField} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import IAppStateModel from "../../../../types/AppState";
import {SubmitToLender} from "../../../../state/actions/SubmissionActions";
import { RejectRequest } from "../../../../state/actions/RequestsActions";

interface IProps {
    open: boolean;
    onClose: () => void;
    title: string;
    onSubmit:(notes:string)=>void
}

const RejectRequestModal: FunctionComponent<IProps> = ({open,onSubmit, onClose, title}): JSX.Element => {
    const [notes, setNotes] = useState<string>("")
    const onReject = () => {
        notes && onSubmit(notes)
    }

    const onChangeText = (i: any) => {
        if (i.code==="Enter") {
            onReject()
        }
    };
    return (<SimplyModal open={open} onClose={onClose} title={title}>

        <Box display={"flex"} flexDirection={"column"} width={500}>

            <Box width={"100%"}>
                <FormControl fullWidth>
                    <TextField
                        label={"Notes"}
                        fullWidth
                        value={notes}
                        size={"medium"}
                        onChange={(i: any) => {
                            setNotes(i.target.value)
                        }}
                        onKeyPressCapture={(i) => {
                            onChangeText(i);
                        }}
                    />
                </FormControl>

            </Box>
            <Box display={"flex"} justifyContent={"flex-end"} paddingTop={5}>
                <Button color={"primary"} variant={"contained"} onClick={onReject}>{title}</Button>
            </Box>

        </Box>
    </SimplyModal>)
}

export default RejectRequestModal;
