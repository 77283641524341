import React, {FunctionComponent} from "react";
import LoanView from "../../LoanView";
import PersonIcon from "@material-ui/icons/Person";
import {ComponentRequestsProductLoan, Maybe} from "../../../../types/content";
import {IProductDetails, IProductLoan} from "../../../../types/Request";
import { useTranslation } from "react-i18next";

interface IProps {
    model?: IProductLoan;
    content: Maybe<ComponentRequestsProductLoan> | undefined;
    onEditClick?: () => void;
}

const ProductsLoanInfo: FunctionComponent<IProps> = ({
                                                         onEditClick,
                                                         content,
                                                         model,
                                                     }) => {

    //TODO SHOW ALL
    const value = model?.products && model.products[0];
    const {t, i18n} = useTranslation("common");
    return (
        <LoanView.Card
            title={content?.stepTitle || ""}
            icon={<PersonIcon fontSize={"large"}/>}
            onEditClick={onEditClick}
        >
            {model?.products?.map((i, index) => <>
                <LoanView.CardSection isLastSection>
                    <LoanView.SectionItem
                        label={content?.description || ""}
                        value={i.description || ""}
                    />
                    <LoanView.SectionItem
                        label={content?.quantity || ""}
                        value={i.quantity?.toString()|| ""}
                    />

                    <LoanView.SectionItem
                        label={content?.itemPrice || ""}
                        value={i.itemPrice?.toString() || ""}
                    />
                </LoanView.CardSection>
                <LoanView.CardSection isLastSection={index===model?.products!.length - 1}>
                    <LoanView.SectionItem
                        label={content?.notes || ""}
                        value={i?.notes?.toString() || t("none")}
                    />

                </LoanView.CardSection>

            </>)}
        </LoanView.Card>
    );
};
export default ProductsLoanInfo;
