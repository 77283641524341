import { Box, Button, Typography } from "@material-ui/core"
import React, { FunctionComponent } from "react"
import SimplyModal from "../../../shared/SimplyModal"

interface IProps {
    open: boolean;
    onClose: () => void;
    title: string;
    onSubmit?:()=>void
    dangerButton?:boolean
    discription:string
}
const ConfirmationModal: FunctionComponent<IProps> = ({open, onClose, title, onSubmit,dangerButton,discription}): JSX.Element => {
    return (<SimplyModal open={open} onClose={onClose} title={title}>

        <Box display={"flex"} flexDirection={"column"} width={500}>
            <Typography>{discription}</Typography>
            <Box display={"flex"} justifyContent={"flex-end"} paddingTop={5}>
                <Button color={"primary"} style={{backgroundColor:dangerButton?"red":"primary"}} variant={"contained"} onClick={onSubmit}>{title}</Button>
            </Box>

        </Box>
    </SimplyModal>)
}
export default ConfirmationModal
