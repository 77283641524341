import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { useFormik } from 'formik';
import * as yup from "yup";
import {Button, Grid, TextField, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { IUpdateMerchant } from '../../../types/Merchant';
import MerchantsClient from '../../../apiClient/MerchantsClient';
import { useHistory } from 'react-router-dom';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            direction: theme.direction ,
        },
        paper: {
            backgroundColor: theme.palette.background.paper,
            border: '2px solid #000',
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
        },
        footer: {
            textAlign: "end",
            paddingTop:25

        },
        input: {
            "& .MuiFormLabel-root": {
                left: "auto",
                right: "auto",
                transformOrigin: "top right",
                textAlign: "right",
            }
        }
    }),
);

const validationSchema = yup.object({
    nameAr: yup
        .string()
        .max(20, " Maximum 20 Character").min(3, " Minimum 3 Characters")
        .required(" is Required"),
    nameEn: yup
        .string()
        .max(20, " Maximum 20 Character").min(3, " Minimum 3 Characters")
        .required(" is Required"),
});

interface IProps{
    onClose:()=>void
    id:number
    client:any
    title:string
    merchant:any
}

export const EditModal=({onClose,id,client,title,merchant}:IProps)=> {
    const classes = useStyles();
    let history = useHistory();
    const {t,i18n} = useTranslation("merchants")
    let labelStyle = i18n.language.slice(0, 2).toLowerCase() === "ar" ? true : false;

    console.log(merchant  )
    const formik = useFormik({
        initialValues: {
            ...merchant,
            nameAr: '',
            nameEn: '',
            commission:''
        },
        validationSchema: validationSchema,
        validateOnBlur: true,

        onSubmit: async (values, {resetForm}) => {
            let res = await client.Edit(values as IUpdateMerchant,id);
            if (res.succeeded) {
                window.location.reload();
            } else {

            }
        },
    });

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={true}
                onClose={onClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={true}>
                    <div className={classes.paper}>
                        <Typography variant="h6" gutterBottom>
                            {title}
                        </Typography>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                 className={labelStyle ? classes.input : ''}
                                    required
                                    id="nameEn"
                                    name="nameEn"
                                    label={t("nameEn")}
                                    fullWidth
                                    value={formik.values.nameEn}
                                    onChange={formik.handleChange}
                                    helperText={(formik.touched.nameEn && formik.errors.nameEn &&t("nameEn")+ formik.errors.nameEn) || ""}
                                    error={
                                        formik.touched.nameEn &&
                                        Boolean(formik.errors.nameEn)
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                 className={labelStyle ? classes.input : ''}
                                    required
                                    id="nameAr"
                                    name="nameAr"
                                    label={t("nameAr")}
                                    fullWidth
                                    value={formik.values.nameAr}
                                    onChange={formik.handleChange}
                                    helperText={(formik.touched.nameAr&& formik.errors.nameAr &&t("nameAr")+ formik.errors.nameAr) || ""}
                                    error={
                                        formik.touched.nameAr &&
                                        Boolean(formik.errors.nameAr)
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                 className={labelStyle ? classes.input : ''}
                                    required
                                    id="commission"
                                    name="commission"
                                    label={t("commission")}
                                    fullWidth
                                    value={formik.values.commission}
                                    onChange={formik.handleChange}
                                    helperText={(formik.touched.commission&& formik.errors.commission &&t("commission")+ formik.errors.commission) || ""}
                                    error={
                                        formik.touched.commission &&
                                        Boolean(formik.errors.commission)
                                    }
                                />
                            </Grid>

                        </Grid>

                        <Grid className={classes.footer} >
                            <Button onClick={formik.submitForm} variant="contained">{t("save")}</Button>

                        </Grid>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
}
export default EditModal
