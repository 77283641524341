import * as ActionsTypes from '../ActionsTypes';
import IAction from "../../types/Action";
import IPagedResults, {IPagedSearch} from "../../types/Paged";
import CompaniesClient from "../../apiClient/CompaniesClient";
import ICompany, {ISearchCompanies} from "../../types/Companies";
import {DispatchErrorSnackbar, SetIsLoading} from "./UiActions";

export const SearchCompaniesAsync = (search?: IPagedSearch<ISearchCompanies>) => {
    return async (dispatch: any, getState: any) => {
        dispatch(SetIsLoading(true))
        const companiesClient = new CompaniesClient();
        let response = await companiesClient.Search(search);

        if (response.succeeded) {
          
                dispatch(setCompanies(response.data as IPagedResults<ICompany>))


        } else {
            dispatch(DispatchErrorSnackbar())
        }
        dispatch(SetIsLoading(false))

    };
}

const setCompanies = (companies: IPagedResults<ICompany>): IAction => {
    return {
        type: ActionsTypes.SET_COMPANIES,
        payload: companies
    }
}