import React from 'react';
import {useSelector} from "react-redux";
import IAppStateModel from "../../types/AppState";
import {UserType} from "../../types/Profile";


interface IProps{
    children?:any
    allowedTypes?:string[]
}

const Component = (props:IProps) => {
    const userType = useSelector((state: IAppStateModel) => state.profile?.type) ;

    if (!props.allowedTypes || props.allowedTypes?.includes(userType as UserType))
        return props.children
    else return <></>
}

export default Component;
