import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { useFormik } from 'formik';
import { Button, Grid, Typography, FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { IEmployee } from "../../../types/Lender";
import { EditUser } from "../../../state/actions/UserActions";
import { useDispatch } from "react-redux";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            direction: theme.direction,
        },
        paper: {
            backgroundColor: theme.palette.background.paper,
            border: '2px solid #000',
            padding: theme.spacing(2, 4, 3),
            width: 400,
        },
        footer: {
            textAlign: "end",
            paddingTop: 25

        },
        selectLabel: {
            left: "auto",
            right: "auto",
            transformOrigin: "top right",
            textAlign: "right",
            paddingRight: "2rem",
        }
    }),
);

interface IProps {
    onClose: () => void
    title: string
    row: IEmployee
}

export const EditModal = ({ onClose, title, row }: IProps) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation("lenders")
    let labelStyle = i18n.language.slice(0, 2).toLowerCase() === "ar" ? true : false;
    const formik = useFormik({
        initialValues: {
            type: row.userType,
        },
        onSubmit: async (values, { resetForm }) => {
          dispatch(EditUser(row.id,Number(values.type),true))
          onClose();
        },
    });

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={true}
                onClose={onClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={true}>
                    <div className={classes.paper}>
                        <Typography variant="h6" gutterBottom>
                            {title}
                        </Typography>

                        <Grid  >
                            <FormControl style={{ width: "85%" }}>
                                <InputLabel className={labelStyle ? classes.selectLabel: ''}>{t("userType")}</InputLabel>
                                <Select onChange={(e) => {
                                    formik.setFieldValue("type", e.target.value)
                                }}
                                    value={formik.values.type}
                                    displayEmpty={true}>
                                    <MenuItem value={151}>{"BankOfficer"}</MenuItem>
                                    <MenuItem value={153}>{"BankManager"}</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>



                        <Grid className={classes.footer} >
                            <Button onClick={formik.submitForm} variant="contained">{t("save")}</Button>

                        </Grid>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
}
export default EditModal
