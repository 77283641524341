import {Box, FormControl, Grid, IconButton, InputAdornment, TextField, Theme, Typography} from "@material-ui/core";
import React, {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextFieldWithValidation from "../../shared/TextFieldWithValidation";
import UsersClient from "../../../apiClient/UsersClient";
import {SetUser} from "../../../state/actions/UserActions";
import {useDispatch} from "react-redux";
import {Visibility, VisibilityOff} from "@material-ui/icons";
import ForgetPasswordModal from "../Components/forgetPasswordModal"


const useStyles = makeStyles((theme: Theme) => ({
    container: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "100%",
    },
    stepBox: {
        marginTop: "3rem",
    },
    bottomContainer: {
        [theme.breakpoints.up("sm")]: {
            paddingTop: "5rem",
        },
        paddingTop: "2rem",

        alignItems: "center",
        justifyContent: "space-evenly",
    },
    linearProgress: {
        width: "100%",
    },
    root: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        marginTop: "2rem",
        [theme.breakpoints.down("xs")]: {
            paddingRight: "0.5rem",
            paddingLeft: "0.5rem",
        },
        [theme.breakpoints.up("lg")]: {
            paddingRight: "2rem",
            paddingLeft: "2rem",
        },
    },
    title: {
        fontSize: "1rem",
        [theme.breakpoints.up("xl")]: {
            fontSize: "1.2rem",
        },
    },
    forgetPassword: {
        fontSize: "0.7rem",
        [theme.breakpoints.up("xl")]: {
            fontSize: "1rem",
        },
    },
    resize: {
        fontSize: "0.75rem",
        [theme.breakpoints.up("xl")]: {
            fontSize: "1rem",
        },
        textTransform: "none",
    },
}));


const Login = () => {
    const classes = useStyles();
    const [emailFilled, setEmailFilled] = useState(false);
    const [passwordFilled, setPasswordFilled] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [failedLogin, setFailedLogin] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const client = new UsersClient();
    const dispatch = useDispatch();
    const handleLogin = async () => {
        const response = await client.LoginAsync(email, password);
        if (response.succeeded) {
            dispatch(SetUser(response.data.jwt));

        } else {
            setFailedLogin(true);
        }
    }
    const [forgetPasswordModal, setForgetPasswordModal] = useState(false)

    return (
        <>
            <Box className={classes.root}>
                {forgetPasswordModal && <ForgetPasswordModal onClose={() => setForgetPasswordModal(false)}/>}
                <Grid
                    className={classes.root}
                    xs={12}
                    sm={5}
                    md={4}
                    lg={3}
                    xl={3}
                    container
                    direction={"column"}
                    justifyContent={"center"}
                    alignItems={"center"}
                >

                    <img
                        alt={"login"}
                        height={46}
                        width={46}
                        src="/images/loginIcon.svg"
                    />
                    <Box padding={2}>
                        <Typography color={"secondary"} className={classes.title}>
                            Login
                        </Typography>
                    </Box>
                    <FormControl fullWidth>
                        <TextFieldWithValidation
                            placeHolder={"email@address.com"}
                            validatedField={emailFilled}
                            onChange={(e) => {
                                e.target.value.endsWith(".com")
                                    ? setEmailFilled(true)
                                    : setEmailFilled(false);
                                setEmail(e.target.value);
                            }}
                        />
                    </FormControl>
                    <Box padding={2}/>
                    <FormControl fullWidth>
                        <TextField
                            placeholder={"**********"}
                            
                            id="standard-adornment-password"
                            type={showPassword ? 'text' : 'password'}
                            onChange={(e) => {
                                e.target.value.length > 5
                                    ? setPasswordFilled(true)
                                    : setPasswordFilled(false);
                                setPassword(e.target.value);
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => setShowPassword(showPassword => !showPassword)}
                                            onMouseDown={(event) => event.preventDefault()}
                                        >
                                            {showPassword ? <Visibility/> : <VisibilityOff/>}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}

                        />
                    </FormControl>
                    <Box alignSelf={"flex-end"} padding={1} onClick={() => setForgetPasswordModal(true)}
                         style={{cursor: "pointer"}}>
                        <Typography
                            color={"secondary"}
                            className={classes.forgetPassword}
                        >
                            Forget Password?
                        </Typography>
                    </Box><Box padding={2}/>
                    {failedLogin && <Typography color={"error"}>
                        {"Wrong username or password"}
                    </Typography>}
                    <Box padding={1}/>
                    <FormControl fullWidth>
                        <Button
                            className={classes.resize}
                            size={"large"}
                            variant={"contained"}
                            color={"primary"}
                            onClick={handleLogin}
                        >
                            Proceed
                        </Button>
                    </FormControl>
                    <Box padding={2}/>

                </Grid>
            </Box>
        </>
    );
};


export default Login;
