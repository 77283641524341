import React, {FunctionComponent, useEffect, useState} from "react";
import {makeStyles} from "@material-ui/styles";
import {Theme} from "@material-ui/core/styles";
import IRequest, {RequestTrailModel} from "../../../types/Request";
import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography
} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {
  ExportToExcel,
  ScoreRequest,
  SearchRequests,
  SetRequestsSearch
} from "../../../state/actions/RequestsActions";
import IAppStateModel from "../../../types/AppState";
import Table from "../../shared/Table";
import {Link} from "react-router-dom";
import {MyLocation, Visibility} from "@material-ui/icons";
import {useTranslation} from "react-i18next";
import {RiFileExcel2Line} from "react-icons/ri";
import {ImSearch} from "react-icons/all";
import {parseDate} from "../../../utils/dateUtil";
import RefreshIcon from '@material-ui/icons/Refresh';
import TrailsTable from "./components/TrailsTable";
import DateRangePicker from "../../shared/DateRangePicker";
import {getValueWithFallback} from "../../../utils/util";
import ClearIcon from '@material-ui/icons/Clear';
import Divider from "@material-ui/core/Divider";
import AccessControl from "../../shared/AccessControl";
import {
  BankManager,
  BankOfficer,
  CustomerService,
  CustomerServiceManager,
  HeadOfBusiness,
  LenderEmployee,
  MerchantsAndBanksManager,
  PlatformEmployee,
  Sales,
  TeamLeader
} from "../../common/JobRole";


const useStyles = makeStyles((theme: Theme) => ({
  search: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "1rem",
    paddingBottom: "0",
    width: "100%"
  },
  container: {
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "1.2rem",
      paddingRight: "1.2rem",
    },
    paddingLeft: "3rem",
    paddingRight: "3rem",
    paddingTop: "1rem",
    display: "flex",

  },
  title: {
    fontWeight: "bold",
    fontSize: "1.5rem",
  },
  paging: {
    alignItems: "center",
    justifyContent: "center",
    paddingTop: "2rem",
    paddingBottom: "2rem",
  },
  exportButton: {
    color: "#1D6F42",
  },
  paper: {
    width: "100%",
    marginTop: "0.4rem",

    marginBottom: "1.66rem",
    padding: "1rem"
  },
  selectLabel: {

    left: "auto",
    right: "auto",
    transformOrigin: "top right",
    textAlign: "right",
    paddingRight: "2rem",

  }
}));

interface IProps {
}

const Index: FunctionComponent<IProps> = ({}: IProps): JSX.Element => {
  const classes = useStyles();
  const requests = useSelector((state: IAppStateModel) => state.requests.results);
  const profile = useSelector((state: IAppStateModel) => state.profile);
  const lookUps = useSelector((state: IAppStateModel) => state.lookUpTables);
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation("requests")
  const searchCriteria = useSelector((state: IAppStateModel) => state.requests.search)
  const [search, setSearch] = useState()
  let labelStyle = i18n.language.slice(0, 2).toLowerCase() === "ar";
  const scoreRequest = (id: string) => {
    dispatch(ScoreRequest(id, (id) => refreshData()))
  }
  useEffect
  (() => {
    refreshData()
  }, [searchCriteria]);

  const refreshData = () => {
    dispatch(SearchRequests(i18n.language, search));
  }
  const onClearClick = (i: any) => {
    dispatch(SetRequestsSearch({
      ...searchCriteria, search: {
        search: "",
        type: undefined,
        status: undefined,
        currentSubmissionStatus: undefined,
        lenderId: undefined,
        merchantId: undefined,
        score: undefined
      }
    }))
    setSearch(undefined)
  }
  const onClickExport = () => {
    dispatch(ExportToExcel(search))
  }
  const [trials, setTrails] = useState<RequestTrailModel[]>()
  useEffect(() => {
  }, [searchCriteria])

  const resolveAdminTypes = () => {
    return (profile?.type === PlatformEmployee.label || profile?.type === HeadOfBusiness.label || profile?.type === MerchantsAndBanksManager.label
      || profile?.type === TeamLeader.label || profile?.type === Sales.label || profile?.type === CustomerServiceManager.label || profile?.type === CustomerService.label)
  }


  return (
    <div>
      <Grid container className={classes.container}>
        <Grid container justifyContent={"space-between"} alignItems={"center"}>
          <Grid item>
            <Typography className={classes.title}>{t("title")}</Typography>
          </Grid>
          {(trials?.length || 0) > 0 && <TrailsTable trails={trials || []}
                                                     closeModal={() => setTrails([])}/>}
        </Grid>
        <AccessControl
          allowedTypes={[PlatformEmployee.label, HeadOfBusiness.label, MerchantsAndBanksManager.label,
            TeamLeader.label, Sales.label, CustomerServiceManager.label, CustomerService.label, LenderEmployee.label,
            BankManager.label, BankOfficer.label]}>
          <Paper className={classes.paper} elevation={3}>
            <Box width={"100%"} display={"flex"} justifyContent={"flex-end"}>
              <IconButton className={classes.exportButton}
                          onClick={() => onClickExport()}>
                <RiFileExcel2Line size={29}/>
              </IconButton>
            </Box>
            <Grid container className={classes.search} spacing={2}>
              <Grid item md={2}>
                <FormControl fullWidth>
                  <Input
                    style={{ boxShadow: "none" }}
                    id="outlined-adornment-password"
                    value={search ?? ""}
                    onChange={(i: any) => {
                      setSearch(i.target.value)
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        {search && <ClearIcon style={{ cursor: "pointer" }}
                                              onClick={() => {
                                                setSearch(undefined)
                                                dispatch(SearchRequests(i18n.language))
                                              }
                                              }/>}
                      </InputAdornment>
                    }
                    placeholder={t("search")}
                  />
                  <Divider/>
                </FormControl>
              </Grid>
              <Grid item md={2}>
                <FormControl style={{ width: "85%" }}>
                  <InputLabel
                    className={labelStyle ? classes.selectLabel : ''}>{t("requestType")}</InputLabel>
                  <Select onChange={(e) => {
                    dispatch(SetRequestsSearch({
                      ...searchCriteria,
                      search: {
                        ...searchCriteria.search,
                        type: e.target?.value as string ?? ""
                      }
                    }))
                  }}
                          value={searchCriteria?.search?.type || ""}
                          displayEmpty={true}
                  >

                    {
                      lookUps?.requestTypes && lookUps?.requestTypes?.map((i) =>
                        (<MenuItem key={i.label+i.value} value={i.value}
                        >

                          {
                            i.label}
                        </MenuItem>)
                      )
                    }

                  </Select>
                </FormControl>
                {searchCriteria.search?.type &&
                  <ClearIcon style={{ cursor: "pointer", marginTop: 25 }}
                             onClick={() => {
                               dispatch(SetRequestsSearch({
                                 ...searchCriteria, search: {
                                   ...searchCriteria.search,
                                   type: undefined,
                                 }
                               }))
                             }
                             }/>}
              </Grid>
              <AccessControl
                allowedTypes={[PlatformEmployee.label, HeadOfBusiness.label, MerchantsAndBanksManager.label,
                  TeamLeader.label, Sales.label, CustomerServiceManager.label, CustomerService.label
                ]}>
                <Grid item md={2}>

                  <FormControl style={{ width: "85%" }}>
                    <InputLabel
                      className={labelStyle ? classes.selectLabel : ''}>{t("status")}</InputLabel>
                    <Select onChange={(e) => {
                      dispatch(SetRequestsSearch({
                        ...searchCriteria,
                        search: {
                          ...searchCriteria.search,
                          status: e.target?.value as string ?? "" ?? ""
                        }
                      }))
                    }} value={searchCriteria?.search?.status || ""}
                            displayEmpty={true}>
                      {
                        lookUps?.requestStatuses && lookUps?.requestStatuses?.map((i) =>
                          (<MenuItem key={i.label+i.value} value={i.value}>{i.label}</MenuItem>)
                        )
                      }

                    </Select>
                  </FormControl>
                  {searchCriteria.search?.status &&
                    <ClearIcon style={{ cursor: "pointer", marginTop: 25 }}
                               onClick={() => {
                                 dispatch(SetRequestsSearch({
                                   ...searchCriteria, search: {
                                     ...searchCriteria.search,
                                     status: undefined,
                                   }
                                 }))
                               }
                               }/>}
                </Grid>
              </AccessControl>

              <Grid item md={2}>

                <FormControl style={{ width: "85%" }}>
                  <InputLabel
                    className={labelStyle ? classes.selectLabel : ''}>{t("latestSubmissionStatus")}</InputLabel>
                  <Select onChange={(e) => {
                    dispatch(SetRequestsSearch({
                      ...searchCriteria,
                      search: {
                        ...searchCriteria.search,
                        currentSubmissionStatus: e.target?.value as string ?? "" ?? ""
                      }
                    }))
                  }}
                          value={searchCriteria?.search?.currentSubmissionStatus || ""}
                          displayEmpty={true}>
                    {/*<MenuItem value="">{"None"}</MenuItem>*/}
                    {
                      lookUps?.submissionsStatuses && lookUps?.submissionsStatuses?.map((i) =>
                        (<MenuItem key={i.label+i.value} value={i.value}>{i.label}</MenuItem>)
                      )
                    }

                  </Select>
                </FormControl>
                {searchCriteria.search?.currentSubmissionStatus &&
                  <ClearIcon style={{ cursor: "pointer", marginTop: 25 }}
                             onClick={() => {
                               dispatch(SetRequestsSearch({
                                 ...searchCriteria, search: {
                                   ...searchCriteria.search,
                                   currentSubmissionStatus: undefined,
                                 }
                               }))
                             }
                             }/>}
              </Grid>

              {lookUps?.lenders && <Grid item md={2}>
                <FormControl style={{ width: "85%" }}>
                  <InputLabel
                    className={labelStyle ? classes.selectLabel : ''}>{t("lender")}</InputLabel>

                  <Select onChange={(e) => {
                    dispatch(SetRequestsSearch({
                      ...searchCriteria,
                      search: {
                        ...searchCriteria.search,
                        lenderId: e.target?.value as string ?? "" ?? ""
                      }
                    }))
                  }} value={searchCriteria?.search?.lenderId || ""}
                          displayEmpty={true}>
                    {
                      lookUps?.lenders && lookUps?.lenders?.map((i) =>
                        (<MenuItem key={i.label+i.value} value={i.value}> {i.label} </MenuItem>)
                      )
                    }
                  </Select>
                </FormControl>
                {searchCriteria.search?.lenderId &&
                  <ClearIcon style={{ cursor: "pointer", marginTop: 25 }}
                             onClick={() => {
                               dispatch(SetRequestsSearch({
                                 ...searchCriteria, search: {
                                   ...searchCriteria.search,
                                   lenderId: undefined,
                                 }
                               }))
                             }
                             }/>}
              </Grid>
              }

              {lookUps?.merchants && <Grid item md={2}>

                <FormControl style={{ width: "85%" }}>
                  <InputLabel
                    className={labelStyle ? classes.selectLabel : ''}>{t("merchant")}</InputLabel>
                  <Select onChange={(e) => {
                    dispatch(SetRequestsSearch({
                      ...searchCriteria,
                      search: {
                        ...searchCriteria.search,
                        merchantId: e.target?.value as string ?? "" ?? ""
                      }
                    }))
                  }} value={searchCriteria?.search?.merchantId || ""}
                          displayEmpty={true}>
                    {/*<MenuItem value="">{"None"}</MenuItem>*/}

                    {
                      lookUps?.merchants && lookUps?.merchants?.map((i) =>
                        (<MenuItem key={i.label+i.value} value={i.value}>{i.label}</MenuItem>)
                      )
                    }

                  </Select>
                </FormControl>
                {searchCriteria.search?.merchantId &&
                  <ClearIcon style={{ cursor: "pointer", marginTop: 25 }}
                             onClick={() => {
                               dispatch(SetRequestsSearch({
                                 ...searchCriteria, search: {
                                   ...searchCriteria.search,
                                   merchantId: undefined
                                 }
                               }))
                             }
                             }/>}
              </Grid>}

              <Grid item md={2}>

                <DateRangePicker dateTo={searchCriteria.search?.createdTo}
                                 dateFrom={searchCriteria.search?.createdFrom}
                                 startDatePlaceholderText={t('createdFrom')}
                                 endDatePlaceholderText={t('createdTo')}
                                 onChange={(from, to) => dispatch(SetRequestsSearch({
                                   ...searchCriteria,
                                   search: {
                                     ...searchCriteria.search,
                                     createdTo: to,
                                     createdFrom: from
                                   }
                                 }))}/>
              </Grid>{(searchCriteria.search?.createdFrom || searchCriteria.search?.createdTo) &&
              <ClearIcon style={{ cursor: "pointer" }} onClick={() => {
                dispatch(SetRequestsSearch({
                  ...searchCriteria, search: {
                    ...searchCriteria.search,
                    createdFrom: undefined,
                    createdTo: undefined
                  }
                }))
              }
              }/>}

              <Grid item md={2}>
                <FormControl style={{ width: "85%" }}>
                  <InputLabel
                    className={labelStyle ? classes.selectLabel : ''}>{t("score")}</InputLabel>

                  <Select onChange={(e) => {
                    dispatch(SetRequestsSearch({
                      ...searchCriteria,
                      search: {
                        ...searchCriteria.search,
                        score: Number(e.target?.value)
                      }
                    }))
                  }} value={searchCriteria?.search?.score || ""}
                          displayEmpty={true}>

                    <MenuItem value={31}>{"A"}</MenuItem>
                    <MenuItem value={91}>{"F"}</MenuItem>
                  </Select>
                </FormControl>
                {searchCriteria.search?.score &&
                  <ClearIcon style={{ cursor: "pointer", marginTop: 25 }}
                             onClick={() => {
                               dispatch(SetRequestsSearch({
                                 ...searchCriteria, search: {
                                   ...searchCriteria.search,
                                   score: undefined,
                                 }
                               }))
                             }
                             }/>}
              </Grid>

              <Grid item md={12} container justify={"flex-end"}>
                <Button style={{ margin: 2, backgroundColor: "green" }}
                        variant={"contained"}
                        color={"primary"}
                        onClick={() => refreshData()}>
                  <RefreshIcon/>
                </Button>
                <Button
                  style={{ margin: 2, backgroundColor: "red", color: "white" }}
                  variant={"contained"}
                  color={"primary"}
                  onClick={onClearClick}>
                  {t("clear")}
                </Button>
                <Button style={{ margin: 2 }} variant={"contained"}
                        color={"primary"}
                        onClick={() => refreshData()}>
                  {t("search")}
                  <ImSearch style={{ marginLeft: "0.5rem" }}/>

                </Button>
              </Grid>

            </Grid>
          </Paper>
        </AccessControl>
        <AccessControl
          allowedTypes={[PlatformEmployee.label, HeadOfBusiness.label, MerchantsAndBanksManager.label,
            TeamLeader.label, Sales.label, CustomerServiceManager.label, CustomerService.label, LenderEmployee.label,
            BankManager.label, BankOfficer.label]}>
          <Table
            columns={[
              { field: "id", name: t("id") },
              {
                field: "createdOn",
                name: resolveAdminTypes() ? t("createdOn") : t("submissionDate"),
                resolveData: (row) => {
                  return (resolveAdminTypes() ? parseDate(row.createdOn) : parseDate(row.submissions?.find((s: any) => true)?.createdOn))
                }
              },

              { field: "type", name: t("type") },
              {
                field: "requestStatus",
                name: t("requestStatus"),
                resolveData: (i: IRequest) => {
                  return i.requestStatus
                }
              },
              {
                field: "latestSubmissionStatus",
                name: t("latestSubmissionStatus"),
                resolveData: (i: IRequest) => {
                  if (!(profile?.type === LenderEmployee.label 
                    || profile?.type === BankOfficer.label 
                    || profile?.type === BankManager.label)) {
                    return i.submissions?.find(s => true)?.status 
                      ? i.submissions?.find(s => true)?.status 
                      : t("notSubmitted")
                  } else {
                    return i.submissions?.find(s => (s.lenderId == profile.entityId))?.status 
                      ? i.submissions?.find(s => s.lenderId == profile.entityId)?.status 
                      : t("notSubmitted")
                  }
                }
              },
              {
                field: "latestSubmissionName",
                name: t("latestSubmissionName"),
                resolveData: (i: IRequest) => {
                  return i.submissions?.find(s => true)?.status ? i.submissions?.find(s => true)?.lenderName : t("notSubmitted")
                }
              },
              {
                field: "branchName",
                name: t("branchName"),
                resolveData: (i: IRequest) => {
                  return i.submissions?.find(s => true)?.branchName ?? t("noBranch")
                }
              },
              {
                field: "requesterInformation.name",
                name: t("clientName"),
                resolveData: (i: IRequest) => {
                  return (i.requesterInformation?.name)
                }
              },
              {

                field: "merchant.name",
                name: t("merchantName"),
                resolveData: (i: IRequest) => {
                  return i.merchant?.name ? (i.merchant?.name) : t("noMerchant")
                }
              },
              {
                field: "financingDetails.financingValue",
                name: t("financingValue"),
                resolveData: (i: IRequest) => {
                  return (getValueWithFallback(
                    i.submissions?.find(x => x.statusRaw == "Approved")?.offer?.netFinancingValue
                    , i.financingDetails?.financingValue, "0"))
                }
              },
              {
                field: "accountManager.name",
                name: t('salesMan'),
                resolveData: (i: IRequest) => {
                  return (i.accountManager?.name ?? t('noSalesMan'))
                }
              },
              
              {
                field: "score",
                name: t("score"),
                resolveData: (i: IRequest) => {
                  console.log(i.score)
                  // eslint-disable-next-line eqeqeq
                  return i.score != "" ? i.score : <Button
                    onClick={() => scoreRequest(i?.id)}
                    color={"primary"}
                    size={"small"}
                  >
                    {t("score")}
                  </Button>
                }
              },
              {
                field: "dbr",
                name: t('dbr'),
                resolveData: (i: IRequest) => {
                  if (i.dbr) {
                    return ((i?.dbr * 100).toString().slice(0, 3)) + "%"
                  } else if (i.dbr == 0) {
                    return 0
                  } else {
                    return "-"
                  }
                }
              },

            ]}
            data={requests?.matches ?? []}
            limit={searchCriteria.limit}
            offset={searchCriteria.offset}
            total={requests?.total}
            onPageChange={(page: number) => {
              dispatch(SetRequestsSearch({
                ...searchCriteria,
                offset: (searchCriteria?.limit! * page)
              }))
            }}
            onCountChange={(count: number) => {
              dispatch(SetRequestsSearch({ ...searchCriteria, limit: count }))
            }
            }
            actions={(row: IRequest) => (
              <Box display={"flex"} flexDirection={"row"}
                   justifyContent={"space-around"}
                   alignItems={"center"}>
                <AccessControl
                  allowedTypes={[PlatformEmployee.label, HeadOfBusiness.label, MerchantsAndBanksManager.label,
                    TeamLeader.label, Sales.label, CustomerServiceManager.label, CustomerService.label, LenderEmployee.label,
                    BankManager.label, BankOfficer.label]}>
                  <Box paddingTop={1}>
                    <Link to={`requests/${row.id}`}><Visibility/></Link>
                  </Box>
                </AccessControl>
                {row.trails.length > 0 && <AccessControl
                  allowedTypes={[PlatformEmployee.label, HeadOfBusiness.label, MerchantsAndBanksManager.label,
                    TeamLeader.label, Sales.label, CustomerServiceManager.label, CustomerService.label]}>
                  <Box>
                    <Button onClick={() => setTrails(row.trails)}><MyLocation/></Button>
                  </Box>
                </AccessControl>}
              </Box>)}
          />
        </AccessControl>
      </Grid>
    </div>
  );
};
//
export default Index;


