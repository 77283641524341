import LoanView from "../LoanView";
import PersonIcon from "@material-ui/icons/Person";
import React, {FunctionComponent} from "react";
import {ComponentRequestsClientInfo, Maybe} from "../../../types/content";
import {IPersonInformation} from "../../../types/Request";
import IUser from "../../../types/User";
import { useTranslation } from "react-i18next";
import {parseDate} from "../../../utils/dateUtil";

interface IProps {
    requester: IUser;
    model: IPersonInformation
    content: Maybe<ComponentRequestsClientInfo> | undefined
    onEditClick?: (e: any) => void;
    requestType?: string
    merchantName: string
}

const GetCardSection: FunctionComponent<{ requestType: string | undefined, value: any ,children:any}> = ({
    requestType,
    value,
    children
}): JSX.Element => {
    if (requestType !== "SmeLoan") {
        return children as JSX.Element
    } else if (value && value !== 0) {
        return children as JSX.Element
    }

    return <></>

}
const ClientInfoView: FunctionComponent<IProps> = ({
    onEditClick,
    content,
    model,
    requestType,
    merchantName,
    requester
}) => {

    const {t, i18n} = useTranslation("common");

    return (
        <LoanView.Card
            title={content?.stepTitle ?? ""}
            icon={<PersonIcon fontSize={"large"}/>}
            onEditClick={onEditClick}
        >

            <LoanView.CardSection>
                <GetCardSection value={requester?.name} requestType={requestType}>
                    <LoanView.SectionItem
                        label={t("Name") || ""}
                        value={requester?.name || ""}
                    />
                </GetCardSection>
                <GetCardSection value={model?.name} requestType={requestType}>
                    <LoanView.SectionItem
                        label={content?.name || ""}
                        value={model?.name || ""}
                    />
                </GetCardSection>
                <GetCardSection value={model?.email} requestType={requestType}>
                    <LoanView.SectionItem
                        label={content?.email || ""}
                        value={model?.email || ""}
                    />
                </GetCardSection>
                <GetCardSection value={model?.phoneNumber} requestType={requestType}>

                    <LoanView.SectionItem
                        label={content?.phoneNumber || ""}
                        value={model?.phoneNumber || ""}
                    />
                </GetCardSection>
            </LoanView.CardSection>
            
            {requestType == "SmeLoan" &&
              <LoanView.CardSection>
                <GetCardSection value={model?.majorityShareholderAge} requestType={requestType}>
                <LoanView.SectionItem
                    label={t("ageOfOwner")}
                    value={model?.majorityShareholderAge?.toString() || ""}
                />
                </GetCardSection>

                <GetCardSection value={model?.majorityShareholderBankBalanceAmount} requestType={requestType}>
                <LoanView.SectionItem
                    label={t("ownerBankBalance")}
                    value={model?.majorityShareholderBankBalanceAmount?.toString() || ""}
                />
                </GetCardSection>
              </LoanView.CardSection>   
}



            <LoanView.CardSection>
                <GetCardSection value={model?.birthDate} requestType={requestType}>

                    <LoanView.SectionItem
                        label={content?.birthDate || ""}
                        value={parseDate(model?.birthDate,true) || ""}
                    />
                </GetCardSection>
                {requestType!="SmeLoan"&&
                <GetCardSection value={model?.nationality} requestType={requestType}>

                    <LoanView.SectionItem
                        label={content?.nationality || ""}
                        value={model?.nationality || ""
                        }
                    />
                </GetCardSection>}

                <GetCardSection value={model?.address?.city} requestType={requestType}>

                    <LoanView.SectionItem
                        label={content?.address?.city || ""}
                        value={model?.address?.city || ""
                        }
                    />
                </GetCardSection>

            </LoanView.CardSection>
            <LoanView.CardSection isLastSection>
                <GetCardSection value={model?.address?.area} requestType={requestType}>

                    <LoanView.SectionItem
                        label={content?.address?.area || ""}
                        value={
                            model?.address?.area || ""
                        }
                    />
                </GetCardSection>

                <GetCardSection value={model?.identificationNumber ?? model?.passportNumber} requestType={requestType}>

                    {model?.nationality==="JO" || model?.nationality==="Jordan" || model?.nationality==="1" || model?.nationality==="اردني" ? (
                        <LoanView.SectionItem
                            label={content?.identificationNumber || ""}
                            value={model?.identificationNumber || ""}
                        />
                    ) : (
                        <LoanView.SectionItem
                            label={content?.passportNumber || ""}
                            value={model?.passportNumber || ""}
                        />
                    )}
                </GetCardSection>
                {requestType!="MortgageLoan"&&<GetCardSection value={merchantName} requestType={requestType}>

                    <LoanView.SectionItem
                        label={t("merchantName")}
                        value={
                            merchantName == "" ? t("noMerchant") : merchantName
                        }
                    />
                </GetCardSection>}

            </LoanView.CardSection>

        </LoanView.Card>
    );
};

export default ClientInfoView;
