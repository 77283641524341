import * as ActionsTypes from '../ActionsTypes';
import IAction from "../../types/Action";
import IPagedResults, {IPagedSearch} from "../../types/Paged";
import LendersClient from "../../apiClient/LendersClient";
import IAppStateModel, {LookUpModel} from "../../types/AppState";
import ILender, {ILenderPerRequest, ISearchLenders} from "../../types/Lender";
import {DispatchErrorSnackbar, DispatchSuccessSnackbar, SetIsLoading} from "./UiActions";
import MerchantsClient from "../../apiClient/MerchantsClient";
import {SearchMerchantsAsync} from "./MerchantsActions";
import {SET_LENDERS_LOOKUP} from "../ActionsTypes";


export const SearchLendersAsync = (search: IPagedSearch<ISearchLenders>) => {
    return async (dispatch: any, getState: any) => {
        dispatch(SetIsLoading(true))
        const state: IAppStateModel = getState();
        const lendersClient = new LendersClient();
        let response = await lendersClient.Search(search);

        if (response.succeeded) {
            if (search.search?.lookUp) {
                dispatch(setLendersLookup(response.data))
            } else {
                dispatch(setLenders(response.data as IPagedResults<ILender>))

            }

        } else {
            dispatch(DispatchErrorSnackbar())
        }
        dispatch(SetIsLoading(false))

    };
}

export const SearchLendersPerRequestAsync = (search: IPagedSearch<ILenderPerRequest>) => {
    return async (dispatch: any, getState: any) => {
        dispatch(SetIsLoading(true))
        const state: IAppStateModel = getState();
        const lendersClient = new LendersClient();
        let response = await lendersClient.SearchPerRequest(search);

        if (response.succeeded) {
            dispatch(setLendersLookup(response.data))

        } else {
            dispatch(DispatchErrorSnackbar())
        }
        dispatch(SetIsLoading(false))

    };
}

export const SwitchLenderStatusAsync = (lenderId: number, onClose?: () => void) => {
    return async (dispatch: any) => {
        dispatch(SetIsLoading(true))
        const lendersClient = new LendersClient();
        let response = await lendersClient.SwitchStatus(lenderId);

        if (response.succeeded) {
            onClose && onClose()
            dispatch(DispatchSuccessSnackbar("User status changed successfully"))
        } else {
            dispatch(DispatchErrorSnackbar())
        }
        dispatch(SetIsLoading(false))

    };
}

const setLenders = (lenders: IPagedResults<ILender>): IAction => {
    return {
        type: ActionsTypes.SET_LENDERS,
        payload: lenders
    }
}

const setLendersLookup = (lenders: LookUpModel[]): IAction => {
    return {
        type: ActionsTypes.SET_LENDERS_LOOKUP,
        payload: lenders
    }
}

export const SetLendersSearch = (search: IPagedSearch<ISearchLenders>): IAction => {
    return {
        type: ActionsTypes.SET_LENDERS_SEARCH,
        payload: search
    }
}
