import { FunctionComponent } from "react";
import { Box, Grid, Paper , Link} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { useTheme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {useTranslation} from "react-i18next";

interface IProps {
  title: string;
  icon: JSX.Element;
  children: any;
  onEditClick?: (e: any) => void | undefined;
  isAdmin?:boolean
  
  //   | FunctionComponent<IPropsSection>
  //   | FunctionComponent<IPropsSection>[];
}
interface IPropsLoanView {
  children: any;
}

export interface IPropsItem {
    label?: string|undefined;
    value: string|undefined;
    isLink?:boolean;
    link?:string;
}
interface IPropsSection {
  isLastSection?: boolean
  title?:string
  children: any;

  //children: FunctionComponent<IPropsItem>[];
}

const useStyles = makeStyles((theme) => ({
  divider: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  sectionColumn: {
      minHeight: "10vh",
    padding:"1rem"
  },
  cardHeight: {
    minHeight: "15vh",
    maxHeight: "15vh",
  },
  cursor: {
    cursor: "pointer",
  },
  itemHeight: {
  },
  mainSection: {
    background: theme.palette.background.paper,
    paddingY:"1rem",
    paddingLeft:"1rem"
  },
}));

const getBorderRadius=(locale:string,borderRadius:number)=>{
    if(locale=="en"){
        return `${borderRadius}px 0 0 ${borderRadius}px`
    }
    return `  0 ${borderRadius}px  ${borderRadius}px 0`
}
const Card = ({ children, icon, title, onEditClick, isAdmin }: IProps) => {
  const { shape } = useTheme();
  const { cursor, mainSection } = useStyles();
  const {i18n}=useTranslation()
  return (
      <Box paddingY={1}>
        <Box boxShadow={4} borderRadius={shape.borderRadius} bgcolor={"white"}>
          <Grid container>
            <Grid item md={1}>
              <Box
                  display={"flex"}
                  minHeight="100%"
                  bgcolor={"secondary.main"}
                  width={"100%"}
                  borderRadius={getBorderRadius(i18n.language,shape.borderRadius) }
                  flexDirection={"column"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  color={"white"}
                  textAlign={"center"}
              >
                {icon}
                {title}
              </Box>
            </Grid>

            <Grid item md={10} container className={mainSection}>
              {children}
            </Grid>
            {onEditClick&&isAdmin && (
                <Grid item md={1}>
                  <Box
                      display={"flex"}
                      alignItems={"center"}
                      paddingX={2}
                      color={"white"}
                      bgcolor={"primary.main"}
                      borderRadius={`0 ${shape.borderRadius}px ${shape.borderRadius}px 0`}
                      boxShadow={"inset 10px 0 10px -5px #00000033"}
                      className={cursor}
                      justifyContent={"center"}
                      height={"100%"}
                      onClick={onEditClick}
                  >
                    <EditIcon />
                  </Box>
                </Grid>
            )}
          </Grid>
          {/*</Paper>*/}
        </Box>
      </Box>
  );
};
const SectionItem: FunctionComponent<IPropsItem> = ({ label, value,link,isLink }) => {
    const { itemHeight } = useStyles();

    return (
        <Grid item md={12} container className={itemHeight}>
            <Grid
                item
                md={12}
                container
                alignItems={"center"}

            >
                {label && <Box textAlign={"start"} fontWeight={"bold"} paddingRight={2} textOverflow={"ellipsis"}>
                    {label}:
                </Box>}
            </Grid>
            <Grid
                item
                md={12}
                container
                alignItems={"center"}

            >
                { isLink ?<Link color={"secondary"} href={link} target={"_blank"}>{value}</Link>:<Box textOverflow={"ellipsis"}>{value || ""}</Box>}
            </Grid>
        </Grid>
    );
};

const CardSection: FunctionComponent<IPropsSection> = ({
                                                         isLastSection,
                                                         children,
                                                         title
                                                       }) => {
  const { divider, sectionColumn } = useStyles();
  return (


      <Grid
          item
          md={3}
          container
          className={`${sectionColumn} ${!isLastSection && divider}`}
          alignContent={"flex-start"}

      >
        {title && <Typography>{title}</Typography>}
        {children}
      </Grid>

  );
};

const LoanView = ({ children }: IPropsLoanView) => {
  return (
      <Box display={"flex"} flexDirection={"column"} width={"100%"}>
        {children}
      </Box>
  );
};
LoanView.SectionItem = SectionItem;
LoanView.CardSection = CardSection;
LoanView.Card = Card;

export default LoanView;
