import React, {useEffect, useState} from 'react';
import Header from '../shared/Header';
import Footer from "../shared/Footer";
import {Redirect, Route, Switch} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import IAppStateModel from "../../types/AppState";
import {useLocation} from "react-router-dom";
import CookiesManager from "../../utils/CookiesManager";
import {SetUser} from "../../state/actions/UserActions";
import Requests from "../views/Requests/Requests";
import Request from "../views/Requests/Request";
import Lenders from "../views/Lenders/Lenders";
import Lender from "../views/Lenders/Lender";
import Merchants from "../views/Merchants/Merchants";
import Users from "../views/Users/Users";
import CreateLender from "../views/Lenders/CreateLender";
import CreateMerchant from "../views/Merchants/CreateMerchant";
import PlatformUsers from "../views/Platform/PlatformUsers";
import {GetLookUpTables} from "../../state/actions/UiActions";
import {useTranslation} from "react-i18next";
import Loading from "../shared/Loading";
import {
    BankManager, BankOfficer,
    CustomerService,
    CustomerServiceManager,
    HeadOfBusiness, LenderEmployee,
    MerchantsAndBanksManager,
    PlatformEmployee,
    Sales,
    TeamLeader
} from "../common/JobRole";
import AccessControl from "../shared/AccessControl";
import RequestsValidations from "../views/RequestsValidation/RequestsValidations";
import Contact from "../views/Crm/Contact";
import Agents from "../views/Agents/Agents";
import CreateAgent from "../views/Agents/components/CreateAgent";
import Companies from "../views/Companies/companies";


interface IProps {

}

const Layout = (props: IProps & any) => {
    const {profileId, entityId, isLoading} = useSelector((state: IAppStateModel) => {
        return {
            profileId: state.profile?.id,
            entityId: state.profile?.entityId,
            isLoading: state.isLoading
        }
    });

    const location = useLocation();
    const [authenticated, setAuthenticated] = useState(!!CookiesManager.getAccessToken());
    const {i18n} = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {

        if (!profileId) {
            const jwt = CookiesManager.getAccessToken();
            if (jwt) {
                dispatch(SetUser(jwt));
                setAuthenticated(true);
            } else setAuthenticated(false);
        } else {
            setAuthenticated(true)
        }
    }, [profileId])


    useEffect(() => {
        dispatch(GetLookUpTables(i18n.language))

    }, [profileId,i18n.language])
    return (
        <>
            <Header/>
            {!authenticated && <Redirect to={`/login?returnurl=${location.pathname}`}/>}
            {authenticated && location.pathname==="/" && <Redirect to={`/requests`}/>}
            {isLoading && <Loading/>}

            <main>
                <Switch>
                    <Route path="/requests/:id">
                        <AccessControl
                            allowedTypes={[PlatformEmployee.label, HeadOfBusiness.label, MerchantsAndBanksManager.label,
                                TeamLeader.label, Sales.label, CustomerServiceManager.label, CustomerService.label,LenderEmployee.label,
                                BankManager.label,BankOfficer.label]}>
                        <Request/>
                        </AccessControl>
                    </Route>
                    <Route path="/requests">
                        <Requests/>
                    </Route>

                    <Route path="/lenders/new">
                        <AccessControl allowedTypes={[PlatformEmployee.label,HeadOfBusiness.label,MerchantsAndBanksManager.label]}>
                        <CreateLender/>
                        </AccessControl>
                    </Route>
                    <Route path="/lenders/:id">
                        <AccessControl allowedTypes={[PlatformEmployee.label,HeadOfBusiness.label,MerchantsAndBanksManager.label,LenderEmployee.label,BankManager.label,BankOfficer.label]}>
                        <Lender/>
                        </AccessControl>
                    </Route>

                    <Route path="/lenders">
                        <AccessControl allowedTypes={[PlatformEmployee.label,HeadOfBusiness.label,MerchantsAndBanksManager.label]}>
                        <Lenders/>
                        </AccessControl>
                    </Route>
                    <Route path="/merchants/new">
                        <AccessControl allowedTypes={[PlatformEmployee.label,HeadOfBusiness.label,MerchantsAndBanksManager.label]}>
                        <CreateMerchant/>
                        </AccessControl>
                    </Route>
                    <Route path="/merchants">
                        <AccessControl allowedTypes={[PlatformEmployee.label,HeadOfBusiness.label,MerchantsAndBanksManager.label]}>
                        <Merchants/>
                        </AccessControl>
                    </Route>
                    <Route path="/users">
                        <AccessControl allowedTypes={[PlatformEmployee.label,HeadOfBusiness.label]}>
                        <Users/>
                        </AccessControl>
                    </Route>
                    <Route path="/platform/users">
                        <AccessControl allowedTypes={[PlatformEmployee.label,HeadOfBusiness.label]}>
                        <PlatformUsers/>
                        </AccessControl>
                    </Route>
                    <Route path="/RequestsValidations">
                        <AccessControl allowedTypes={[PlatformEmployee.label,HeadOfBusiness.label]}>
                            <RequestsValidations/>
                        </AccessControl>
                    </Route>

                    <Route path="/lender">
                        <AccessControl allowedTypes={[PlatformEmployee.label,HeadOfBusiness.label,MerchantsAndBanksManager.label,LenderEmployee.label,BankManager.label,BankOfficer.label]}>
                        <Redirect to={`/lenders/${entityId}`}/>
                        </AccessControl>
                    </Route>
                    <Route path="/crm">
                        <AccessControl allowedTypes={[PlatformEmployee.label,HeadOfBusiness.label,Sales.label]}>
                            <Contact/>
                        </AccessControl>
                    </Route>
                    <Route path="/agents/new">
                        <AccessControl allowedTypes={[PlatformEmployee.label,HeadOfBusiness.label]}>
                        <CreateAgent/>
                        </AccessControl>
                    </Route>
                    <Route path="/agents">
                        <AccessControl allowedTypes={[PlatformEmployee.label,HeadOfBusiness.label]}>
                        <Agents/>
                        </AccessControl>
                    </Route>
                    <Route path="/companies">
                        <AccessControl allowedTypes={[PlatformEmployee.label,HeadOfBusiness.label]}>
                        <Companies/>
                        </AccessControl>
                    </Route>
                    <Route path={"/"}>
                        <></>
                    </Route>
                </Switch>
            </main>
            <Footer/>
        </>
    )
}

export default Layout;
