import {ApiResponse, ClientFactory} from "@simplify9/simplyapiclient";
import IClient from "@simplify9/simplyapiclient/dist/Models/IClient";
import CookieManager from "../utils/CookiesManager";
import {IPagedSearch} from "../types/Paged";
import {ICreateContactModel, ISearchCrm} from "../types/Crm";
import {ISearchRequests} from "../types/Request";

class CrmClient {
    client: IClient;
    locale: string;

    constructor() {
        this.client = ClientFactory();
        this.locale = CookieManager.getLocale();
    }


    async Search(search?: IPagedSearch<ISearchCrm>): Promise<ApiResponse> {
        const rs: ApiResponse = await this.client.SimplyGetAsync(
            `contacts?culture=${this.locale}&name=${search?.search?.name ?? ""}&mobileNumber=${search?.search?.mobileNumber ?? ""}&email=${search?.search?.email ?? ""}&limit=${search?.limit ?? 20}&status=${search?.search?.status ?? ""}&assignedSalesmanId=${search?.search?.assignedSalesmanId ?? ""}&offset=${search?.offset?? 0}`);
        return rs;
    }

    async Create(info: ICreateContactModel): Promise<ApiResponse> {
        let res = await this.client.SimplyPostAsync('contacts', info);
        return res;
    }

    async CreateContactAction(actionType: string, id: string): Promise<ApiResponse> {
        let res = await this.client.SimplyPostAsync(`contacts/${id}/action`, {action: actionType});
        return res;
    }

    async AssignToSalesMan(id?: string, accountManagerId?: string): Promise<ApiResponse> {
        console.log(id, "id")
        let res = await this.client.SimplyPostAsync(`contacts/${id}/assign`, {SalesmanId: accountManagerId});
        return res;
    }

    async ExportToExcelAsync(
        search?: IPagedSearch<ISearchCrm>
    ): Promise<ApiResponse> {

        const rs: ApiResponse = await this.client.SimplyGetAsync(
            `contacts/exportToExcel?culture=${this.locale}&name=${search?.search?.name ?? ""}&mobileNumber=${search?.search?.mobileNumber ?? ""}&email=${search?.search?.email ?? ""}&limit=${search?.limit ?? ""}&status=${search?.search?.status ?? ""}&assignedSalesmanId=${search?.search?.assignedSalesmanId ?? ""}&offset=${search?.offset ?? ""}`);
        return rs;
    }

    async ImportExcelFile(
        formData:any
    ): Promise<ApiResponse> {
        const res= await this.client.SimplyPostFormAsync(
            "files/upload",
            formData
        )
        return res
    }

    async AddDataFromExcelFile(
        fileUrl:any
    ): Promise<ApiResponse> {
        const res= await this.client.SimplyPostFormAsync(
            "contacts/import",
            {fileUrl: fileUrl}
        )
        return res
    }
}

export default CrmClient;
