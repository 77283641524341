import * as ActionsTypes from '../ActionsTypes';
import IAction from "../../types/Action";
import {ApiResponse, ClientFactory} from "@simplify9/simplyapiclient";
import CookieManager from "../../utils/CookiesManager";
import {BaseUrl} from "../../config";
import IAppStateModel, {ISnackBarModel, LookUpModel, LookUpTables} from "../../types/AppState";
import {
    CustomerService,
    CustomerServiceManager,
    HeadOfBusiness,
    LenderEmployee,
    MerchantsAndBanksManager,
    PlatformEmployee, Sales, TeamLeader
} from "../../components/common/JobRole";


export const GetLookUpTables = (locale: string) => {

    return async (dispatch: any, getState: any) => {

        const client = ClientFactory();
        const state: IAppStateModel = getState();

        const request: ApiResponse = await client.SimplyGetAsync(`meta?culture=${locale ?? "ar"}`);
        let merchants: ApiResponse = {succeeded: false, status: 100};
        let lenders: ApiResponse = {succeeded: false, status: 100}
        if (state.profile?.type === PlatformEmployee.label || state.profile?.type ===HeadOfBusiness.label || state.profile?.type ===MerchantsAndBanksManager.label ||
            state.profile?.type ===TeamLeader.label || state.profile?.type ===Sales.label || state.profile?.type ===CustomerServiceManager.label || state.profile?.type ===CustomerService.label){
            merchants = await client.SimplyGetAsync(`merchants?lookup=true&culture=${locale ?? "ar"}`)
            lenders = await client.SimplyGetAsync(`Lenders?lookup=true&culture=${locale ?? "ar"}`)
        }
       let companies = await client.SimplyGetAsync(`companies?lookup=true&culture=${locale ?? "ar"}`)
        const lookUpTable: LookUpTables = {
            requestStatuses: request?.data?.requestStatuses,
            salesMen:request?.data?.salesMen,
            secretResult:request?.data?.secretResult,
            submissionsStatuses: request.data?.submissionsStatuses,
            requestTypes: request?.data?.requestTypes,
            userTypes: request?.data?.userTypes,
            cities: request?.data?.cities,
            companyTypes: request?.data?.companyTypes,
            countries: request?.data?.countries,
            donors: request?.data?.donors,
            financingTypes: request?.data?.financingTypes,
            industries: request?.data?.industries,
            jobTypes: request?.data?.jobTypes,
            nationalities: request?.data?.nationalities,
            personalPurposes: request?.data?.personalPurposes,
            smePurposes: request?.data?.smePurposes,
            vehicleCatalog: request?.data?.vehicleCatalog,
            merchants: merchants?.status === 200 ? merchants.data : null,
            lenders: lenders?.status === 200 ? lenders.data : null,
            companies: companies?.status === 200 ? companies.data : null,
        }

        dispatch(setLookUpTables(lookUpTable))
    };
}

export const vehicleModelLookupsAsync = async (manufacturerId: string, locale: string): Promise<LookUpModel[] | null> => {
    const client = ClientFactory();

    const res: ApiResponse = await client.SimplyGetAsync(`/meta/${manufacturerId}/vehicle_models?culture=${locale}`)
    if (res.succeeded) {
        return res.data;

    }
    return null;
}

export const DispatchSuccessSnackbar = (message: string) => {

    return async (dispatch: any) => {
        dispatch(setSnackBar({severity: "success", message: message, open: true}))
    };
}

export const DispatchErrorSnackbar = (message?: string) => {

    return async (dispatch: any) => {

        dispatch(setSnackBar({severity: "error", message: message ?? "Something went Wrong", open: true}))
    };
}


export const setLookUpTables = (model: LookUpTables): IAction => {
    return {
        type: ActionsTypes.SET_LOOKUP_TABLES,
        payload: {
            model
        }
    }
}

export const SetIsLoading = (isLoading: boolean): IAction => {

    return {
        type: ActionsTypes.SET_IS_LOADING,
        payload: {
            isLoading
        }
    }
}

export const setSnackBar = (snackbar: ISnackBarModel): IAction => {

    return {
        type: ActionsTypes.SET_SNACKBAR,
        payload: {
            snackbar
        }
    }
}
