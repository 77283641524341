// import {ISubmitOfferModel, RequestTrailModel} from "../../../../types/Request";
import {
    Backdrop,
    Box, Fade,
    Modal, TextField,
    Button, InputAdornment, IconButton
} from "@material-ui/core";

import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from "react";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { IForgetModel } from "../../Auth/ForgetPassword";
import UsersClient from "../../../../apiClient/UsersClient";
import { useDispatch } from "react-redux";
import MerchantsClient from "../../../../apiClient/MerchantsClient";
import { DispatchErrorSnackbar, DispatchSuccessSnackbar, SetIsLoading } from "../../../../state/actions/UiActions";
import { useLocation } from "react-router-dom";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,

};
const useStyles = makeStyles((theme: Theme) => ({
    modal: {
        position: 'absolute',
        top: '10%',
        left: '10%',
        overflow: 'scroll',
        height: '100%',
        display: 'block',
        padding: 50,
        direction: theme.direction,
    },
    div: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    submitButton: {
        backgroundColor: 'orange',
        marginTop: '15px'
    },

    input: {
        "& .MuiFormLabel-root": {
            left: "auto",
            right: "auto",
            transformOrigin: "top right",
            textAlign: "right",
        }
    }
}))


interface IProps {
    merchantId?: number | string
    onClose: () => void
}

const ChangePasswordModal: React.FC<IProps> = (props) => {
    const classes = useStyles()
    const { t, i18n } = useTranslation("merchants");
    const [newPassword, setNewPassword] = useState("")
    const [confirmedNewPassword, setConfirmedNewPassword] = useState("")
    const [showPassword, setShowPassword] = useState(false);
    let labelStyle = i18n.language.slice(0, 2).toLowerCase() === "ar" ? true : false;

    const dispatch = useDispatch();


    const submitHandler = async () => {
        const client = new MerchantsClient();
        dispatch(SetIsLoading(true))

        if (newPassword.length >= 6 && confirmedNewPassword.length >= 6 && newPassword === confirmedNewPassword && props.merchantId) {
            const response = await client.updatePassword(props.merchantId, newPassword);
            if (response.succeeded) {
                props.onClose()
                dispatch(DispatchSuccessSnackbar("Password reset was successful"))
            }
        } else {
            dispatch(DispatchErrorSnackbar("Password at least should be 6 characters"))
        }
        dispatch(SetIsLoading(false))
    }

    return (

        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={true}
            className={classes.modal}
            onClose={props.onClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={true}>
                <Box sx={style}>
                    <TextField
                        className={labelStyle ? classes.input : ''}
                        fullWidth
                        placeholder={"******"}
                        label={t("newPassword")}
                        type={showPassword ? "text" : "password"}
                        autoComplete="off"
                        value={newPassword ?? ""}
                        onChange={(e) => setNewPassword(e.target.value ?? "")}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => setShowPassword(showPassword => !showPassword)}
                                        onMouseDown={(event) => event.preventDefault()}
                                    >
                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}

                    />

                    <TextField
                        className={labelStyle ? classes.input : ''}
                        fullWidth
                        label={t("confirmPassword")}
                        name="confirmNewPassword"
                        id="confirmNewPassword"
                        type={showPassword ? "text" : "password"}
                        value={confirmedNewPassword ?? ""}
                        onChange={(e) => setConfirmedNewPassword(e.target.value)}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => setShowPassword(showPassword => !showPassword)}
                                        onMouseDown={(event) => event.preventDefault()}
                                    >
                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}

                    />
                    <div className={classes.div}>
                        <Button onClick={submitHandler} className={classes.submitButton}>{t('submit')}</Button>
                    </div>
                </Box>
            </Fade>
        </Modal>
    );
}

export default ChangePasswordModal;