import React from 'react';
import Header from "../shared/Header";
import Footer from "../shared/Footer";
import {Redirect, Route, Switch} from "react-router";
import Login from "../views/Auth/Login";
import {useSelector} from "react-redux";
import IAppStateModel from "../../types/AppState";
import {useLocation} from "react-router-dom";
import ForgetPassword from '../views/Auth/ForgetPassword';

interface IProps {

}

const Layout = (props: IProps & any) => {
    const profile = useSelector((state: IAppStateModel) => state.profile);
    const location = useLocation();

    return (
        <>
            {profile?.id && <Redirect to={`${location.search.slice(11)}`}/>}
            <Header/>
            <main>
                <Switch>
                    <Route path="/login">
                        <Login/>
                    </Route>
                    <Route path="/forgetPassword">
                        <ForgetPassword/>
                    </Route>
                </Switch>
            </main>
            <Footer/>
        </>
    )
}

export default Layout;
