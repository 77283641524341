import React, {
    FunctionComponent,
    useCallback,
    useEffect,
    useState
} from "react";
import {useParams} from "react-router";
import {makeStyles} from "@material-ui/styles";
import {Theme} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import {Box, Button, Grid, Paper, Typography} from "@material-ui/core";
import IRequest, {
    BackGroundCheckResult,
    IRequestAttachment,
    RequestTrailModel,
    RequestType,
} from "../../../types/Request";
import RequestsClient from "../../../apiClient/RequestsClient";
import {ApiResponse} from "@simplify9/simplyapiclient";
import {
    GetRequestContent,
    RequestContent,
} from "../../../apiClient/contentClient";
import {IPropsItem} from "../../common/LoanView";
import SubmitRequestModal from "./components/SubmitRequestModal";
import SendOfferModal from "./components/SendOfferModal";
import {useDispatch, useSelector} from "react-redux";
import IAppStateModel from "../../../types/AppState";
import {
    DenyOffer,
    StartStudying,
} from "../../../state/actions/SubmissionActions";
import {
    AddAttachments,
    ExportRequestAsync,
    FinalizeOffer,
    RejectRequest,
    ScoreRequest,
    SignedOffer,
    PullFromMarket,
    SendToMarket,
} from "../../../state/actions/RequestsActions";
import {
    DispatchErrorSnackbar,
    DispatchSuccessSnackbar,
    SetIsLoading
} from "../../../state/actions/UiActions";
import {Skeleton} from "@material-ui/lab";
import SubmissionTable from "./components/SubmissionTable";
import {MdAssignment, MdPictureAsPdf, MdSend, TiCancel} from "react-icons/all";
import ProductsLoanInfo from "../../common/views/products/ProductsLoanInfo";
import AttachmentsInfoView from "../../common/views/AttachmentsInfoView";
import EmployerInfo from "../../common/views/EmployerInfo";
import SmeFinancialInfoView from "../../common/views/sme/FinancialInfo";
import {parseDate} from "../../../utils/dateUtil";
import ClientInfo from "../../common/views/ClientInfo";
import CarLoanInfo from "../../common/views/car/CarLoanInfo";
import FinancialInfo from "../../common/views/FinancialInfo";
import {
    ComponentGeneralMortgageLoan,
    ComponentRequestsAttachmentsComponent,
} from "../../../types/content";
import FinancingInfo from "../../common/views/FinancingInfo";
import RejectRequestModal from "./components/RejectRequestModal";
import ConfirmationModal from "./components/ConfirmationModal";
import AssignToBranchModal from "./components/AssignToBranchModal";
import MortgageLoanInfo from "../../common/views/mortgage/MorgageLoanInfo";
import {
    BankManager,
    BankOfficer,
    CustomerService,
    CustomerServiceManager,
    HeadOfBusiness,
    LenderEmployee,
    MerchantsAndBanksManager,
    PlatformEmployee,
    Sales,
    TeamLeader
} from "../../common/JobRole";
import AccessControl from "../../shared/AccessControl";
import {
    SearchLendersPerRequestAsync
} from "../../../state/actions/LendersActions";
import {IPagedSearch} from "../../../types/Paged";
import {ILenderPerRequest} from "../../../types/Lender";
import BackgroundCheckResultInfoView
    from "../../common/views/BackgroundCheckResultInfoView";
import BackgroundCheckModal from "./components/BackgroundCheckModal";
import TrailsTable from "./components/TrailsTable";
import SalesManModal, {SalesManModel} from "./components/SalesManModal";
import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        [theme.breakpoints.down("xs")]: {
            paddingLeft: "1.2rem",
            paddingRight: "1.2rem",
        },
        paddingLeft: "3rem",
        paddingRight: "3rem",
        paddingTop: "1rem",
        paddingBottom: "3rem",
    },
    sendButton: {
        backgroundColor: theme.palette.success.main,
        color: "white",
    fontSize:"0.75rem",
    },
    rejectButton: {
        backgroundColor: theme.palette.error.main,
        color: "white",
    fontSize:"0.75rem"
    },
    headerText: {
        color: theme.palette.secondary.main,
        fontSize: "0.875rem",
        fontWeight: 600,
    },
    skeleton: {
        borderRadius: 13,
    },
}));

const Component: FunctionComponent = () => {
    const classes = useStyles();
    const branchId = useSelector(
        (state: IAppStateModel) => state.profile?.branchId
    );
    const isLoading = useSelector((state: IAppStateModel) => state.isLoading);
    const userType = useSelector((state: IAppStateModel) => state.profile?.type);


    const dispatch = useDispatch();
    const {t, i18n} = useTranslation("requests");
    const profile = useSelector((state: IAppStateModel) => state.profile);
    const {id} = useParams() as any;
    type ModalNames =
        | "none"
        | "createSubmissionModal"
        | "sendOfferModal"
        | "rejectSubmissionModal"
        | "executeRequestModal"
        | "withDrawRequestModal"
        | "deniSubmissionModal"
        | "assignToBranchModal"
        | "signedModal"
        | "backgroundCheckModal"
        | "SalesManModal"
        | "onStartStudying";
    const [openModal, setOpenModal] = useState<ModalNames>("none");
    const [request, setRequest] = useState<IRequest | null>(null);
    const [content, setContent] = useState<RequestContent | null>(null);
    const [searchLender, setSearch] = useState<IPagedSearch<ILenderPerRequest>>({
        offset: 0,
        limit: 20
    })

    const translteFinancingTypeToEnglish = (financeTypeName?: string) => {
        switch (financeTypeName) {
            case "تجاري": {
                return "Commercial"
            }
            case "اسلامي" : {
                return "Islamic"
            }
            case "غير محدد":
                return "Not Specified"
            default:
                return ""
        }
    }


    const refreshData = useCallback(async (requestId: string) => {
        dispatch(SetIsLoading(true));
        const res = await getRequest(id);
        if (res.succeeded) {
            const Request: IRequest = res.data;
            console.log(i18n.language, "language")
            const requestContent: RequestContent | null = await GetRequestContent(
                i18n.language ?? "en",
                Request.type as RequestType
            );
            console.log(Request, "request")
            setRequest(Request);
            setAttachments(Request.attachments || [])
            setContent(requestContent);
            setSecretValue(Request.backgroundCheckResult)
        }
        dispatch(SetIsLoading(false));
        dispatch(SearchLendersPerRequestAsync({
            ...searchLender,
            search: {...searchLender.search, requestId: requestId}
        }));
    }, [id])

    useEffect(() => {

        if (userType && [LenderEmployee.label, BankManager.label, BankOfficer.label].includes(userType)) {

            if (request?.submissions &&
                request?.submissions[0]?.statusRaw === "Submitted") {
                onStartStudying()

            }
        }
    }, [request?.id])
    useEffect(() => {
        refreshData(id).then()
    }, [openModal, i18n.language]);

    const requestType: RequestType = `${request?.type
        ?.charAt(0)
        .toLowerCase()}${request?.type?.slice(1)}` as RequestType;

    const getRequest = useCallback(async (rid: string) => {
        const requestsClient = new RequestsClient();

        const res: ApiResponse = await requestsClient.GetAsync(rid, i18n.language);
        setRequest(res.data);
        return res;
    }, [i18n.language]);
    const [attachments, setAttachments] = useState<IRequestAttachment[]>(
        request?.attachments || []
    );
    const [secretValue, setSecretValue] = useState<BackGroundCheckResult>()
    const HeaderItem: FunctionComponent<IPropsItem> = ({
                                                           label,
                                                           value,
                                                       }): JSX.Element => {

        return (
            <Box display={"flex"} flexDirection={"row"} px={1} alignItems={"center"}>
                <Box px={1}>
                    <Typography  className={classes.headerText} color={"secondary"}>
                        {label}
                    </Typography>
                </Box>
                <Typography className={classes.headerText} color={"secondary"}>
                    {":"}
                </Typography>
                <Box px={1}>
                    <Typography style={{fontWeight:"normal"}} className={classes.headerText} color={"secondary"}>
                        {value}
                    </Typography>
                </Box>
            </Box>
        );
    };
    const [trials, setTrails] = useState<RequestTrailModel[]>()

    const onStartStudying = () => {
        dispatch(StartStudying(request?.id!, getRequest));
        onCLoseModal()
    };
    const onCLoseModal = () => {
        setOpenModal("none");
    };
    const onDeny = (notes: string) => {
        dispatch(DenyOffer(request?.id!, notes, onCLoseModal));
    };
    const onExport = () => {
        dispatch(ExportRequestAsync(request?.id!, getRequest));
    };

    const scoreRequest = (id: string) => {
        dispatch(ScoreRequest(id, (id) => getRequest(id)))
    }
    const GetModal = ({openModal}: { openModal: ModalNames }): JSX.Element => {
        setOpenModal(openModal);
        const [salesMan, setSalesMan] = useState<SalesManModel>()
        const client = new RequestsClient()


        const onSubmit = async () => {
            if (salesMan) {
                dispatch(SetIsLoading(true))
                const res = await client.EditSalesManAsync(salesMan);
                if (res.succeeded) {
                    dispatch(DispatchSuccessSnackbar(t('salesManSuccessMessage')))
                    onCLoseModal()
                } else {
                    dispatch(DispatchErrorSnackbar())
                }
            } else {
                dispatch(DispatchErrorSnackbar(t('requiredMessage')))
            }
            dispatch(SetIsLoading(false))
        }

        switch (openModal) {
            case "sendOfferModal": {
                return (
                    <SendOfferModal
                        request={request as IRequest}
                        title={"Send Request To Customer"}
                        open={openModal === "sendOfferModal"}
                        onClose={onCLoseModal}
                    />
                );
            }
            case "SalesManModal": {
                return (
                    <SalesManModal
                        id={request?.id}
                        accountManagerId={request?.accountManagerId}
                        open={openModal === "SalesManModal"}
                        onClose={onCLoseModal}
                        onSubmit={onSubmit}
                        setState={(e: any) => {
                            setSalesMan(e)
                        }}
                    />
                );
            }
            case "backgroundCheckModal": {
                return (
                    <BackgroundCheckModal
                        id={request?.id}
                        model={request?.backgroundCheckResult}
                        open={openModal === "backgroundCheckModal"}
                        onClose={onCLoseModal}
                    />
                );
            }
            case "createSubmissionModal": {
                return (
                    <SubmitRequestModal
                        onClose={onCLoseModal}
                        open={openModal === "createSubmissionModal"}
                        title={t("submit")}
                        requestId={Number(request?.id!)}
                    />
                );
            }
            case "rejectSubmissionModal": {
                return (
                    <RejectRequestModal
                        onSubmit={(notes) => dispatch(RejectRequest(request?.id!, notes, onCLoseModal))}
                        onClose={onCLoseModal}
                        open={openModal === "rejectSubmissionModal"}
                        title={t("reject")}
                    />
                );
            }
            case "deniSubmissionModal": {
                return (
                    <RejectRequestModal
                        onSubmit={(notes) => onDeny(notes)}
                        onClose={onCLoseModal}
                        open={openModal === "deniSubmissionModal"}
                        title={"Deny"}
                    />
                );
            }
            case "executeRequestModal": {
                return (
                    <ConfirmationModal
                        onClose={onCLoseModal}
                        open={openModal === "executeRequestModal"}
                        title={t("execute")}
                        discription={t("executeConfirmation")}
                        onSubmit={() =>
                            dispatch(
                                FinalizeOffer(request?.id!, true, "Executed", onCLoseModal)
                            )
                        }
                    />
                );
            }
            case "onStartStudying": {
                return (
                    <ConfirmationModal
                        onClose={onCLoseModal}
                        open={openModal === "onStartStudying"}
                        title={t("startStudying")}
                        discription={t("startStudyingConfirmation")}
                        onSubmit={onStartStudying}
                    />
                );
            }
            case "withDrawRequestModal": {
                return (
                    <ConfirmationModal
                        onClose={onCLoseModal}
                        open={openModal === "withDrawRequestModal"}
                        title={t("withDraw")}
                        dangerButton={true}
                        discription={t("withdrawConfirmation")}
                        onSubmit={() =>
                            dispatch(
                                FinalizeOffer(request?.id!, false, "Withdrawn", onCLoseModal)
                            )
                        }
                    />
                );
            }
            case "signedModal": {
                return (
                    <ConfirmationModal
                        onClose={onCLoseModal}
                        open={openModal === "signedModal"}
                        title={t("sign")}
                        discription={t("signConfirmation")}
                        onSubmit={() =>
                            dispatch(
                                SignedOffer(request?.id!, onCLoseModal, request?.submissions && request?.submissions[0]?.lenderId)
                            )
                        }
                    />
                );
            }
            case "assignToBranchModal": {
                return (
                    <AssignToBranchModal
                        onClose={onCLoseModal}
                        title={t("assign")}
                        requestId={request?.id!}
                    />
                );
            }
        }
        return <></>;
    };
    if (isLoading) {
        return (
            <div className={classes.container}>
                <Box>
                    <Skeleton
                        className={classes.skeleton}
                        variant={"rect"}
                        height={120}
                        animation={"wave"}
                    />
                </Box>

                <Box paddingTop={8}>
                    <Skeleton
                        className={classes.skeleton}
                        variant={"rect"}
                        height={140}
                        animation={"wave"}
                    />
                </Box>

                <Box paddingY={1}>
                    <Skeleton
                        className={classes.skeleton}
                        variant={"rect"}
                        height={140}
                        animation={"wave"}
                    />
                </Box>
                <Box paddingY={1}>
                    <Skeleton
                        className={classes.skeleton}
                        variant={"rect"}
                        height={140}
                        animation={"wave"}
                    />
                </Box>
                <Box paddingY={1}>
                    <Skeleton
                        className={classes.skeleton}
                        variant={"rect"}
                        height={140}
                        animation={"wave"}
                    />
                </Box>
            </div>
        );
      
    } else {
        return (
           
            <div className={classes.container}>
              
                <GetModal openModal={openModal}/>
                {(trials?.length || 0) > 0 &&
                <TrailsTable trails={trials || []} closeModal={() => setTrails([])}/>}
                <Box width={"100%"} paddingY={1}>
                    <Paper elevation={3}>
                        <Box width={"100%"} paddingY={2}>
                            <Grid container spacing={2}>


                                <Grid container md={6} item>
                                    <HeaderItem label={t("id")} value={request?.id}/>
                                    <AccessControl
                                        allowedTypes={[PlatformEmployee.label, HeadOfBusiness.label]}>
                                        <Typography style={{alignSelf:"center"}} className={classes.headerText} color={"secondary"}>
                                            |
                                        </Typography>
                                        <HeaderItem
                                            label={t("score")}
                                            value={request?.score}
                                        />
                                        <Typography style={{alignSelf:"center"}} className={classes.headerText} color={"secondary"}>
                                            |
                                        </Typography>
                                    
                                        <HeaderItem
                                            label={t("dbr")}
                                            value={request?.dbr?(request?.dbr *100).toString().slice(0,3) + '%':''??''}
                                        />
                                        


                                         
                                    </AccessControl>
                                </Grid>

                                <Grid justifyContent={"flex-end"} container md={6} item>
                                    {!branchId && request?.submissions &&
                                    request?.submissions[0]?.statusRaw === "UnderStudying" &&<AccessControl
                                        allowedTypes={[LenderEmployee.label, BankManager.label, BankOfficer.label]}>

                                        <Grid item>
                                            <Button
                                                onClick={() =>
                                                    setOpenModal("assignToBranchModal")
                                                }
                                                style={{backgroundColor: "#00384C", color: "white", marginInline: 4,fontSize:"0.75rem"}}
                                                color={"primary"}
                                                variant={"contained"}
                                                endIcon={<MdAssignment/>}
                                            >
                                                Assign Branch
                                            </Button>
                                        </Grid>

                                    </AccessControl>}
                                    <AccessControl
                                        allowedTypes={[PlatformEmployee.label, HeadOfBusiness.label]}>
                                        <Button
                                            variant={"contained"}
                                            style={{backgroundColor: "#00384C", color: "white", marginInline: 4,fontSize:"0.75rem"}}
                                            onClick={() =>
                                                setOpenModal("SalesManModal")
                                            }
                                        >{t('salesMan')}</Button>
                                    </AccessControl>
                                    <AccessControl
                                        allowedTypes={[PlatformEmployee.label, HeadOfBusiness.label, MerchantsAndBanksManager.label,
                                            TeamLeader.label, Sales.label, CustomerServiceManager.label, CustomerService.label, LenderEmployee.label,
                                            BankManager.label, BankOfficer.label]}>
                                        <Button
                                            style={{marginInline: 4,fontSize:"0.75rem"}}
                                            onClick={onExport}
                                            color={"primary"}
                                            variant={"contained"}
                                            endIcon={<MdPictureAsPdf/>}
                                        >
                                            Export
                                        </Button>
                                    </AccessControl>
                                </Grid>

                            </Grid>
                            <Divider style={{
                                marginTop: "0.5rem",
                                marginBottom: "0.5rem"
                            }}/>

                            <Grid container spacing={2}>
                                {/*<Grid md={3} item>*/}
                                {/*    <HeaderItem label={t("id")} value={request?.id}/>*/}
                                {/*</Grid>*/}
                                <Grid md={3} item>
                                    <AccessControl
                                        allowedTypes={[PlatformEmployee.label, HeadOfBusiness.label, MerchantsAndBanksManager.label,
                                            TeamLeader.label, Sales.label, CustomerServiceManager.label, CustomerService.label]}>
                                        <HeaderItem
                                            label={t("requestStatus")}
                                            value={i18n.language == 'ar' ? request?.requestStatus : request?.requestStatusRaw}
                                        />
                                    </AccessControl>
                                    <AccessControl
                                        allowedTypes={[LenderEmployee.label,
                                            BankManager.label, BankOfficer.label]}>
                                        <HeaderItem
                                            label={t("submissionStatus")}
                                            value={i18n.language == 'ar' ? request?.requestStatus : request?.requestStatusRaw}
                                        />
                                    </AccessControl>
                                </Grid>
                                <Grid md={3} item>
                                    <HeaderItem label={t("type")}
                                                value={i18n.language == 'ar' ? request?.typeName : request?.type}/>
                                </Grid>
                                <Grid md={3} item>
                                    <HeaderItem
                                        label={t("financingType")}
                                        value={i18n.language == 'ar' ? request?.financingDetails?.financingType : translteFinancingTypeToEnglish(request?.financingDetails?.financingType)}
                                    />
                                </Grid>

                                <Grid  item>
                                    <HeaderItem
                                        label={t("createdOn")}
                                        value={parseDate(request?.createdOn)}
                                    />
                                </Grid>
                                {request?.accountManager?.name &&
                                <Grid style={{textAlign:"start"}} md={3} item>
                                    <HeaderItem
                                        label={t("salesMan")}
                                        value={request?.accountManager ? (request?.accountManager?.name + " ( " + request?.accountManager?.phone + " ) ") ?? t('noSalesMan') : "-"}
                                    />
                                </Grid>
                                }
                                {request?.feedback && <AccessControl
                                    allowedTypes={[PlatformEmployee.label, HeadOfBusiness.label, MerchantsAndBanksManager.label,
                                        TeamLeader.label, Sales.label, CustomerServiceManager.label, CustomerService.label]}>
                                    <Grid md={3} item>
                                        <HeaderItem
                                            label={t("note")}
                                            value={request?.feedback}
                                        />
                                    </Grid></AccessControl>}
                            </Grid>
                        </Box>
                        <Box width={"100%"} px={3} display={"flex"} paddingY={1}>
                            <Grid container justifyContent={"flex-end"} spacing={3}>
                                <AccessControl
                                    allowedTypes={[PlatformEmployee.label, HeadOfBusiness.label]}>
                                    <Grid item>
                                        <Button
                                            style={{fontSize:"0.75rem"}}
                                            variant={"contained"}
                                            onClick={() => setTrails(request?.trails)}
                                        >{t('trail')}</Button>
                                    </Grid>

                                </AccessControl>
                                {request?.score && <AccessControl
                                    allowedTypes={[PlatformEmployee.label, HeadOfBusiness.label]}>
                                    <Grid item>
                                        <Button
                                            onClick={() => scoreRequest(request?.id)}
                                            style={{backgroundColor: '#0090FF', color: 'white',fontSize:"0.75rem"}}
                                            variant={"contained"}
                                        >
                                            {t("score")}
                                        </Button>
                                    </Grid>
                                </AccessControl>}

                                <AccessControl
                                    allowedTypes={[LenderEmployee.label, BankManager.label, BankOfficer.label]}>
                                    {request?.submissions &&
                                    request?.submissions[0]?.statusRaw === "UnderStudying" && (
                                        <>
                                            <Grid item>
                                            </Grid>
                                        </>
                                    )}
                                    {request?.submissions &&
                                    request?.submissions[0]?.statusRaw ===
                                    "UnderStudying" && (
                                        <Grid item>
                                            <Button
                                                onClick={() => setOpenModal("deniSubmissionModal")}
                                                className={classes.rejectButton}
                                                color={"secondary"}
                                                variant={"contained"}
                                                endIcon={<TiCancel/>}
                                            >
                                                Deny
                                            </Button>
                                        </Grid>
                                    )}
                                    {request?.submissions &&
                                    request?.submissions[0]?.statusRaw ===
                                    "UnderStudying" && (
                                        <Grid item>
                                            <Button
                                                onClick={() => setOpenModal("sendOfferModal")}
                                                className={classes.sendButton}
                                                variant={"contained"}
                                                endIcon={<MdSend/>}
                                            >
                                                Send Offer
                                            </Button>
                                        </Grid>
                                    )}
                                </AccessControl>
                                <AccessControl
                                    allowedTypes={[LenderEmployee.label, BankManager.label, BankOfficer.label, PlatformEmployee.label, HeadOfBusiness.label]}>
                                    {
                                        request?.requestStatusRaw === "Approved" && request?.submissions && request?.submissions[0]?.statusRaw ===
                                        "Approved" && (
                                            <Grid item>
                                                <Button
                                                    onClick={() => setOpenModal("signedModal")}
                                                    className={classes.sendButton}
                                                    variant={"contained"}
                                                    endIcon={<MdSend/>}
                                                >
                                                    Signed
                                                </Button>
                                            </Grid>
                                        )}
                                </AccessControl>
                                <AccessControl
                                    allowedTypes={[PlatformEmployee.label, HeadOfBusiness.label]}>
                                    {
                                        (request?.requestStatusRaw === "Created" ||
                                            request?.requestStatusRaw === "UnderProcess") &&
                                        !request?.submissions?.find(
                                            (s) =>
                                                s.statusRaw === "Submitted" ||
                                                s.statusRaw === "UnderStudying"
                                        ) && (
                                            <>
                                                <Grid item>
                                                    <Button
                                                        // onClick={onReject}
                                                        onClick={() => setOpenModal("rejectSubmissionModal")}
                                                        className={classes.rejectButton}
                                                        color={"secondary"}
                                                        variant={"contained"}
                                                        endIcon={<TiCancel/>}
                                                    >
                                                        Reject
                                                    </Button>
                                                </Grid>

                                                <Grid item>
                                                    <Button
                                                        onClick={() => setOpenModal("createSubmissionModal")}
                                                        className={classes.sendButton}
                                                        variant={"contained"}
                                                        endIcon={<MdSend/>}
                                                    >
                                                        Submit
                                                    </Button>
                                                </Grid>
                                            </>
                                        )}
                                </AccessControl>
                                <AccessControl
                                    allowedTypes={[PlatformEmployee.label, HeadOfBusiness.label,MerchantsAndBanksManager.label,TeamLeader.label]}>
                                    {
                                        (request?.requestStatusRaw === "Created" && !request.availableToMarketPlace)
                                           &&
                                            <>
                                                <Grid item>
                                                    <Button
                                                      
                                                         onClick={() => {
                                                            dispatch(SendToMarket(id))
                                                            refreshData(id).then()
                                                        }}
                                                        className={classes.sendButton}
                                                        color={"secondary"}
                                                        variant={"contained"}
                                                        endIcon={<MdSend/>}
                                                    >
                                                        {t("sendToMarket")}
                                                    </Button>
                                                </Grid>
                                            </>
                                        }
                                </AccessControl>
                                <AccessControl
                                    allowedTypes={[PlatformEmployee.label, HeadOfBusiness.label,MerchantsAndBanksManager.label,TeamLeader.label]}>
                                    {
                                        request?.availableToMarketPlace  
                                           &&
                                        
                                            <>

                                                <Grid item>
                                                    <Button
                                                         onClick={() => {
                                                            dispatch(PullFromMarket(id))
                                                            refreshData(id).then()
                                                        }}
                                                        className={classes.sendButton}
                                                        color={"secondary"}
                                                        variant={"contained"}
                                                        endIcon={<MdSend/>}
                                                    >
                                                         {t("pullFromMarket")}
                                                    </Button>
                                                </Grid>
                                            </>
                                        }
                                </AccessControl>
                                <AccessControl
                                    allowedTypes={[PlatformEmployee.label, HeadOfBusiness.label, BankManager.label, BankOfficer.label, LenderEmployee.label]}>
                                    {
                                        request?.requestStatusRaw === "Approved" && (
                                            <Grid item>
                                                <Button
                                                    // onClick={onReject}
                                                    onClick={() => setOpenModal("withDrawRequestModal")}
                                                    className={classes.rejectButton}
                                                    color={"secondary"}
                                                    variant={"contained"}
                                                    endIcon={<TiCancel/>}
                                                >
                                                    WithDraw
                                                </Button>
                                            </Grid>
                                        )}
                                    {
                                        request?.requestStatusRaw === "Approved" && request?.submissions && request?.submissions[0]?.statusRaw ===
                                        "Signed" && (
                                            <Grid item>
                                                <Button
                                                    onClick={() => setOpenModal("executeRequestModal")}
                                                    className={classes.sendButton}
                                                    variant={"contained"}
                                                    endIcon={<MdSend/>}
                                                >
                                                    Execute
                                                </Button>
                                            </Grid>
                                        )}
                                </AccessControl>
                            </Grid>
                        </Box>
                    </Paper>
                </Box>
                <Box width={"100%"} paddingY={3} paddingX={0}>
                    <ClientInfo
                        requestType={request?.type}
                        content={content?.PersonInformation}
                        requester={request?.requester!}
                        model={request?.requesterInformation!}
                        merchantName={request?.merchant?.name || ""}
                    />

                    <EmployerInfo
                        requestType={requestType}
                        content={{
                            CompanyDetails: content?.CompanyDetails,
                            EmploymentDetails: content?.EmploymentDetails,
                        }}
                        companyInfo={request?.companyDetails}
                        employmentInfo={request?.employmentDetails}
                    />
                    <FinancialInfo
                        jobType={request?.employmentDetails?.jobType || ""}
                        model={request?.incomeDetails}
                        content={content?.IncomeDetails}
                        requestType={requestType as RequestType}
                    />

                    <FinancingInfo
                        model={request?.financingDetails}
                        content={content?.FinancingDetails}
                        requestType={requestType as RequestType}
                    />
                    {requestType === "autoLoan" && (
                        <CarLoanInfo
                            price={request?.financingDetails?.financingValue || 0}
                            model={request?.autoLoan}
                            content={content?.AutoLoan}
                        />
                    )}
                    {requestType === "smeLoan" && (
                        <SmeFinancialInfoView
                            model={request?.smeLoan}
                            content={content?.SmeLoan}
                        />
                    )}
                    {requestType === "productLoan" && (
                        <ProductsLoanInfo
                            content={content?.ProductLoan}
                            model={request?.productLoan}
                        />
                    )}
                    {requestType === "mortgageLoan" && (
                        <MortgageLoanInfo
                            content={content?.MortgageLoan as ComponentGeneralMortgageLoan}
                            model={request?.mortgageLoan}
                        />
                    )}
                    {!(profile?.type === LenderEmployee.label || profile?.type === BankOfficer.label || profile?.type === BankManager.label) &&
                    <BackgroundCheckResultInfoView
                        setModal={() => setOpenModal("backgroundCheckModal")}
                        model={secretValue}/>}
                    <AttachmentsInfoView
                        requestType={request?.type as RequestType}
                        // @ts-ignore
                        attachments={attachments as IRequestAttachment[]}
                        content={
                            content?.AttachmentsComponent as ComponentRequestsAttachmentsComponent
                        }
                        setAttachments={(attachments) => setAttachments(attachments)}
                    />
                    <AccessControl
                        allowedTypes={[PlatformEmployee.label, HeadOfBusiness.label]}>
                        {attachments.length > 0 && <Box textAlign={"end"} marginTop={2}>
                            <Button
                                onClick={() => dispatch(AddAttachments(request?.id || "", attachments, getRequest))}
                                color={"secondary"} variant={"contained"}
                                style={{textTransform: "none",fontSize:"0.75rem"}}>{t('save')}</Button>
                        </Box>}
                    </AccessControl>
                </Box>
                <Box>
                    {request?.submissions && (
                        <SubmissionTable
                            requestType={requestType}
                            requestStatus={request.requestStatusRaw}
                            onAction={getRequest}
                            requestId={request?.id!}
                            data={request?.submissions ?? null}
                            show={true}
                        />
                    )}
                </Box>
                {/*<Box height={25}/>*/}
                {/*<Box>*/}
                {/*    {userType==="PlatformEmployee" &&*/}
                {/*    <TrailsTable trails={request?.trails ?? null}/>*/}
                {/*    }*/}
                {/*</Box>*/}
            </div>
        );
    }
};
export default Component;
