import React, {FunctionComponent} from "react";
import {FormControl, TextField} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { useTranslation } from 'react-i18next';

interface IProps {
    name?: string
    value: string | null | number | undefined
    onChange: (i: string) => void
    label: string
    onKeyPressCapture?: (i: any) => any
    type?: string
    disabled?: boolean
    multiline?:boolean
    isInvalid?: boolean;
    validationError?: string;
    onBlur?: (e: any) => void;
    notFullWidth?:boolean
}
const useStyles = makeStyles((theme: Theme) => ({

    input: {
        "& .MuiFormLabel-root": {
            left: "auto",
            right: "auto",
            transformOrigin: "top right",
            textAlign: "right",
        }
    }
}))

const TextInput: FunctionComponent<IProps> = (props: IProps): JSX.Element => {
    const classes = useStyles()
    const {i18n } = useTranslation();
    let labelStyle = i18n.language.slice(0, 2).toLowerCase() === "ar" ? true : false;
   
    if(!props.notFullWidth){
        return (
            <FormControl fullWidth>
                <TextField
                    className={labelStyle ? classes.input : ''}
                    name={props.name}
                    label={props.label}
                    variant={"standard"}
                    fullWidth
                    multiline={props.multiline}
                    disabled={props.disabled}
                    error={props.isInvalid}
                    helperText={props.validationError}
                    type={props.type ?? ""}
                    value={props.value}
                    size={"medium"}
                    onBlur={props.onBlur}
                    minRows={props.multiline?5:1}
                    onChange={(i) => {
                        props.onChange(i.target.value);
                    }}
                    onKeyPressCapture={(i) => {
                        props.onKeyPressCapture && props.onKeyPressCapture(i)
                    }}
                />
            </FormControl>
        )
    }
    else {
        return (
            <FormControl>
                <TextField
                    className={labelStyle ? classes.input : ''}
                    name={props.name}
                    label={props.label}
                    variant={"standard"}
                    fullWidth
                    multiline={props.multiline}
                    disabled={props.disabled}
                    error={props.isInvalid}
                    helperText={props.validationError}
                    type={props.type ?? ""}
                    value={props.value}
                    size={"medium"}
                    onBlur={props.onBlur}
                    minRows={props.multiline?5:1}
                    onChange={(i) => {
                        props.onChange(i.target.value);
                    }}
                    onKeyPressCapture={(i) => {
                        props.onKeyPressCapture && props.onKeyPressCapture(i)
                    }}
                />
            </FormControl>
        )
    }
}

export default TextInput
