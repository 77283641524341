import React, {FunctionComponent, useState} from "react";
import SimplyModal from "../../../shared/SimplyModal";
import {Box, Button, FormControl, Select} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import IAppStateModel from "../../../../types/AppState";
import {SubmitToLender} from "../../../../state/actions/SubmissionActions";

interface IProps {
    open: boolean;
    onClose: () => void;
    title: string;
    requestId: number;
}

const SubmitRequestModal: FunctionComponent<IProps> = ({open, onClose, title, requestId}): JSX.Element => {
    const lenders = useSelector((state: IAppStateModel) => state.lendersLookup);
    const [selectedLender, setSelectedLender] = useState<number>()
    const dispatch = useDispatch()
    const onSubmit = () => {

        dispatch(SubmitToLender({LenderId: selectedLender, requestId: requestId},onClose))
    }

    return (<SimplyModal open={open} onClose={onClose} title={title}>

        <Box display={"flex"} flexDirection={"column"} width={500}>
            <Box width={"100%"}>
                <FormControl fullWidth>
                    <Select
                        
                        value={selectedLender}
                        onChange={(i) => {
                            setSelectedLender(Number(i.target.value))
                        }}

                    >

                        <option value="">{"Lender"}</option>
                        {
                            lenders && lenders?.map((i) =>
                                (<option value={i.value}>{i.label}</option>)
                            )
                        }
                    </Select>
                </FormControl>

            </Box>
            <Box display={"flex"} justifyContent={"flex-end"} paddingTop={5}>
                <Button color={"primary"} variant={"contained"} onClick={onSubmit}>{title}</Button>
            </Box>

        </Box>
    </SimplyModal>)
}

export default SubmitRequestModal;
