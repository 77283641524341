// import {ISubmitOfferModel, RequestTrailModel} from "../../../../types/Request";
import {Backdrop, Box, Fade, Modal, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import {Theme} from "@material-ui/core/styles";
import {useTranslation} from 'react-i18next';
import ContactsIcon from '@material-ui/icons/Contacts';
import Button from "@material-ui/core/Button";

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1000,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,

};
const useStyles = makeStyles((theme: Theme) => ({
  modal: {
    position: 'absolute',
    top: '10%',
    left: '10%',
    overflow: 'scroll',
    height: '100%',
    display: 'block',
    padding: 50,
    direction: theme.direction ,
  },
  div: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '10px'
  },
  header: {
    fontSize: '20px',
    fontWeight: 'bold'
  }
}))

interface IProps {
  extraInfo?: any
  onClose: () => void
}

const InfoModal: React.FC<IProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation("crm")

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={true}
      className={classes.modal}
      onClose={props.onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={true}>
        <Box sx={style} style={{ width: "700px" }}>
          <div className={classes.div}>
            <Typography className={classes.header}> Full Contact
              Information</Typography>
            <ContactsIcon fontSize={"large"} style={{ color: 'orange' }}/>
          </div>
          <ul>
            <li>
              <Typography
                style={{ marginBottom: '10px' }}>{t("name")}: {props?.extraInfo?.name ?? "N/A"}</Typography>
            </li>
            <li>
              <Typography
                style={{ marginBottom: '10px' }}>{t("mobileNumber")}: {props?.extraInfo?.mobileNumber ?? "N/A"}</Typography>
            </li>

            <li>
              <Typography
                style={{ marginBottom: '10px' }}>{t("email")}: {props?.extraInfo?.email ?? "N/A"}</Typography>
            </li>
            <li>
              <Typography
                style={{ marginBottom: '10px' }}>{t("status")}: {props?.extraInfo?.status ?? "N/A"}</Typography>
            </li>
            <li>
              <Typography
                style={{ marginBottom: '10px' }}>{t("assignedTo")}: {props?.extraInfo?.assignedTo?.name ?? "N/A"}</Typography>
            </li>
            <li>
              <Typography
                style={{ marginBottom: '10px' }}>{t("priority")}: {t(props?.extraInfo?.highPriority?.toString()) ?? "N/A"}</Typography>
            </li>
            <li>
              <Typography
                style={{ marginBottom: '10px' }}>{t("createdBy")}: {props?.extraInfo?.createdByUser ?? "N/A"}</Typography>
            </li>
            <li>
              <Typography
                style={{ marginBottom: '10px' }}>{t("companyName")}: {props?.extraInfo?.companyName ?? "N/A"}</Typography>
            </li>
            <li>
              <Typography
                style={{ marginBottom: '10px' }}>{t("companyLocation")}: {props.extraInfo.companyLocation ?? "N/A"}</Typography>
            </li>
            <li>
              <Typography
                style={{ marginBottom: '10px' }}>{t("companyRegistrationDate")}: {props?.extraInfo?.companyRegistrationDate?.slice(0, 10) ?? "N/A"}</Typography>
            </li>

            <li>
              <Typography
                style={{ marginBottom: '10px' }}>{t("lastContactDate")}: {props?.extraInfo?.lastContactDate?.slice(0, 10) ?? "N/A"}</Typography>
            </li>
            <li>
              <Typography
                style={{ marginBottom: '10px' }}>{t("contactEconomicalStatus")}: {props?.extraInfo?.contactEconomicalStatus ?? "N/A"}</Typography>
            </li>

            <li>
              <Typography
                style={{ marginBottom: '10px' }}>{t("isRegisteredWithSocialSecurity")}: {props?.extraInfo?.isRegisteredWithSocialSecurity?.toString() ?? "N/A"}</Typography>
            </li>
            <li>
              <Typography
                style={{ marginBottom: '10px' }}>{t("isVocationalLicenseIssued")}: {props?.extraInfo?.isVocationalLicenseIssued?.toString() ?? "N/A"}</Typography>
            </li>
            <li>
              <Typography
                style={{ marginBottom: '10px' }}>{t("isTradeLicenseIssued")}: {props?.extraInfo?.isTradeLicenseIssued?.toString() ?? "N/A"}</Typography>
            </li>

            <li>
              <Typography
                style={{ marginBottom: '10px' }}>{t("note")}: {props?.extraInfo?.note ?? "N/A"}</Typography>
            </li>
            <li>
              <Typography
                  style={{ marginBottom: '10px' }}>{t("contactSource")}: {props?.extraInfo?.contactSource ?? "N/A"}</Typography>
            </li>
            <li>
              <Typography
                  style={{ marginBottom: '10px' }}>{t("productType")}: {props?.extraInfo?.productType ?? "N/A"}</Typography>
            </li>
            <li>
              <Typography
                  style={{ marginBottom: '10px' }}>{t("gender")}: {props?.extraInfo?.gender ?? "N/A"}</Typography>
            </li>
            <li>
              <Typography
                  style={{ marginBottom: '10px' }}>{t("countryCode")}: {props?.extraInfo?.countryCode ?? "N/A"}</Typography>
            </li>
            <li>
              <Typography
                  style={{ marginBottom: '10px' }}>{t("area")}: {props?.extraInfo?.area ?? "N/A"}</Typography>
            </li>



          </ul>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button style={{ backgroundColor: 'orange', color: 'white' }}
                    onClick={() => {
                      props?.onClose()
                    }}>CLOSE</Button>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
}

export default InfoModal;