import React, {FunctionComponent} from "react";
import Toolbar from "@material-ui/core/Toolbar";
import AppBar from "@material-ui/core/AppBar";
import {createStyles, makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import {Box, IconButton, Menu, MenuItem, Theme} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import Button from "@material-ui/core/Button";
import CookieManager from "../../utils/CookiesManager";
import {Link} from 'react-router-dom';
import {ExitToApp} from "@material-ui/icons";
import {useDispatch, useSelector} from "react-redux";
import IAppStateModel from "../../types/AppState";
import {Logout} from "../../state/actions/UserActions";
import AccessControl from "./AccessControl";
import {UserType} from "../../types/Profile";
import {
    BankManager, BankOfficer,
    HeadOfBusiness,
    LenderEmployee,
    MerchantsAndBanksManager,
    PlatformEmployee, Sales
} from "../common/JobRole";

interface IProps {
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,

        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        title: {
            flexGrow: 1,
        },
        toolbar: {display: "flex",},
        typography: {
            paddingRight: 8,
            paddingLeft: 8,
            fontSize: "0.93rem",
            [theme.breakpoints.down("xs")]: {
                fontSize: "0.8rem",
            },
            textTransform: "none"
        },
        menu:{
          paddingRight: 10,
          paddingLeft:10
        },
        changeLanguage:{
            width:"100%",
            justifyContent:'flex-end'
        }
    }),
);
const Header: FunctionComponent<IProps> = () => {

    const classes = useStyles();
    const {t, i18n} = useTranslation("header");


    const profile = useSelector((state:IAppStateModel) => state.profile)

    const dispatch = useDispatch();


    return (
        <div className={classes.root}>
            <AppBar position="static">
                <Toolbar className={classes.toolbar}>
                    <Box>
                        <a href={"/"}>
                            <img src="/logo.svg" alt={"Logo"}/>
                        </a>
                    </Box>


                    <Box className={classes.menu} display={"flex"} alignItems={"start"} flexDirection={"row"}>
                        {profile?.id&&<Link to="/requests">
                            <Typography className={classes.typography} noWrap variant="subtitle1">
                                {t("requests")}
                            </Typography>
                        </Link>}
                        <AccessControl allowedTypes={[PlatformEmployee.label,HeadOfBusiness.label,MerchantsAndBanksManager.label]}>
                        <Link to="/lenders">
                            <Typography className={classes.typography} noWrap variant="subtitle1">
                                {t("lenders")}
                            </Typography>
                        </Link>
                        </AccessControl>
                        <AccessControl allowedTypes={[PlatformEmployee.label,HeadOfBusiness.label,MerchantsAndBanksManager.label]}>
                        <Link to="/merchants">
                            <Typography className={classes.typography} noWrap variant="subtitle1">
                                {t("merchants")}
                            </Typography>
                        </Link>
                        </AccessControl>
                        <AccessControl allowedTypes={[PlatformEmployee.label,HeadOfBusiness.label]}>
                        <Link to="/users">
                            <Typography className={classes.typography} noWrap variant="subtitle1">
                                {t("users")}
                            </Typography>
                        </Link>
                        </AccessControl>
                        <AccessControl allowedTypes={[LenderEmployee.label,BankManager.label]}>
                            {!profile?.branchId && <Link to="/lender">
                                <Typography className={classes.typography} noWrap variant="subtitle1">
                                    {t("myLenderProfile")}
                                </Typography>
                            </Link>}
                        </AccessControl>
                        <AccessControl allowedTypes={[PlatformEmployee.label,HeadOfBusiness.label]}>
                            <Link to="/agents">
                                <Typography className={classes.typography} noWrap variant="subtitle1">
                                    {t("agents")}
                                </Typography>
                            </Link>
                        </AccessControl>
                        <AccessControl allowedTypes={[PlatformEmployee.label,HeadOfBusiness.label]}>
                            <Link to="/companies">
                                <Typography className={classes.typography} noWrap variant="subtitle1">
                                    {t("companies")}
                                </Typography>
                            </Link>
                        </AccessControl>
                        <AccessControl allowedTypes={[PlatformEmployee.label,HeadOfBusiness.label]}>
                            <Link to="/platform/users">
                                <Typography className={classes.typography} noWrap variant="subtitle1">
                                    {t("platformUsers")}
                                </Typography>
                            </Link>
                        </AccessControl>
                        <AccessControl allowedTypes={[PlatformEmployee.label,HeadOfBusiness.label]}>
                            <Link to="/RequestsValidations">
                                <Typography className={classes.typography} noWrap variant="subtitle1">
                                    {t("requestValidation")}
                                </Typography>
                            </Link>
                        </AccessControl>
                        <AccessControl allowedTypes={[PlatformEmployee.label,HeadOfBusiness.label,Sales.label]}>
                            <Link to="/crm">
                                <Typography className={classes.typography} noWrap variant="subtitle1">
                                    {t("crm")}
                                </Typography>
                            </Link>
                        </AccessControl>
                    </Box>


                    <Box className={classes.changeLanguage} display={"flex"} flexDirection={"row"} alignItems={"center"}>


                        <Button
                            variant="text"
                            color="secondary"
                            onClick={() => {
                                i18n.changeLanguage(i18n.language === "en" ? "ar" : "en");
                                CookieManager.setLocale(i18n.language);
                            }}
                        >
                            {i18n.language === "en" ? "العربية" : "English"}
                        </Button>
                        {profile && <Button onClick={() => dispatch(Logout())}>
                            <ExitToApp/>
                        </Button>}
                    </Box>


                </Toolbar>
            </AppBar>
        </div>

    );
};
export default Header;
