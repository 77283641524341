import React, { FunctionComponent } from "react";
import LoanView from "../LoanView";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import { ComponentRequestsIncomeDetailsModel, Maybe } from "../../../types/content";
import { IIncomeDetails } from "../../../types/Request";
import { Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { RequestType } from "../../../types/Request";
import { useTranslation } from "react-i18next";
import { lookUpResolver } from "../../../utils/lookupUtil";
import { useSelector } from "react-redux";
import AppState from "../../../types/AppState";


interface IProps {
    model?: IIncomeDetails;
    content: Maybe<ComponentRequestsIncomeDetailsModel> | undefined;
    onEditClick?: () => void;
    jobType: string
    requestType: RequestType
}


const FinancialInfoView: FunctionComponent<IProps> = ({
    onEditClick,
    content,
    model,
    requestType,
    jobType
}) => {


    const { t, i18n } = useTranslation("common");
    const metaLookUps = useSelector((state: AppState) => state.lookUpTables)
    return (
        <LoanView.Card
            title={content?.stepTitle || ""}
            icon={<AccountBalanceWalletIcon fontSize={"large"} />}
            onEditClick={onEditClick}
        >
            {/*{requestType=='smeLoan' && model?.obligations?.length! < 1 &&*/}
            {/*<LoanView.CardSection>*/}
            {/*    <LoanView.SectionItem*/}
            {/*        label={t("obligations")}*/}
            {/*        value={t("noObligations")}*/}
            {/*    /></LoanView.CardSection>}*/}

            {requestType == 'smeLoan' &&
                <LoanView.CardSection>
                    <LoanView.SectionItem
                        label={t("hasMortg")}
                        value={model?.hasAMortgage? t("yes"):t("no")}
                    />
                </LoanView.CardSection>
            }
            {requestType != 'smeLoan' && <LoanView.CardSection>
                {model?.monthlyIncome !== 0 && <LoanView.SectionItem
                    label={content?.monthlyIncome || ""}
                    value={model?.monthlyIncome?.toString() || ""}
                />}
                {(jobType === "1" || jobType === "salaried" || jobType === "موظف" || jobType === "مغترب"
                    || jobType === "31") && <><LoanView.SectionItem
                        label={content?.isSubjectToSocialSecurity || ""}
                        value={model?.isSubjectToSocialSecurity ? t("yes") : t("no")}
                    />

                        <LoanView.SectionItem
                            label={content?.isBankTransferred || ""}
                            value={model?.isBankTransferred ? t("yes") : t("no")}
                        /></>}
            </LoanView.CardSection>}
            {(jobType !== "اصحاب المهن الحرة" && jobType !== "المهنيون") &&
                <LoanView.CardSection>
                    <LoanView.SectionItem
                        //TODO get it from content
                        label={t("bankTransferMethod")}
                        //value={model?.bankTransferMethod?.toString() || t("NA")}
                        value={model?.bankTransferMethod ?
                            model?.bankTransferMethod == 'SalarySlip' ? t("salarySlip")
                                :
                                model.bankTransferMethod == 'CashDeposit' ? t("cashDeposit")
                                    :
                                    model.bankTransferMethod == 'CheckDeposit' ? t("checkDeposit")
                                        :
                                        t("NA")
                            : t("NA")
                        }
                    />
                </LoanView.CardSection>
            }
            {console.log("jobType", jobType)}
            {(jobType === "10" || jobType === "اصحاب المهن الحرة" || jobType === "المهنيون" || jobType === "21") &&
                <>
                    <LoanView.CardSection>
                        <LoanView.SectionItem
                            //TODO fix the content
                            label={t("depositsPeriodInMonths")}
                            value={model?.depositsPeriodInMonths?.toString() || t("NA")}
                        />
                    </LoanView.CardSection>
                    <LoanView.CardSection>
                        <LoanView.SectionItem
                            //TODO fix the content
                            label={t("deposits")}
                            value={model?.deposits?.toString() || t("NA")}
                        />
                    </LoanView.CardSection>

                    <LoanView.CardSection>
                        <LoanView.SectionItem
                            //TODO fix the content
                            label={t("hasACheckBook")}
                            value={model?.hasACheckBook ? t("yes") : t("no")}
                        />
                    </LoanView.CardSection>
                    <LoanView.CardSection>
                        <LoanView.SectionItem
                            //TODO fix the content
                            label={t("hasStatementOfAccounts")}
                            value={model?.hasStatementOfAccounts ? t("yes") : t("no")}
                        />
                    </LoanView.CardSection>
                </>
            }
            {model?.additionalIncome ? <LoanView.CardSection isLastSection>
                <LoanView.SectionItem
                    label={content?.additionalIncome || ""}
                    value={model?.additionalIncome?.toString() || ""}
                />
                <LoanView.SectionItem
                    label={content?.totalMonthlyIncome || ""}
                    value={model?.totalMonthlyIncome?.toString() || ""}
                />
            </LoanView.CardSection> : <></>}
            {(model?.obligations && model?.obligations?.length > 0) ?
                <Grid item md={12}><Typography style={{ textAlign: "start" }}>{content?.obligations?.obligationTitle + " :"}</Typography></Grid> :
                <LoanView.CardSection isLastSection>
                    <LoanView.SectionItem
                        label={t("obligations")}
                        value={t("noObligations")}
                    /></LoanView.CardSection>}
            {model?.obligations?.map((i, index) => <>
                <LoanView.CardSection isLastSection>
                    <LoanView.SectionItem
                        label={content?.obligations?.obligationType || ""}
                        value={i.obligationType || ""}
                    />
                    <LoanView.SectionItem
                        label={content?.obligations?.financingType || ""}
                        value={i.financingType || ""}
                    />

                    <LoanView.SectionItem
                        label={content?.obligations?.monthlyInstallment || ""}
                        value={i.monthlyInstallment?.toString() || ""}
                    />
                </LoanView.CardSection>
                <LoanView.CardSection isLastSection={index === model?.obligations!.length - 1}>
                    <LoanView.SectionItem
                        label={content?.obligations?.outstandingBalance || ""}
                        value={i?.outstandingBalance?.toString() || ""}
                    />
                    {requestType != "mortgageLoan" && <LoanView.SectionItem
                        label={content?.obligations?.donorId || ""}
                        value={lookUpResolver(i.donorId, metaLookUps?.donors) || ""}
                    />}

                </LoanView.CardSection>

            </>)}
        </LoanView.Card>
    );
};

export default FinancialInfoView;
