import { Box, Typography } from "@material-ui/core";
import { DropzoneArea } from "material-ui-dropzone";


import React, {FunctionComponent, useState} from "react";
import {IRequestAttachment} from "../../../types/Request";
import RequestsClient from "../../../apiClient/RequestsClient";
import { makeStyles } from "@material-ui/styles";
interface IProps {
  onFileUpload: (name:string,location: string) => void;
  onFileDelete:(name:string)=>void
  dropzoneText: string;
  initialValue?: IRequestAttachment[];
  onClear: () => void;
  setLoading:(b:boolean)=>void
}

const useStyles = makeStyles(() => ({
  button: {
    "& .MuiDropzonePreviewList-removeButton":{
      top: "0",
      right: "0",
      position: "inherit",

    }

  }

  }))


const UploadFileInput: FunctionComponent<IProps> = ({
  dropzoneText,
  onFileUpload,
  initialValue,
  onClear,
                                                      onFileDelete,
                                                      setLoading
}): JSX.Element => {
  const requestsClient = new RequestsClient();
  const classes = useStyles();
  const onUpload = async (files: File[], e: any) => {
    setLoading(true)
    for (const file of files) {
      let formData = new FormData();
      formData.append("file", file);
      const res= await requestsClient.UploadAttachments(formData)
      if (res.succeeded) {
        onFileUpload(file.name, res.data.location);
      }
    }
    setLoading(false)
  };

  const onDelete=(file:File)=>{
    onFileDelete(file.name)
  }

 
  return (
    <Box className={classes.button}
      width={"100%"}
      boxShadow={" 0px 3px 6px #00000033"}
      borderRadius={"1.5rem"}
    >
      
    

     
      <DropzoneArea
         
          showFileNamesInPreview={true}
          onDelete={onDelete}
          dropzoneText={dropzoneText}
          filesLimit={100}

          maxFileSize={50000000}
          onDrop={onUpload}
          showFileNames={true}
          initialFiles={initialValue?.map(iv => iv.type!)}
          acceptedFiles={["image/*", ".pdf", ".doc", ".docx"]}
        />


    </Box>
  );
};

export default UploadFileInput;
