import {ApiResponse, ClientFactory} from "@simplify9/simplyapiclient";
import IClient from "@simplify9/simplyapiclient/dist/Models/IClient";
import CookieManager from "../utils/CookiesManager";
import {IPagedSearch} from "../types/Paged";
import {BackGroundCheckResult, IRejectSubmission, IRequestAttachment, ISearchRequests,} from "../types/Request";
import {SalesManModel} from "../components/views/Requests/components/SalesManModal";

class RequestsClient {
    client: IClient;
    // BaseUrl: string;
    locale: string;

    constructor() {
        // this.BaseUrl = `${Config.baseUrl}`;
        this.client = ClientFactory();
        this.locale = CookieManager.getLocale();
    }


    async SearchAsync(
        locale: string,
        search?: IPagedSearch<ISearchRequests>
    ): Promise<ApiResponse> {
        const params = search?.search

        let query = `requests?culture=${locale}&limit=${search?.limit ?? 10}&offset=${search?.offset ?? 0}`
        query += `&search=${params?.search ?? ""}&lenderId=${params?.lenderId ?? ""}&merchantId=${params?.merchantId ?? ""}&status=${params?.status ?? ""}&type=${params?.type ?? ""}&CurrentSubmissionStatus=${params?.currentSubmissionStatus ?? ""}&score=${params?.score ?? ""}`
        if (params?.createdTo) {
            query += `&createdTo=${params.createdTo.toUTCString()}`;
        }
        if (params?.createdFrom ) {

            query += `&createdFrom=${params.createdFrom.toUTCString()}`;
        }
        const rs: ApiResponse = await this.client.SimplyGetAsync(query);
        return rs;
    }

    async GetAsync(
        id: string,
        locale: string
    ): Promise<ApiResponse> {

        const q: string = `requests/${id}?culture=${locale}`
        const rs: ApiResponse = await this.client.SimplyGetAsync(q);
        return rs;
    }

    async UploadAttachments(
        formData:any
    ): Promise<ApiResponse> {
        const res= await this.client.SimplyPostFormAsync(
            "files/upload",
            formData
        )
        return res
    }

    async Score(
        id:string
    ): Promise<ApiResponse> {
        const res= await this.client.SimplyPostFormAsync(
            `requests/Score`,
            {RequestID:id}
        )
        return res
    }

    async AddAttachments(
        addAttachment:{
            newAttachments:IRequestAttachment[]
        },
        id:string
    ): Promise<ApiResponse> {
        const res= await this.client.SimplyPostFormAsync(
            `requests/${id}/addAttachments`,
            addAttachment
        )
        return res
    }

    async ApproveOfferAsync(
        id: string
    ): Promise<ApiResponse> {

        const q: string = `requests/${id}/ApproveOffer`
        const rs: ApiResponse = await this.client.SimplyPostAsync(q, {});
        return rs;
    }

    async FinalizeOfferAsync(
        id: string,
        Executed: boolean
    ): Promise<ApiResponse> {

        const q: string = `requests/${id}/finalize`
        const rs: ApiResponse = await this.client.SimplyPostAsync(q, {Executed: Executed});
        return rs;
    }

    async SignedOfferAsync(
        id: string,
        lenderId?:number
    ): Promise<ApiResponse> {

        const q: string = `submissions/${id}/signed`
        const rs: ApiResponse = await this.client.SimplyPostAsync(q, {lenderId});
        return rs;
    }

    async RejectRequestAsync(
        id: string,
        model: IRejectSubmission
    ): Promise<ApiResponse> {

        const q: string = `requests/${id}/Reject`
        const rs: ApiResponse = await this.client.SimplyPostAsync(q, model);
        return rs;
    }

    async EditSecretAsync(
        id: string,
        model?: BackGroundCheckResult
    ): Promise<ApiResponse> {

        const q: string = `requests/${id}/editSecret`
        const rs: ApiResponse = await this.client.SimplyPostAsync(q, model);
        return rs;
    }

    async EditSalesManAsync(
        model?: SalesManModel
    ): Promise<ApiResponse> {

        const q: string = `requests/AssignToAccountManager`
        const rs: ApiResponse = await this.client.SimplyPostAsync(q, model);
        return rs;
    }

    async ExportAsPdfAsync(
        id: string,
    ): Promise<ApiResponse> {

        const q: string = `requests/${id}/pdf`
        const rs: ApiResponse = await this.client.SimplyGetAsync(q, {});
        return rs;
    }

    async ExportToExcelAsync(
        search?: ISearchRequests
    ): Promise<ApiResponse> {

        let query: string = `requests/ExportToExcel?search=${search?.search ?? ""}&lenderId=${search?.lenderId ?? ""}&merchantId=${search?.merchantId ?? ""}&status=${search?.status ?? ""}&type=${search?.type ?? ""}
        &CurrentSubmissionStatus=${search?.currentSubmissionStatus ?? ""}`
        if (search?.createdTo) {
            query += `&createdTo=${search.createdTo.toUTCString()}`;
        }
        if (search?.createdFrom ) {

            query += `&createdFrom=${search.createdFrom.toUTCString()}`;
        }
        const rs: ApiResponse = await this.client.SimplyGetAsync(query);
        return rs;
    }

    async SendToMarket(
        id:string
    ): Promise<ApiResponse> {
        const res= await this.client.SimplyPostFormAsync(
            `requests/${id}/SendToMarket`,{}
        )
        return res
    }

    async PullFromMarket(
        id:string
    ): Promise<ApiResponse> {
        const res= await this.client.SimplyPostFormAsync(
            `requests/${id}/PullFromMarket`,{}
        )
        return res
    }



}

export default RequestsClient;
