import React from 'react';

import {makeStyles} from "@material-ui/styles";
import {Theme} from "@material-ui/core/styles";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import {useTranslation} from "react-i18next";
import {useSelector} from 'react-redux';
import IAppStateModel from '../../types/AppState';
import {
    BankManager,
    BankOfficer,
    HeadOfBusiness,
    LenderEmployee,
    MerchantsAndBanksManager,
    PlatformEmployee, Sales, TeamLeader
} from "../common/JobRole";


const useStyles = makeStyles((theme: Theme) => ({

    search: {

        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        paddingTop: "1rem"

    },
    container: {
        [theme.breakpoints.down("xs")]: {
            paddingLeft: "1.2rem",
            paddingRight: "1.2rem",

        },
        paddingLeft: "3rem",
        paddingRight: "3rem",
        paddingTop: "1rem"
    },
    title: {
        fontWeight: "bold",
        fontSize: "1.5rem"
    },
    paging: {
        alignItems: "center",
        justifyContent: "center",
        paddingTop: "2rem",
        paddingBottom: "2rem"

    }
}))

function createData(name: any, calories: any, fat: any) {
    return {name, calories, fat};
}


interface IProps {
    columns: IColumn[]
    data: any[]
    limit: number;
    offset?: number;
    total?: number;
    onPageChange?: (page: number) => void
    onCountChange?: (count: number) => void
    actions?: (row: any) => JSX.Element
}

export interface IColumn {
    field: string;
    name: string;
    resolveData?: (row: any) => any

}


const Component = (props: IProps) => {
    const {t} = useTranslation();
    const classes = useStyles();
    const profile = useSelector((state: IAppStateModel) => state.profile);
    return (

     
        <TableContainer component={Paper}>
            <Table aria-label="custom pagination table">
                <TableHead>
                    <TableRow>
                        {props.columns.map((headCell) => (
                                (((headCell.field === "merchant.name" ||headCell.field === "requestStatus"||headCell.field==="score"||headCell.field==="latestSubmissionName") && (profile?.type === LenderEmployee.label || profile?.type === BankOfficer.label || profile?.type === BankManager.label))||(headCell.field=="branchName" && (profile?.type ===PlatformEmployee.label||profile?.type ===HeadOfBusiness.label||profile?.type ===MerchantsAndBanksManager.label||profile?.type ===TeamLeader.label||profile?.type ===Sales.label))) ? "" :
                                    <TableCell
                                    align={"center"}
                                    key={headCell.field}
                                    style={{fontWeight: "bold"}}
                                >
                                    {headCell.name}
                                </TableCell>
                        ))}
                        {props.actions && <TableCell style={{fontWeight: "bold", textAlign: "center"}} key={"actions"}>
                            {t("actions")}
                        </TableCell>}
                    </TableRow>
                </TableHead>
                
                <TableBody>
                  
                    {props.data.map((row: any) =>{
                       return  (
                        <TableRow>
                            {props.columns.map((headCell) => (
                                ((headCell.field === "merchant.name" || headCell.field === "requestStatus"||headCell.field==="score"||headCell.field==="latestSubmissionName") && (profile?.type === LenderEmployee.label || profile?.type === BankOfficer.label || profile?.type === BankManager.label)) || (headCell.field=="branchName" && (profile?.type === PlatformEmployee.label || profile?.type === HeadOfBusiness.label || profile?.type ===MerchantsAndBanksManager.label || profile?.type ===TeamLeader.label || profile?.type ===Sales.label)) ? "" :
                                    <TableCell component="th" scope="row" align={"center"}>
                                        {headCell.resolveData ? headCell.resolveData(row) : row[headCell.field]}
                                    </TableCell>
                            ))}
                            {props.actions && <TableCell style={{textAlign: "center"}} key={"actions"}>
                                {props.actions(row)}
                            </TableCell>}
                        </TableRow>
                    )})}

                </TableBody>
                <TableFooter>
                    <TablePagination
                        rowsPerPageOptions={[10, 20, 50]}
                        colSpan={6}
                        count={props.total ?? props.data.length}
                        rowsPerPage={props.limit}
                        labelRowsPerPage={t("rowsPerPage")}
                        page={Math.ceil((props.offset ?? 1) / (props.limit ?? 20))}
                        SelectProps={{
                            inputProps: {'aria-label': 'rows per page'},
                            native: true,
                        }}
                        onPageChange={(e, page) => {
                            props.onPageChange!(page)
                        }}
                        onRowsPerPageChange={(e: any) => {
                            props.onCountChange!(e.target.value)
                        }}

                    />
                </TableFooter>
            </Table>
        </TableContainer>


    )
}
export default Component;
