import {FunctionComponent, useState} from "react";
import {ISubmitOfferModel, RequestType} from "../../../../types/Request";
import {
    Box,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@material-ui/core";
import {SiCheckmarx} from "react-icons/all";
import {useDispatch, useSelector} from "react-redux";
import {ApproveOffer} from "../../../../state/actions/RequestsActions";
import IAppStateModel from "../../../../types/AppState";
import {useTranslation} from "react-i18next";
import ConfirmationModal from "./ConfirmationModal";
import AccessControl from "../../../shared/AccessControl";
import {
    CustomerService,
    CustomerServiceManager,
    HeadOfBusiness,
    MerchantsAndBanksManager,
    PlatformEmployee,
    Sales,
    TeamLeader
} from "../../../common/JobRole";

interface IProps {
    data: ISubmitOfferModel[] | null;
    show: boolean;
    requestId: string;
    onAction: any;
    requestStatus: string;
    requestType: RequestType;
}

const SubmissionTable: FunctionComponent<IProps> = ({
                                                        data,
                                                        show,
                                                        requestId,
                                                        onAction,
                                                        requestStatus,
                                                        requestType,
                                                    }: IProps): JSX.Element => {
    const lenders = useSelector(
        (state: IAppStateModel) => state.lookUpTables.lenders
    );
    const dispatch = useDispatch();
    const onApproveOffer = () => {
        dispatch(ApproveOffer(requestId, onAction));
    };
    const userType = useSelector((state: IAppStateModel) => state.profile?.type);
    const resolveAdminTypes=()=>{
        return (userType === PlatformEmployee.label || userType === HeadOfBusiness.label||userType === MerchantsAndBanksManager.label
            || userType === TeamLeader.label||userType === Sales.label||userType === CustomerServiceManager.label||userType=== CustomerService.label)
    }
    const {t} = useTranslation("requests");
    const [showModal, setShowModal] = useState(false)
    if (!show || data?.length === 0) {
        return <></>;
    } else {
        return (
            <Box>
                {showModal &&
                <ConfirmationModal onSubmit={onApproveOffer} open={true} onClose={() => setShowModal(false)}
                                   title={t("approve")} discription={t("approveConfirmation")}/>}
                <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">{t("lender")}</TableCell>

                                <TableCell align="center">{t("status")}</TableCell>
                                <TableCell align="center">{t("branch")}</TableCell>

                                <TableCell align="center">{t("isCountered")}</TableCell>
                                <TableCell align="center">{t("financingValue")}</TableCell>
                                <TableCell align="center">{t("interestRate")}</TableCell>
                                <TableCell align="center">
                                    {t("numberOfInstallments")}
                                </TableCell>
                                {requestType === "autoLoan" && (
                                    <TableCell align="center">{t("downPayment")}</TableCell>
                                )}
                                <TableCell align="center">{t("note")}</TableCell>
                                <TableCell align="center">Last Action</TableCell>
                                {resolveAdminTypes() && <TableCell align="center">Actions</TableCell>}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data?.map((row: ISubmitOfferModel) => (
                                <TableRow key={row.offer?.netFinancingValue}>
                                    <TableCell align="center">
                                        {row.lenderName}
                                        {/*{*/}
                                        {/*  lenders?.find((l) => l.value === row.lenderId?.toString())*/}
                                        {/*    ?.label*/}
                                        {/*}*/}
                                    </TableCell>
                                    <TableCell align="center">{row.status}</TableCell>
                                    <TableCell align="center">{row.branchName ?? "-"}</TableCell>
                                    <TableCell align="center">
                                        {row.offer?.isCountered?.toString() ?? "Not set"}
                                    </TableCell>
                                    <TableCell align="center">
                                        {row.offer?.netFinancingValue ?? "Not set"}
                                    </TableCell>
                                    <TableCell align="center">
                                        {row.offer?.interestRate ?? "Not set"}
                                    </TableCell>
                                    <TableCell align="center">
                                        {row.offer?.numberOfInstallments ?? "Not set"}
                                    </TableCell>
                                    {requestType === "autoLoan" && (
                                        <TableCell align="center">
                                            {row.offer?.downPayment ?? "Not set"}
                                        </TableCell>
                                    )}
                                    <TableCell align="center">
                                        {row.offer?.note ? row.offer?.note : row.feedback ? row.feedback : "Not set"}
                                    </TableCell>
                                    <TableCell align="center">
                                        {row.modifiedOn?.toDateString() ?? "Not set"}
                                    </TableCell>
                                    <AccessControl allowedTypes={[PlatformEmployee.label,HeadOfBusiness.label]}>
                                    <TableCell align="center">
                                        {requestStatus === "UnderProcess" &&
                                        row.statusRaw === "Approved" && (
                                            <Box style={{cursor:"pointer"}} onClick={()=>setShowModal(true)}>
                                                <SiCheckmarx
                                                    size={33}
                                                    color={"#4cd964"}
                                                />
                                            </Box>
                                        )}
                                    </TableCell>
                                    </AccessControl>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        );
    }
};

export default SubmissionTable;
