import React, {useState} from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import {useFormik} from 'formik';
import * as yup from "yup";
import {Button, Grid, TextField, Typography} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import UsersClient from "../../../apiClient/UsersClient";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            direction: theme.direction ,
        },
        paper: {
            backgroundColor: theme.palette.background.paper,
            border: '2px solid #00384b',
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
            width: 400,
        },
        footer: {
            textAlign: "end",
            paddingTop: 25

        }
    }),
);

const validationSchema = yup.object({
    email: yup.string().email().required(" must be a valid email"),
});

interface IProps {
    onClose: () => void
}

export const ForgetPasswordModal = ({onClose}: IProps) => {
    const classes = useStyles();
    let history = useHistory();
    const {t} = useTranslation("common")
    const client = new UsersClient();

    const formik = useFormik({
        initialValues: {
            email: ""
        },
        validationSchema: validationSchema,
        validateOnBlur: true,

        onSubmit: async (values, {resetForm}) => {
            let res = await client.ResetPasswordAsync(values.email);
            if (res.succeeded) {
                setEmailSent(true)
            } else {
                setEmailNotSent(true)
            }
        },
    });

    const [emailSent, setEmailSent] = useState(false)
    const [emailNotSent, setEmailNotSent] = useState(false)
    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={true}
                onClose={onClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={true}>
                    <div className={classes.paper}>
                        <Typography variant="h6" gutterBottom>
                            Enter Your Email :
                        </Typography>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    required
                                    id="email"
                                    name="email"
                                    label={"Email"}
                                    fullWidth
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    helperText={(formik.touched.email && formik.errors.email && formik.errors.email) || ""}
                                    error={
                                        formik.touched.email &&
                                        Boolean(formik.errors.email)
                                    }
                                />
                            </Grid>
                        </Grid>
                        <Grid className={classes.footer}>
                            {emailSent ? <Typography align={"center"} variant="h6" gutterBottom>
                                    Check your email to reset your Passowrd.
                                </Typography> : emailNotSent ? <Typography style={{color:"#ff3333"}} align={"center"} variant="h6" gutterBottom>
                                    Wrong Email .
                                </Typography> : ""}
                                <Button disabled={emailSent} onClick={formik.submitForm} color={"primary"} style={{textTransform: "none"}}
                                        variant="contained">Send</Button>
                        </Grid>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
}
export default ForgetPasswordModal
