import {DispatchErrorSnackbar, SetIsLoading} from "./UiActions";
import IAppStateModel from "../../types/AppState";
import LendersClient from "../../apiClient/LendersClient";
import IPagedResults, {IPagedSearch} from "../../types/Paged";
import ILender, {ISearchLenders} from "../../types/Lender";
import ValidationClient from "../../apiClient/validationClient";
import {ISearchValidation, RequestScoreCriteriaModel,SMERequestScoreCriteriaModel} from "../../types/RequestValidation";
import IAction from "../../types/Action";
import * as ActionsTypes from "../ActionsTypes";

const setRequestValidations = (validations: IPagedResults<RequestScoreCriteriaModel>): IAction => {
    return {
        type: ActionsTypes.SET_VALIDATIONS,
        payload: validations
    }

}
const setSmeRequestValidations = (validations: IPagedResults<SMERequestScoreCriteriaModel>): IAction => {
    return {
        type: ActionsTypes.SET_SME_VALIDATIONS,
        payload: validations
    }

}
export const SearchRequestValidation=(locale:string,search?:IPagedSearch<ISearchValidation>)=> {
    return async (dispatch: any, getState: any) => {
        dispatch(SetIsLoading(true))

        const state: IAppStateModel = getState();
        const validationClient = new ValidationClient();
        let response = await validationClient.Search(locale, search);
        if (response.succeeded) {
            dispatch(setRequestValidations(response.data as IPagedResults<RequestScoreCriteriaModel>))

        } else {
            dispatch(DispatchErrorSnackbar())
        }
        dispatch(SetIsLoading(false))

    };

}
export const SearchSmeRequestValidation=(locale:string,search?:IPagedSearch<ISearchValidation>)=> {
    return async (dispatch: any, getState: any) => {
        dispatch(SetIsLoading(true))
        const validationClient = new ValidationClient();
        let response = await validationClient.SearchSme(locale, search);
        if (response.succeeded) {
            dispatch(setSmeRequestValidations(response.data as IPagedResults<SMERequestScoreCriteriaModel>))
        } else {
            dispatch(DispatchErrorSnackbar())
        }
        dispatch(SetIsLoading(false))

    };

}
