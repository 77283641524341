import {LookUpModel} from "../../types/AppState";

export const Requester:IJobRole={label:"Requester",value:1}
export const Merchant:IJobRole={label:"Merchant",value:20}
export const LenderEmployee:IJobRole={label:"LenderEmployee",value:50}
export const PlatformEmployee:IJobRole={label:"PlatformEmployee",value:100}
export const HeadOfBusiness:IJobRole={label:"HeadOfBusiness",value:111}
export const MerchantsAndBanksManager:IJobRole={label:"MerchantsAndBanksManager",value:113}
export const TeamLeader:IJobRole={label:"TeamLeader",value:121}
export const Sales:IJobRole={label:"Sales",value:131}
export const CustomerService:IJobRole={label:"CustomerService",value:141}
export const CustomerServiceManager:IJobRole={label:"CustomerServiceManager",value:143}
export const BankOfficer:IJobRole={label:"BankOfficer",value:151}
export const BankManager:IJobRole={label:"BankManager",value:153}



interface IJobRole{
    label:string
    value:number
}