import { Overrides } from "@material-ui/core/styles/overrides";
import { colors } from "@material-ui/core";
import palette, { shape } from "./palette";
import transitions from "@material-ui/core/styles/transitions";

const overrides: Overrides = {
 
  MuiAppBar: {
    colorPrimary: {
      backgroundColor: colors.common.white,
      color: palette.secondary.main,
    },
  },
};
export default overrides;
