import {RequestType} from "../types/Request";
import {ApiResponse, ClientFactory} from "@simplify9/simplyapiclient";
import {Request} from "../types/content";
import Config from '../config';

export interface RequestContent extends Request {
}
export const GetRequestContent = async (locale: string,type?:string): Promise<RequestContent | null> => {
    let itemId;
    switch (type) {
        case "autoLoan":
            itemId = locale==="en"?2:6;
            break;
        case "productLoan":
            itemId = locale==="en"?3:7;
            break;
        case "SmeLoan":
            itemId = locale==="en"?4:8;
            break;
        case "personalLoan":
            itemId = locale==="en"?1:5;
            break;
        default: {
            itemId = locale==="en"?2:6;
        }

    }

    const client = ClientFactory({
        baseUrl: 'https://cms-stg.tamweelna.me/',

    });
    const res: ApiResponse = await client.SimplyGetAsync(`requests/${itemId}?_locale=${locale}`)
    if (res.succeeded) {
        return JSON.parse(JSON.stringify(res.data))
    }
    return null;
}
