import {ICreateUserModel, UserType} from "../../types/User";
import React, {FunctionComponent, useState} from "react";
import {Box, Button, createStyles, FormControl, Grid, Select} from "@material-ui/core";
import {Theme} from "@material-ui/core/styles";
import {makeStyles} from "@material-ui/styles";
import SimplyModal from "./SimplyModal";
import TextInput from "./TextInput";
import {useDispatch, useSelector} from "react-redux";
import {CreateUser} from "../../state/actions/UserActions";
import {useTranslation} from "react-i18next";
import AppState, {LookUpModel} from "../../types/AppState";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({}),
);

interface IProps {

    open: boolean
    onClose: () => void

    userType: UserType

}

const AddUserModal: FunctionComponent<IProps> = (props: IProps): JSX.Element => {
    const [user, setUser] = useState<ICreateUserModel>({})
    const classes = useStyles()
    const {t} = useTranslation(["createForms", "common", "users"])
    const dispatch = useDispatch()
    const onSubmit = () => {

        dispatch(CreateUser(user))
        props.onClose()
    }

    return (
        <SimplyModal title={t("addPlatformUserTitle")} open={props.open} onClose={props.onClose}>
            <Box>
            <Grid container>

                <Grid container spacing={3}>
                    <Grid item md={6}>
                        <TextInput
                            label={t('nameEn')}
                            value={user.nameEn}
                            onChange={(i: string) => {
                                setUser((u: ICreateUserModel) => ({...u, nameEn: i}));
                            }}
                        />
                    </Grid>
                    <Grid item md={6}>
                        <TextInput
                            label={t('nameAr')}

                            value={user.nameAr}
                            onChange={(i: string) => {
                                setUser((u: ICreateUserModel) => ({...u, nameAr: i}));
                            }}
                        />
                    </Grid>
                    <Grid item md={6}>
                        <TextInput
                            label={t("email")}
                            value={user.email}
                            onChange={(i: string) => {
                                setUser((u: ICreateUserModel) => ({...u, email: i}));
                            }}
                        />
                    </Grid>
                    <Grid item md={6}>
                        <TextInput
                            label={t("phone")}
                            value={user.phoneNumber}
                            onChange={(i: string) => {
                                setUser((u: ICreateUserModel) => ({...u, phoneNumber: i}));
                            }}
                        />
                    </Grid>
                    <Grid item md={6}>
                        <TextInput
                            label={t("password")}
                            value={user.password}
                            type={"password"}
                            onChange={(i: string) => {
                                setUser((u: ICreateUserModel) => ({...u, password: i}));
                            }}
                        />
                    </Grid>
                    <Grid item md={6}>
                        <FormControl fullWidth>
                        <Select
                            variant={"outlined"}
                            native
                            value={user.userType}
                            onChange={(i) => {
                                setUser((u: ICreateUserModel) => ({...u, userType: Number(i.target.value)}));
                            }}
                        >
                            <option value="">{t("userType")}</option>
                            {
                                adminTypes?.map((i) =>
                                    (<option value={i.value}>{i.label}</option>)
                                )
                            }
                        </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <Box display="flex" paddingTop={9} justifyContent={"flex-end"} alignSelf={"end"}>
                            <Button variant={"contained"} color={"secondary"} onClick={onSubmit}>{t("submit")}</Button>
                        </Box>

                    </Grid>

                </Grid>
            </Grid>
            </Box>
        </SimplyModal>

    )
}

export default AddUserModal

export const adminTypes:LookUpModel[]=[{label:"Head Of Business",value:"111"},
    {label:"Merchants And Banks Manager",value:"113"},
    {label:"Team Leader",value:"121"},
    {label:"Sales",value:"131"},
    {label:"Customer Service Manager",value:"143"},
    {label:"Customer Service",value:"141"},
]
