import {ApiResponse, ClientFactory} from "@simplify9/simplyapiclient";
import IClient from "@simplify9/simplyapiclient/dist/Models/IClient";
import CookieManager from "../utils/CookiesManager";
import {IPagedSearch} from "../types/Paged";
import {ICreateMerchant, ISearchMerchants, IUpdateMerchant} from "../types/Merchant";
import {IForgetModel} from "../components/views/Auth/ForgetPassword";

class MerchantsClient {
    client: IClient;
    // BaseUrl: string;
    locale: string;

    constructor() {
        // this.BaseUrl = `${Config.baseUrl}`;
        this.client = ClientFactory();
        this.locale = CookieManager.getLocale();
    }

    async Search(
        search?: IPagedSearch<ISearchMerchants>
    ): Promise<ApiResponse> {
        const rs: ApiResponse = this.client.SimplyGetAsync(`merchants?culture=${this.locale}&limit=${search?.limit}&offset=${search?.offset}&name=${search?.search?.name ? search?.search?.name : ""}`);
        return rs;
    }


    async Create(info: ICreateMerchant): Promise<ApiResponse> {
        let res = await this.client.SimplyPostAsync(`merchants?culture=${this.locale}`, info);
        return res;
    }

    async Edit(info: IUpdateMerchant, id: number): Promise<ApiResponse> {
        let res = await this.client.SimplyPostAsync(`merchants/${id}`, info);
        return res;
    }

    async SwitchStatus(id: number): Promise<ApiResponse> {
        let res = await this.client.SimplyPostAsync(`users/${id}/ChangeAccountStatus`, {});
        return res;
    }
    async updatePassword(
        merchantId: string | number | undefined,
        newPassword: string,
    ): Promise<ApiResponse> {
        const rs: ApiResponse = this.client.SimplyPostAsync(`users/${merchantId}/updatePassword`, {
            newPassword
        });
        console.log(rs.data, "rssssss")
        return rs;
    }
}

export default MerchantsClient;
