import React, { useEffect, useState } from 'react';
import { Box, FormControl, Grid, Paper, Input, InputAdornment, InputLabel, Select, MenuItem } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Table from "../../shared/Table";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { useDispatch, useSelector } from "react-redux";
import IAppStateModel from "../../../types/AppState";
import { SearchCompaniesAsync } from "../../../state/actions/CompaniesActions";
import { ISearchCompanies } from "../../../types/Companies";
import { useTranslation } from "react-i18next";
import { parseDate } from "../../../utils/dateUtil";
import { IPagedSearch } from "../../../types/Paged";
import { ImSearch } from "react-icons/all";
import ClearIcon from "@material-ui/icons/Clear";


const useStyles = makeStyles((theme: Theme) => ({

    search: {

        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",

    },
    container: {
        [theme.breakpoints.down("xs")]: {
            paddingLeft: "1.2rem",
            paddingRight: "1.2rem",

        },
        paddingLeft: "3rem",
        paddingRight: "3rem",
        paddingTop: "1rem",
    },
    title: {
        fontWeight: "bold",
        fontSize: "1.5rem"
    },
    tableContainer: {
        marginTop: '2rem'
    },
    paper: {
        width: "100%",
        marginTop: "0.4rem",

        marginBottom: "1.66rem",
        padding: "1rem"
    },
    selectLabel: {

        left: "auto",
        right: "auto",
        transformOrigin: "top right",
        textAlign: "right",
        paddingRight: "2rem",

    },
    input: {
        "& .MuiFormLabel-root": {
            left: "auto",
            right: "auto",
            transformOrigin: "top right",
            textAlign: "right",
        }
    },
}))

const Component = () => {
    const classes = useStyles();
    const companiesResults = useSelector((state: IAppStateModel) => state.companies.results);
    const [searchData, setSearchData] = useState<IPagedSearch<ISearchCompanies>>({ search: { name: "",rating: undefined}, limit: 20, offset: 0 })
    const { t, i18n } = useTranslation("companies")
    let labelStyle = i18n.language.slice(0, 2).toLowerCase() === "ar";
    const dispatch = useDispatch();
    const refreshData = () => {
        dispatch(SearchCompaniesAsync())
    }
    useEffect(() => {
        refreshData();
    }, [])
    return (
        <Grid container className={classes.container}>
            <Grid container justifyContent={"space-between"} alignItems={"center"}>
                <Grid item>
                    <Typography className={classes.title}>{t("title")}</Typography>
                </Grid>
            </Grid>
            <Paper className={classes.paper} elevation={3}>
                <Grid container spacing={2}>
                    <Grid item md={4}>
                        <FormControl fullWidth>
                            <InputLabel className={labelStyle ? classes.selectLabel : ''} htmlFor="name">{t("name")}</InputLabel>
                            <Input
                                id="name"
                                value={searchData?.search?.name ?? ""}
                                onChange={(i: any) => {
                                    setSearchData(s => ({ ...s, search: { ...s.search, name: i.target.value } }))
                                }}
                                endAdornment={
                                    <InputAdornment position="end">
                                        {searchData?.search?.name && <ClearIcon style={{ cursor: "pointer" }} onClick={() => {
                                            setSearchData(s => ({ ...s, search: { ...s.search, name: '' } }))
                                            refreshData()
                                        }
                                        } />}
                                    </InputAdornment>
                                }
                                placeholder={t("name")}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item md={2}>
                        <FormControl style={{ width: "85%" }}>
                            <InputLabel
                                className={labelStyle ? classes.selectLabel : ''}>{t("rating")}</InputLabel>

                            <Select
                                onChange={(i: any) => {
                                    setSearchData(s => ({ ...s, search: { ...s.search, rating: Number(i.target.value) } }))
                                }}
                                value={searchData?.search?.rating ?? 0}
                                displayEmpty={true}>
                                <MenuItem value={1}>{"A"}</MenuItem>
                                <MenuItem value={3}>{"B"}</MenuItem>
                                <MenuItem value={5}>{"C"}</MenuItem>
                                <MenuItem value={7}>{"Other"}</MenuItem>
                            </Select>
                        </FormControl>
                        {searchData?.search?.rating && <ClearIcon style={{ cursor: "pointer" }} onClick={() => {
                            setSearchData(s => ({ ...s, search: { ...s.search, rating: undefined } }))
                            refreshData()
                        }
                        } />}
                    </Grid>

                </Grid>
                <Box width={"100%"} display={"flex"} alignItems={"center"} justifyContent={"flex-end"}>
                    <FormControl>
                        <Button style={{ margin: 2, backgroundColor: "red", color: "white" }} variant={"contained"} color={"primary"}

                            onClick={() => {
                                setSearchData({ search: { name: "",rating: undefined }, limit: 20, offset: 0 })
                                refreshData()
                            }

                            }>
                            {t("clear")}
                        </Button>
                    </FormControl>
                    <FormControl>
                        <Button variant={"contained"} color={"primary"}
                            onClick={() => dispatch(SearchCompaniesAsync(searchData))}>
                            {t("search")}
                            <ImSearch style={{ marginLeft: "0.5rem" }} />
                        </Button>
                    </FormControl>
                </Box>
            </Paper>

            <Grid className={classes.tableContainer} container>
                <Table
                    columns={[
                        { field: "id", name: t("id") },
                        { field: "nameEn", name: t("name") },
                        { field: "rating", name: t("rating") },
                        {
                            field: "createdOn", name: t("createdOn"), resolveData: (row) => {
                                return parseDate(row.createdOn)
                            }
                        },
                    ]}
                    data={companiesResults?.matches ?? []}
                    limit={companiesResults?.limit!}
                    offset={companiesResults?.offset}
                    total={companiesResults?.total}
                    onPageChange={(page: number) => dispatch(SearchCompaniesAsync({ ...searchData, offset: (searchData.limit * page) }))}
                    onCountChange={(count: number) => dispatch(SearchCompaniesAsync({ ...searchData, limit: count }))} />
            </Grid>

        </Grid>
    )
}
export default Component;


