import {AppBar, Box, Tab, Tabs, useTheme} from "@material-ui/core";
import SwipeableViews from "react-swipeable-views";
import React from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {RequestScoreCriteriaModel} from "../../../../types/RequestValidation";
import AppState from "../../../../types/AppState";
import Typography from "@material-ui/core/Typography";
import ValidationsForm from "./validationsForm";

interface IProps {
    requestTypeId: number
    businessOwner?: boolean
    selfEmployed?: boolean
    salariedPrivate?: boolean
    salariedPublic?: boolean 
    mortgageDownPaymentWeightFlag?:boolean
    realStateTypeWeightFlag?:boolean
    automobileAgeWeightFlag?: boolean
    autoLoanDownPaymentWeightFlag?: boolean
    realStateAgeWeightFlag?: boolean
    requestType?:string;
    companyRentFlag?:boolean;
    expat?: boolean;
    companyRentPersonalFlag?: boolean;  
}

const SwipeableView = (props: IProps) => {
    const data = useSelector((state: AppState) => state.requestValidation.results?.matches) as RequestScoreCriteriaModel[]
    const [value, setValue] = React.useState(0);
    const {t, i18n} = useTranslation("validations")

    const handleChange = (event: any, newValue: number) => {
        setValue(newValue);
    };
    const handleChangeIndex = (index: number) => {
        setValue(index);
    };
    const theme = useTheme();
    return (
        
        <Box sx={{bgcolor: 'background.paper', width: "100%"}}>
            <AppBar position="static">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    aria-label="full width tabs example"
                >
                    {props.salariedPrivate && <Tab key={0} label={t('salariedPrivate')} {...a11yProps(0)} />}
                    {props.salariedPublic && <Tab key={1} label={t('salariedPublic')} {...a11yProps(1)} />}
                    {props.businessOwner && <Tab key={2} label={t('BusinessOwner')} {...a11yProps(2)} />}
                    {props.selfEmployed && <Tab key={3} label={t('SelfEmployed')} {...a11yProps(3)} />}
                    {props.expat && <Tab key={4} label={t('Expat')} {...a11yProps(4)} />}
                </Tabs>
            </AppBar>
            <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={value}
                onChangeIndex={handleChangeIndex}
            >
                <TabPanel key={0} value={value} index={0} dir={theme.direction}>
                    <ValidationsForm   
                    mortgageDownPaymentWeightFlag={props.mortgageDownPaymentWeightFlag}
                    realStateTypeWeightFlag={props.realStateTypeWeightFlag}
                    automobileAgeWeightFlag={props.automobileAgeWeightFlag}
                    autoLoanDownPaymentWeightFlag={props.autoLoanDownPaymentWeightFlag}
                    realStateAgeWeightFlag={props.realStateAgeWeightFlag}
                    salariedPrivateFlag={true}
                    requestTypeId={props.requestTypeId} 
                    jobTypeId={1} 
                    row={data?.find(i =>i.requestType == props.requestType && i.jobType == 'Salaried'&& i.companyForm == 'NonGovernmentalOrganization' )}
                    />

                </TabPanel>
                <TabPanel key={1} value={value} index={1} dir={theme.direction}>
                    <ValidationsForm   
                    mortgageDownPaymentWeightFlag={props.mortgageDownPaymentWeightFlag}
                    realStateTypeWeightFlag={props.realStateTypeWeightFlag}
                    automobileAgeWeightFlag={props.automobileAgeWeightFlag}
                    autoLoanDownPaymentWeightFlag={props.autoLoanDownPaymentWeightFlag}
                    realStateAgeWeightFlag={props.realStateAgeWeightFlag}
                    requestTypeId={props.requestTypeId} 
                    jobTypeId={1}
                    row={data?.find(i =>i.requestType == props.requestType && i.jobType == 'Salaried' && i.companyForm == 'GovernmentalSector' )}/>
                </TabPanel>
                <TabPanel key={2} value={value} index={2} dir={theme.direction}>
                    <ValidationsForm  
                    requestTypeId={props.requestTypeId} 
                    jobTypeId={10} 
                    mortgageDownPaymentWeightFlag={props.mortgageDownPaymentWeightFlag}
                    realStateTypeWeightFlag={props.realStateTypeWeightFlag}
                    automobileAgeWeightFlag={props.automobileAgeWeightFlag} 
                    autoLoanDownPaymentWeightFlag={props.autoLoanDownPaymentWeightFlag}
                    realStateAgeWeightFlag={props.realStateAgeWeightFlag}
                    companyRentFlag={props.companyRentFlag}
                    row={data?.find(i =>i.requestType == props.requestType && i.jobType == 'BusinessOwner' )}/>
                </TabPanel>
                <TabPanel key={3} value={value} index={3} dir={theme.direction}>
                    <ValidationsForm 
                      requestTypeId={props.requestTypeId} 
                      jobTypeId={21}
                      mortgageDownPaymentWeightFlag={props.mortgageDownPaymentWeightFlag}
                      realStateTypeWeightFlag={props.realStateTypeWeightFlag}
                      automobileAgeWeightFlag={props.automobileAgeWeightFlag}
                      autoLoanDownPaymentWeightFlag={props.autoLoanDownPaymentWeightFlag}
                      realStateAgeWeightFlag={props.realStateAgeWeightFlag}
                      selfEmployedFlag={true}
                      companyRentFlag={props.companyRentFlag}
                      companyRentPersonalFlag={props.companyRentPersonalFlag}
                      row={data?.find(i =>i.requestType == props.requestType && i.jobType == 'SelfEmployed' )}/>
                </TabPanel>
                <TabPanel key={4} value={value} index={4} dir={theme.direction}>
                    <ValidationsForm 
                      requestTypeId={props.requestTypeId} 
                      jobTypeId={31}  
                      mortgageDownPaymentWeightFlag={props.mortgageDownPaymentWeightFlag}
                      realStateTypeWeightFlag={props.realStateTypeWeightFlag}
                      automobileAgeWeightFlag={props.automobileAgeWeightFlag}
                      autoLoanDownPaymentWeightFlag={props.autoLoanDownPaymentWeightFlag}
                      realStateAgeWeightFlag={props.realStateAgeWeightFlag}  
                      expatSalaryWeightFlag={true}
                      row={data?.find(i =>i.requestType == props.requestType && i.jobType == 'Expat' )}/>
                </TabPanel>
            </SwipeableViews>
        </Box>
    )
}
export default SwipeableView

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
    const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const a11yProps = (index: number) => {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}
