import {Box, FormControl, Grid, IconButton, InputAdornment, TextField, Theme, Typography} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import UsersClient from "../../../apiClient/UsersClient";
import {SetUser} from "../../../state/actions/UserActions";
import {useDispatch} from "react-redux";
import {Visibility, VisibilityOff} from "@material-ui/icons";
import { useParams,useLocation, useHistory } from "react-router-dom";


const useStyles = makeStyles((theme: Theme) => ({
    container: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "100%",
    },
    stepBox: {
        marginTop: "3rem",
    },
    bottomContainer: {
        [theme.breakpoints.up("sm")]: {
            paddingTop: "5rem",
        },
        paddingTop: "2rem",

        alignItems: "center",
        justifyContent: "space-evenly",
    },
    linearProgress: {
        width: "100%",
    },
    root: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        marginTop: "2rem",
        [theme.breakpoints.down("xs")]: {
            paddingRight: "0.5rem",
            paddingLeft: "0.5rem",
        },
        [theme.breakpoints.up("lg")]: {
            paddingRight: "2rem",
            paddingLeft: "2rem",
        },
    },
    title: {
        fontSize: "1rem",
        [theme.breakpoints.up("xl")]: {
            fontSize: "1.2rem",
        },
    },
    forgetPassword: {
        fontSize: "0.7rem",
        [theme.breakpoints.up("xl")]: {
            fontSize: "1rem",
        },
    },
    resize: {
        fontSize: "0.75rem",
        [theme.breakpoints.up("xl")]: {
            fontSize: "1rem",
        },
        textTransform: "none",
    },
}));
export interface IForgetModel{
    token:string
    accountId:string
}

const ForgetPassword = () => {
    const classes = useStyles();
    const router = useLocation()
    const routers = useHistory()
    const [forgetModel, setForgetModel] = useState<IForgetModel | null>(null);

    useEffect(()=>{
        const string=router.search as string;
        const [initialToken,initialId]=string.split("&")
        const  Token = initialToken.split("=")[1]
        const  AccountId = initialId.split("=")[1]
        setForgetModel({token:Token,accountId:AccountId})
    })
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [failedReset, setFailedReset] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const client = new UsersClient();
    const dispatch = useDispatch();
    const handleReset = async () => {
        if (password.length > 5 && confirmPassword.length > 5 && password===confirmPassword&&forgetModel) {
            const response = await client.CompleteResetPasswordAsync(forgetModel,password,confirmPassword);
            if (response.succeeded) {
                routers.push('/login')
            }
        } else
            setFailedReset(true)
    }


    return (
        <>
            <Box className={classes.root}>
                <Grid
                    className={classes.root}
                    xs={12}
                    sm={5}
                    md={4}
                    lg={3}
                    xl={3}
                    container
                    direction={"column"}
                    justifyContent={"center"}
                    alignItems={"center"}
                >

                    <img
                        alt={"login"}
                        height={46}
                        width={46}
                        src="/images/loginIcon.svg"
                    />
                    <Box padding={2}>
                        <Typography color={"secondary"} className={classes.title}>
                            Reset Your Password
                        </Typography>
                    </Box>
                    <FormControl fullWidth>
                        <TextField
                            placeholder={"**********"}
                            id="standard-adornment-password"
                            type={showPassword ? 'text' : 'password'}
                            onChange={(e) => {
                                setPassword(e.target.value);
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => setShowPassword(showPassword => !showPassword)}
                                            onMouseDown={(event) => event.preventDefault()}
                                        >
                                            {showPassword ? <Visibility/> : <VisibilityOff/>}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}

                        />
                    </FormControl>
                    <Box padding={2}/>
                    <FormControl fullWidth>
                        <TextField
                            placeholder={"**********"}
                            id="standard-adornment-password"
                            type={showPassword ? 'text' : 'password'}
                            onChange={(e) => {
                                setConfirmPassword(e.target.value);
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => setShowPassword(showPassword => !showPassword)}
                                            onMouseDown={(event) => event.preventDefault()}
                                        >
                                            {showPassword ? <Visibility/> : <VisibilityOff/>}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}

                        />
                    </FormControl>
                    <Box padding={2}/>
                    {failedReset && <Typography color={"error"}>
                        {"Wrong Passwords"}
                    </Typography>}
                    <Box padding={1}/>
                    <FormControl fullWidth>
                        <Button
                            className={classes.resize}
                            size={"large"}
                            variant={"contained"}
                            color={"primary"}
                            onClick={handleReset}
                        >
                            Proceed
                        </Button>
                    </FormControl>
                    <Box padding={2}/>

                </Grid>
            </Box>
        </>
    );
};


export default ForgetPassword;
