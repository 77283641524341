import LoanView from "../LoanView";
import React, {FunctionComponent} from "react";

import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import {ComponentRequestsFinancingDetails, Maybe} from "../../../types/content";
import {IFinancingDetails, RequestType} from "../../../types/Request";
import {useTranslation} from "react-i18next";

interface IProps {
    model?: IFinancingDetails;
    content: Maybe<ComponentRequestsFinancingDetails> | undefined;
    onEditClick?: () => void;
    withLookUpResolving?: boolean;
    requestType: RequestType
}

const FinancingInfoView: FunctionComponent<IProps> = ({
    onEditClick,
    content,
    model,
    withLookUpResolving,
    requestType
}) => {
    const {t, i18n} = useTranslation("requests");

    const financingTypeResolver = (name?: string) => {
        switch (name) {
            case 'تجاري': {
                return t('Commercial')
            }
            case 'اسلامي': {
                return t('Islamic')
            }
            case 'غير محدد': {
                return t('Any')
            }
        }
    }

    return (
        <LoanView.Card
            title={content?.stepTitle || ""}
            icon={<MonetizationOnIcon fontSize={"large"}/>}
            onEditClick={onEditClick}
        >
            <LoanView.CardSection>
                <LoanView.SectionItem
                    label={content?.financingValue || ""}
                    value={model?.financingValue?.toString() || ""}
                />
                <LoanView.SectionItem
                    label={content?.financingType || ""}
                    value={financingTypeResolver(model?.financingType) || ""}
                />

                {model?.downPayment && <LoanView.SectionItem
                    label={content?.downPayment || ""}
                    value={model?.downPayment?.toString() || ""}
                />}

                {model?.purpose && model?.purpose != "0" && <LoanView.SectionItem
                    label={content?.purpose || ""}
                    value={model?.purpose || " "}
                />}
                {model?.downPayment == 0 && requestType == "autoLoan" && <LoanView.SectionItem
                    label={content?.purpose || ""}
                    value={model?.purpose || " "}
                />}
            </LoanView.CardSection>
            <LoanView.CardSection isLastSection>
                <LoanView.SectionItem
                    label={content?.numberOfInstallments || ""}
                    value={model?.numberOfInstallments?.toString() || ""}
                />
            {requestType == 'smeLoan' && 
                <LoanView.SectionItem
                    label={t("hasAsponser")}
                    value={model?.hasFinancialSponsors? t("yes"):t("no")}
                />
            }
            </LoanView.CardSection>
        </LoanView.Card>
    );
};

export default FinancingInfoView;
