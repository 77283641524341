import React, { FunctionComponent } from "react";
import LoanView from "../LoanView";
import WorkIcon from "@material-ui/icons/Work";
import { ICompanyDetails, IEmploymentDetails, RequestType } from "../../../types/Request";
import { useTranslation } from "react-i18next";
import { parseDate } from "../../../utils/dateUtil";
import { lookUpResolver } from "../../../utils/lookupUtil";
import { useSelector } from "react-redux";
import AppState from "../../../types/AppState";
interface IProps {
    companyInfo?: ICompanyDetails;
    employmentInfo?: IEmploymentDetails;
    content: any | undefined;
    onEditClick?: () => void;
    requestType: RequestType;

}

const EmployerInfoView: FunctionComponent<IProps> = ({
    content,
    companyInfo,
    employmentInfo,
    onEditClick,
    requestType,

}) => {
    const { t, i18n } = useTranslation("common");
    const metaLookUps = useSelector((state: AppState) => state.lookUpTables)


    return (
        <LoanView.Card
            title={content?.CompanyDetails?.stepTitle || ""}
            icon={<WorkIcon fontSize={"large"} />}
            onEditClick={onEditClick}
        >
            <LoanView.CardSection>
                {requestType !== "smeLoan" && (
                    <>
                        <LoanView.SectionItem
                            label={content?.EmploymentDetails?.jobType || ""}
                            value={
                                employmentInfo?.jobType || ""
                            }
                        />
                        {employmentInfo?.position != null && <LoanView.SectionItem
                            label={content?.EmploymentDetails?.position || ""}
                            value={ employmentInfo?.position || "" }
                        />
                        }
 
                    </>
                )}
                <LoanView.SectionItem
                    label={content?.CompanyDetails?.companyName || ""}
                    value={lookUpResolver(companyInfo?.companyName, metaLookUps?.companies) || ""}
                />

                {/*//this dependency to maintain 3 items per column*/}
                {(requestType === "smeLoan" || employmentInfo?.jobType === "21" || requestType === "productLoan" ||
                    employmentInfo?.jobType === "المهنيون" ||
                    employmentInfo?.jobType === "SelfEmployed" || employmentInfo?.jobType === "مغترب" ||
                    employmentInfo?.jobType === "31") && (
                        <>
                            {companyInfo?.companyAddress?.country && 
                            <LoanView.SectionItem
                                label={content?.CompanyDetails?.companyAddress?.country || ""}
                                value={
                                    companyInfo?.companyAddress?.country || ""
                                }
                            />}
                            {companyInfo?.companyAddress?.area && <LoanView.SectionItem
                                label={content?.CompanyDetails?.companyAddress?.area || ""}
                                value={
                                    companyInfo?.companyAddress?.area || ""
                                }
                            />}
                            <LoanView.SectionItem
                                label={content?.CompanyDetails?.companyAddress?.city || ""}
                                value={
                                    companyInfo?.companyAddress?.city || ""
                                }
                            />
                            
                        </>
                    )}
            </LoanView.CardSection>

            {
                (employmentInfo?.jobType === "10"
                    || employmentInfo?.jobType === "21"
                    || employmentInfo?.jobType === "BusinessOwner"
                    || employmentInfo?.jobType === "SelfEmployed"
                    || employmentInfo?.jobType === "المهنيون"
                    || employmentInfo?.jobType === "اصحاب المهن الحرة") &&
                <LoanView.CardSection>
                    <LoanView.SectionItem
                        //TODO get it from content
                        label={t("isCompanyCommerciallyLicensed")}
                        value={companyInfo?.isCompanyCommerciallyLicensed ? t("yes") : t("NA")}
                    />

                    <LoanView.SectionItem
                        //TODO get it from content
                        label={t("companyOfficeRentContractPeriod")}
                        value={companyInfo?.companyOfficeRentContractPeriod?.toString() || t("NA")}
                    />
                </LoanView.CardSection>
            }

            {(requestType === "smeLoan" ||
                employmentInfo?.jobType === "10" ||
                employmentInfo?.jobType === "BusinessOwner" ||
                employmentInfo?.jobType === "اصحاب المهن الحرة" ||
                employmentInfo?.jobType === "21" ||
                employmentInfo?.jobType === "المهنيون" ||
                employmentInfo?.jobType === "SelfEmployed") && (
                    <LoanView.CardSection>
                        {companyInfo?.companyIndustry && companyInfo?.companyIndustry !== "0" && <LoanView.SectionItem
                            label={content?.CompanyDetails?.companyIndustry || ""}
                            value={
                                companyInfo?.companyIndustry || ""
                            }
                        />}
                        <LoanView.SectionItem
                            // label={
                            //     content?.CompanyDetails?.isCompanyVocationalLicenseIssued || ""
                            // }
                            label={
                                (employmentInfo?.jobType === "المهنيون" || employmentInfo?.jobType === "SelfEmployed" || employmentInfo?.jobType === "21") ?
                                    t("BusinessLicense") : t("isCompanyVocationalLicenseIssued")
                            }
                            // label={t("isCompanyVocationalLicenseIssued")}
                            value={
                                companyInfo?.isCompanyVocationalLicenseIssued === true ? t("yes") : t("no")
                            }
                        />

                        {requestType === "smeLoan" && 
                            <LoanView.SectionItem
                                label={t("companyStatus")}
                                value={companyInfo?.companyStatus || ""} 
                        />}

                        {companyInfo?.companyRegistrationDate && <LoanView.SectionItem
                            label={content?.CompanyDetails?.companyRegistrationDate || ""}
                            value={parseDate(companyInfo?.companyRegistrationDate, true) || ""}
                        />}
                    </LoanView.CardSection>
                )}
            <LoanView.CardSection isLastSection>
                {employmentInfo?.companyForm && <LoanView.SectionItem
                    label={content?.EmploymentDetails?.companyForm || ""}
                    value={employmentInfo?.companyForm || ""}
                />}
                {requestType !== "smeLoan" &&
                    (employmentInfo?.jobType !== "21" && companyInfo?.companyAddress?.area) && (
                        <LoanView.SectionItem
                            label={content?.CompanyDetails?.companyAddress?.area || ""}
                            value={
                                companyInfo?.companyAddress?.area || " "
                            }
                        />
                    )}
                {(requestType === "smeLoan" ||
                    employmentInfo?.jobType === "10" ||
                    employmentInfo?.jobType === "BusinessOwner" ||
                    employmentInfo?.jobType === "اصحاب المهن الحرة" ||
                    employmentInfo?.jobType === "21" ||
                    employmentInfo?.jobType === "المهنيون" ||
                    employmentInfo?.jobType === "SelfEmployed") && (
                        <>
                            {companyInfo?.companyRegistrationId && <LoanView.SectionItem
                                label={content?.CompanyDetails?.companyRegistrationId || ""}
                                value={companyInfo?.companyRegistrationId || ""}
                            />}

                            {companyInfo?.companyType && companyInfo?.companyType !== "0" && <LoanView.SectionItem
                                label={content?.CompanyDetails?.companyType || ""}
                                value={
                                    companyInfo?.companyType || ""
                                }
                            />}
                            
                        {requestType === "smeLoan" && 
                            <LoanView.SectionItem
                                label={t("banckBalance")}
                                value={companyInfo?.companyBankBalance || ""} 
                            />}
                        </>
                    )}
                {(employmentInfo?.jobType === "1" ||
                    employmentInfo?.jobType === "موظف" || employmentInfo?.jobType === "Salaried"
                    || employmentInfo?.jobType === "Salaried" || employmentInfo?.jobType === "مغترب" ||
                    employmentInfo?.jobType === "31") &&
                    employmentInfo?.employmentDate?.toString() !== "0001-01-01T00:00:00" && (
                        <LoanView.SectionItem
                            value={parseDate(employmentInfo?.employmentDate, true) || ""}
                            label={content?.EmploymentDetails?.employmentDate || ""}
                        />
                    )}
            </LoanView.CardSection>
        </LoanView.Card>
    );
};

export default EmployerInfoView;

