import React from 'react';
import {makeStyles, Theme, createStyles} from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import {useFormik} from 'formik';
import * as yup from "yup";
import {
    Box,
    Button, Checkbox,
    FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel,
    Grid,
    Table,
    TableBody,
    TableCell, TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography
} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {IUpdateMerchant} from '../../../types/Merchant';
import MerchantsClient from '../../../apiClient/MerchantsClient';
import {useHistory} from 'react-router-dom';
import ILender, {IUpdateLender} from "../../../types/Lender";
import Paper from "@material-ui/core/Paper";
import TextInput from "../../shared/TextInput";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modal: {
            position: 'absolute',
            top: '10%',
            left: '10%',
            overflow: 'scroll',
            height: '100%',
            display: 'block',
            padding: 50,
            direction: theme.direction ,
        },
        paper: {
            backgroundColor: theme.palette.background.paper,
            border: '2px solid #000',
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
        },
        footer: {
            textAlign: "end",
            paddingTop: 25

        },
        input: {
            "& .MuiFormLabel-root": {
                left: "auto",
                right: "auto",
                transformOrigin: "top right",
                textAlign: "right",
            }
        }
    }),
);

const validationSchema = yup.object({
    nameAr: yup
        .string()
        .min(3, " Minimum 3 Characters")
        .required(" is Required"),
    nameEn: yup
        .string()
        .min(3, " Minimum 3 Characters")
        .required(" is Required"),
});

interface IProps {
    onClose: () => void
    row: ILender
    client: any
    title: string
}

export const LenderEditModal = ({onClose, row, client, title}: IProps) => {
    const classes = useStyles();
    let history = useHistory();
    const {t,i18n} = useTranslation("lenders")
    let labelStyle = i18n.language.slice(0, 2).toLowerCase() === "ar" ? true : false;
    const formik = useFormik({
        initialValues: {
            nameAr: row.nameAr,
            nameEn: row.nameEn,
            commissions: {
                productLoanCommission: row.lenderCommissions?.productLoanCommission,
                autoLoanCommission: row.lenderCommissions?.autoLoanCommission,
                personalLoanCommission: row.lenderCommissions?.personalLoanCommission,
                mortgageLoanCommission: row.lenderCommissions?.mortgageLoanCommission,
                smeLoanCommission: row.lenderCommissions?.smeLoanCommission,
            },
            preferences: {
                acceptsPersonalLoans: row.preferences?.acceptsPersonalLoans,
                acceptsMortgageLoans: row.preferences?.acceptsMortgageLoans,
                acceptsAutoLoans: row.preferences?.acceptsAutoLoans,
                acceptsProductLoans: row.preferences?.acceptsProductLoans,
                acceptsSMELoans: row.preferences?.acceptsSMELoans,
            }
        },
        validationSchema: validationSchema,
        validateOnBlur: true,

        onSubmit: async (values, {resetForm}) => {
            let res = await client.Edit(values as IUpdateLender, row.id);
            if (res.succeeded) {
                window.location.reload();
            } else {

            }
        },
    });

    // const [state, setState] = React.useState({
    //     PersonalLoan: false,
    //     AutoLoan: false,
    //     ProductLoan: false,
    //     SmeLoan: false,
    //     MortgageLoan: false,
    // });

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, fieldName: string, commissionName: string) => {
        formik.setFieldValue(commissionName, undefined)
        formik.setFieldValue(fieldName, event.target.checked)
    };


    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={true}
                onClose={onClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={true}>
                    <div className={classes.paper}>
                        <Typography variant="h6" gutterBottom>
                            {title}
                        </Typography>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={12} md={6}>
                                <TextField
                                 className={labelStyle ? classes.input : ''}
                                    required
                                    id="nameEn"
                                    name="nameEn"
                                    label={t("nameEn")}
                                    fullWidth
                                    value={formik.values.nameEn}
                                    onChange={formik.handleChange}
                                    helperText={(formik.touched.nameEn && formik.errors.nameEn && t("nameEn") + formik.errors.nameEn) || ""}
                                    error={
                                        formik.touched.nameEn &&
                                        Boolean(formik.errors.nameEn)
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <TextField
                                 className={labelStyle ? classes.input : ''}
                                    required
                                    id="nameAr"
                                    name="nameAr"
                                    label={t("nameAr")}
                                    fullWidth
                                    value={formik.values.nameAr}
                                    onChange={formik.handleChange}
                                    helperText={(formik.touched.nameAr && formik.errors.nameAr && t("nameAr") + formik.errors.nameAr) || ""}
                                    error={
                                        formik.touched.nameAr &&
                                        Boolean(formik.errors.nameAr)
                                    }
                                />
                            </Grid>
                            {/*</Grid>*/}
                            <Grid item xs={12} sm={12}>
                                <TableContainer component={Paper}>
                                    <Table aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="center">
                                                    {t("loanType")}</TableCell>
                                                <TableCell align="center">{t("commission")}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell align="center">
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={formik.values.preferences.acceptsPersonalLoans}
                                                                onChange={(event) => handleChange(event, "preferences.acceptsPersonalLoans", "commissions.personalLoanCommission")}
                                                                name="PersonalLoan"/>
                                                        }
                                                        label={t("personalLoan")}
                                                    />
                                                </TableCell>
                                                <TableCell align="center">
                                                    <FormControl>
                                                        <TextInput
                                                            notFullWidth={true}
                                                            disabled={!formik.values.preferences.acceptsPersonalLoans}
                                                            name="commissions.personalLoanCommission"
                                                            label={t("personalLoan")}
                                                            value={formik.values.commissions.personalLoanCommission || ""}
                                                            onChange={(v: string) => formik.setFieldValue("commissions.personalLoanCommission", v)}
                                                            isInvalid={
                                                                formik.touched.commissions?.personalLoanCommission && Boolean(formik.errors.commissions?.personalLoanCommission)
                                                            }
                                                            validationError={
                                                                (formik.touched.commissions?.personalLoanCommission && formik.errors?.commissions?.personalLoanCommission) || ""
                                                            }
                                                            onBlur={formik.handleBlur}
                                                        />
                                                    </FormControl>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align="center">
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={formik.values.preferences.acceptsAutoLoans}
                                                                onChange={(event) => handleChange(event, "preferences.acceptsAutoLoans", "commissions.autoLoanCommission")}
                                                                name="AutoLoan"/>
                                                        }
                                                        label={t("autoLoan")}
                                                    />
                                                </TableCell>
                                                <TableCell align="center">
                                                    <FormControl>
                                                        <TextInput
                                                            disabled={!formik.values.preferences.acceptsAutoLoans}
                                                            notFullWidth={true}
                                                            name="commissions.autoLoanCommission"
                                                            label={t("autoLoan")}
                                                            value={formik.values.commissions.autoLoanCommission || ""}
                                                            onChange={(v: string) => formik.setFieldValue("commissions.autoLoanCommission", v)}
                                                            isInvalid={
                                                                formik.touched.commissions?.autoLoanCommission && Boolean(formik.errors.commissions?.autoLoanCommission)
                                                            }
                                                            validationError={
                                                                (formik.touched.commissions?.autoLoanCommission && formik.errors?.commissions?.autoLoanCommission) || ""
                                                            }
                                                            onBlur={formik.handleBlur}
                                                        />
                                                    </FormControl>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align="center">
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={formik.values.preferences.acceptsProductLoans}
                                                                onChange={(event) => handleChange(event, "preferences.acceptsProductLoans", "commissions.productLoanCommission")}
                                                                name="ProductLoan"/>
                                                        }
                                                        label={t("productLoan")}
                                                    />
                                                </TableCell>
                                                <TableCell align="center">
                                                    <FormControl>
                                                        <TextInput
                                                            disabled={!formik.values.preferences.acceptsProductLoans}
                                                            notFullWidth={true}
                                                            name="commissions.productLoanCommission"
                                                            label={t("productLoan")}
                                                            value={formik.values.commissions.productLoanCommission || ""}
                                                            onChange={(v: string) => formik.setFieldValue("commissions.productLoanCommission", v)}
                                                            isInvalid={
                                                                formik.touched.commissions?.productLoanCommission && Boolean(formik.errors.commissions?.productLoanCommission)
                                                            }
                                                            validationError={
                                                                (formik.touched.commissions?.productLoanCommission && formik.errors?.commissions?.productLoanCommission) || ""
                                                            }
                                                            onBlur={formik.handleBlur}
                                                        />
                                                    </FormControl>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align="center">
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={formik.values.preferences.acceptsSMELoans}
                                                                onChange={(event) => handleChange(event, "preferences.acceptsSMELoans", "commissions.smeLoanCommission")}
                                                                name="SmeLoan"/>
                                                        }
                                                        label={t("SMELoan")}
                                                    />
                                                </TableCell>
                                                <TableCell align="center">
                                                    <FormControl>
                                                        <TextInput
                                                            disabled={!formik.values.preferences.acceptsSMELoans}
                                                            notFullWidth={true}
                                                            name="commissions.smeLoanCommission"
                                                            label={t("SMELoan")}
                                                            value={formik.values.commissions.smeLoanCommission || ""}
                                                            onChange={(v: string) => formik.setFieldValue("commissions.smeLoanCommission", v)}
                                                            isInvalid={
                                                                formik.touched.commissions?.smeLoanCommission && Boolean(formik.errors.commissions?.smeLoanCommission)
                                                            }
                                                            validationError={
                                                                (formik.touched.commissions?.smeLoanCommission && formik.errors?.commissions?.smeLoanCommission) || ""
                                                            }
                                                            onBlur={formik.handleBlur}
                                                        />
                                                    </FormControl>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align="center">
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={formik.values.preferences.acceptsMortgageLoans}
                                                                onChange={(event) => handleChange(event, "preferences.acceptsMortgageLoans", "commissions.mortgageLoanCommission")}
                                                                name="MortgageLoan"/>
                                                        }
                                                        label={t("mortgageLoan")}
                                                    />
                                                </TableCell>
                                                <TableCell align="center">
                                                    <FormControl>
                                                        <TextInput
                                                            disabled={!formik.values.preferences.acceptsMortgageLoans}
                                                            notFullWidth={true}
                                                            name="commissions.mortgageLoanCommission"
                                                            label={t("mortgageLoan")}
                                                            value={formik.values.commissions.mortgageLoanCommission || ""}
                                                            onChange={(v: string) => formik.setFieldValue("commissions.mortgageLoanCommission", v)}
                                                            isInvalid={
                                                                formik.touched.commissions?.mortgageLoanCommission && Boolean(formik.errors.commissions?.mortgageLoanCommission)
                                                            }
                                                            validationError={
                                                                (formik.touched.commissions?.mortgageLoanCommission && formik.errors?.commissions?.mortgageLoanCommission) || ""
                                                            }
                                                            onBlur={formik.handleBlur}
                                                        />
                                                    </FormControl>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>

                        </Grid>

                        <Grid className={classes.footer}>
                            <Button onClick={formik.submitForm} variant="contained">{t("save")}</Button>

                        </Grid>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
}
export default LenderEditModal
