import React, { useEffect, useState } from 'react';
import { useParams } from "react-router";
import LendersClient from "../../../apiClient/LendersClient";
import ILender, {ICreateEmployee, IEmployee} from "../../../types/Lender";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { FormControl, FormHelperText, IconButton, InputLabel, MenuItem, Select, TableCell } from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import Toolbar from "@material-ui/core/Toolbar";
import Tooltip from '@material-ui/core/Tooltip';
import AddBoxIcon from '@material-ui/icons/AddBox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { useFormik } from "formik";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { useDispatch } from 'react-redux';
import { DispatchErrorSnackbar } from '../../../state/actions/UiActions';
import AccessControl from "../../shared/AccessControl";
import { BankManager, HeadOfBusiness, LenderEmployee, PlatformEmployee } from "../../common/JobRole";
import { LookUpModel } from "../../../types/AppState";
import { SwitchLenderStatusAsync } from "../../../state/actions/LendersActions";
import { Edit } from "@material-ui/icons";
import EmployeeEditModal from "./EditEmployeeRole"
const useStyles = makeStyles((theme: Theme) => ({
    container: {
        [theme.breakpoints.down("xs")]: {
            paddingLeft: "1.2rem",
            paddingRight: "1.2rem",

        },
        paddingLeft: "3rem",
        paddingRight: "3rem",
        paddingTop: "1rem",
        paddingBottom: "3rem",
        marginTop: "1rem",
        marginBottom: "3rem"
    },
    title: {
        flex: '1 1 100%',
        textAlign: "start"
    },
    dialog: {
        direction: theme.direction ,
    },
    input: {
        "& .MuiFormLabel-root": {
            left: "auto",
            right: "auto",
            transformOrigin: "top right",
            textAlign: "right",
        }
    },
    selectLabel: {

            left: "auto",
            right: "auto",
            transformOrigin: "top right",
            textAlign: "right",
            paddingRight:"2rem"

    }



}));

const validationSchema = yup.object({
    name: yup
        .string().min(3, " must be at least 3 characters")
        .max(200)
        .required(" is Required"),
    email: yup
        .string()
        .email().required(" is Required"),
    password: yup
        .string().min(5, " must be at least 5 characters")
        .max(200)
        .required(" is Required"),
    phone: yup
        .string().min(10, " must be at least 10 characters")
        .max(10, " must be at most 10 characters")
        .required(" is Required"),
    userType: yup
        .string().min(2, "User Type is Required")
        .max(25, "User Type is Required")
        .required("User Type is Required"),

});

const Component = () => {
    const classes = useStyles();

    const { id } = useParams() as any;
    const client = new LendersClient();

    const [lender, setLender] = useState<ILender | null>(null);
    const [addMode, setAddMode] = useState(false);
    const [addBranchMode, setAddBranchMode] = useState(false);
    const [employeeForEditRole, setEmployeeForEditRole] = useState<IEmployee>()
    const [editRoleModal, setEditRoleModal] = useState(false)

    const { t,i18n } = useTranslation("lenders");
    let labelStyle = i18n.language.slice(0, 2).toLowerCase() === "ar" ? true : false;
    const refresh = async () => {
        let res = await client.Get(id);
        if (res.succeeded) {
            setLender(res.data as ILender);
        } else {

        }
    };

    useEffect(() => {
        refresh();
    }, [id]);

    const dispatch = useDispatch()
    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            password: '',
            phone: '',
            branchId: '',
            userType: 0
        },
        validationSchema: validationSchema,
        validateOnBlur: true,

        onSubmit: async (values, { resetForm }) => {
            let res = await client.AddEmployee(id, values as ICreateEmployee);
            if (res.succeeded) {
                setAddMode(false);
                await refresh();
            } else {
                dispatch(DispatchErrorSnackbar(`Duplicate entry`))
            }
        },
    });
    const branchFormik = useFormik({
        initialValues: {
            nameAr: '',
            nameEn: '',
        },
        validateOnBlur: true,

        onSubmit: async (values, { resetForm }) => {
            let res = await client.AddBranch(id, values as any);
            if (res.succeeded) {
                setAddBranchMode(false);
                await refresh();
            } else {
                dispatch(DispatchErrorSnackbar(`Duplicate entry`))
            }
        },
    });
    const types: LookUpModel[] = [{ label: "BankOfficer", value: "151" }, { label: "BankManager", value: "153" }]
    return (
        <div className={classes.container}>
            {editRoleModal &&employeeForEditRole&&
            <EmployeeEditModal
            title={t("editUserTypeTitle")}
            row={employeeForEditRole}
            onClose={async () =>{
                setEditRoleModal(false);
                await refresh();
            }}
            />
        }
            <Paper className={classes.container}>
                <Typography variant="h6" gutterBottom>
                    {lender?.name}
                </Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                         className={labelStyle ? classes.input : ''}
                            disabled
                            label={t("nameEn")}
                            fullWidth
                            value={lender?.nameEn ?? " "}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                         className={labelStyle ? classes.input : ''}
                            disabled
                            label={t("nameAr")}
                            fullWidth
                            value={lender?.nameAr ?? " "}
                        />
                    </Grid>

                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                        <TableContainer component={Paper}>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center">{t("loanType")}</TableCell>
                                        <TableCell align="center">{t("commission")}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell align="center">
                                            {t("personalLoan")}
                                        </TableCell>
                                        <TableCell align="center">
                                            {lender?.lenderCommissions?.personalLoanCommission ?? 0}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align="center">
                                            {t("autoLoan")}
                                        </TableCell>
                                        <TableCell align="center">
                                            {lender?.lenderCommissions?.autoLoanCommission ?? 0}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align="center">
                                            {t("productLoan")}
                                        </TableCell>
                                        <TableCell align="center">
                                            {lender?.lenderCommissions?.productLoanCommission ?? 0}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align="center">
                                            {t("SMELoan")}
                                        </TableCell>
                                        <TableCell align="center">
                                            {lender?.lenderCommissions?.smeLoanCommission ?? 0}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align="center">
                                            {t("mortgageLoan")}
                                        </TableCell>
                                        <TableCell align="center">
                                            {lender?.lenderCommissions?.mortgageLoanCommission ?? 0}
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>

                </Grid>

            </Paper>
            <Paper className={classes.container}>
                <Toolbar>
                    <Typography className={classes.title} variant="h6" gutterBottom>
                        {t("branchesTitle")}
                    </Typography>
                    <AccessControl
                        allowedTypes={[LenderEmployee.label, BankManager.label, PlatformEmployee.label, HeadOfBusiness.label]}>
                        <Tooltip title={"Add"}>
                            <IconButton onClick={() => setAddBranchMode(true)} aria-label="filter list">
                                <AddBoxIcon />
                            </IconButton>
                        </Tooltip>
                    </AccessControl>
                </Toolbar>

                <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>{t("id")}</TableCell>
                                <TableCell>{t("nameAr")}</TableCell>
                                <TableCell>{t("nameEn")}</TableCell>
                                <TableCell>{t("createdOn")}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {lender?.branches?.map((row) => (
                                <TableRow key={row.name}>
                                    <TableCell>{row.id}</TableCell>
                                    <TableCell>{row.nameAr}</TableCell>
                                    <TableCell>{row.nameEn}</TableCell>
                                    <TableCell>{row.createdOn}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
            <Paper className={classes.container}>
                <Toolbar>
                    <Typography className={classes.title} variant="h6" gutterBottom>
                        {t("employeesTitle")}
                    </Typography>
                    <AccessControl
                        allowedTypes={[LenderEmployee.label, BankManager.label, PlatformEmployee.label, HeadOfBusiness.label]}>
                        <Tooltip title={"Add"}>
                            <IconButton onClick={() => setAddMode(true)} aria-label="filter list">
                                <AddBoxIcon />
                            </IconButton>
                        </Tooltip>
                    </AccessControl>
                </Toolbar>

                <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>{t("name")}</TableCell>
                                <TableCell>{t("email")}</TableCell>
                                <TableCell>{t("phone")}</TableCell>
                                <TableCell>{t("createdOn")}</TableCell>
                                <TableCell>{t("branch")}</TableCell>
                                <TableCell>{t("inactive")}</TableCell>
                                <TableCell>{t("userType")}</TableCell>
                                <TableCell>{t("activation")}</TableCell>
                                <TableCell>{t("editRole")}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {lender?.employees?.map((row,i) => (
                                <TableRow key={row.name}>
                                    <TableCell align={"center"}>{row.name}</TableCell>
                                    <TableCell align={"center"}>{row.email}</TableCell>
                                    <TableCell align={"center"}>{row.phone}</TableCell>
                                    <TableCell align={"center"}>{row.createdOn}</TableCell>
                                    <TableCell
                                        align={"center"}>{lender?.branches?.find(b => b.id === row.branchId)?.name || "-"}</TableCell>
                                    <TableCell align={"center"}>{row.inactive.toString()}</TableCell>
                                    <TableCell align={"center"}>{row.userType}</TableCell>
                                    <TableCell align={"center"}><Button
                                        onClick={() => dispatch(SwitchLenderStatusAsync(row.id, () => refresh()))}
                                        style={{ backgroundColor: row.inactive ? "green" : "red" }} type="button">
                                        <Typography style={{
                                            color: "white",
                                            fontSize: 13
                                        }}>{row.inactive ? t("activate") : t("deActivate")}</Typography>
                                    </Button></TableCell>
                                    <TableCell align={"center"}>
                                        <>
                                        {row.branchId==null?<Button
                                            onClick={() => {
                                                setEmployeeForEditRole(row)
                                                setEditRoleModal(true)
                                            }}
                                        >
                                            <Edit/>
                                        </Button>:"-"}
                                        </>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>

            {addMode && <Dialog className={classes.dialog} maxWidth={"lg"} open={addMode} onClose={() => setAddMode(false)}
                aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{t("addEmployee")}</DialogTitle>

                <DialogContent >
                    <Grid >

                        <Grid container spacing={3} >
                            <Grid item xs={12} sm={12} >
                                <TextField
                                  className={labelStyle ? classes.input : ''}
                                    required
                                    id="name"
                                    name="name"
                                    label={t("name")}
                                    fullWidth
                                    value={formik.values.name}
                                    onChange={formik.handleChange}
                                    helperText={(formik.touched.name && formik.errors.name && t("name") + formik.errors.name) || ""}
                                    error={
                                        formik.touched.name &&
                                        Boolean(formik.errors.name)
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                 className={labelStyle ? classes.input : ''}
                                    required
                                    id="email"
                                    name="email"
                                    label={t("email")}
                                    fullWidth
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    helperText={(formik.touched.email && formik.errors.email && formik.errors.email) || ""}
                                    error={
                                        formik.touched.email &&
                                        Boolean(formik.errors.email)
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                 className={labelStyle ? classes.input : ''}
                                    required
                                    id="password"
                                    name="password"
                                    label={t("password")}
                                    fullWidth
                                    value={formik.values.password}
                                    onChange={formik.handleChange}
                                    helperText={(formik.touched.password && formik.errors.password && t('password') + formik.errors.password) || ""}
                                    error={
                                        formik.touched.password &&
                                        Boolean(formik.errors.password)
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                 className={labelStyle ? classes.input : ''}
                                    required
                                    id="mobile"
                                    name="phone"
                                    label={t("phone")}
                                    fullWidth
                                    value={formik.values.phone}
                                    onChange={formik.handleChange}
                                    helperText={(formik.touched.phone && formik.errors.phone && t('phone') + formik.errors.phone) || ""}
                                    error={
                                        formik.touched.phone &&
                                        Boolean(formik.errors.phone)
                                    }
                                />
                            </Grid>

                            <Grid item xs={12} sm={12}>
                                <FormControl fullWidth>
                                    <InputLabel className={labelStyle ? classes.selectLabel : ''}>{t("branch")}</InputLabel>
                                    <Select onChange={(e) => {
                                        formik.setFieldValue("branchId", e.target.value || '')
                                    }}
                                        value={formik.values.branchId || ""}
                                        displayEmpty={true}
                                    >
                                        {/*<MenuItem value="">{"None"}</MenuItem>*/}
                                        {
                                            lender?.branches && lender?.branches?.map((i) =>
                                            (<MenuItem value={i.id}>
                                                {i.name}
                                            </MenuItem>)
                                            )
                                        }

                                    </Select>

                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <FormControl fullWidth>
                                    <InputLabel className={labelStyle ? classes.selectLabel : ''}
                                        style={{ color: formik.errors.userType ? "#FF0000" : "secondary" }}>{t("userType")}</InputLabel>
                                    <Select onChange={(e) => {
                                        formik.setFieldValue("userType", e.target.value || '')
                                    }}

                                        error={
                                            formik.touched.userType &&
                                            Boolean(formik.errors.userType)
                                        }
                                        value={formik.values.userType || ""}
                                        displayEmpty={true}
                                    >
                                        {/*<MenuItem value="">{"None"}</MenuItem>*/}
                                        {
                                            types?.map((i) =>
                                            (<MenuItem value={i.value}>
                                                {i.label}
                                            </MenuItem>)
                                            )
                                        }

                                    </Select>
                                    <FormHelperText style={{ color: "#FF0000" }}>{formik.errors.userType}</FormHelperText>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogActions>
                    <Button onClick={() => setAddMode(false)} color="primary">
                        {t("cancel")}
                    </Button>
                    <Button onClick={formik.submitForm} color="primary">
                        {t("save")}
                    </Button>
                </DialogActions>
            </Dialog>}


            {addBranchMode && <Dialog className={classes.dialog} maxWidth={"lg"} open={addBranchMode} onClose={() => setAddBranchMode(false)}
                aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{t("addBranch")}</DialogTitle>
                <DialogContent>
                    <Grid>

                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                 className={labelStyle ? classes.input : ''}
                                    required
                                    id="namear"
                                    name="nameAr"
                                    label={t("nameAr")}
                                    fullWidth
                                    value={branchFormik.values.nameAr}
                                    onChange={branchFormik.handleChange}
                                    helperText={(branchFormik.touched.nameAr && branchFormik.errors.nameAr && t("name") + branchFormik.errors.nameAr) || ""}
                                    error={
                                        branchFormik.touched.nameAr &&
                                        Boolean(branchFormik.errors.nameAr)
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                 className={labelStyle ? classes.input : ''}
                                    required
                                    id="nameEn"
                                    name="nameEn"
                                    label={t("nameEn")}
                                    fullWidth
                                    value={branchFormik.values.nameEn}
                                    onChange={branchFormik.handleChange}
                                    helperText={(branchFormik.touched.nameEn && branchFormik.errors.nameEn && t("name") + branchFormik.errors.nameEn) || ""}
                                    error={
                                        branchFormik.touched.nameEn &&
                                        Boolean(branchFormik.errors.nameEn)
                                    }
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setAddBranchMode(false)} color="primary">
                        {t("cancel")}
                    </Button>
                    <Button onClick={branchFormik.submitForm} color="primary">
                        {t("save")}
                    </Button>
                </DialogActions>
            </Dialog>}

        </div>

    )
}
export default Component;
