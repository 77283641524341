import React from 'react';
import ValidationClient from "../../../../apiClient/validationClient";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { SMERequestScoreCriteriaModel } from "../../../../types/RequestValidation";
import { DispatchErrorSnackbar } from "../../../../state/actions/UiActions";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
    Button,
    Grid,
    TextField,
    Paper,
    Typography
} from "@material-ui/core";
interface IProps {
    row?: SMERequestScoreCriteriaModel
}

const SmeValidationForm = (props: IProps) => {
    const classes = useStyles()
    const validationClient = new ValidationClient();
    const { t, i18n } = useTranslation("validations")
    const dispatch = useDispatch()
    let labelStyle = i18n.language.slice(0, 2).toLowerCase() === "ar" ? true : false;

    const formik = useFormik<SMERequestScoreCriteriaModel>({
        enableReinitialize:true,
        initialValues: {
            vocationalLicenseWeight: {
                issued: props.row?.vocationalLicenseWeight?.issued ?? 0,
                notIssued: props.row?.vocationalLicenseWeight?.notIssued ?? 0,
            },
            hasFinancialSponsorsWeight: { 
                has: props.row?.hasFinancialSponsorsWeight?.has ?? 0,
                hasNot: props.row?.hasFinancialSponsorsWeight?.hasNot ?? 0,
            },
            companyIndustryWeight: {
                industrial: props.row?.companyIndustryWeight?.industrial ?? 0,
                commercial: props.row?.companyIndustryWeight?.commercial ?? 0,
                services: props.row?.companyIndustryWeight?.services ?? 0,
                agriculture: props.row?.companyIndustryWeight?.agriculture ?? 0,
                health: props.row?.companyIndustryWeight?.health ?? 0,
            },
            backgroundCheckWeight: { 
                noRecord: props.row?.backgroundCheckWeight?.noRecord ?? 0,
                lowRecord: props.row?.backgroundCheckWeight?.lowRecord ?? 0,
                mediumRecord: props.row?.backgroundCheckWeight?.mediumRecord ?? 0,
                highRecord: props.row?.backgroundCheckWeight?.highRecord ?? 0,
            },
            commercialLicenseWeight: {
                provided: props.row?.commercialLicenseWeight?.provided ?? 0,
                notProvided: props.row?.commercialLicenseWeight?.notProvided ?? 0,
            },
            hasCheckBookWeight: { 
                provided: props.row?.hasCheckBookWeight?.provided ?? 0,
                notProvided: props.row?.hasCheckBookWeight?.notProvided ?? 0,
            },
            hasAMortgageWeight: {
                has: props.row?.hasAMortgageWeight?.has ?? 0,
                hasNot: props.row?.hasAMortgageWeight?.hasNot ?? 0,
            },
            companyStatusWeight: { 
                unknown: props.row?.companyStatusWeight?.unknown ?? 0,
                active: props.row?.companyStatusWeight?.active ?? 0,
                terminated: props.row?.companyStatusWeight?.terminated ?? 0,
                suspended: props.row?.companyStatusWeight?.suspended ?? 0,
                shutdown: props.row?.companyStatusWeight?.shutdown ?? 0,
                delisted: props.row?.companyStatusWeight?.delisted ?? 0,
                seized: props.row?.companyStatusWeight?.seized ?? 0,
            },
            commercialLicenseAge: { 
                rangeA: props.row?.commercialLicenseAge?.rangeA ?? 0,
                rangeB: props.row?.commercialLicenseAge?.rangeB ?? 0,
                rangeC: props.row?.commercialLicenseAge?.rangeC ?? 0,
                rangeD: props.row?.commercialLicenseAge?.rangeD ?? 0,
            },
            companyBankBalanceWeight: {
                rangeA: props.row?.companyBankBalanceWeight?.rangeA ?? 0,
                rangeB: props.row?.companyBankBalanceWeight?.rangeB ?? 0,
                rangeC: props.row?.companyBankBalanceWeight?.rangeC ?? 0,
                rangeD: props.row?.companyBankBalanceWeight?.rangeD ?? 0,
                rangeE: props.row?.companyBankBalanceWeight?.rangeE ?? 0,
                rangeF: props.row?.companyBankBalanceWeight?.rangeF ?? 0,
            },
            majorityShareholderBankBalanceAmountWeight: {
                rangeA: props.row?.majorityShareholderBankBalanceAmountWeight?.rangeA ?? 0,
                rangeB: props.row?.majorityShareholderBankBalanceAmountWeight?.rangeB ?? 0,
                rangeC: props.row?.majorityShareholderBankBalanceAmountWeight?.rangeC ?? 0,
                rangeD: props.row?.majorityShareholderBankBalanceAmountWeight?.rangeD ?? 0,
                rangeE: props.row?.majorityShareholderBankBalanceAmountWeight?.rangeE ?? 0,
            },
            hasStatementOfAccountsWeight: {
                provided: props.row?.hasStatementOfAccountsWeight?.provided ?? 0,
                notProvided: props.row?.hasStatementOfAccountsWeight?.notProvided ?? 0,
            },
            majorityShareholderAgeWeight: {
                rangeA: props.row?.majorityShareholderAgeWeight?.rangeA ?? 0,
                rangeB: props.row?.majorityShareholderAgeWeight?.rangeB ?? 0,
                rangeC: props.row?.majorityShareholderAgeWeight?.rangeC ?? 0,
                rangeD: props.row?.majorityShareholderAgeWeight?.rangeD ?? 0,
            },

        },
        validateOnBlur: true,
        onSubmit: async (values, { resetForm }) => {
            let res
            console.log("values to update", values)
            res = await validationClient.SmeUpdate(values, props.row?.id!);

            if (res.succeeded) {

               window.location.reload();
            } else {
                dispatch(DispatchErrorSnackbar(`Something went wrong!`))
            }
        },
    });
    return (
        <>
            <Grid container spacing={5}>
                <Grid item xs={12} sm={11} md={4} xl={4}>
                    <Paper className={classes.section}>
                        <Grid item>
                            <Typography className={classes.title} >{t("VocationalLicenseWeight")}</Typography>
                        </Grid>
                        <TextField
                            className={labelStyle ? classes.input : ''}
                            type={"number"}
                            value={formik.values.vocationalLicenseWeight?.issued}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            name={"vocationalLicenseWeight.issued"}
                            fullWidth
                            label={t('Issued')}
                        />
                        <TextField
                            className={labelStyle ? classes.input : ''}
                            type={"number"}
                            value={formik.values.vocationalLicenseWeight?.notIssued}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            name={"vocationalLicenseWeight.notIssued"}
                            fullWidth
                            label={t('NotIssued')}
                        />
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={11} md={4} xl={4}>
                    <Paper className={classes.section}>

                        <Grid item>
                            <Typography
                                className={classes.title}>{t("hasFinancialSponsorsWeight")}</Typography>
                        </Grid>
                        <TextField
                            className={labelStyle ? classes.input : ''}
                            type={"number"}
                            value={formik.values.hasFinancialSponsorsWeight?.has}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            name={"hasFinancialSponsorsWeight.has"}
                            fullWidth
                            label={t('Provided')}
                        />
                        <TextField
                            className={labelStyle ? classes.input : ''}
                            type={"number"}
                            value={formik.values.hasFinancialSponsorsWeight?.hasNot}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            name={"hasFinancialSponsorsWeight.hasNot"}
                            fullWidth
                            label={t('NotProvided')}
                        />
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={11} md={4} xl={4}>
                    <Paper className={classes.section}>

                        <Grid item>
                            <Typography
                                className={classes.title}>{t("CheckBookWeight")}</Typography>
                        </Grid>

                        <TextField
                            className={labelStyle ? classes.input : ''}
                            type={"number"}
                            value={formik.values.hasCheckBookWeight?.provided}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            name={"hasCheckBookWeight.provided"}
                            fullWidth
                            label={t('Provided')}
                        />
                        <TextField
                            className={labelStyle ? classes.input : ''}
                            type={"number"}
                            value={formik.values.hasCheckBookWeight?.notProvided}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            name={"hasCheckBookWeight.notProvided"}
                            fullWidth
                            label={t('NotProvided')}
                        />

                    </Paper>
                </Grid>
                <Grid item xs={12} sm={11} md={4} xl={4}>
                    <Paper className={classes.section}>
                        <Grid item>
                            <Typography className={classes.title} >{t("hasAMortgageWeight")}</Typography>
                        </Grid>
                        <TextField
                            className={labelStyle ? classes.input : ''}
                            type={"number"}
                            value={formik.values.hasAMortgageWeight?.has}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            name={"hasAMortgageWeight.has"}
                            fullWidth
                            label={t('Provided')}
                        />
                        <TextField
                            className={labelStyle ? classes.input : ''}
                            type={"number"}
                            value={formik.values.hasAMortgageWeight?.hasNot}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            name={"hasAMortgageWeight.hasNot"}
                            fullWidth
                            label={t('NotProvided')}
                        />
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={11} md={4} xl={4}>
                    <Paper className={classes.section}>
                        <Grid item>
                            <Typography className={classes.title} >{t("CommercialLicenseWeight")}</Typography>
                        </Grid>
                        <TextField
                            className={labelStyle ? classes.input : ''}
                            type={"number"}
                            value={formik.values.commercialLicenseWeight?.provided}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            name={"commercialLicenseWeight.provided"}
                            fullWidth
                            label={t('Provided')}
                        />
                        <TextField
                            className={labelStyle ? classes.input : ''}
                            type={"number"}
                            value={formik.values.commercialLicenseWeight?.notProvided}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            name={"commercialLicenseWeight.notProvided"}
                            fullWidth
                            label={t('NotProvided')}
                        />
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={11} md={4} xl={4}>
                    <Paper className={classes.section}>
                        <Grid item>
                            <Typography className={classes.title} >{t("hasStatementOfAccountsWeight")}</Typography>
                        </Grid>
                        <TextField
                            className={labelStyle ? classes.input : ''}
                            type={"number"}
                            value={formik.values.hasStatementOfAccountsWeight?.provided}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            name={"hasStatementOfAccountsWeight.provided"}
                            fullWidth
                            label={t('Provided')}
                        />
                        <TextField
                            className={labelStyle ? classes.input : ''}
                            type={"number"}
                            value={formik.values.hasStatementOfAccountsWeight?.notProvided}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            name={"hasStatementOfAccountsWeight.notProvided"}
                            fullWidth
                            label={t('NotProvided')}
                        />
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={11} md={4} xl={4}>
                    <Paper className={classes.section}>
                        <Grid item>
                            <Typography className={classes.title} >{t("companyIndustryWeight")}</Typography>
                        </Grid>
                        <TextField
                            className={labelStyle ? classes.input : ''}
                            type={"number"}
                            value={formik.values.companyIndustryWeight?.industrial}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            name={"companyIndustryWeight.industrial"}
                            fullWidth
                            label={t('industrial')}
                        />
                        <TextField
                            className={labelStyle ? classes.input : ''}
                            type={"number"}
                            value={formik.values.companyIndustryWeight?.commercial}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            name={"companyIndustryWeight.commercial"}
                            fullWidth
                            label={t('commercial')}
                        />
                        <TextField
                            className={labelStyle ? classes.input : ''}
                            type={"number"}
                            value={formik.values.companyIndustryWeight?.services}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            name={"companyIndustryWeight.services"}
                            fullWidth
                            label={t('services')}
                        />
                        <TextField
                            className={labelStyle ? classes.input : ''}
                            type={"number"}
                            value={formik.values.companyIndustryWeight?.agriculture}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            name={"companyIndustryWeight.agriculture"}
                            fullWidth
                            label={t('agriculture')}
                        />
                        <TextField
                            className={labelStyle ? classes.input : ''}
                            type={"number"}
                            value={formik.values.companyIndustryWeight?.health}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            name={"companyIndustryWeight.health"}
                            fullWidth
                            label={t('health')}
                        />
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={11} md={4} xl={4}>
                    <Paper className={classes.section}>

                        <Grid item>
                            <Typography
                                className={classes.title}>{t("companyStatusWeight")}</Typography>
                        </Grid>
                        <TextField
                            className={labelStyle ? classes.input : ''}
                            type={"number"}
                            value={formik.values.companyStatusWeight?.unknown}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            name={"companyStatusWeight.unknown"}
                            fullWidth
                            label={t('unknown')}
                        />
                        <TextField
                            className={labelStyle ? classes.input : ''}
                            type={"number"}
                            value={formik.values.companyStatusWeight?.active}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            name={"companyStatusWeight.active"}
                            fullWidth
                            label={t('active')}
                        />
                        <TextField
                            className={labelStyle ? classes.input : ''}
                            type={"number"}
                            value={formik.values.companyStatusWeight?.terminated}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            name={"companyStatusWeight.terminated"}
                            fullWidth
                            label={t('terminated')}
                        />
                        <TextField
                            className={labelStyle ? classes.input : ''}
                            type={"number"}
                            value={formik.values.companyStatusWeight?.suspended}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            name={"companyStatusWeight.suspended"}
                            fullWidth
                            label={t('suspended')}
                        />
                          <TextField
                            className={labelStyle ? classes.input : ''}
                            type={"number"}
                            value={formik.values.companyStatusWeight?.shutdown}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            name={"companyStatusWeight.shutdown"}
                            fullWidth
                            label={t('shutdown')}
                        />
                          <TextField
                            className={labelStyle ? classes.input : ''}
                            type={"number"}
                            value={formik.values.companyStatusWeight?.delisted}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            name={"companyStatusWeight.delisted"}
                            fullWidth
                            label={t('delisted')}
                        />
                          <TextField
                            className={labelStyle ? classes.input : ''}
                            type={"number"}
                            value={formik.values.companyStatusWeight?.seized}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            name={"companyStatusWeight.seized"}
                            fullWidth
                            label={t('seized')}
                        />

                    </Paper>
                </Grid>
                <Grid item xs={12} sm={11} md={4} xl={4}>
                    <Paper className={classes.section}>
                        <Grid item>
                            <Typography
                                className={classes.title}>{t("commercialLicenseAge")}</Typography>
                        </Grid>
                        <TextField
                            className={labelStyle ? classes.input : ''}
                            type={"number"}
                            value={formik.values.commercialLicenseAge?.rangeA}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            name={"commercialLicenseAge.rangeA"}
                            fullWidth
                            label={t('commercialLicenseAge.rangeA')}
                        />
                        <TextField
                            className={labelStyle ? classes.input : ''}
                            type={"number"}
                            value={formik.values.commercialLicenseAge?.rangeB}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            name={"commercialLicenseAge.rangeB"}
                            fullWidth
                            label={t('commercialLicenseAge.rangeB')}
                        />
                        <TextField
                            className={labelStyle ? classes.input : ''}
                            type={"number"}
                            value={formik.values.commercialLicenseAge?.rangeC}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            name={"commercialLicenseAge.rangeC"}
                            fullWidth
                            label={t('commercialLicenseAge.rangeC')}
                        />
                        <TextField
                            className={labelStyle ? classes.input : ''}
                            type={"number"}
                            value={formik.values.commercialLicenseAge?.rangeD}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            name={"commercialLicenseAge.rangeD"}
                            fullWidth
                            label={t('commercialLicenseAge.rangeD')}
                        />
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={11} md={4} xl={4}>
                    <Paper className={classes.section}>
                        <Grid item>
                            <Typography className={classes.title} >{t("BackgroundCheckWeight")}</Typography>
                        </Grid>
                        <TextField
                            className={labelStyle ? classes.input : ''}
                            type={"number"}
                            value={formik.values.backgroundCheckWeight?.noRecord}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            name={"backgroundCheckWeight.noRecord"}
                            fullWidth
                            label={t('NoRecord')}
                        />
                        <TextField
                            className={labelStyle ? classes.input : ''}
                            type={"number"}
                            value={formik.values.backgroundCheckWeight?.lowRecord}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            name={"backgroundCheckWeight.lowRecord"}
                            fullWidth
                            label={t('LowRecord')}
                        />
                        <TextField
                            className={labelStyle ? classes.input : ''}
                            type={"number"}
                            value={formik.values.backgroundCheckWeight?.mediumRecord}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            name={"backgroundCheckWeight.mediumRecord"}
                            fullWidth
                            label={t('MediumRecord')}
                        />
                        <TextField
                            className={labelStyle ? classes.input : ''}
                            type={"number"}
                            value={formik.values.backgroundCheckWeight?.highRecord}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            name={"backgroundCheckWeight.highRecord"}
                            fullWidth
                            label={t('HighRecord')}
                        />
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={11} md={4} xl={4}>
                    <Paper className={classes.section}>
                        <Grid item>
                            <Typography
                                className={classes.title}>{t("companyBankBalanceWeight")}
                            </Typography>
                        </Grid>
                        <TextField
                            className={labelStyle ? classes.input : ''}
                            type={"number"}
                            value={formik.values.companyBankBalanceWeight?.rangeA}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            name={"companyBankBalanceWeight.rangeA"}
                            fullWidth
                            label={t('companyBankBalanceWeight.rangeA')}
                        />
                        <TextField
                            className={labelStyle ? classes.input : ''}
                            type={"number"}
                            value={formik.values.companyBankBalanceWeight?.rangeB}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            name={"companyBankBalanceWeight.rangeB"}
                            fullWidth
                            label={t('companyBankBalanceWeight.rangeB')}
                        />
                        <TextField
                            className={labelStyle ? classes.input : ''}
                            type={"number"}
                            value={formik.values.companyBankBalanceWeight?.rangeC}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            name={"companyBankBalanceWeight.rangeC"}
                            fullWidth
                            label={t('companyBankBalanceWeight.rangeC')}
                        />
                        <TextField
                            className={labelStyle ? classes.input : ''}
                            type={"number"}
                            value={formik.values.companyBankBalanceWeight?.rangeD}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            name={"companyBankBalanceWeight.rangeD"}
                            fullWidth
                            label={t('companyBankBalanceWeight.rangeD')}
                        />
                        <TextField
                            className={labelStyle ? classes.input : ''}
                            type={"number"}
                            value={formik.values.companyBankBalanceWeight?.rangeE}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            name={"companyBankBalanceWeight.rangeE"}
                            fullWidth
                            label={t('companyBankBalanceWeight.rangeE')}
                        />
                        <TextField
                            className={labelStyle ? classes.input : ''}
                            type={"number"}
                            value={formik.values.companyBankBalanceWeight?.rangeF}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            name={"companyBankBalanceWeight.rangeF"}
                            fullWidth
                            label={t('companyBankBalanceWeight.rangeF')}
                        />
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={11} md={4} xl={4}>
                    <Paper className={classes.section}>
                        <Grid item>
                            <Typography
                                className={classes.title}>{t("majorityShareholderBankBalanceAmountWeight")}
                            </Typography>
                        </Grid>
                        <TextField
                            className={labelStyle ? classes.input : ''}
                            type={"number"}
                            value={formik.values.majorityShareholderBankBalanceAmountWeight?.rangeA}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            name={"majorityShareholderBankBalanceAmountWeight.rangeA"}
                            fullWidth
                            label={t('majorityShareholderBankBalanceAmountWeight.rangeA')}
                        />
                        <TextField
                            className={labelStyle ? classes.input : ''}
                            type={"number"}
                            value={formik.values.majorityShareholderBankBalanceAmountWeight?.rangeB}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            name={"majorityShareholderBankBalanceAmountWeight.rangeB"}
                            fullWidth
                            label={t('majorityShareholderBankBalanceAmountWeight.rangeB')}
                        />
                        <TextField
                            className={labelStyle ? classes.input : ''}
                            type={"number"}
                            value={formik.values.majorityShareholderBankBalanceAmountWeight?.rangeC}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            name={"majorityShareholderBankBalanceAmountWeight.rangeC"}
                            fullWidth
                            label={t('majorityShareholderBankBalanceAmountWeight.rangeC')}
                        />
                        <TextField
                            className={labelStyle ? classes.input : ''}
                            type={"number"}
                            value={formik.values.majorityShareholderBankBalanceAmountWeight?.rangeD}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            name={"majorityShareholderBankBalanceAmountWeight.rangeD"}
                            fullWidth
                            label={t('majorityShareholderBankBalanceAmountWeight.rangeD')}
                        />
                        <TextField
                            className={labelStyle ? classes.input : ''}
                            type={"number"}
                            value={formik.values.majorityShareholderBankBalanceAmountWeight?.rangeE}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            name={"majorityShareholderBankBalanceAmountWeight.rangeE"}
                            fullWidth
                            label={t('majorityShareholderBankBalanceAmountWeight.rangeE')}
                        />
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={11} md={4} xl={4}>
                    <Paper className={classes.section}>
                        <Grid item>
                            <Typography
                                className={classes.title}>{t("majorityShareholderAgeWeight")}
                            </Typography>
                        </Grid>
                        <TextField
                            className={labelStyle ? classes.input : ''}
                            type={"number"}
                            value={formik.values.majorityShareholderAgeWeight?.rangeA}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            name={"majorityShareholderAgeWeight.rangeA"}
                            fullWidth
                            label={t('majorityShareholderAgeWeight.rangeA')}
                        />
                        <TextField
                            className={labelStyle ? classes.input : ''}
                            type={"number"}
                            value={formik.values.majorityShareholderAgeWeight?.rangeB}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            name={"majorityShareholderAgeWeight.rangeB"}
                            fullWidth
                            label={t('majorityShareholderAgeWeight.rangeB')}
                        />
                        <TextField
                            className={labelStyle ? classes.input : ''}
                            type={"number"}
                            value={formik.values.majorityShareholderAgeWeight?.rangeC}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            name={"majorityShareholderAgeWeight.rangeC"}
                            fullWidth
                            label={t('majorityShareholderAgeWeight.rangeC')}
                        />
                        <TextField
                            className={labelStyle ? classes.input : ''}
                            type={"number"}
                            value={formik.values.majorityShareholderAgeWeight?.rangeD}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            name={"majorityShareholderAgeWeight.rangeD"}
                            fullWidth
                            label={t('majorityShareholderAgeWeight.rangeD')}
                        />
                    </Paper>
                </Grid>
            </Grid>
            <Button
                variant="contained"
                color="primary"
                onClick={() => formik.handleSubmit()}
            >{t('submitButton')}</Button>

        </>
    )

}
export default SmeValidationForm

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modal: {
            position: 'absolute',
            top: '10%',
            left: '10%',
            overflow: 'scroll',
            height: '100%',
            display: 'block',
            padding: 50,
            direction: theme.direction,
        },
        paper: {
            backgroundColor: theme.palette.background.paper,
            padding: theme.spacing(2, 4, 3),
        },
        footer: {
            textAlign: "end",
            paddingTop: 25

        },
        section: {
            marginTop: "0.4rem",
            marginBottom: "1.66rem",
            padding: "1rem",
            boxShadow: theme.shadows[5],
        },
        title: {
            fontWeight: "bold",
            fontSize: "1rem",
        },
        input: {
            "& .MuiFormLabel-root": {
                left: "auto",
                right: "auto",
                transformOrigin: "top right",
                textAlign: "right",
                paddingRight: "3rem"
            }
        }
    }),
);
