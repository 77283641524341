import React, {FunctionComponent, useEffect, useState} from "react";
import SimplyModal from "../../../shared/SimplyModal";
import {Box, Button, Grid} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import IRequest, {ISubmitOfferModel} from "../../../../types/Request";
import TextInput from "../../../shared/TextInput";
import {useTranslation} from "react-i18next";
import IAppStateModel from "../../../../types/AppState";
import {SubmitToCustomer} from "../../../../state/actions/SubmissionActions";
import AccessControl from "../../../shared/AccessControl";
import {BankManager, BankOfficer, LenderEmployee} from "../../../common/JobRole";

interface IProps {
    open: boolean;
    onClose: () => void;
    title: string;
    request: IRequest;

}

const SendOfferModal: FunctionComponent<IProps> = ({open, onClose, title, request}): JSX.Element => {
    const dispatch = useDispatch();
    const {t} = useTranslation("requests");
    const [disableInput, setDisableInput] = useState(true)
    const [submitOffer, setSubmitOffer] = useState<ISubmitOfferModel>({})
    useEffect(() => {
        if (request?.financingDetails) {
            setSubmitOffer({
                requestId: Number(request.id),
                offer: {
                    ...request.financingDetails,
                    netFinancingValue: request.financingDetails.financingValue,
                    isCountered: false
                },
            })
        }
    }, [])

    const onSubmit = () => {

        dispatch(SubmitToCustomer(submitOffer, onClose))
    }

    const onCounter = () => {
        setDisableInput(false)
        setSubmitOffer((u: ISubmitOfferModel) => ({...u, offer: {...u.offer, isCountered: true}}));

    }

    return (<SimplyModal open={open} onClose={onClose} title={title}>
        <Box display={"flex"} width={"44vw"} p={1}>


            <Grid container spacing={3}>


                <Grid item md={6}>
                    <TextInput
                        label={`${t("financingValue")}  `}
                        disabled={disableInput}
                        value={submitOffer?.offer?.netFinancingValue}
                        onChange={(i: string) => {
                            setSubmitOffer((u: ISubmitOfferModel) => ({
                                ...u,
                                offer: {...u.offer, netFinancingValue: i as unknown as number}
                            }));
                        }}
                    />
                </Grid>
                <Grid item md={6}>
                    <TextInput
                        label={t("numberOfInstallments")}
                        value={submitOffer?.offer?.numberOfInstallments}
                        disabled={disableInput}

                        onChange={(i: string) => {
                            setSubmitOffer((u: ISubmitOfferModel) => ({
                                ...u,
                                offer: {...u.offer, numberOfInstallments: i as unknown as number}
                            }));
                        }}
                    />
                </Grid>
                {/*<Grid item md={6}>*/}
                {/*    <TextInput*/}
                {/*        label={t("interestRate")}*/}
                {/*        disabled={disableInput}*/}

                {/*        value={submitOffer?.offer?.interestRate}*/}
                {/*        onChange={(i: string) => {*/}
                {/*            setSubmitOffer((u: ISubmitOfferModel) => ({*/}
                {/*                ...u,*/}
                {/*                offer: {...u.offer, interestRate: i as unknown as number}*/}
                {/*            }));*/}
                {/*        }}*/}
                {/*    />*/}
                {/*</Grid>*/}
                {request.type === 'autoLoan' && <Grid item md={6}>
                    <TextInput
                        label={t("downPayment")}
                        disabled={disableInput}

                        value={submitOffer?.offer?.downPayment}
                        onChange={(i: string) => {
                            setSubmitOffer((u: ISubmitOfferModel) => ({
                                ...u,
                                offer: {...u.offer, downPayment: i as unknown as number}
                            }));
                        }}
                    />
                </Grid>}
                <Grid item md={12}>
                    <TextInput
                        label={t("note")}
                        value={submitOffer?.offer?.note}
                        multiline
                        onChange={(i: string) => {
                            setSubmitOffer((u: ISubmitOfferModel) => ({
                                ...u,
                                offer: {...u.offer, note: i}
                            }));
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Box display="flex" paddingTop={9} gridGap={5} justifyContent={"flex-end"} alignSelf={"end"}>
                        <AccessControl allowedTypes={[LenderEmployee.label,BankManager.label,BankOfficer.label]}>
                        <Button variant={"contained"} color={"secondary"} onClick={onCounter}>{t("alternativeOffer")}</Button>
                        </AccessControl>
                        <Button variant={"contained"} color={"primary"} onClick={onSubmit}>{t("submit")}</Button>

                    </Box>

                </Grid>

            </Grid>
        </Box>
    </SimplyModal>)
}

export default SendOfferModal;
