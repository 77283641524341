import {ApiResponse, ClientFactory} from "@simplify9/simplyapiclient";
import IClient from "@simplify9/simplyapiclient/dist/Models/IClient";
import CookieManager from "../utils/CookiesManager";
import {ISearchCompanies} from "../types/Companies";
import {IPagedSearch} from "../types/Paged";


class CompaniesClient {
    client: IClient;
    locale: string;

    constructor() {
        this.client = ClientFactory();
        this.locale = CookieManager.getLocale();
    }

    async Search(
        search?: IPagedSearch<ISearchCompanies>
    ): Promise<ApiResponse> {
        const rs: ApiResponse = this.client.SimplyGetAsync(`companies?culture=${this.locale}&name=${search?.search?.name?? ''}&rating=${search?.search?.rating??''}&limit=${search?.limit??20}&offset=${search?.offset??0}`);
        return rs;
    }
}

export default CompaniesClient;