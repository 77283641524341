import IClient from "@simplify9/simplyapiclient/dist/Models/IClient";
import {ApiResponse, ClientFactory} from "@simplify9/simplyapiclient";
import CookieManager from "../utils/CookiesManager";
import {IPagedSearch} from "../types/Paged";
import {ICreateLender, ISearchLenders} from "../types/Lender";
import {ISearchValidation, RequestScoreCriteriaModel, SMERequestScoreCriteriaModel} from "../types/RequestValidation";
import {ISearchRequests} from "../types/Request";

class validationClient {
    client: IClient;
    // BaseUrl: string;
    locale: string;

    constructor() {
        // this.BaseUrl = `${Config.baseUrl}`;
        this.client = ClientFactory();
        this.locale = CookieManager.getLocale();
    }

    async Search(
        locale: string,
        search?: IPagedSearch<ISearchValidation>): Promise<ApiResponse> {
        const rs: ApiResponse = this.client.SimplyGetAsync(`scoring?culture=${locale}
        &limit=${search?.limit ?? 10}&offset=${search?.offset ?? 0}`);
        return rs;
    }

    async SearchSme(
        locale: string,
        search?: IPagedSearch<ISearchValidation>): Promise<ApiResponse> {
        const rs: ApiResponse = this.client.SimplyGetAsync(`scoring/sme?culture=${locale}
        &limit=${search?.limit ?? 10}&offset=${search?.offset ?? 0}`);
        return rs;
    }

    async Update(info: RequestScoreCriteriaModel, id: number): Promise<ApiResponse> {
        let res = await this.client.SimplyPostAsync(`scoring/${id}`, {data:info});
        return res;
    }

    async SmeUpdate(info: SMERequestScoreCriteriaModel, id: number): Promise<ApiResponse> {
        let res = await this.client.SimplyPostAsync(`scoring/${id}/sme`, {data:info});
        return res;
    }

}
export default validationClient;

