import {Box, Button, FormControl, Select, Typography} from "@material-ui/core"
import React, {FunctionComponent, useEffect, useState} from "react"
import SimplyModal from "../../../shared/SimplyModal"
import {useDispatch, useSelector} from "react-redux";
import IAppStateModel from "../../../../types/AppState";
import {AssignToBranch, SubmitToLender} from "../../../../state/actions/SubmissionActions";
import SubmissionsClient from "../../../../apiClient/submissionsClient";
import LendersClient from "../../../../apiClient/LendersClient";

interface IProps {
    onClose: () => void;
    title: string;
    requestId?:string;

}

const ConfirmationModal: FunctionComponent<IProps> = ({onClose, title, requestId}): JSX.Element => {

    const [branches, setBranches] = useState<any[]>([])
    const lendersClient = new LendersClient();
    const getBranches = async ( ) => {
        let res = await lendersClient.GetBranches();
        setBranches(res.data);
    }
    useEffect(() => {
        getBranches()
    })

    const [selectedBranch, setSelectedBranch] = useState<number>()
    const dispatch = useDispatch()
    const onSubmit = () => {
        dispatch(AssignToBranch(requestId!, selectedBranch as any, onClose))
    }


    return (<SimplyModal open={true} onClose={onClose} title={title}>

        <Box display={"flex"} flexDirection={"column"} width={500}>

            <Box width={"100%"}>
                <FormControl fullWidth>
                    <Select
                        
                        value={selectedBranch}
                        onChange={(i) => {
                            setSelectedBranch(Number(i.target.value))
                        }}
                    >
                        <option value="">{"Branch"}</option>
                        {
                            branches && branches?.map((i) =>
                                (<option value={i.value}>{i.label}</option>)
                            )
                        }
                    </Select>
                </FormControl>

            </Box>
            <Box display={"flex"} justifyContent={"flex-end"} paddingTop={5}>
                <Button color={"primary"} variant={"contained"} onClick={onSubmit}>{title}</Button>
            </Box>

        </Box>


    </SimplyModal>)
}
export default ConfirmationModal
