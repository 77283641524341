import * as ActionsTypes from '../ActionsTypes';
import IAction from "../../types/Action";
import IPagedResults, {IPagedSearch} from "../../types/Paged";
import MerchantsClient from "../../apiClient/MerchantsClient";
import IAppStateModel from "../../types/AppState";
import IMerchant, {ISearchMerchants} from "../../types/Merchant";
import {DispatchErrorSnackbar, SetIsLoading} from "./UiActions";


export const SearchMerchantsAsync = (merchantName?: string) => {
    return async (dispatch: any, getState: any) => {
        dispatch(SetIsLoading(true))

        const state: IAppStateModel = getState();
        const merchantsClient = new MerchantsClient();
        let response = await merchantsClient.Search({...state.merchants?.search, search: {name: merchantName}});

        if (response.succeeded) {
            dispatch(setMerchants(response.data as IPagedResults<IMerchant>))

        } else {
            dispatch(DispatchErrorSnackbar())
        }
        dispatch(SetIsLoading(false))

    };
}
export const SwitchMerchantStatusAsync = (merchantId: number) => {
    return async (dispatch: any) => {
        dispatch(SetIsLoading(true))
        const merchantsClient = new MerchantsClient();
        let response = await merchantsClient.SwitchStatus(merchantId);

        if (response.succeeded) {
            dispatch(SearchMerchantsAsync())

        } else {
            dispatch(DispatchErrorSnackbar())
        }
        dispatch(SetIsLoading(false))

    };
}

const setMerchants = (merchants: IPagedResults<IMerchant>): IAction => {
    return {
        type: ActionsTypes.SET_MERCHANTS,
        payload: merchants
    }
}

export const SetMerchantsSearch = (search: IPagedSearch<ISearchMerchants>): IAction => {
    return {
        type: ActionsTypes.SET_MERCHANTS_SEARCH,
        payload: search
    }
}
