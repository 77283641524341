import SimplyModal from "../../../shared/SimplyModal";
import {Box, Button, Grid, TextField} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import AppState from "../../../../types/AppState";
import {useState} from "react";
import {BackGroundCheckResult} from "../../../../types/Request";
import {Autocomplete} from "@material-ui/lab";
import {EditScore} from "../../../../state/actions/RequestsActions";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";

interface IProp{
    onClose:()=>void
    id?:string
    model?:BackGroundCheckResult
    open: boolean;
}
const useStyles = makeStyles((theme: Theme) => ({
   
    input: {
        "& .MuiFormLabel-root": {
            left: "auto",
            right: "auto",
            transformOrigin: "top right",
            textAlign: "right",
            paddingRight:"3rem"
        }
    }
}))

const BackgroundCheckModal=(props:IProp)=>{
    const classes = useStyles()
    const {t, i18n} = useTranslation("common")
    let labelStyle = i18n.language.slice(0, 2).toLowerCase() === "ar" ? true : false;
    const metaLookUps=useSelector((state:AppState)=>state.lookUpTables)
    const [secretValue,setSecretValue]=useState<BackGroundCheckResult>(props.model||{})
    const dispatch=useDispatch()
    return(
        <SimplyModal open={props.open} onClose={props.onClose} title={t('BackgroundCheck')}>
            <Box display={"flex"} width={"25vw"} p={1}>
                <Grid container spacing={3}>
                    <Grid style={{alignSelf:"end"}} item xs={12} sm={11} md={12} xl={12}>
                        <Autocomplete
                            value={
                                metaLookUps.secretResult?.find((i) => i.value === secretValue?.secretResultId?.toString() || i.label === secretValue?.secretResult) || { label: "", value: "" }
                            }
                            onChange={(v,newValue) => setSecretValue({...secretValue,secretResult:newValue?.label,secretResultId:newValue?.value})}
                            options={metaLookUps.secretResult}
                            getOptionLabel={(option: any) => option.label}
                            renderInput={(params) => (
                                <TextField
                                className={labelStyle ? classes.input : ''}
                                    {...params}
                                    placeholder={t('SecretValue')}
                                    label={t('SecretValue')}
                                    fullWidth
                                />
                            )}
                            fullWidth
                        />
                    </Grid>
                    <Grid style={{alignSelf:"end"}} item xs={12} sm={11} md={12} xl={12}>
                        <TextField
                           className={labelStyle ? classes.input : ''}
                            value={secretValue?.note||""}
                            variant="filled"
                            onChange={(e)=>setSecretValue({...secretValue,note:e.target.value})}
                            placeholder={t('Note')}
                            fullWidth
                            label={t('Note')}
                        />
                    </Grid>
                    <Grid style={{alignSelf:"end",width:"100%",textAlign:"end"}} item>
                        <Button
                            style={{textTransform:"none"}}
                            onClick={()=>dispatch(EditScore(props.id||"",props.onClose,secretValue))}
                            color={"primary"}
                            variant={"contained"}
                        >
                            {t('save')}
                        </Button>
                    </Grid>
                </Grid>
            </Box></SimplyModal>
    )
}
export default BackgroundCheckModal
