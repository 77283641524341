import React, {FunctionComponent} from "react";
import LoanView from "../../LoanView";
import PersonIcon from "@material-ui/icons/Person";
import {IAutoLoan} from "../../../../types/Request";
import {ComponentRequestsAutoLoan, Maybe} from "../../../../types/content";

interface IProps {
    model?: IAutoLoan;
    content: Maybe<ComponentRequestsAutoLoan> | undefined
    onEditClick?: () => void;
    price:number
}

const CarLoanInfo: FunctionComponent<IProps> = ({
                                                    onEditClick,
                                                    content,
                                                    model,
                                                    price
                                                }) => {

    return (
        <LoanView.Card
            title={content?.stepTitle || ""}
            icon={<PersonIcon fontSize={"large"}/>}
            onEditClick={onEditClick}
        >
            <LoanView.CardSection>
                <LoanView.SectionItem
                    value={model?.vehicleManufacturer || ""}
                    label={content?.vehicleManufacturer || ""}
                />
                <LoanView.SectionItem
                    value={model?.vehicleModel || ""}
                    label={content?.vehicleModel || ""}
                />
                {model?.vehicleRegistrationType && <LoanView.SectionItem
                    value={model?.vehicleRegistrationType || ""}
                    label={content?.vehicleRegistrationType || ""}
                />}
                <LoanView.SectionItem
                    value={price?.toString()}
                    label={content?.vehiclePrice?.toString() || ""}
                />
            </LoanView.CardSection>
            <LoanView.CardSection isLastSection>
                <LoanView.SectionItem
                    value={model?.vehicleCondition || ""}
                    label={content?.vehicleCondition || ""}
                />
                <LoanView.SectionItem
                    value={model?.vehicleYear?.toString() || ""}
                    label={content?.vehicleYear || ""}
                />
            </LoanView.CardSection>
        </LoanView.Card>
    );
};
export default CarLoanInfo;
