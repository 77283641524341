import * as ActionsTypes from '../ActionsTypes';
import IAction from "../../types/Action";
import IPagedResults, {IPagedSearch} from "../../types/Paged";
import IRequest, {
    BackGroundCheckResult,
    IRejectSubmission,
    IRequestAttachment,
    ISearchRequests,
} from "../../types/Request";
import RequestsClient from "../../apiClient/RequestsClient";
import {DispatchErrorSnackbar, DispatchSuccessSnackbar, SetIsLoading} from "./UiActions";
import { useSelector } from 'react-redux';
import IAppStateModel from '../../types/AppState';


export const SearchRequests = (locale: string,searchValue?:string) => {
    return async (dispatch: any, getState: any) => {
        dispatch(SetIsLoading(true))
        const requestsClient = new RequestsClient();
        //const offset = ((((searchModel?.pageNumber ?? 0) - 1) ?? 0) * 10)
        let state: IAppStateModel= getState();
        const response = await requestsClient.SearchAsync(locale, {
            search: {...state.requests.search.search,search:searchValue},
            offset: state.requests.search.offset ?? 0,
            limit: state.requests.search.limit ?? 20
        });

        if (response.succeeded) {
            dispatch(setRequests(response.data as IPagedResults<IRequest>))
            console.log(response.data, "response data")

        } else {
            dispatch(DispatchErrorSnackbar())

        }
        dispatch(SetIsLoading(false))

    };
}
export const ExportToExcel = (searchText?:string) => {
    return async (dispatch: any, getState: any) => {
        dispatch(SetIsLoading(true))
        let state: IAppStateModel= getState();

        const requestsClient = new RequestsClient();

        const response = await requestsClient.ExportToExcelAsync({...state.requests.search.search,search:searchText??""});

        if (response.succeeded) {
            dispatch(DispatchSuccessSnackbar("Data exported to excel successfully"))
            window.open(response.data.location, "_blank", "noreferrer")
        } else {
            dispatch(DispatchErrorSnackbar())
        }
        dispatch(SetIsLoading(false))


    };
}

export const ApproveOffer = (id: string, onFinish: (id: string) => any) => {
    return async (dispatch: any, getState: any) => {
        dispatch(SetIsLoading(true))

        const requestsClient = new RequestsClient();

        const response = await requestsClient.ApproveOfferAsync(id);

        if (response.succeeded) {
            dispatch(DispatchSuccessSnackbar("Offer Approved successfully"))
            onFinish(id)
        } else {
            dispatch(DispatchErrorSnackbar())
        }

        dispatch(SetIsLoading(false))

    };
}

export const SignedOffer = (id: string, onFinish: () => any,lenderId?:number) => {
    return async (dispatch: any, getState: any) => {
        dispatch(SetIsLoading(true))

        const requestsClient = new RequestsClient();

        const response = await requestsClient.SignedOfferAsync(id,lenderId);

        if (response.succeeded) {
            dispatch(DispatchSuccessSnackbar("Offer Signed successfully"))
            onFinish()
        } else {
            dispatch(DispatchErrorSnackbar())
        }

        dispatch(SetIsLoading(false))

    };
}


export const FinalizeOffer = (id: string,executed:boolean,title:string, onFinish: () => any) => {
    return async (dispatch: any, getState: any) => {
        dispatch(SetIsLoading(true))

        const requestsClient = new RequestsClient();

        const response = await requestsClient.FinalizeOfferAsync(id,executed);

        if (response.succeeded) {
            dispatch(DispatchSuccessSnackbar(`Offer ${title} successfully`))
            onFinish()
        } else {
            dispatch(DispatchErrorSnackbar())
        }

        dispatch(SetIsLoading(false))

    };
}


export const ExportRequestAsync = (id: string, onFinish: (id: string) => any) => {
    return async (dispatch: any) => {
        dispatch(SetIsLoading(true))

        const requestsClient = new RequestsClient();

        const response = await requestsClient.ExportAsPdfAsync(id);

        if (response.succeeded) {
            window.open(response.data.location, "_blank")
            dispatch(DispatchSuccessSnackbar("Request exported successfully"))
            onFinish(id)

        } else {
            dispatch(DispatchErrorSnackbar())

        }
        dispatch(SetIsLoading(false))


    };
}

export const RejectRequest = (id: string,notes:string, onFinish: (id: string) => any, model?: IRejectSubmission) => {
    return async (dispatch: any, getState: any) => {
        dispatch(SetIsLoading(true))

        const requestsClient = new RequestsClient();

        const response = await requestsClient.RejectRequestAsync(id, {Feedback:notes});

        if (response.succeeded) {
            dispatch(DispatchSuccessSnackbar("Request rejected successfully"))
            onFinish(id)

        } else {
            dispatch(DispatchErrorSnackbar())

        }
        dispatch(SetIsLoading(false))


    };
}

export const EditScore = (id: string, onFinish: (id: string) => any, model?: BackGroundCheckResult) => {
    return async (dispatch: any, getState: any) => {
        dispatch(SetIsLoading(true))
        const requestsClient = new RequestsClient();

        const response = await requestsClient.EditSecretAsync(id, model);

        if (response.succeeded) {
            dispatch(DispatchSuccessSnackbar("Secret Edited successfully"))
            onFinish(id)

        } else {
            dispatch(DispatchErrorSnackbar())

        }
        dispatch(SetIsLoading(false))


    };
}

export const ScoreRequest = (id: string, onFinish: (id: string) => any, model?: IRejectSubmission) => {
    return async (dispatch: any, getState: any) => {
        dispatch(SetIsLoading(true))

        const requestsClient = new RequestsClient();

        const response = await requestsClient.Score(id);
        if (response.succeeded) {
            dispatch(DispatchSuccessSnackbar("Request Scored successfully"))
            onFinish(id)

        } else {
            dispatch(DispatchErrorSnackbar())

        }
        dispatch(SetIsLoading(false))


    };
}

export const AddAttachments = (id: string,newAttachments:IRequestAttachment[], onFinish: (id:string) => any) => {
    return async (dispatch: any, getState: any) => {
        dispatch(SetIsLoading(true))

        const requestsClient = new RequestsClient();

        const response = await requestsClient.AddAttachments({newAttachments:newAttachments},id);

        if (response.succeeded) {
            dispatch(DispatchSuccessSnackbar("Attachments Added Successfully"))
            onFinish(id)

        } else {
            dispatch(DispatchErrorSnackbar())

        }
        dispatch(SetIsLoading(false))


    };
}

const setRequests = (requests: IPagedResults<IRequest>): IAction => {
    return {
        type: ActionsTypes.SET_REQUESTS,
        payload: requests
    }
}

export const SetRequestsSearch = (search: IPagedSearch<ISearchRequests>): IAction => {
    return {
        type: ActionsTypes.SET_REQUESTS_SEARCH,
        payload: search
    }
}

export const PullFromMarket = (id: string) => {
    return async (dispatch: any, getState: any) => {
        dispatch(SetIsLoading(true))

        const requestsClient = new RequestsClient();

        const response = await requestsClient.PullFromMarket(id);
        if (response.succeeded) {
            dispatch(DispatchSuccessSnackbar("Pulled successfully"))

        } else {
            dispatch(DispatchErrorSnackbar())

        }
        dispatch(SetIsLoading(false))


    };
}

export const SendToMarket = (id: string) => {
    return async (dispatch: any, getState: any) => {
        dispatch(SetIsLoading(true))

        const requestsClient = new RequestsClient();

        const response = await requestsClient.SendToMarket(id);
        if (response.succeeded) {
            dispatch(DispatchSuccessSnackbar("Pushed successfully"))

        } else {
            dispatch(DispatchErrorSnackbar())

        }
        dispatch(SetIsLoading(false))


    };
}
