import PersonIcon from "@material-ui/icons/Person";
import LoanView from "../LoanView";
import React from "react";
import {BackGroundCheckResult} from "../../../types/Request";
import {useTranslation} from "react-i18next";

interface IProps {
    model?: BackGroundCheckResult
    setModal: () => void
}

const BackgroundCheckResultInfoView = (props: IProps) => {
    const {t, i18n} = useTranslation("common")
    const secretResultResolver = (name?: string) => {
        switch (name) {
            case 'خطر': {
                return t('HighRisk')
            }
            case 'متوسط الخطورة': {
                return t('MediumRisk')
            }
            case 'قليل الخطورة': {
                return t('LowRisk')
            }
            case 'لا يوجد سجل':{
                return t('NoRecord')
            }
            default :
                return "No Secret"
        }
    }

    return (
        <LoanView.Card
            title={t('BackgroundCheck')}
            onEditClick={() => props.setModal()}
            isAdmin
            icon={<PersonIcon fontSize={"large"}/>}
        >
            <LoanView.CardSection isLastSection>
                <LoanView.SectionItem
                    value={secretResultResolver(props.model?.secretResult)}
                    label={t('SecretValue')}
                />
                {props.model?.note && <LoanView.SectionItem
                    value={props.model?.note}
                    label={t('Note')}
                />}
            </LoanView.CardSection>
        </LoanView.Card>
    )
}
export default BackgroundCheckResultInfoView
