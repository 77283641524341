import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import LendersClient from "../../../apiClient/LendersClient";
import * as yup from "yup";
import { ICreateLender } from "../../../types/Lender";
import { useTranslation } from "react-i18next";
import TextInput from "../../shared/TextInput";
import { FormControl, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import { RequestTrailModel } from "../../../types/Request";
import { parseDate } from "../../shared/DateFormat";
import { useSelector } from "react-redux";
import AppState from "../../../types/AppState";
import { ICreateUserModel } from "../../../types/User";


const useStyles = makeStyles((theme: Theme) => ({
    container: {
        [theme.breakpoints.down("xs")]: {
            paddingLeft: "1.2rem",
            paddingRight: "1.2rem",

        },
        paddingLeft: "3rem",
        paddingRight: "3rem",
        paddingTop: "1rem",
        paddingBottom: "3rem",
    },

    divider: {
        marginTop: "5rem",
        marginBottom: "1rem"
    },
    sectionTitle: {
        textAlign: "start"
    },
    footer: {
        textAlign: "end"
    },
    

}))

const validationSchema = yup.object({
    nameAr: yup
        .string()
        .matches(/^[\u0621-\u064A ]*$/, "onlyArabicLettersError")
        .max(200, "")
        .required("Required"),
    nameEn: yup
        .string()
        .matches(/^[A-Za-z ]*$/, "onlyEnglishLettersError")
        .max(200, "")
        .required("Required"),

});

const Component = () => {
    const classes = useStyles();
    const client = new LendersClient();
    const { t } = useTranslation("lenders");

    let history = useHistory();
    const formik = useFormik({
        initialValues: {
            nameAr: '',
            nameEn: '',
            commissions: {
                productLoanCommission: 0,
                autoLoanCommission: 0,
                personalLoanCommission: 0,
                mortgageLoanCommission: 0,
                smeLoanCommission: 0
            }
        },
        validationSchema: validationSchema,
        validateOnBlur: true,

        onSubmit: async (values, { resetForm }) => {
            let res = await client.Create(values as ICreateLender);
            if (res.succeeded) {
                history.push(`/lenders/${res.data.id}`)
            } else {

            }
        },
    });

    const types = useSelector((state: AppState) => state.lookUpTables.requestTypes)

    return (
        <div className={classes.container}>
            <Paper className={classes.container}>

                <Typography variant="h6" gutterBottom>
                    {t("add")}
                </Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <TextInput
                            name="nameEn"
                            label={t("nameEn")}
                            value={formik.values.nameEn}
                            onChange={(v: string) => formik.setFieldValue("nameEn", v)}
                            isInvalid={
                                formik.touched.nameEn && Boolean(formik.errors.nameEn)
                            }
                            validationError={
                                (formik.touched.nameEn && formik.errors?.nameEn) || ""
                            }
                            onBlur={formik.handleBlur}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextInput
                            name="nameAr"
                            label={t("nameAr")}
                            value={formik.values.nameAr}
                            onChange={(v: string) => formik.setFieldValue("nameAr", v)}
                            isInvalid={
                                formik.touched.nameAr && Boolean(formik.errors.nameAr)
                            }
                            validationError={
                                (formik.touched.nameAr && formik.errors?.nameAr) || ""
                            }
                            onBlur={formik.handleBlur}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                        <TableContainer component={Paper}>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center">{t("loanType")}</TableCell>
                                        <TableCell align="center">{t("commission")}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell align="center">
                                            {t("personalLoan")}
                                        </TableCell>
                                        <TableCell align="center">
                                            <FormControl>
                                                <TextInput
                                                    notFullWidth={true}
                                                    name="commissions.personalLoanCommission"
                                                    label={t("personalLoan")}
                                                    value={formik.values.commissions.personalLoanCommission}
                                                    onChange={(v: string) => formik.setFieldValue("commissions.personalLoanCommission", v)}
                                                    isInvalid={
                                                        formik.touched.commissions?.personalLoanCommission && Boolean(formik.errors.commissions?.personalLoanCommission)
                                                    }
                                                    validationError={
                                                        (formik.touched.commissions?.personalLoanCommission && formik.errors?.commissions?.personalLoanCommission) || ""
                                                    }
                                                    onBlur={formik.handleBlur}
                                                />
                                            </FormControl>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align="center">
                                            {t("autoLoan")}
                                        </TableCell>
                                        <TableCell align="center">
                                            <FormControl>
                                                <TextInput
                                                    notFullWidth={true}
                                                    name="commissions.autoLoanCommission"
                                                    label={t("autoLoan")}
                                                    value={formik.values.commissions.autoLoanCommission}
                                                    onChange={(v: string) => formik.setFieldValue("commissions.autoLoanCommission", v)}
                                                    isInvalid={
                                                        formik.touched.commissions?.autoLoanCommission && Boolean(formik.errors.commissions?.autoLoanCommission)
                                                    }
                                                    validationError={
                                                        (formik.touched.commissions?.autoLoanCommission && formik.errors?.commissions?.autoLoanCommission) || ""
                                                    }
                                                    onBlur={formik.handleBlur}
                                                />
                                            </FormControl>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align="center">
                                            {t("productLoan")}
                                        </TableCell>
                                        <TableCell align="center">
                                            <FormControl>
                                                <TextInput
                                                    notFullWidth={true}
                                                    name="commissions.productLoanCommission"
                                                    label={t("productLoan")}
                                                    value={formik.values.commissions.productLoanCommission}
                                                    onChange={(v: string) => formik.setFieldValue("commissions.productLoanCommission", v)}
                                                    isInvalid={
                                                        formik.touched.commissions?.productLoanCommission && Boolean(formik.errors.commissions?.productLoanCommission)
                                                    }
                                                    validationError={
                                                        (formik.touched.commissions?.productLoanCommission && formik.errors?.commissions?.productLoanCommission) || ""
                                                    }
                                                    onBlur={formik.handleBlur}
                                                />
                                            </FormControl>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align="center">
                                            {t("SMELoan")}
                                        </TableCell>
                                        <TableCell align="center">
                                            <FormControl>
                                                <TextInput
                                                    notFullWidth={true}
                                                    name="commissions.smeLoanCommission"
                                                    label={t("SMELoan")}
                                                    value={formik.values.commissions.smeLoanCommission}
                                                    onChange={(v: string) => formik.setFieldValue("commissions.smeLoanCommission", v)}
                                                    isInvalid={
                                                        formik.touched.commissions?.smeLoanCommission && Boolean(formik.errors.commissions?.smeLoanCommission)
                                                    }
                                                    validationError={
                                                        (formik.touched.commissions?.smeLoanCommission && formik.errors?.commissions?.smeLoanCommission) || ""
                                                    }
                                                    onBlur={formik.handleBlur}
                                                />
                                            </FormControl>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align="center">
                                            {t("mortgageLoan")}
                                        </TableCell>
                                        <TableCell align="center">
                                            <FormControl>
                                                <TextInput
                                                   
                                                    notFullWidth={true}
                                                    name="commissions.mortgageLoanCommission"
                                                    label={t("mortgageLoan")}
                                                    value={formik.values.commissions.mortgageLoanCommission}
                                                    onChange={(v: string) => formik.setFieldValue("commissions.mortgageLoanCommission", v)}
                                                    isInvalid={
                                                        formik.touched.commissions?.mortgageLoanCommission && Boolean(formik.errors.commissions?.mortgageLoanCommission)
                                                    }
                                                    validationError={
                                                        (formik.touched.commissions?.mortgageLoanCommission && formik.errors?.commissions?.mortgageLoanCommission) || ""
                                                    }
                                                    onBlur={formik.handleBlur}
                                                />
                                            </FormControl>
                                        </TableCell>
                                    </TableRow>
                                    {/*{props.trails?.slice(0).reverse().map((row: RequestTrailModel) => (*/}
                                    {/*    <TableRow key={row.code}>*/}
                                    {/*        <TableCell align="center">*/}
                                    {/*            {row.code}*/}
                                    {/*        </TableCell>*/}
                                    {/*        <TableCell align="center">*/}
                                    {/*            {parseDate(row.createdOn)}*/}
                                    {/*        </TableCell>*/}
                                    {/*    </TableRow>*/}
                                    {/*))}*/}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>


                <Divider className={classes.divider} />


                <Grid className={classes.footer}>
                    <Button onClick={formik.submitForm} variant="contained">{t("save")}</Button>
                </Grid>
            </Paper>
        </div>
    )
}
export default Component;
