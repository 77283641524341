import React, {useEffect} from 'react';
import {FormControl, Grid, TextField} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Table from "../../shared/Table";
import {makeStyles} from "@material-ui/styles";
import {Theme} from "@material-ui/core/styles";
import {useDispatch, useSelector} from "react-redux";
import IAppStateModel from "../../../types/AppState";
import {SearchUsers, SetUsersSearch} from "../../../state/actions/UserActions";
import {useTranslation} from "react-i18next";
import {parseDate} from "../../../utils/dateUtil";

const Component = () => {
    const classes = useStyles();
    const {usersResults, search} = useSelector((state: IAppStateModel) => {
        return {
            usersResults: state.users?.results,
            search: state.users?.search
        }
    })
    const {t} = useTranslation("users")

    const dispatch = useDispatch();
    const refreshData = () => {
        dispatch(SearchUsers());
    }

    useEffect(() => {
        refreshData();
    }, [search])

    return (
        <Grid container className={classes.container}>
            <Grid container justifyContent={"space-between"} alignItems={"center"}>
                <Grid item>
                    <Typography className={classes.title}>{t("title")}</Typography>
                </Grid>

            </Grid>
            <Grid container>
                <Grid item xs={12} className={classes.search}>
                    <FormControl fullWidth>

                        <TextField
                            placeholder={t("searchPlaceholder")}
                            fullWidth
                            size={"medium"}
                        />
                    </FormControl>

                </Grid>


            </Grid>


            <Grid className={classes.tableContainer} container>
                <Table
                    columns={[
                        {field: "id", name: t("id")},
                        {field: "name", name: t("name")},
                        {field: "phone", name: t("phone")},
                        {field: "userType", name: t("Role")},

                        {field: "createdOn", name: t("createdOn"), resolveData: (e) => parseDate(e.createdOn)},
                        {
                            field: "inactive", name: t("status"),
                            resolveData: (row: any) => row.inactive ? t("inactive") : t("active")
                        }
                    ]}
                    data={usersResults?.matches ?? []}
                    limit={usersResults?.limit!}
                    offset={usersResults?.offset}
                    total={usersResults?.total}
                    onPageChange={(page: number) => {
                        dispatch(SetUsersSearch({...search, offset: (search?.limit! * page)}))
                    }}
                    onCountChange={(count: number) => {
                        dispatch(SetUsersSearch({...search, limit: count}))
                    }
                    }
                />


            </Grid>

        </Grid>
    )
}
export default Component;

const useStyles = makeStyles((theme: Theme) => ({

    search: {

        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        paddingTop: "1rem"

    },
    container: {
        [theme.breakpoints.down("xs")]: {
            paddingLeft: "1.2rem",
            paddingRight: "1.2rem",

        },
        paddingLeft: "3rem",
        paddingRight: "3rem",
        paddingTop: "1rem",
    },
    title: {
        fontWeight: "bold",
        fontSize: "1.5rem"
    },
    tableContainer: {
        marginTop: '2rem'
    }

}))
