import React, {useEffect, useState} from 'react';
import {Box, FormControl, Grid, Paper, TextField} from "@material-ui/core";
import {Link} from 'react-router-dom';
import Typography from "@material-ui/core/Typography";
import Table from "../../shared/Table";
import {makeStyles} from "@material-ui/styles";
import {Theme} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import {useDispatch, useSelector} from "react-redux";
import IAppStateModel from "../../../types/AppState";
import {SearchLendersAsync} from "../../../state/actions/LendersActions";
import {Edit, Visibility} from "@material-ui/icons";
import ILender, {ISearchLenders} from "../../../types/Lender";
import IMerchant from "../../../types/Merchant";
import {useTranslation} from "react-i18next";
import {parseDate} from "../../../utils/dateUtil";
import {IPagedSearch} from "../../../types/Paged";
import {ImSearch} from "react-icons/all";
import EditModal from '../Components/EditModal';
import LendersClient from '../../../apiClient/LendersClient';
import {LenderEditModal} from "../Components/LenderEditModal";


const useStyles = makeStyles((theme: Theme) => ({

    search: {

        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",

    },
    container: {
        [theme.breakpoints.down("xs")]: {
            paddingLeft: "1.2rem",
            paddingRight: "1.2rem",

        },
        paddingLeft: "3rem",
        paddingRight: "3rem",
        paddingTop: "1rem",
    },
    title: {
        fontWeight: "bold",
        fontSize: "1.5rem"
    },
    tableContainer: {
        marginTop: '2rem'
    },
    paper: {
        width: "100%",
        marginTop: "0.4rem",

        marginBottom: "1.66rem",
        padding: "1rem"
    }

}))

const Component = () => {
    const classes = useStyles();
    const {lendersResults} = useSelector((state: IAppStateModel) => {
        return {
            lendersResults: state.lenders?.results,
        }
    });

    const [search, setSearch] = useState<IPagedSearch<ISearchLenders>>({search: {name: ""}, limit: 20, offset: 0})
    const {t} = useTranslation("lenders")

    const dispatch = useDispatch();

    const refreshData = () => {
        dispatch(SearchLendersAsync(search));
    }
    const onClearClick = (i : any)=>{
        setSearch({...search,search:{name:""}})
    }

    useEffect(() => {
        refreshData();
    }, [search?.limit, search?.offset,search?.search])

    const [modal, setModal] = useState(false)
    const [lenderForEdit, setLenderForEdit] = useState<ILender>()
    const client = new LendersClient();

    return (
        <Grid container className={classes.container}>
            {modal &&lenderForEdit&& <LenderEditModal title={t("edit")} client={client} row={lenderForEdit} onClose={()=>setModal(false)}/>}
            <Grid container justifyContent={"space-between"} alignItems={"center"}>
                <Grid item>
                    <Typography className={classes.title}>{t("title")}</Typography>
                </Grid>
                <Grid item>

                    <Button variant={"contained"} color={"secondary"}>
                        <Link to={"lenders/new"}>
                            {t("add")}
                        </Link>
                    </Button>
                </Grid>
            </Grid>
            <Paper className={classes.paper} elevation={3}>

                <Grid container>
                    <Grid item xs={12} md={4} className={classes.search}>
                        <FormControl fullWidth>

                            <TextField
                                placeholder={t("searchPlaceholder")}
                                value={search.search?.name}
                                onChange={(e) =>
                                    setSearch(s => ({...s, search: {...s.search, name: e.target.value}}))}
                                size={"medium"}

                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <Box width={"100%"} display={"flex"} alignItems={"center"} justifyContent={"flex-end"}>
                    <FormControl>
                        <Button style={{margin:2,backgroundColor:"red",color:"white"}} variant={"contained"} color={"primary"}
                                onClick={onClearClick}>
                            {t("clear")}
                        </Button>
                    </FormControl>
                    <FormControl>
                        <Button variant={"contained"} color={"primary"}
                                onClick={refreshData}>
                            {t("search")}
                            <ImSearch style={{marginLeft: "0.5rem"}}/>
                        </Button>
                    </FormControl>
                </Box>
            </Paper>

            <Grid className={classes.tableContainer} container>
                <Table
                    columns={[
                        {field: "id", name: t("id")},
                        {field: "name", name: t("name")},
                        // {field: "phone", name: t("phone")},
                        // {field: "commission", name: t("commission")},

                        {
                            field: "createdOn", name: t("createdOn"), resolveData: (row) => {
                                return parseDate(row.createdOn)
                            }
                        },
                        {
                            field: "inactive",
                            name: t("status"),
                            resolveData: (row: IMerchant) => row.inactive ? t("inactive") : t("active")
                        },
                    ]}
                    data={lendersResults?.matches ?? []}
                    limit={lendersResults?.limit!}
                    offset={lendersResults?.offset}
                    total={lendersResults?.total}
                    onPageChange={(page: number) => setSearch((s) => ({
                        ...s!,
                        offset: (s!.limit! * page)
                    }))}
                    onCountChange={(count: number) => setSearch((s) => ({...s, limit: count ?? 10}))}
                    actions={(row: ILender) => <>
                        <Link to={`lenders/${row.id}`}><Visibility/></Link>
                        <button style={{marginLeft: 10}} type="button"
                                onClick={()=> {
                            setLenderForEdit(row)
                            setModal(true)
                        }}
                        >
                            <Edit/>
                        </button>
                    </>}
                />


            </Grid>

        </Grid>
    )
}
export default Component;


