import { ISubmitOfferModel, RequestTrailModel } from "../../../../types/Request";
import SimplyModal from "../../../shared/SimplyModal";
import { useTranslation } from "react-i18next";
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@material-ui/core";
import { parseDate } from "../../../shared/DateFormat";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";


const useStyles = makeStyles((theme: Theme) => ({
    tableHeader: {
        color: theme.palette.secondary.main,
        fontWeight: "bold",
    },
}))


interface IProps {
    trails: RequestTrailModel[] | null
    closeModal: () => void
}

const TrailsTable = (props: IProps) => {
    const classes = useStyles()
    const { t } = useTranslation("requests");

    return (
        <SimplyModal open={true} onClose={() => props.closeModal()} title={t('trailData')}>

            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.tableHeader} align="center">{t('trail')}</TableCell>
                            <TableCell className={classes.tableHeader} align="center">{t('createdOn')}</TableCell>
                            <TableCell className={classes.tableHeader} align="center">{t('comment')}</TableCell>
                            <TableCell className={classes.tableHeader} align="center">{t('extraComments')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>

                        {props.trails?.slice(0).reverse().map((row: RequestTrailModel) => (
                            <TableRow key={row.code}>
                                <TableCell align="center">
                                    {row.code}
                                </TableCell>
                                <TableCell align="center">
                                    {parseDate(row.createdOn)}
                                </TableCell>
                                <TableCell align="center">
                                    {row.comment}
                                </TableCell>
                                <TableCell align="center">
                                    {row.extraComments.map((comment) => {
                                        if (comment.includes("https")) {
                                            return (
                                                <a href={comment}>open link</a>
                                            )
                                        } else {
                                            return <p>{comment}</p>
                                        }

                                    })}
                                </TableCell>


                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </SimplyModal>

    )
}

export default TrailsTable
