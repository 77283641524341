import React from 'react';
import Divider from "@material-ui/core/Divider";
import { Button, TextField, Grid, Typography, Paper, Select } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import * as yup from "yup";
import { useFormik } from "formik";
import { ICreateAgent } from '../../../../types/Agents';
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import AgentsClient from '../../../../apiClient/AgentsClient';
import { DispatchErrorSnackbar } from '../../../../state/actions/UiActions';
import AutocompleteInput from "../../../shared/AutoCompleteStep";
import IAppStateModel from "../../../../types/AppState";

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        [theme.breakpoints.down("xs")]: {
            paddingLeft: "1.2rem",
            paddingRight: "1.2rem",

        },
        paddingLeft: "3rem",
        paddingRight: "3rem",
        paddingTop: "1rem",
        paddingBottom: "3rem",
    },

    divider: {
        marginTop: "5rem",
        marginBottom: "1rem"
    },
    footer: {
        textAlign: "end"
    },
    input: {
        "& .MuiFormLabel-root": {
            left: "auto",
            right: "auto",
            transformOrigin: "top right",
            textAlign: "right",
        }
    },
    selectLabel: {

        left: "auto",
        right: "auto",
        transformOrigin: "top right",
        textAlign: "right",
        paddingRight: "2rem",

    },
}))


const Component = () => {
    const classes = useStyles();
    const dispatch = useDispatch()
    const { t, i18n } = useTranslation("agents");
    const lookUps = useSelector((state: IAppStateModel) => state.lookUpTables);
    let entityOptions = lookUps?.donors?.map((i) => {
        return (
            { "value": i.value, "label": i.label }
        )
    });
    let labelStyle = i18n.language.slice(0, 2).toLowerCase() === "ar" ? true : false;
    const client = new AgentsClient();
    let history = useHistory();
    const validationSchema = yup.object({
        nameAr: yup
            .string()
            .matches(/^[\u0621-\u064A ]*$/, " " + t("matchArabic"))
            .max(200, "")
            .min(3, " " + t("Minimum3Characters"))
            .required(" " + t("Required")),
        nameEn: yup
            .string()
            .matches(/^[A-Za-z ]*$/, " " + t("matchEnglish"))
            .max(200, "")
            .min(3, " " + t("Minimum3Characters"))
            .required(" " + t("Required")),
        email: yup.string().email(" " + t("validEmailError")).required(" " + t("Required")),
        phone: yup
            .string()
            .max(10, " " + t(" Maximum10Character")).min(8, " " + t("Minimum8Characters"))
            .required(" " + t("Required")),
        metaLenderId: yup
            .number()
            .required(" " + t("Required")),
    });
    const formik = useFormik({
        initialValues: {
            nameAr: '',
            nameEn: '',
            email: '',
            phone: '',
            initialDigitsOfMobileNumber: "962",
            metaLenderId: 0,

        },
        validationSchema: validationSchema,
        validateOnBlur: true,

        onSubmit: async (values, { resetForm }) => {
            values = { ...values, metaLenderId: Number(values.metaLenderId) };
            if (values.phone.length == 10) {
                values.phone = values.initialDigitsOfMobileNumber + values.phone;
                values.phone = values.phone.replace("0", "")
            } else if (values.phone.length == 9) {
                values.phone = values.initialDigitsOfMobileNumber + values.phone;
            } else {
                values.phone = values.initialDigitsOfMobileNumber + values.phone
            }
            console.log("values", values)
            let res = await client.Create(values as ICreateAgent);
            if (res.succeeded) {
                history.push("/agents")
            } else {
                dispatch(DispatchErrorSnackbar(res.data.REGISTRATION_FAILED))
            }
        },
    });


    return (
        <div className={classes.container}>
            <Paper className={classes.container}>
                <Typography variant="h6" gutterBottom>
                    {t("add")}
                </Typography>
                <Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                className={labelStyle ? classes.input : ''}
                                required
                                id="nameEn"
                                name="nameEn"
                                label={t("nameEn")}
                                fullWidth
                                value={formik.values.nameEn}
                                onChange={formik.handleChange}
                                helperText={(formik.touched.nameEn && formik.errors.nameEn && t("nameEn") + formik.errors.nameEn) || ""}
                                error={
                                    formik.touched.nameEn &&
                                    Boolean(formik.errors.nameEn)
                                }
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                className={labelStyle ? classes.input : ''}
                                required
                                id="nameAr"
                                name="nameAr"
                                label={t("nameAr")}
                                fullWidth
                                value={formik.values.nameAr}
                                onChange={formik.handleChange}
                                helperText={(formik.touched.nameAr && formik.errors.nameAr && t("nameAr") + formik.errors.nameAr) || ""}
                                error={
                                    formik.touched.nameAr &&
                                    Boolean(formik.errors.nameAr)
                                }
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                className={labelStyle ? classes.input : ''}
                                required
                                id="email"
                                name="email"
                                label={t("email")}
                                fullWidth
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                helperText={(formik.touched.email && formik.errors.email && t("email") + formik.errors.email) || ""}
                                error={
                                    formik.touched.email &&
                                    Boolean(formik.errors.email)
                                }
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <AutocompleteInput
                                required={true}
                                options={entityOptions ?? []}
                                name={"metaLenderId"}
                                value={formik.values.metaLenderId}
                                onChange={(v: any) =>
                                    formik.setFieldValue("metaLenderId", v)
                                }
                                isInvalid={
                                    formik.touched?.metaLenderId &&
                                    Boolean(formik.errors.metaLenderId)
                                }
                                validationError={formik.errors?.metaLenderId}
                                onBlur={formik.handleBlur}
                                placeHolder={t('entityId')}
                                fullWidth
                                helperText={(formik.touched.metaLenderId && formik.errors.metaLenderId && t("entityId") + formik.errors.metaLenderId) || ""}
                                error={
                                    formik.touched.metaLenderId &&
                                    Boolean(formik.errors.metaLenderId)
                                }
                            />
                        </Grid>

                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                        <AutocompleteInput
                                required={true}
                                options={[
                                    {value:"962" , label:"962 الاردن"},
                                    {value:"965" , label:"965 الكويت"},
                                    {value:"966" , label:"966 السعودية"},
                                    {value:"971" , label:"971 الامارات"},
                                    {value:"968" , label:"968 عمان"},
                                    {value:"973" , label:"973 بحرين"},
                                    {value:"974" , label:"974 قطر"},
                                ] }
                                name={"initialDigitsOfMobileNumber"}
                                value={formik.values.initialDigitsOfMobileNumber}
                                placeholder={"الاردن"}
                                onChange={(v: any) =>
                                    formik.setFieldValue("initialDigitsOfMobileNumber", v ?? "962")
                                }
                                isInvalid={
                                    formik.touched?.initialDigitsOfMobileNumber &&
                                    Boolean(formik.errors.initialDigitsOfMobileNumber)
                                }
                                validationError={formik.errors?.initialDigitsOfMobileNumber}
                                onBlur={formik.handleBlur}
                                fullWidth
                                helperText={(formik.touched.initialDigitsOfMobileNumber && formik.errors.initialDigitsOfMobileNumber && t("initialDigitsOfMobileNumber") + formik.errors.initialDigitsOfMobileNumber) || ""}
                                error={
                                    formik.touched.initialDigitsOfMobileNumber &&
                                    Boolean(formik.errors.initialDigitsOfMobileNumber)
                                }
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                        <TextField
                                className={labelStyle ? classes.input : ''}
                                required
                                id="phone"
                                name="phone"
                                label={t("phone")}
                                fullWidth
                                value={formik.values.phone}
                                onChange={formik.handleChange}
                                helperText={(formik.touched.phone && formik.errors.phone && t("phone") + formik.errors.phone) || ""}
                                error={
                                    formik.touched.phone &&
                                    Boolean(formik.errors.phone)
                                }
                            />
                        </Grid>

                    </Grid>
                    
                </Grid>
                <Divider className={classes.divider} />
                <Grid className={classes.footer}>
                    <Button onClick={formik.submitForm} variant="contained">{t("save")}</Button>
                </Grid>
            </Paper>
        </div>
    )
}
export default Component;
