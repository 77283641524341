import {
  Backdrop,
  Box,
  Fade,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import {Theme} from "@material-ui/core/styles";
import {parseDate} from "../../shared/DateFormat";
import {useTranslation} from 'react-i18next';
import {IContactTrailModel} from "../../../types/Crm";

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1000,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,

};
const useStyles = makeStyles((theme: Theme) => ({
  modal: {
    position: 'absolute',
    top: '10%',
    left: '10%',
    overflow: 'scroll',
    height: '100%',
    display: 'block',
    padding: 50,
    direction: theme.direction ,
  },
}))

interface IProps {
  trails?: IContactTrailModel[]
  onClose: () => void
}

const TrailModal: React.FC<IProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation("crm")

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={true}
      className={classes.modal}
      onClose={props.onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={true}>
        <Box sx={style}>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">{t("CreatedOn")}</TableCell>
                  <TableCell align="center">{t("Status")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.trails?.map((row: IContactTrailModel) => (
                  <TableRow key={Math.random()}>
                    <TableCell align="center">
                      {parseDate(row.createdOn)}
                    </TableCell>
                    <TableCell align="center">
                      {row.status}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Fade>
    </Modal>
  );
}

export default TrailModal;