import { createTheme } from "@material-ui/core/styles";
import { Direction } from "@material-ui/core";
import overrides from "./overrides";
import palette, { shape } from "./palette";

const theme = (direction: Direction) => {
    return createTheme({

        palette,
        overrides,
        shape,
        direction: direction,
        typography: {
            allVariants:{
                color:palette.secondary.main,
            },
            fontFamily: ["Poppins", "sans-serif"].join(","),

        },
    });
};

export default theme;
