import IAppStateModel from "../types/AppState";

const initialState: IAppStateModel = {
    snackBar:{
        open:false,

    },
    lookUpTables: {
        lenders: [],
        salesMen:[],
        // crm:[],
        secretResult:[],
        merchants: [],
        userTypes: [],
        requestStatuses: [],
        requestTypes: [],
        submissionsStatuses:[],
        vehicleCatalog:{
            conditions:[],
            manufacturers:[],
            models:[]
        },
        smePurposes:[],
        personalPurposes:[],
        nationalities:[],
        jobTypes:[],
        industries:[],
        countries:[],
        cities:[],
        areas:[],
        financingTypes:[],
        donors:[],
        companyTypes:[],
        companies:[],
    },
    isLoading: false,


    requests: {
        search: {
            offset: 0,
            limit: 20
        }
    },
    requestValidation:{
        search:{
            offset:0,
            limit:20
        }
      },
      smeRequestValidation:{
        search:{
            offset:0,
            limit:20
        }
      },
    merchants: {
        search: {
            offset: 0,
            limit: 20
        }
    },

    users: {
        search: {
            offset: 0,
            limit: 20
        }
    },
    lenders: {
        search: {
            offset: 0,
            limit: 20
        }
    },
    platformUsers: {
        search: {
            offset: 0,
            limit: 100
        }
    },
    crm: {
        search: {
            offset: 0,
            limit: 20
        }
    },
    agents: {
        search: {
            offset: 0,
            limit: 20
        }
    },
    companies: {
        search: {
            offset: 0,
            limit: 20
        }
    },
}

export default initialState;
