import React, {FunctionComponent} from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import {Box, Typography} from "@material-ui/core";
import {AiFillCloseCircle} from "react-icons/ai";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            maxWidth:"80vw",

            display: "flex",
            flexDirection: "column",
            backgroundColor: "#eeeeee",
            borderRadius: 8,
            boxShadow: theme.shadows[5],
            padding: theme.spacing(1, 2, 2, 2),
        },
        title: {
            fontWeight: "bold",
            fontSize: "1.4rem",
        },
        subTitle: {

            fontSize: "0.8rem",
            color: "#444"

        },
        modal: {
          direction: theme.direction ,
          minWidth:300,
        }
    }),
);

interface IProps {
    children: JSX.Element
    open: boolean
    onClose: () => void
    title: string
    subTitle?: string

}

const SimplyModal: FunctionComponent<IProps> = ({children, open, onClose, title, subTitle}: IProps) => {
    const classes = useStyles();

    return (
        <div
        >
            <Modal
                className={classes.modal}
                open={open}
                onClose={onClose}

            >
                <Box display={"flex"} alignItems={"center"} paddingTop={13} justifyContent={"center"}>

                    <Box display={"flex"} className={classes.paper}>

                        <Box width={"100%"} justifyContent={"space-between"} alignItems={"center"} display={"flex"}>
                            <Box display={"flex"} flexDirection={"column"}>
                                <Typography color={"secondary"} className={classes.title}>
                                    {title}
                                </Typography>
                                {
                                    subTitle &&
                                    <Typography color={"secondary"} className={classes.subTitle}>
                                        {subTitle}
                                    </Typography>
                                }

                            </Box>
                            <Box display={"flex"} onClick={onClose}>
                                <AiFillCloseCircle color={"#FF605C"} size={27}/>
                            </Box>
                        </Box>

                        <Box paddingTop={2}>
                            {children}
                        </Box>

                    </Box>
                </Box>

            </Modal>
        </div>
    );
}

export default SimplyModal
