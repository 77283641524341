import React, {FunctionComponent, useState} from "react";
import moment from "moment";
import 'react-dates/initialize';
import {DateRangePicker} from "react-dates";
import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';
import Divider from "@material-ui/core/Divider";
interface IProps{

    onChange:(from:any,to:any)=>void
    dateFrom:any
    dateTo:any
    startDatePlaceholderText?: string
    endDatePlaceholderText?:string
}

const DateRangePickerInput:FunctionComponent<IProps>=({onChange,dateFrom,dateTo,startDatePlaceholderText,endDatePlaceholderText})=>{
    const [dateRangeFocusedInput, setDateRangeFocusedInput] = useState<any>();

    return (<div style={{
        height: 37,
        backgroundColor: "white",
    }}> <DateRangePicker
            noBorder={true}
            isOutsideRange={() => false}
            small
            isDayBlocked={() => false}
            minimumNights={0}
            displayFormat={"YYYY-MM-DD"}
            startDatePlaceholderText={startDatePlaceholderText ?? 'Created From'}
            endDatePlaceholderText={endDatePlaceholderText ?? "Created To"}
            startDate={
                dateFrom
                    ? moment(dateFrom)
                    : null
            }
            startDateId="123" // PropTypes.string.isRequired,
            endDate={
                dateTo
                    ? moment(dateTo)
                    : null
            }
            endDateId="321" // PropTypes.string.isRequired,
            onDatesChange={({startDate, endDate}) => {
                onChange(startDate?.toDate(),endDate?.toDate())
            }}
            focusedInput={dateRangeFocusedInput}
            onFocusChange={(focusedInput) => {
                setDateRangeFocusedInput(focusedInput);
            }}
    />                                <Divider style={{height:1.5}}  />
    </div>)
}

export default DateRangePickerInput
