import { red } from "@material-ui/core/colors";

const palette = {
  primary: {
    main: "#F19220",
    contrastText: "#fff",
  },
  secondary: {
    main: "#00384b",
  },
  error: {
    main: red.A400,
  },
  success: {
    main: "#00C569",
  },

  background: {
    default: "#FFFFFF",
  },
};
export const shape = {
  borderRadius: 13,
};
export default palette;
