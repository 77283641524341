import * as ActionsTypes from '../ActionsTypes';
import IAction from "../../types/Action";
import ICrm, {ICreateContactModel, ISearchCrm} from "../../types/Crm";
import {DispatchErrorSnackbar, DispatchSuccessSnackbar, SetIsLoading} from "./UiActions";
import IAppStateModel, {LookUpModel} from "../../types/AppState";
import IPagedResults, {IPagedSearch} from "../../types/Paged";
import CrmClient from "../../apiClient/CrmClient";
import RequestsClient from "../../apiClient/RequestsClient";

export const SearchCrmAsync = (search?: IPagedSearch<ISearchCrm>) => {
    return async (dispatch: any, getState: any) => {
        dispatch(SetIsLoading(true))
        const crmClient = new CrmClient();
        let response = await crmClient.Search(search);
        if (response.succeeded) {
            if (search?.search?.lookUp) {
                dispatch(setCrmLookup(response.data))
            } else {

                dispatch(setCrm(response.data as IPagedResults<ICrm>))
            }

        } else {
            dispatch(DispatchErrorSnackbar())
        }
        dispatch(SetIsLoading(false))
    };
};

export const ExportCrmToExcel = (search?: IPagedSearch<ISearchCrm>) => {
    return async (dispatch: any, getState: any) => {
        dispatch(SetIsLoading(true))
        let state: IAppStateModel= getState();

        const crmClient = new CrmClient();

        const response = await crmClient.ExportToExcelAsync(search);

        if (response.succeeded) {
            dispatch(DispatchSuccessSnackbar("Data exported to excel successfully"))
            window.open(response.data.location, "_blank", "noreferrer")
        } else {
            dispatch(DispatchErrorSnackbar())
        }
        dispatch(SetIsLoading(false))


    };
}


export const CreateCrmAsync = (create: ICreateContactModel) => {

    return async (dispatch: any, getState: any) => {
        dispatch(SetIsLoading(true))
        const state: IAppStateModel = getState();
        const crmClient = new CrmClient();
        console.log(create, "Create")
        const response = await crmClient.Create(create);

        if (response.succeeded) {
            dispatch(DispatchSuccessSnackbar("added!"))

        } else {
            dispatch(DispatchErrorSnackbar())

        }
        dispatch(SetIsLoading(false))

    };

};

export const ContactAction = (type: string, id: string) => {
    return async (dispatch: any, getState: any) => {
        dispatch(SetIsLoading(true))

        const state: IAppStateModel = getState();
        const crmClient = new CrmClient();
        let response = await crmClient.CreateContactAction(type, id);

        if (response.succeeded) {
            dispatch(DispatchSuccessSnackbar("added successfully"))

        } else {
            dispatch(DispatchErrorSnackbar())
        }
        dispatch(SetIsLoading(false))

    };
}

const setCrm = (crm: IPagedResults<ICrm>): IAction => {
    return {
        type: ActionsTypes.SET_CRM,
        payload: crm
    }
}

const setCrmLookup = (crm: LookUpModel[]): IAction => {
    return {
        type: ActionsTypes.SET_CRM_LOOKUP,
        payload: crm
    }
}

