import {ApiResponse, ClientFactory} from "@simplify9/simplyapiclient";
import IClient from "@simplify9/simplyapiclient/dist/Models/IClient";
import CookieManager from "../utils/CookiesManager";
import Config from '../config';
import {IPagedSearch} from "../types/Paged";
import {ICreateUserModel, ISearchPlatformUsers, ISearchUsers} from "../types/User";
import { IForgetModel } from "../components/views/Auth/ForgetPassword";

class UsersClient {
    client: IClient;
    // BaseUrl: string;
    locale:string;

    constructor() {
        // this.BaseUrl = `${Config.baseUrl}`;
        this.client = ClientFactory();
        this.locale = CookieManager.getLocale();
    }


    async LoginAsync(
        email: string,
        password: string,
    ): Promise<ApiResponse> {
        const rs: ApiResponse = this.client.SimplyPostAsync("users/adminlogin", {
            email,
            password,
        });
        return rs;
    }

    async CompleteResetPasswordAsync(
        forgetModel:IForgetModel,
        newPassword: string,
        confirmNewPassword: string,
    ): Promise<ApiResponse> {
        const rs: ApiResponse = this.client.SimplyPostAsync("users/CompletePasswordReset", {
            token:forgetModel.token,
            accountId:forgetModel.accountId,
            newPassword,
            confirmNewPassword
        });

        return rs;
    }

    async CreateUserAsync(user: ICreateUserModel): Promise<ApiResponse> {
        const rs: ApiResponse = this.client.SimplyPostAsync("users/", user);
        return rs;
    }

    async EditUserAsync(id:number,type: number): Promise<ApiResponse> {
        const rs: ApiResponse = this.client.SimplyPostAsync(`users/${id}/setRole`, {type:type});
        return rs;
    }

    async SearchPlatformUsersAsync(
        search?: IPagedSearch<ISearchPlatformUsers>
    ): Promise<ApiResponse> {
        const rs: ApiResponse = this.client.SimplyGetAsync(`users?platformUsers=true&culture=${this.locale}&limit=100`);
        return rs;
    }

    async SearchUsersAsync(
        search?: IPagedSearch<ISearchUsers>,
        withLookup?:boolean
    ): Promise<ApiResponse> {
        let q =`users?culture=${this.locale}&limit=${search?.limit}&offset=${search?.offset}`
        if(withLookup)
            q+=`&lookup=${true}`
        const rs: ApiResponse = this.client.SimplyGetAsync(q);
        return rs;
    }

    async ResetPasswordAsync(
        email: string,
    ): Promise<ApiResponse> {
        const rs: ApiResponse = this.client.SimplyPostAsync("users/InitiatePasswordReset", {
            userName:email,
        });
        return rs;
    }
}


export default UsersClient;
