import React, { useEffect, useState } from 'react';
import { Box, FormControl, Grid, Paper, Input, InputAdornment, InputLabel, Select, MenuItem } from "@material-ui/core";
import { Link } from 'react-router-dom';
import Typography from "@material-ui/core/Typography";
import Table from "../../shared/Table";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { useDispatch, useSelector } from "react-redux";
import IAppStateModel from "../../../types/AppState";
import { SearchAgentsAsync } from "../../../state/actions/AgentsActions";
import { ISearchAgents } from "../../../types/Agents";
import { useTranslation } from "react-i18next";
import { parseDate } from "../../../utils/dateUtil";
import { IPagedSearch } from "../../../types/Paged";
import { ImSearch } from "react-icons/all";
import ClearIcon from "@material-ui/icons/Clear";


const useStyles = makeStyles((theme: Theme) => ({

    search: {

        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",

    },
    container: {
        [theme.breakpoints.down("xs")]: {
            paddingLeft: "1.2rem",
            paddingRight: "1.2rem",

        },
        paddingLeft: "3rem",
        paddingRight: "3rem",
        paddingTop: "1rem",
    },
    title: {
        fontWeight: "bold",
        fontSize: "1.5rem"
    },
    tableContainer: {
        marginTop: '2rem'
    },
    paper: {
        width: "100%",
        marginTop: "0.4rem",

        marginBottom: "1.66rem",
        padding: "1rem"
    },
    selectLabel: {

        left: "auto",
        right: "auto",
        transformOrigin: "top right",
        textAlign: "right",
        paddingRight: "2rem",

    },
    input: {
        "& .MuiFormLabel-root": {
            left: "auto",
            right: "auto",
            transformOrigin: "top right",
            textAlign: "right",
        }
    },
}))

const Component = () => {
    const classes = useStyles();
    const agentsResults = useSelector((state: IAppStateModel) => state.agents.results);
    const [searchData, setSearchData] = useState<IPagedSearch<ISearchAgents>>({ search: { mobileNumber: "", metaLenderId: null }, limit: 20, offset: 0 })
    const { t, i18n } = useTranslation("agents")
    let labelStyle = i18n.language.slice(0, 2).toLowerCase() === "ar";
    const lookUps = useSelector((state: IAppStateModel) => state.lookUpTables);
    const dispatch = useDispatch();

    const refreshData = () => {
        dispatch(SearchAgentsAsync());
    }

    useEffect(() => {
        refreshData();
    }, [])

    return (
        <Grid container className={classes.container}>
            <Grid container justifyContent={"space-between"} alignItems={"center"}>
                <Grid item>
                    <Typography className={classes.title}>{t("title")}</Typography>
                </Grid>
                <Grid item>
                    <Button variant={"contained"} color={"secondary"}>
                        <Link to={"agents/new"}>
                            {t("add")}
                        </Link>
                    </Button>
                </Grid>
            </Grid>
            <Paper className={classes.paper} elevation={3}>
                <Grid container  spacing={2}>
                    <Grid item md={2}>
                        <FormControl fullWidth>
                            <InputLabel className={labelStyle ? classes.selectLabel : ''}htmlFor="phone">{t("phone")}</InputLabel>
                            <Input
                                id="phone"
                                value={searchData?.search?.mobileNumber ?? ""}
                                onChange={(i: any) => {
                                    setSearchData(s => ({ ...s, search: { ...s.search, mobileNumber: i.target.value } }))
                                }}
                                endAdornment={
                                    <InputAdornment position="end">
                                        {searchData?.search?.mobileNumber && <ClearIcon style={{ cursor: "pointer" }} onClick={() => {
                                            setSearchData(s => ({ ...s, search: { ...s.search, mobileNumber: '' } }))
                                            refreshData()
                                        }
                                        } />}
                                    </InputAdornment>
                                }
                                placeholder={t("phone")}
                            />
                        </FormControl>
                    </Grid>

                    {lookUps?.donors &&
                        <Grid item md={2}>
                            <FormControl style={{ width: "85%" }}>
                                <InputLabel
                                    className={labelStyle ? classes.selectLabel : ''}>{t("entityName")}</InputLabel>

                                <Select
                                    onChange={(i: any) => {
                                        setSearchData(s => ({ ...s, search: { ...s.search, metaLenderId: Number(i.target.value) } }))
                                    }}
                                    value={searchData?.search?.metaLenderId ?? 0}
                                    displayEmpty={true}>
                                    {
                                        lookUps?.donors && lookUps?.donors?.map((i) =>
                                            (<MenuItem key={i.label + i.value} value={i.value}> {i.label} </MenuItem>)
                                        )
                                    }
                                </Select>
                            </FormControl>
                            {searchData?.search?.metaLenderId && <ClearIcon style={{ cursor: "pointer" }} onClick={() => {
                                setSearchData(s => ({ ...s, search: { ...s.search, metaLenderId: null } }))
                                refreshData()
                            }
                            } />}
                        </Grid>
                    }
                </Grid>
                <Box width={"100%"} display={"flex"} alignItems={"center"} justifyContent={"flex-end"}>
                    <FormControl>
                        <Button style={{ margin: 2, backgroundColor: "red", color: "white" }} variant={"contained"} color={"primary"}

                            onClick={() => {
                                setSearchData({ search: { mobileNumber: "", metaLenderId: null }, limit: 20, offset: 0 })
                                refreshData()
                            }

                            }>
                            {t("clear")}
                        </Button>
                    </FormControl>
                    <FormControl>
                        <Button variant={"contained"} color={"primary"}
                            onClick={() => dispatch(SearchAgentsAsync(searchData))}>
                            {t("search")}
                            <ImSearch style={{ marginLeft: "0.5rem" }} />
                        </Button>
                    </FormControl>
                </Box>
            </Paper>

            <Grid className={classes.tableContainer} container>
                <Table
                    columns={[
                        { field: "id", name: t("id") },
                        { field: "name", name: t("name") },
                        { field: "phone", name: t("phone") },
                        { field: "metaLenderName", name: t("entityName") },

                        {
                            field: "createdOn", name: t("createdOn"), resolveData: (row) => {
                                return parseDate(row.createdOn)
                            }
                        },
                    ]}
                    data={agentsResults?.matches ?? []}
                    limit={agentsResults?.limit!}
                    offset={agentsResults?.offset}
                    total={agentsResults?.total}
                    onPageChange={(page: number) => dispatch(SearchAgentsAsync({...searchData,offset:(searchData.limit * page)}))}
                    onCountChange={(count: number) => dispatch(SearchAgentsAsync({...searchData,limit: count}))} />
            </Grid>

        </Grid>
    )
}
export default Component;


