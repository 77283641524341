import React, {useEffect, useState} from 'react';
import {
    Box,
    FormControl,
    Grid,
    Input,
    InputAdornment,
    Paper
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Table from "../../shared/Table";
import {makeStyles} from "@material-ui/styles";
import {Theme} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import {useDispatch, useSelector} from "react-redux";
import IAppStateModel from "../../../types/AppState";
import {
    SearchMerchantsAsync,
    SetMerchantsSearch,
    SwitchMerchantStatusAsync
} from "../../../state/actions/MerchantsActions";
import IMerchant from "../../../types/Merchant";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {ImSearch} from "react-icons/all";
import {Edit} from "@material-ui/icons";
import {EditModal} from "../Components/EditModal"
import MerchantsClient from '../../../apiClient/MerchantsClient';
import ClearIcon from "@material-ui/icons/Clear";
import ChangePasswordModal from "./Modals/ChangePasswordModal";


const useStyles = makeStyles((theme: Theme) => ({

    search: {

        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        paddingTop: "1rem"

    },
    container: {
        [theme.breakpoints.down("xs")]: {
            paddingLeft: "1.2rem",
            paddingRight: "1.2rem",

        },
        paddingLeft: "3rem",
        paddingRight: "3rem",
        paddingTop: "1rem",
    },
    title: {
        fontWeight: "bold",
        fontSize: "1.5rem"
    },
    tableContainer: {
        marginTop: '2rem'
    },
    paper: {
        width: "100%",
        marginTop: "0.4rem",

        marginBottom: "1.66rem",
        padding: "1rem"
    }

}))


const Component = () => {
    const classes = useStyles();
    const {merchantsResults, search} = useSelector((state: IAppStateModel) => {
        return {
            merchantsResults: state.merchants?.results,
            search: state.merchants?.search
        }
    })
    const {t} = useTranslation("merchants")

    const dispatch = useDispatch();
    const refreshData = () => {
        dispatch(SearchMerchantsAsync());
    }
    const client = new MerchantsClient();
    const [modal, setModal] = useState(false)
    const [merchantForEdit, setMerchantForEdit] = useState(0)
    const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
    const [merchantAccountId, setMerchantAccountId] = useState<number | string>()

    const [merchantName, setMerchantName] = useState("")
    useEffect(() => {
        refreshData();
    }, [search])
    return (
        <Grid container className={classes.container}>

            {showChangePasswordModal && <ChangePasswordModal
                merchantId={merchantAccountId}
                onClose={() => {setShowChangePasswordModal(false)}}/>}

            {modal &&
            <EditModal title={t("edit")} merchant={merchantsResults?.matches?.find(x=>x.id==merchantForEdit)} client={client} id={merchantForEdit} onClose={() => setModal(false)}/>}
            <Grid container justifyContent={"space-between"} alignItems={"center"}>
                <Grid item>
                    <Typography className={classes.title}>{t("title")}</Typography>
                </Grid>
                <Button variant={"contained"} color={"secondary"}>
                    <Link to={`merchants/new`}>
                        {t("add")}
                    </Link>
                </Button>

            </Grid>
            <Paper className={classes.paper} elevation={3}>

                <Grid container>
                    <Grid item xs={12} md={4} className={classes.search}>
                        <FormControl fullWidth>
                            <Input
                                id="outlined-adornment-password"
                                value={merchantName ?? ""}
                                onChange={(i: any) => {
                                    setMerchantName(i.target.value)
                                }}
                                endAdornment={
                                    <InputAdornment position="end">
                                        {merchantName && <ClearIcon style={{cursor: "pointer"}} onClick={() => {
                                            setMerchantName("")
                                            refreshData()
                                        }
                                        }/>}
                                    </InputAdornment>
                                }
                                placeholder={t("search")}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <Box width={"100%"} display={"flex"} alignItems={"center"} justifyContent={"flex-end"}>
                    <FormControl>
                        <Button variant={"contained"} color={"primary"}
                                onClick={() => dispatch(SearchMerchantsAsync(merchantName))}>
                            {t("search")}
                            <ImSearch style={{marginLeft: "0.5rem"}}/>
                        </Button>
                    </FormControl>
                </Box>
            </Paper>

            <Grid className={classes.tableContainer} container>
                <Table
                    columns={[
                        {field: "id", name: t("id")},
                        {field: "name", name: t("name")},
                        {field: "username", name: t("username")},
                        {field: "phone", name: t("phone")},
                        {field: "commission", name: t("commission")},
                        {
                            field: "inactive",
                            name: t("status"),
                            resolveData: (row: IMerchant) => row.inactive ? t("inactive") : t("active")
                        },
                    ]}
                    data={merchantsResults?.matches ?? []}
                    limit={search?.limit!}
                    offset={search?.offset}
                    total={merchantsResults?.total}
                    onPageChange={(page: number) => dispatch(SetMerchantsSearch({
                        ...search!,
                        offset: (search!.limit! * page)
                    }))}
                    onCountChange={(count: number) => dispatch(SetMerchantsSearch({...search!, limit: count}))}
                    actions={(row: IMerchant) => <>
                        <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
                        <Button type="button" onClick={() => {
                            setMerchantForEdit(row.id)
                            setModal(true)
                        }
                        }>
                            <Edit/>
                        </Button>
                        <Button onClick={() => dispatch(SwitchMerchantStatusAsync(row.id))}
                                style={{width: '93px', backgroundColor: row.inactive ? "green" : "red"}} type="button">
                            <Typography style={{color:"white",fontSize:13}}>{row.inactive ? t("activate") : t("deActivate")}</Typography>
                        </Button>
                        <Button style={{backgroundColor: "orange", color:"white",fontSize: 13 }}
                                onClick={() => {setShowChangePasswordModal(true);
                                setMerchantAccountId(row.id)}}>
                           {t('ChangePassword')} 
                        </Button>
                        </div>

                    </>}
                />
            </Grid>
        </Grid>
    )
}


export default Component;



