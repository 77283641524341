import * as ActionsTypes from '../ActionsTypes';
import IAction from "../../types/Action";

import CookiesManager from "../../utils/CookiesManager";
import jwt from "jsonwebtoken";
import IAppStateModel from "../../types/AppState";
import UsersClient from "../../apiClient/UsersClient";
import IPagedResults, {IPagedSearch} from "../../types/Paged";
import IUser, {ICreateUserModel, IPlatformUser, ISearchPlatformUsers, ISearchUsers} from "../../types/User";
import {DispatchErrorSnackbar, DispatchSuccessSnackbar, SetIsLoading} from "./UiActions";


export const SetUser = (jwtToken: string): IAction => {
    const info = jwt.decode(jwtToken)! as { [k: string]: any };
    CookiesManager.setAccessToken(jwtToken);
    return {
        type: ActionsTypes.SET_PROFILE,
        payload: {
            id: info['UserId'],
            nameAr: info['NameAr'],
            nameEn: info['NameEn'],
            type: info['Type'],
            entityId: info['EntityId'],
            branchId: info['BranchId']
        }
    }
}


export const SearchUsers = () => {
    return async (dispatch: any, getState: any) => {
        dispatch(SetIsLoading(true))

        const state: IAppStateModel = getState();
        const usersClient = new UsersClient();
        let response = await usersClient.SearchUsersAsync(state.users?.search);

        if (!response.succeeded) {
        } else {
            dispatch(setUsers(response.data as IPagedResults<IUser>))
        }
        dispatch(SetIsLoading(false))

    };
}
export const SearchWithLookupUsers = () => {
    return async (dispatch: any, getState: any) => {
        dispatch(SetIsLoading(true))
        const state: IAppStateModel = getState();
        const usersClient = new UsersClient();
        let response = await usersClient.SearchUsersAsync(state.users?.search, true);
        if (!response.succeeded) {
        } else {
            dispatch(setUsers({matches: response.data}))
        }
        dispatch(SetIsLoading(false))
    };
}
export const SearchPlatformUsers = () => {

    return async (dispatch: any, getState: any) => {
        dispatch(SetIsLoading(true))

        const state: IAppStateModel = getState();
        const usersClient = new UsersClient();
        let response = await usersClient.SearchPlatformUsersAsync(state.platformUsers?.search);

        if (!response.succeeded) {
        } else {
            dispatch(setPlatformUsers(response.data as IPagedResults<IPlatformUser>))
        }
        dispatch(SetIsLoading(false))

    };
}
export const CreateUser = (user: ICreateUserModel) => {
    return async (dispatch: any, getState: any) => {
        dispatch(SetIsLoading(true))

        const state: IAppStateModel = getState();
        const usersClient = new UsersClient();
        let response = await usersClient.CreateUserAsync(user);

        if (response.succeeded) {
            dispatch(SearchPlatformUsers())
            dispatch(DispatchSuccessSnackbar("User Created successfully"))

        } else {
            dispatch(DispatchErrorSnackbar(response.data.REGISTRATION_FAILED))
        }
        dispatch(SetIsLoading(false))

    };
}

export const EditUser = (id: number, type: number, fromLender?: boolean) => {
    return async (dispatch: any, getState: any) => {
        dispatch(SetIsLoading(true))

        const state: IAppStateModel = getState();
        const usersClient = new UsersClient();
        let response = await usersClient.EditUserAsync(id, type);

        if (response.succeeded) {
            !fromLender && dispatch(SearchPlatformUsers())
            dispatch(DispatchSuccessSnackbar("User Edited successfully"))

        } else {
            dispatch(DispatchErrorSnackbar(response.data.REGISTRATION_FAILED))
        }
        dispatch(SetIsLoading(false))

    };
}


const setUsers = (users: IPagedResults<IUser>): IAction => {
    return {
        type: ActionsTypes.SET_USERS,
        payload: users
    }
}

export const SetUsersSearch = (search: IPagedSearch<ISearchUsers>): IAction => {
    return {
        type: ActionsTypes.SET_USERS_SEARCH,
        payload: search
    }
}


const setPlatformUsers = (users: IPagedResults<IPlatformUser>): IAction => {
    return {
        type: ActionsTypes.SET_PLATFORM_USERS,
        payload: users
    }
}

export const SetPlatformUsersSearch = (search: IPagedSearch<ISearchPlatformUsers>): IAction => {
    return {
        type: ActionsTypes.SET_PLATFORM_USERS_SEARCH,
        payload: search
    }
}
export const Logout = (): IAction => {
    CookiesManager.deleteAllCookies();
    return {
        type: ActionsTypes.DELETE_PROFILE,
        payload: {}
    }
}

