import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

export const parseDate = (date: Date|undefined): string => {

    if(!(date)){
        return ""
    }
    dayjs.extend(timezone);
    dayjs.extend(utc);
    const djs = dayjs.utc(date)
    const s: string = djs.tz("Asia/Amman").format("YYYY/MM/DD hh:mm")

    return s;
}

