import {ApiResponse, ClientFactory} from "@simplify9/simplyapiclient";
import IClient from "@simplify9/simplyapiclient/dist/Models/IClient";
import CookieManager from "../utils/CookiesManager";
import Config from '../config';
import {ICreateSubmission, IDenySubmission, IStudySubmission, ISubmitOfferModel} from "../types/Request";

class SubmissionsClient {
    client: IClient;
    // BaseUrl: string;
    locale:string;

    constructor() {
        // this.BaseUrl = `${Config.baseUrl}`;
        this.client = ClientFactory();
        this.locale = CookieManager.getLocale();
    }


    async CreateSubmissionAsync(
        model?: ICreateSubmission
    ): Promise<ApiResponse> {

        const q: string = `Submissions`
        const rs: ApiResponse = await this.client.SimplyPostAsync(q, model);
        return rs;

    }

    async AssignToBranchAsync(
        requestId:string,branchId:string
    ): Promise<ApiResponse> {

        const rs: ApiResponse = await this.client.SimplyPostAsync(`submissions/assign`, {
            requestId,branchId
        });
        return rs;

    }

    async DenyAsync(
        id: string,
        model?: IDenySubmission
    ): Promise<ApiResponse> {

        const q: string = `Submissions/${id}/deny`
        const rs: ApiResponse = await this.client.SimplyPostAsync(q, model);
        return rs;
    }

    async StudyAsync(
        id: string,
        model?: IStudySubmission
    ): Promise<ApiResponse> {

        const q: string = `Submissions/${id}/study`
        const rs: ApiResponse = await this.client.SimplyPostAsync(q, model);
        return rs;
    }

    async SendOfferAsync(
        model?: ISubmitOfferModel
    ): Promise<ApiResponse> {

        const q: string = `Submissions/offer`
        const rs: ApiResponse = await this.client.SimplyPostAsync(q, model);
        return rs;
    }


}

export default SubmissionsClient;
