import LoanView from "../../LoanView";
import PersonIcon from "@material-ui/icons/Person";
import React, {FunctionComponent} from "react";
import {ComponentRequestsSmeLoan, Maybe} from "../../../../types/content";
import {ISmeLoan} from "../../../../types/Request";
import { useTranslation } from "react-i18next";

interface IProps {
    model?: ISmeLoan;
    content: Maybe<ComponentRequestsSmeLoan> | undefined;
    onEditClick?: () => void;
}

const SmeFinancialInfoView: FunctionComponent<IProps> = ({
                                                             content,
                                                             model,
                                                             onEditClick,
                                                         }) => {
    const {t, i18n} = useTranslation("common");

    return (
        <LoanView.Card
            title={content?.stepTitle || ""}
            icon={<PersonIcon fontSize={"large"}/>}
            onEditClick={onEditClick}
        >
            <LoanView.CardSection>
                <LoanView.SectionItem
                    label={content?.isCompanySubjectToSocialSecurity || ""}
                    value={model?.isCompanySubjectToSocialSecurity?t("yes"):t("no")}
                />
                <LoanView.SectionItem
                    label={content?.numberOfEmployees || ""}
                    value={model?.numberOfEmployees?.toString() || ""}
                />
                <LoanView.SectionItem label={content?.rentValue || ""} value={model?.rentValue?.toString() || ""}/>
            </LoanView.CardSection>


            <LoanView.CardSection>
                <LoanView.SectionItem
                    label={content?.hasFinancialStatements || ""}
                    value={model?.hasFinancialStatements?t("yes"):t("no")}
                />
                <LoanView.SectionItem
                    label={content?.averageMonthlySales || ""}
                    value={model?.averageMonthlySales?.toString() || ""}
                />
                <LoanView.SectionItem
                    label={content?.averageMonthlySalaries || ""}
                    value={model?.averageMonthlySalaries?.toString() || ""}
                />
            </LoanView.CardSection>
            {/*<LoanView.CardSection>*/}
            {/*  <LoanView.SectionItem*/}
            {/*    label={content?.FinancialStatements || ""}*/}
            {/*    value={model?.hasFinancialStatements ? "Yes" : "No"}*/}
            {/*  />*/}
            {/*  <LoanView.SectionItem*/}
            {/*    label={content?.MonthlySalesRate || ""}*/}
            {/*    value={model?.averageMonthlySales?.toString() || ""}*/}
            {/*  />*/}
            {/*  <LoanView.SectionItem*/}
            {/*    label={content?.MonthlySalariesAmount || ""}*/}
            {/*    value={model?.averageMonthlySalaries?.toString() || ""}*/}
            {/*  />*/}
            {/*</LoanView.CardSection>*/}

            <LoanView.CardSection>
                <LoanView.SectionItem
                    label={content?.assetsValue || ""}
                    value={model?.assetsValue?.toString() || ""}
                />
                <LoanView.SectionItem
                    label={content?.currentStockValue || ""}
                    value={model?.currentStockValue?.toString() || ""}
                />
                <LoanView.SectionItem
                    label={content?.receivables || ""}
                    value={model?.receivables?.toString() || ""}
                />
            </LoanView.CardSection>
            <LoanView.CardSection isLastSection>
                <LoanView.SectionItem
                    label={content?.accountPayableBalance || ""}
                    value={model?.accountPayableBalance?.toString() || ""}
                />
                <LoanView.SectionItem
                    label={content?.hasBankAccount || ""}
                    value={model?.hasBankAccount?t("yes"):t("no")}
                />
            </LoanView.CardSection>
        </LoanView.Card>
    );
};

export default SmeFinancialInfoView;
