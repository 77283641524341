const dev = {
    baseUrl: "https://localhost:5001/api/",
    cmsBaseUrl: "https://cms-stg.tamweelna.me/"

}
const stg = {
    baseUrl: "https://stg.tamweelna.me/api/",
    cmsBaseUrl: "https://cms-stg.tamweelna.me/"

}

const prod = {
    baseUrl: "https://new.tamweelna.me/api/",
    cmsBaseUrl: "https://cms.tamweelna.me/"

}
export const BaseUrl = "https://stg.tamweelna.me/api/"


const config = (env: string | undefined) => {
    const stage: string = env ?? "stg"
    switch (stage) {
        case 'dev':
            return dev;
        case 'stg':
            return stg;
        case 'prod':
            return prod;
    }
}

export default {
    ...config(process?.env?.REACT_APP_STAGE)
};
