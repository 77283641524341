export interface ISearchUsers {
    name?: string;
}

export type UserType = 1 | 20 | 50 | 100
type IUserTypes = {
    Requester: UserType,
    Merchant: UserType,
    LenderEmployee: UserType,
    PlatformEmployee: UserType
}

export const UserTypes: IUserTypes = {
    Requester: 1,
    Merchant: 20,
    LenderEmployee: 50,
    PlatformEmployee: 100
}

interface IUser {
    id: number;
    name: string;
    email: string;
    phone: string;
    createdOn: Date;
    inactive: boolean;
}

export interface ICreateUserModel {
    userType?: number
    nameEn?: string;
    nameAr?: string;
    email?: string;
    phoneNumber?: string;
    password?: string
}

export interface ISearchPlatformUsers {

}

export interface IPlatformUser {
    id: number;
    name: string;
    email: string;
    phone: string;
    createdOn: Date;
    inactive: boolean;
    userType?:string
}

export default IUser;
