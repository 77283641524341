import * as ActionsTypes from '../ActionsTypes';
import IAction from "../../types/Action";
import IPagedResults, {IPagedSearch} from "../../types/Paged";
import AgentsClient from "../../apiClient/AgentsClient";
import IAgent, {ISearchAgents} from "../../types/Agents";
import {DispatchErrorSnackbar, SetIsLoading} from "./UiActions";

export const SearchAgentsAsync = (search?: IPagedSearch<ISearchAgents>) => {
    return async (dispatch: any, getState: any) => {
        dispatch(SetIsLoading(true))
        const agentsClient = new AgentsClient();
        let response = await agentsClient.Search(search);

        if (response.succeeded) {
          
                dispatch(setAgents(response.data as IPagedResults<IAgent>))


        } else {
            dispatch(DispatchErrorSnackbar())
        }
        dispatch(SetIsLoading(false))

    };
}

const setAgents = (agents: IPagedResults<IAgent>): IAction => {
    return {
        type: ActionsTypes.SET_AGENTS,
        payload: agents
    }
}